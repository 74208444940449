import React from "react";
import { Col, Row } from "antd";

import Header from "./../../../components/Header";
import MenuCard from "./../../../components/MenuCard";

const Estructura = () => {
  const breadcrumb = [
    { id: 1, title: "Administración" },
    {
      id: 2,
      title: "Estructura",
      url: "/administracion/estructura",
    },
  ];

  const items = [
    {
      id: 1,
      titulo: "Departamento Ejecutivo",
      path: "/administracion/estructura/ejecutivo",
      color: "primary",
      icono: "FiBriefcase",
    },
    {
      id: 2,
      titulo: "H.C.D",
      path: "/administracion/estructura/hcd",
      color: "primary",
      icono: "FiBook",
    },
    {
      id: 3,
      titulo: "S.E.O.S",
      path: "/administracion/estructura/seos",
      color: "primary",
      icono: "FiBookmark",
    },
  ];

  return (
    <div className="inicio">
      <Header
        title="Estructura Organizacional"
        breadcrumb={breadcrumb}
        buttons={() => {}}
      />

      <Row gutter={[32, 36]}>
        {items.map((i) => (
          <Col key={i.id} xs={12} md={8} xl={6} xxl={4}>
            <MenuCard item={i} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Estructura;
