/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { renderTagEstado } from "./Liquidacion";

import "./LiquidacionHistorial.scss";

const LiquidacionHistorial = ({ items }) => {
  return (
    <table className="liquidacion-historial">
      <thead>
        <tr>
          <th>Fecha</th>
          <th>Hora</th>
          <th>Estado</th>
          <th>Usuario</th>
        </tr>
      </thead>
      <tbody>
        {items.map((x) => (
          <tr key={x.id}>
            <td>{x.fecha}</td>
            <td>{x.hora}</td>
            <td>{renderTagEstado(x.estado)}</td>
            <td>{x.usuario}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default LiquidacionHistorial;
