/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import { Form, Rate } from "antd";
import * as FeatherIcons from "react-icons/fi";

import http from "./../services/http";

import "./MenuCard.scss";

const MenuCard = ({ item }) => {
  const [form] = Form.useForm();

  const Icon = FeatherIcons[item.icono];
  const [count, setCount] = useState(undefined);

  const onFavoriteClick = (e) => {
    e.preventDefault();
  };

  const onFavoriteChange = async () => {
    await http.post("menu/favoritos", item);
  };

  useEffect(() => {
    async function getCard() {
      const rCard = await http.post("menu/card", item);
      if (rCard && rCard.data.code === 0) {
        const response = rCard.data.data;
        setCount(response.count);
        form.setFieldsValue({ favorito: response.favourite ? 1 : 0 });
      }
    }

    getCard();
  }, [item]);

  if (item.visible !== undefined && item.visible === false) {
    return <React.Fragment></React.Fragment>;
  }

  return (
    <Link to={item.path}>
      <div className={`menu-card ${item.color}`}>
        <div className="icon">
          <Icon />
        </div>

        {count !== undefined && (
          <React.Fragment>
            <div className="count">
              <CountUp end={count} />
            </div>
            <div className="title">{item.titulo}</div>
          </React.Fragment>
        )}

        {count === undefined && <div className="title-lg">{item.titulo}</div>}

        <div className="favourite" onClick={onFavoriteClick}>
          <Form form={form}>
            <Form.Item name="favorito">
              <Rate count={1} onChange={onFavoriteChange} />
            </Form.Item>
          </Form>
        </div>
      </div>
    </Link>
  );
};

export default MenuCard;
