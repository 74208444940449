/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Form, Input, Button, Select } from "antd";
import { FiMap } from "react-icons/fi";

import { Context } from "./../../../../services/context";
import http from "./../../../../services/http";
import { required } from "./../../../../services/validation";

const { Option } = Select;

const ItemsForm = ({ item, onClose }) => {
  const [form] = Form.useForm();

  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [provincias, setProvincias] = useState([]);

  const onSave = async (values) => {
    setLoading(true);
    values.bancoId = state.id;

    const rSave =
      item.id === undefined
        ? await http.post("bancos/sucursales", values)
        : await http.put(`bancos/sucursales/${item.id}`, values);

    if (rSave && rSave.data.code === 0) {
      form.resetFields();

      setLoading(false);
      onClose();
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    async function getData() {
      const result = await http.get("bancos/data");

      if (result && result.data.code === 0) {
        const data = result.data.data;
        setProvincias(data.provincias);
      }

      form.setFieldsValue(item);
    }

    getData();
  }, []);

  return (
    <Form form={form} name="form-sucursal" layout="vertical" onFinish={onSave}>
      <div className="form">
        <div className="form-icon">
          <FiMap />
        </div>

        <Form.Item label="Detalle" name="detalle" rules={[required]}>
          <Input />
        </Form.Item>

        <Form.Item label="Sucursal" name="sucursal" rules={[required]}>
          <Input />
        </Form.Item>

        <Form.Item label="Código" name="codigo" rules={[required]}>
          <Input type="number" />
        </Form.Item>

        <Form.Item label="Provincia" name="provinciaId">
          <Select allowClear showSearch optionFilterProp="children">
            {provincias.map((x) => (
              <Option key={x.value} value={x.value} disabled={x.disabled}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <div className="form-actions">
          <Button type="secondary" onClick={onClose}>
            Volver
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            Guardar
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default ItemsForm;
