/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Button, Form, Menu, Modal, Select } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { FiCalendar, FiEdit, FiPlus, FiTrash2 } from "react-icons/fi";

import { ContextProvider } from "../../../../services/context";
import http from "../../../../services/http";
import { showSuccess, showWarning } from "../../../../services/notificacion";
import { hasAction, actions } from "../../../../services/security";

import Periodo from "../Periodo";
import Header from "../../../../components/Header";
import Table from "../../../../components/Table";

const Deducciones = () => {
  const history = useHistory();

  const VER = hasAction(actions.DEDUCCIONES_VER);
  const CREAR = hasAction(actions.DEDUCCIONES_CREAR);
  const EDITAR = hasAction(actions.DEDUCCIONES_EDITAR);
  const ELIMINAR = hasAction(actions.DEDUCCIONES_ELIMINAR);

  const [state, setState] = useState({
    filters: {
      mes: "",
    },
    columns: [
      {
        id: 1,
        title: "Código",
        style: {},
        render: (item) => item.codigo,
      },
      {
        id: 2,
        title: "Nombre",
        style: {},
        render: (item) => item.nombre,
      },
      {
        id: 3,
        title: "Valor",
        style: {},
        render: (item) => item.valor,
      },
    ],
    loading: true,
    loaded: true,
    reload: true,
    list: [],
    total: 0,
    page: 1,
    size: 10,
  });

  const breadcrumb = [
    { id: 1, title: "Liquidación" },
    {
      id: 2,
      title: "Ganancias",
      url: "/liquidacion/ganancias",
    },
    {
      id: 3,
      title: "Deducciones",
      url: "/liquidacion/ganancias/deducciones",
    },
  ];

  const buttons = () => {
    return (
      <React.Fragment>
        <Button type="secondary" icon={<FiCalendar />} onClick={onClickPeriodo}>
          Período
        </Button>
        {CREAR && (
          <Button type="primary" icon={<FiPlus />} onClick={onAdd}>
            Nuevo
          </Button>
        )}
      </React.Fragment>
    );
  };

  function validator(values) {
    const mes = values.mes;
    if (parseInt(mes) > 12) {
      showWarning("El mes debe ser menor o igual a 12");
      return false;
    }
    if (parseInt(mes) < 1) {
      showWarning("El mes debe ser mayor o igual a 1");
      return false;
    }
    return true;
  }

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Mes" name="mes">
          <Select allowClear>
            <Select.Option value="1">Enero</Select.Option>
            <Select.Option value="2">Febrero</Select.Option>
            <Select.Option value="3">Marzo</Select.Option>
            <Select.Option value="4">Abril</Select.Option>
            <Select.Option value="5">Mayo</Select.Option>
            <Select.Option value="6">Junio</Select.Option>
            <Select.Option value="7">Julio</Select.Option>
            <Select.Option value="8">Agosto</Select.Option>
            <Select.Option value="9">Septiembre</Select.Option>
            <Select.Option value="10">Octubre</Select.Option>
            <Select.Option value="11">Noviembre</Select.Option>
            <Select.Option value="12">Diciembre</Select.Option>
          </Select>
        </Form.Item>
      </React.Fragment>
    );
  };

  const menu = (item) => {
    return (
      <Menu className="table-actions-menu">
        {EDITAR && (
          <Menu.Item key="0" onClick={() => onEdit(item)}>
            <FiEdit />
            Editar
          </Menu.Item>
        )}
        {ELIMINAR && (
          <Menu.Item key="1" onClick={() => onDelete(item)}>
            <FiTrash2 />
            Eliminar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const onClickPeriodo = () => {
    let nState = { ...state };
    nState.filters.visible = !nState.filters.visible;
    setState(nState);
  };

  const onAdd = () => {
    history.push("/liquidacion/ganancias/deducciones/nuevo");
  };

  const onEdit = (item) => {
    history.push(`/liquidacion/ganancias/deducciones/${item.id}`);
  };

  const onDelete = (item) => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea eliminar este deducción?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const rDelete = await http.delete(`deducciones/${item.id}`);
        if (rDelete && rDelete.data.code === 0) {
          showSuccess("La deducción fue eliminado exitosamente.");
          setState({ ...state, reload: !state.reload });
        }
      },
    });
  };

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="deducciones page">
      <ContextProvider value={[state, setState]}>
        <Header title="Deducciones" breadcrumb={breadcrumb} buttons={buttons} />
        <Periodo
          filters={filters}
          buttonName={"Calcular"}
          title={"Período"}
          validator={validator}
        />
        <Table url="deducciones/list" menu={menu} />
      </ContextProvider>
    </div>
  );
};

export default Deducciones;
