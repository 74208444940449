/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Modal, Row } from "antd";
import { FiPercent } from "react-icons/fi";

import http from "./../../../services/http";
import { showSuccess } from "./../../../services/notificacion";

import { required } from "../../../services/validation";
import { formatMonth } from "./../../../services/forms";

const AcumuladoForm = (props) => {
  const { item, onSave, onClose } = props;

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.resetFields();

    if (!item) {
      return;
    }

    let nItem = { ...item };
    nItem.mes = formatMonth(item.mes);

    form.setFieldsValue(nItem);
  }, [item]);

  const onSubmit = async (values) => {
    setLoading(true);

    values.empleadoId = item.empleadoId;
    values.mes = item.mes;

    const response = await http.post("acumulado", values);

    if (response && response.data.code === 0) {
      showSuccess("Los valores fueron actualizados exitosamente.");
      onSave();
      onClose();
    }

    setLoading(false);
  };

  return (
    <Modal
      visible={item}
      forceRender
      footer={null}
      maskClosable={false}
      keyboard={false}
      destroyOnClose={true}
      onCancel={onClose}
    >
      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <div className="form">
          <div className="form-icon">
            <FiPercent />
          </div>

          <Form.Item label="Empleado" name="empleado">
            <Input readOnly />
          </Form.Item>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Mes" name="mes">
                <Input readOnly />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Año" name="anio">
                <Input readOnly />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Haberes Sujetos" name="haberes">
            <Input readOnly />
          </Form.Item>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Días" name="dias">
                <Input readOnly />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Días Ingresados"
                name="diasIngresados"
                rules={[required]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>

          <div className="form-actions">
            <Button type="secondary" onClick={onClose}>
              Cancelar
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              {!loading && "Guardar"}
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default AcumuladoForm;
