/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Form, Input, Button, Select, DatePicker, Row, Col, Modal } from "antd";
import { FiPaperclip, FiTrendingDown } from "react-icons/fi";

import locale from "antd/es/date-picker/locale/es_ES";
import "moment/locale/es";

import { parseDate, parseToDate } from "./../../../../services/forms";
import http from "./../../../../services/http";
import { showSuccess } from "./../../../../services/notificacion";
import { hasAction, actions } from "./../../../../services/security";
import { required } from "./../../../../services/validation";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import Header from "./../../../../components/Header";
import { ContextProvider } from "../../../../services/context";
import ArchivosConcursos from "./Archivos";

const { TextArea } = Input;
const { Option } = Select;

const ConcursosQuiebrasForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const VER = hasAction(actions.CONCURSOS_QUIEBRAS_VER);
  const CREAR = hasAction(actions.CONCURSOS_QUIEBRAS_CREAR);
  const EDITAR = hasAction(actions.CONCURSOS_QUIEBRAS_EDITAR);
  const GUARDAR = match.params.id === "nuevo" ? CREAR : EDITAR;

  const [state, setState] = useState({
    title: match.params.id === "nuevo" ? "Crear" : EDITAR ? "Editar" : "Ver",
    id: match.params.id === "nuevo" ? 0 : parseInt(match.params.id),
    loading: false,
    historial: [],
  });

  const [empleados, setEmpleados] = useState([]);
  const [tipos, setTipos] = useState([]);

  const breadcrumb = [
    { id: 1, title: "Novedades" },
    {
      id: 2,
      title: "Liquidación",
      url: "/novedades/liquidacion",
    },
    {
      id: 3,
      title: "Concursos & Quiebras",
      url: "/novedades/liquidacion/concursos_quiebras",
    },
    { id: 4, title: state.title },
  ];

  const onSave = async (values) => {
    Modal.confirm({
      title: "Confirmar actualización",
      icon: <ExclamationCircleOutlined />,
      content:
        "En caso de que el usuario tenga Embargos, se suspenderan todos, sin importar si estan inciados ¿Desea Continuar?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        setState({ ...state, loading: true });

        values.inicio = parseDate(values.inicio);
        values.fin = parseDate(values.fin);
        values.fecha = parseDate(values.fecha);

        const result =
          state.id === 0
            ? await http.post("concursosquiebras", values)
            : await http.put(`concursosquiebras/${state.id}`, values);

        if (result && result.data.code === 0) {
          showSuccess("El concurso/quiebra fue guardado exitosamente.");
          history.push("/novedades/liquidacion/concursos_quiebras");
        } else {
          setState({ ...state, loading: false });
        }
      },
    });
  };

  useEffect(() => {
    async function getForm() {
      const response = await http.get("concursosquiebras/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setEmpleados(data.empleados);
        setTipos(data.tipos);
      }

      const rForm = await http.get(`concursosquiebras/${state.id}`);
      if (rForm && rForm.data.code === 0) {
        const data = rForm.data.data;
        data.inicio = parseToDate(data.inicio);
        data.fin = parseToDate(data.fin);
        data.fecha = parseToDate(data.fecha);
        form.setFieldsValue(data);

        setState({ ...state, historial: data.historial });
      }
    }

    if (VER) {
      getForm();
    }
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <ContextProvider value={[state, setState]}>
      <div className="concursoquiebra">
        <Header
          title={`${state.title} Concurso / Quiebra`}
          breadcrumb={breadcrumb}
        />
        <div className="form card">
          <Form form={form} layout="vertical" onFinish={onSave}>
            <div className="form-icon">
              <FiTrendingDown />
            </div>

            <Row gutter={20}>
              <Col span="12">
                <Form.Item label="Empleado" name="empleado" rules={[required]}>
                  <Select
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    disabled={!GUARDAR}
                  >
                    {empleados.map((x) => (
                      <Option key={x.value} value={x.value}>
                        {x.text}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span="12">
                <Form.Item label="Tipo" name="tipo" rules={[required]}>
                  <Select allowClear disabled={!GUARDAR}>
                    {tipos.map((x) => (
                      <Option key={x.value} value={x.value}>
                        {x.text}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span="8">
                <Form.Item label="Fecha" name="fecha" rules={[required]}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    placeholder=""
                    locale={locale}
                    disabled={true}
                  />
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item label="Inicio" name="inicio" rules={[required]}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    placeholder=""
                    locale={locale}
                    disabled={!GUARDAR}
                  />
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item label="Fin" name="fin">
                  <DatePicker
                    format="DD/MM/YYYY"
                    placeholder=""
                    locale={locale}
                    disabled={!GUARDAR}
                  />
                </Form.Item>
              </Col>

              <Col span="12">
                <Form.Item label="Oficio" name="oficio">
                  <Input type="number" disabled={!GUARDAR} />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item label="Auto" name="auto">
                  <Input disabled={!GUARDAR} />
                </Form.Item>
              </Col>

              <Col span="24">
                <Form.Item label="Juzgado" name="juzgado">
                  <Input disabled={!GUARDAR} />
                </Form.Item>
              </Col>

              <Col span="24">
                <Form.Item label="Observaciones" name="observaciones">
                  <TextArea maxLength={500} disabled={!GUARDAR} showCount />
                </Form.Item>
              </Col>
            </Row>

            <div className="form-actions">
              <Link to="/novedades/liquidacion/concursos_quiebras">
                <Button type="secondary">Volver</Button>
              </Link>
              {GUARDAR && (
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={state.loading}
                >
                  Guardar
                </Button>
              )}
            </div>
          </Form>
          {state.id > 0 && (
            <React.Fragment>
              <hr />
              <div className="section-header">
                <div className="section-title">
                  Documentación
                  <FiPaperclip />
                </div>
                <div className="section-subtitle">
                  Documentación asociada con al concurso.
                </div>
              </div>
              <ArchivosConcursos concursoQuiebra={state.id} />
            </React.Fragment>
          )}
        </div>
      </div>
    </ContextProvider>
  );
};

export default ConcursosQuiebrasForm;
