/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import locale from "antd/es/date-picker/locale/es_ES";
import "moment/locale/es";

import { Context } from "../../../services/context";
import { parseDate, parseToDate } from "../../../services/forms";
import http from "../../../services/http";
import { showSuccess } from "../../../services/notificacion";
import { required } from "../../../services/validation";

const { TextArea } = Input;
const { Option } = Select;

const ExperienciasForm = ({ current, onCurrentChange }) => {
  const [form] = Form.useForm();
  const [state, setState] = useContext(Context);

  const onBack = () => {
    onCurrentChange(null, null);
  };

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    values.empleadoId = state.id;
    values.desde = parseDate(values.desde);
    values.hasta = parseDate(values.hasta);

    const rSave =
      current === 0
        ? await http.post("legajos/experiencias", values)
        : await http.put(`legajos/experiencias/${current}`, values);

    if (rSave && rSave.data.code === 0) {
      showSuccess("El antecedente fue guardado exitosamente.");
      setState({ ...state, loading: false });
      onCurrentChange(null, null);
    } else {
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {
    async function getForm() {
      form.resetFields();

      const rForm = await http.get(`legajos/experiencias/${current}`);
      if (rForm && rForm.data.code === 0) {
        const data = rForm.data.data;
        data.form.desde = parseToDate(data.form.desde);
        data.form.hasta = parseToDate(data.form.hasta);
        form.setFieldsValue(data.form);
      }
    }

    getForm();
  }, [state.id, state.reload]);

  return (
    <div className="experiencias-form ">
      <Form
        form={form}
        className="subsection-form"
        layout="vertical"
        onFinish={onSave}
      >
        <Row gutter={20}>
          <Col span="12">
            <Form.Item label="Empresa" name="empresa" rules={[required]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item label="Dirección" name="direccion">
              <Input />
            </Form.Item>
          </Col>

          <Col span="14">
            <Form.Item label="Puesto" name="puesto" rules={[required]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span="5">
            <Form.Item label="Desde" name="desde" rules={[required]}>
              <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
            </Form.Item>
          </Col>
          <Col span="5">
            <Form.Item label="Hasta" name="hasta">
              <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
            </Form.Item>
          </Col>

          <Col span="24">
            <Form.Item label="Antigüedad" name="antiguedad" rules={[required]}>
              <Select allowClear optionFilterProp="children">
                <Option value="1">Si</Option>
                <Option value="0">No</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span="24">
            <Form.Item label="Motivo" name="motivo">
              <Input />
            </Form.Item>
          </Col>

          <Col span="24">
            <Form.Item
              label="Comentarios"
              name="comentarios"
              className="textarea"
            >
              <TextArea maxLength={2000} showCount rows={4} />
            </Form.Item>
          </Col>
        </Row>

        <div className="form-actions">
          <Button type="secondary" onClick={onBack}>
            Volver
          </Button>
          <Button type="primary" htmlType="submit" loading={state.loading}>
            Guardar
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ExperienciasForm;
