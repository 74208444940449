/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Form, Input, Button, Select } from "antd";
import { FiBookmark } from "react-icons/fi";

import { Context } from "../../../../services/context";
import http from "../../../../services/http";
import { required } from "../../../../services/validation";

const { Option } = Select;

const ItemsForm = ({ item, onClose }) => {
  const [form] = Form.useForm();

  const [state, setState] = useContext(Context);
  const [campos, setCampos] = useState([]);

  const onSave = async (values) => {
    const campo = {
      index: state.campos.indexOf(item),
      campo: values.campo,
      nombre: campos.find((x) => x.value === values.campo).text,
      inicio: values.inicio,
      fin: values.fin,
    };

    let nState = { ...state };

    if (campo.index === -1) {
      nState.campos.push(campo);
    } else {
      nState.campos[campo.index] = campo;
    }

    onClose();
    setState(nState);
  };

  useEffect(() => {
    async function getData() {
      const result = await http.get("importacion/formatos/data");

      if (result && result.data.code === 0) {
        const data = result.data.data;
        setCampos(data.campos);
      }

      form.setFieldsValue(item);
    }

    getData();
  }, []);

  return (
    <Form form={form} name="form-sucursal" layout="vertical" onFinish={onSave}>
      <div className="form">
        <div className="form-icon">
          <FiBookmark />
        </div>

        <Form.Item label="Campo" name="campo">
          <Select allowClear showSearch optionFilterProp="children">
            {campos.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Inicio" name="inicio" rules={[required]}>
          <Input />
        </Form.Item>

        <Form.Item label="Fin" name="fin" rules={[required]}>
          <Input />
        </Form.Item>

        <div className="form-actions">
          <Button type="secondary" onClick={onClose}>
            Volver
          </Button>
          <Button type="primary" htmlType="submit">
            Guardar
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default ItemsForm;
