import React from "react";
import { Col, Row } from "antd";

import { hasAction, actions } from "./../../../services/security";

import Header from "./../../../components/Header";
import MenuCard from "./../../../components/MenuCard";

const Medicina = () => {
  const breadcrumb = [
    {
      id: 1,
      title: "Parámetros",
    },
    {
      id: 2,
      title: "Medicina",
      url: "/parametros/medicina",
    },
  ];

  const items = [
    {
      id: 1,
      titulo: "Tipos de Accidentes",
      path: "/parametros/medicina/tipos_accidentes",
      color: "primary",
      icono: "FiGrid",
      entidad: "TipoAccidente",
      visible: hasAction(actions.TIPOSACCIDENTES_VER),
    },
    {
      id: 2,
      titulo: "Motivos de Accidentes",
      path: "/parametros/medicina/motivos_accidentes",
      color: "primary",
      icono: "FiGrid",
      entidad: "MotivoAccidente",
      visible: hasAction(actions.MOTIVOACCIDENTE_VER),
    },

    {
      id: 3,
      titulo: "Diagnosticos",
      path: "/parametros/medicina/diagnosticos",
      color: "primary",
      icono: "FiGrid",
      entidad: "Diagnostico",
      visible: hasAction(actions.DIAGNOSTICO_VER),
    },
  ];

  return (
    <div className="inicio">
      <Header title="Medicina" breadcrumb={breadcrumb} buttons={() => {}} />

      <Row gutter={[32, 36]}>
        {items.map((i) => (
          <Col key={i.id} xs={12} md={8} xl={6} xxl={4}>
            <MenuCard item={i} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Medicina;
