/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { Redirect } from "react-router-dom";
import { Button, Dropdown, Menu } from "antd";
import { FiPlus, FiPrinter } from "react-icons/fi";
import { ImFilePdf, ImFilePicture } from "react-icons/im";

import { ContextProvider } from "../../../../../services/context";
import http from "../../../../../services/http";
import { hasAction, actions } from "../../../../../services/security";

import Header from "../../../../../components/Header";
import Organigrama from "../../../../../components/Organigrama";
import OrganigramaForm from "../../../../../components/OrganigramaForm";

const Tradicional = () => {
  const VER = hasAction(actions.ORGANIGRAMA_VER);
  const CREAR = hasAction(actions.ORGANIGRAMA_CREAR);

  const [state, setState] = useState({
    refChart: useRef(),
    loading: false,
    reload: false,
    tipo: 2,
    funciones: [],
    cargo: null,
    chart: {},
    current: null,
    form: false,
  });

  const breadcrumb = [
    { id: 1, title: "Administración" },
    {
      id: 2,
      title: "Estructura",
      url: "/administracion/estructura",
    },
    {
      id: 3,
      title: "HCD",
      url: "/administracion/estructura/hcd",
    },
    {
      id: 4,
      title: "Tradicional",
      url: "/administracion/estructura/hcd/tradicional",
    },
  ];

  const buttons = () => {
    return (
      <React.Fragment>
        <Dropdown overlay={menuExport} placement="bottomLeft" arrow>
          <Button type="secondary" icon={<FiPrinter />}>
            Exportar
          </Button>
        </Dropdown>

        {CREAR && (
          <Button type="primary" icon={<FiPlus />} onClick={onAdd}>
            Nuevo
          </Button>
        )}
      </React.Fragment>
    );
  };

  const onExportImage = () => {
    state.refChart.current.exportTo("HCD", "png");
  };

  const onExportPdf = () => {
    state.refChart.current.exportTo("HCD", "pdf");
  };

  const menuExport = (
    <Menu className="table-actions-menu">
      <Menu.Item key="1" icon={<ImFilePicture />} onClick={onExportImage}>
        .png
      </Menu.Item>
      <Menu.Item key="2" icon={<ImFilePdf />} onClick={onExportPdf}>
        .pdf
      </Menu.Item>
    </Menu>
  );

  const onAdd = () => {
    setState({ ...state, current: {}, form: true });
  };

  useEffect(() => {
    async function getChart() {
      let nState = { ...state };

      const response = await http.get(`organigrama/data?tipo=${state.tipo}`);
      if (response && response.data.code === 0) {
        const data = response.data.data;
        nState.funciones = data.funciones;
      }

      const rChart = await http.get(
        `organigrama?id=${state.cargo}&tipo=${state.tipo}`
      );
      if (rChart && rChart.data.code === 0) {
        const data = rChart.data.data;
        nState.chart = data;
      }

      setState(nState);
    }

    getChart();
  }, [state.cargo, state.reload]);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="tradicional">
      <Header
        title="Organigrama Tradicional"
        breadcrumb={breadcrumb}
        buttons={buttons}
      />
      <ContextProvider value={[state, setState]}>
        <Organigrama />
        <OrganigramaForm />
      </ContextProvider>
    </div>
  );
};

export default Tradicional;
