import React from "react";
import { Col, Row } from "antd";

import { hasAction, actions } from "./../../../services/security";

import Header from "./../../../components/Header";
import MenuCard from "./../../../components/MenuCard";

const Estructura = () => {
  const breadcrumb = [
    {
      id: 1,
      title: "Parámetros",
    },
    {
      id: 2,
      title: "Estructura",
      url: "/parametros/estructura",
    },
  ];

  const items = [
    {
      id: 1,
      titulo: "Agrupamientos",
      path: "/parametros/estructura/agrupamientos",
      color: "primary",
      icono: "FiGrid",
      entidad: "Agrupamiento",
      visible: hasAction(actions.AGRUPAMIENTOS_VER),
    },
    {
      id: 2,
      titulo: "Cantidad de Cargos",
      path: "/parametros/estructura/agrupamientos_clases",
      color: "primary",
      icono: "FiGrid",
      entidad: "AgrupamientoClase",
      visible: hasAction(actions.AGRUPAMIENTOSCLASES_VER),
    },
    {
      id: 3,
      titulo: "Cargos Sindicales",
      path: "/parametros/estructura/cargos_sindicales",
      color: "primary",
      icono: "FiList",
      entidad: "CargoSindical",
      visible: hasAction(actions.CARGOSSINDICALES_VER),
    },
    {
      id: 4,
      titulo: "Direcciones",
      path: "/parametros/estructura/direcciones",
      color: "primary",
      icono: "FiBookmark",
      entidad: "Direccion",
      visible: hasAction(actions.DIRECCIONES_VER),
    },
    {
      id: 5,
      titulo: "Lugares de Trabajo",
      path: "/parametros/estructura/lugares_trabajo",
      color: "primary",
      icono: "FiMap",
      entidad: "LugarTrabajo",
      visible: hasAction(actions.LUGARTRABAJOS_VER),
    },
    {
      id: 6,
      titulo: "Relojes Biométricos",
      path: "/parametros/estructura/relojes_biometricos",
      color: "primary",
      icono: "FiClock",
      entidad: "RelojBiometrico",
      visible: hasAction(actions.RELOJESBIOMETRICOS_VER),
    },
    {
      id: 7,
      titulo: "Secretarias",
      path: "/parametros/estructura/secretarias",
      color: "primary",
      icono: "FiTag",
      entidad: "Secretaria",
      visible: hasAction(actions.SECRETARIAS_VER),
    },
    {
      id: 8,
      titulo: "Subdirecciones",
      path: "/parametros/estructura/subdirecciones",
      color: "primary",
      icono: "FiBookmark",
      entidad: "Subdireccion",
      visible: hasAction(actions.SUBDIRECCIONES_VER),
    },
  ];

  return (
    <div className="inicio">
      <Header title="Estructura" breadcrumb={breadcrumb} buttons={() => {}} />

      <Row gutter={[32, 36]}>
        {items.map((i) => (
          <Col key={i.id} xs={12} md={8} xl={6} xxl={4}>
            <MenuCard item={i} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Estructura;
