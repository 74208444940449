/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { FiUsers } from "react-icons/fi";

import { Context } from "./../../../services/context";
import http from "./../../../services/http";

const FamiliaresResumen = () => {
  // const [form] = Form.useForm(); TODO sacar
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useContext(Context);
  const [asignaciones, setAsignaciones] = useState({
    conyuge: 0,
    hijos: 0,
    hijosDiscapacitados:0,
    numerosa: 0,
    primaria: 0,
    superior: 0,
    cargo: 0,
    prenatal: 0,
    prescolar: 0,
    prescolarDiscapacitado: 0,
    primariaDiscapacitados: 0,
    superiorDiscapacitados: 0,
  });

  useEffect(() => {
    async function getForm() {
      // form.resetFields(); TODO sacar

      const rForm = await http.get(
        `legajos/familiares/resumen?empleado=${state.id}`
      );
      if (rForm && rForm.data.code === 0) {
        const data = rForm.data.data;
        // form.setFieldsValue(data.form); TODO sacar
        setAsignaciones({
          conyuge: data.form.asignacionConyuge,
          hijos: data.form.asignacionHijos,
          hijosDiscapacitados: data.form.asignacionHijosDiscapacitados,
          numerosa: data.form.asignacionNumerosa,
          primaria: data.form.asignacionPrimaria,
          superior: data.form.asignacionSuperior,
          cargo: data.form.asignacionCargo,
          prescolar: data.form.asignacionPrescolar,
          prenatal: data.form.asignacionPrenatal,
          prescolarDiscapacitado: data.form.asignacionPrescolarDiscapacitados,
          primariaDiscapacitados: data.form.asignacionPrimariaDiscapacitados,
          superiorDiscapacitados: data.form.asignacionSuperiorDiscapacitados,
        });
      }
    }

    getForm();
  }, [state.id, state.reload]);

  return (
    <div className="familiares-resumen">
      {/* <div className="section-header"> TODO Sacar cuando este seguro
        <div className="section-title">
          Grupo Familiar
          <FiUsers />
        </div>
        <div className="section-subtitle">Grupo familiar del empleado</div>
      </div>

      <Form form={form} className="subsection-form" layout="vertical">
        <Row gutter={20}>
          <Col span="12">
            <Form.Item label="Estado Civil" name="estadoCivil">
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item label="Cobra Asignación" name="cobraAsignacion">
              <Input disabled={true} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span="6">
            <Form.Item label="Hijos" name="hijos">
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col span="6">
            <Form.Item label="Cantidad" name="hijosCantidad">
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col span="6">
            <Form.Item label="Cobra Asignación Hijos" name="hijosAsignacion">
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col span="6">
            <Form.Item label="Cantidad" name="hijosAsignacionCantidad">
              <Input disabled={true} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span="12">
            <Form.Item label="Discapacitado" name="discapacitado">
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item label="Cantidad" name="discapacitadoCantidad">
              <Input disabled={true} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span="12">
            <Form.Item label="Escolaridad" name="escolaridad">
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item label="Cantidad" name="escaloridadCantidad">
              <Input disabled={true} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span="24">
            <Form.Item label="Carga de Familia" name="cargaFamilia">
              <Input disabled={true} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
*/}
      <div className="section-header"> 
        <div className="section-title">
          Asignaciones Familiares
          <FiUsers />
        </div>
        <div className="section-subtitle">
          Asignaciones correspondientes al grupo familiar del empleado.
        </div>
      </div>

      <table className="table-asignaciones subsection-table">
        <thead>
          <tr>
            <th>Código</th>
            <th>Detalle</th>
            <th>Cantidad</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>01</td>
            <td>Cónyuge</td>
            <td>{asignaciones.conyuge}</td>
          </tr>
          <tr>
            <td>02</td>
            <td>Hijos</td>
            <td>{asignaciones.hijos}</td>
          </tr>
          <tr>
            <td>00</td>
            <td>Hijos discapacitados</td>
            <td>{asignaciones.hijosDiscapacitados}</td>
          </tr>
          <tr>
            <td>05</td>
            <td>Familia Numerosa</td>
            <td>{asignaciones.numerosa}</td>
          </tr>
          <tr>
            <td>04</td>
            <td>Prenatal</td>
            <td>{asignaciones.prenatal}</td>
          </tr>
          <tr>
            <td>00</td>
            <td>Escolaridad Prescolar</td>
            <td>{asignaciones.prescolar}</td>
          </tr>
          <tr>
            <td>00</td>
            <td>Escolaridad Prescolar Discapacitados</td>
            <td>{asignaciones.prescolarDiscapacitado}</td>
          </tr>
          <tr>
            <td>08</td>
            <td>Escolaridad Primaria</td>
            <td>{asignaciones.primaria}</td>
          </tr>
          <tr>
            <td>00</td>
            <td>Escolaridad Primaria Discapacitados</td>
            <td>{asignaciones.primariaDiscapacitados}</td>
          </tr>
          <tr>
            <td>10</td>
            <td>Escolaridad Media - Superior</td>
            <td>{asignaciones.superior}</td>
          </tr>
          <tr>
            <td>00</td>
            <td>Escolaridad Media - Superior Discapacitados</td>
            <td>{asignaciones.superiorDiscapacitados}</td>
          </tr>
          <tr>
            <td>16</td>
            <td>Familiar a Cargo</td>
            <td>{asignaciones.cargo}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default FamiliaresResumen;
