/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Row,
  Col,
  Switch,
} from "antd";
import {
  FiActivity,
  FiAlertTriangle,
  FiPaperclip,
  FiPrinter,
} from "react-icons/fi";

import moment from "moment";
import locale from "antd/es/date-picker/locale/es_ES";
import "moment/locale/es";

import http from "../../../../services/http";
import { showSuccess, showWarning } from "../../../../services/notificacion";
import { hasAction, actions } from "../../../../services/security";
import { required } from "../../../../services/validation";

import Header from "../../../../components/Header";
import NovedadesConcursoQuiebra from "./NovedadesConcursoQuiebra";
import DataInfo from "../../../../components/DataInfo";
import Archivos from "./Archivos";
import Historial from "./Historial";
import fileDownload from "js-file-download";

const { Option } = Select;

const EmbargoForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const VER = hasAction(actions.EMBARGO_VER);
  const CREAR = hasAction(actions.EMBARGO_CREAR);
  const EDITAR = hasAction(actions.EMBARGO_EDITAR);
  const GUARDAR = match.params.id === "nuevo" ? CREAR : EDITAR;

  const [state, setState] = useState({
    title:
      match.params.id === "nuevo" ? "Registrar" : EDITAR ? "Editar" : "Ver",
    id: match.params.id === "nuevo" ? 0 : parseInt(match.params.id),
    loading: false,
    consursosQuiebras: [],
    historial: [],
  });

  const [sucursales, setSucursales] = useState([]);
  const [conceptos, setConceptos] = useState([]);
  const [estados, setEstados] = useState([]);
  const [empleados, setEmpleados] = useState([]);
  const [aceptaCuota, setAceptaCuota] = useState(false);
  const [concursoQuiebraVisible, setConsursoQuiebraVisible] = useState(false);

  const breadcrumb = [
    { id: 1, title: "Novedades" },
    {
      id: 2,
      title: "Liquidación",
      url: "/novedades/liquidacion",
    },
    {
      id: 3,
      title: "Embargos",
      url: "/novedades/liquidacion/embargo",
    },
    { id: 4, title: state.title },
  ];
  const onConcursoQuiebra = async () => {
    const empleado = form.getFieldValue("empleadoId");
    if (empleado) {
      setState({ ...state, loading: true });
      const rList = await http.post("concursosquiebras/list", {
        page: 0,
        size: 1000000,
        empleado: empleado,
        desde: "",
        hasta: "",
      });
      if (rList && rList.data.code === 0) {
        const data = rList.data.data;
        setState({
          ...state,
          consursosQuiebras: data.list,
          loading: false,
        });
      } else {
        setState({ ...state, loading: false });
      }
      setConsursoQuiebraVisible(true);
    } else {
      showWarning("Debe seleccionar un empleado");
    }
  };

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    const result =
      state.id === 0
        ? await http.post("Embargo", values)
        : await http.put(`Embargo/${state.id}`, values);

    if (result && result.data.code === 0) {
      showSuccess("El embargo fue guardado exitosamente.");
      history.push("/novedades/liquidacion/embargo");
    } else {
      setState({ ...state, loading: false });
    }
  };
  function handleChange(obj) {
    if (obj && obj.tipo && obj.tipo === "C") {
      // resetear cuota
      setAceptaCuota(true);
      form.setFieldsValue({ monto: null });
    } else {
      setAceptaCuota(false);
      form.setFieldsValue({ cuotas: null });
    }
  }
  const onChangeCantidadFecha = () => {
    const cant = form.getFieldValue("cantidad");
    const type = form.getFieldValue("formaEmbargo");
    const init = form.getFieldValue("fechaInicio");
    if (type === "1" && cant && init) {
      form.setFieldsValue({ fechaFin: moment(init).add(cant, "M") });
    }
    if (type === "2" && cant > 100) {
      form.setFieldsValue({ cantidad: 100 });
    }
  };
  const onExport = async () => {
    const rExport = await http.post(
      "embargo/ExportHistorial/" + state.id,
      state.filters,
      true
    );

    fileDownload(rExport.data, `HistorialEmbargo${Date.now()}.xlsx`);
  };

  useEffect(() => {
    async function getData() {
      const response = await http.get("Embargo/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setConceptos(data.conceptos);
        setEmpleados(data.empleados);
        setEstados(data.estados);
        setSucursales(data.sucursales);
      }
    }
    getData();
    async function getForm() {
      const rForm = await http.get(`Embargo/${state.id}`);
      if (rForm && rForm.data.code === 0) {
        const data = rForm.data.data;
        if (data.fechaInicio) data.fechaInicio = moment(data.fechaInicio);

        if (data.fechaFin) data.fechaFin = moment(data.fechaFin);
        if (data.cuotas) {
          setAceptaCuota(true);
        } else {
          setAceptaCuota(false);
        }

        form.setFieldsValue(data);
        setState({ ...state, historial: data.historial });
      }
    }
    if (VER) {
      getForm();
    }
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="alimentarios">
      <Header title={`${state.title} Embargo`} breadcrumb={breadcrumb} />
      <div className="form card">
        <Form form={form} layout="vertical" onFinish={onSave}>
          <div className="">
            <div className="form-icon">
              <FiAlertTriangle />
            </div>
            <DataInfo
              visible={concursoQuiebraVisible}
              modalContent={
                <NovedadesConcursoQuiebra items={state.consursosQuiebras} />
              }
              onOpen={() => onConcursoQuiebra()}
              onClose={() => setConsursoQuiebraVisible(false)}
              title="Concursos y Quiebras"
              className="historial-trigger"
            >
              <Form.Item label="Empleado" name="empleadoId" rules={[required]}>
                <Select showSearch allowClear optionFilterProp="children">
                  {empleados.map((m) => (
                    <Option key={m.value} value={m.value}>
                      {m.text}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </DataInfo>
            <Row gutter={20}>
              <Col span="24">
                <Form.Item
                  label="Concepto"
                  name="conceptoId"
                  rules={[required]}
                >
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    onChange={(text, index) => {
                      handleChange(index);
                    }}
                  >
                    {conceptos.map((m) => (
                      <Option key={m.value} value={m.value} tipo={m.extra}>
                        {m.text}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span="24">
                <Form.Item label="Juzgado" name="juzgado">
                  <Input disabled={!GUARDAR} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span="12">
                <Form.Item label="Acción" name="accion">
                  <Input disabled={!GUARDAR} />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item label="Deposito" name="deposito">
                  <Input disabled={!GUARDAR} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span="12">
                <Form.Item label="Demandante" name="demandante">
                  <Input disabled={!GUARDAR} />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item label="Número" name="numero">
                  <Input type="number" disabled={!GUARDAR} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span="12">
                <Form.Item label="Autos" name="autos">
                  <Input disabled={!GUARDAR} />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="Cantidad de cuotas"
                  name="cuotas"
                  rules={[
                    {
                      required: aceptaCuota,
                      message: "Este campo es obligatorio",
                    },
                  ]}
                >
                  <Input
                    type="number"
                    min={0}
                    step="1"
                    disabled={!aceptaCuota}
                    onChange={onChangeCantidadFecha}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span="8">
                <Form.Item
                  label="Monto"
                  name="monto"
                  rules={[
                    {
                      required: !aceptaCuota,
                      message: "Este campo es obligatorio",
                    },
                  ]}
                >
                  <Input
                    type="number"
                    min={0}
                    step="0.01"
                    disabled={aceptaCuota}
                  />
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item label="Saldo" name="saldo">
                  <Input type="number" min={0} step="0.01" />
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item label="Acumulado" name="montoAcumulado">
                  <Input type="number" min={0} step="0.01" disabled={true} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span="12">
                <Form.Item label="Fecha Inicio" name="fechaInicio">
                  <DatePicker
                    disabled={true}
                    format="DD/MM/YYYY"
                    placeholder=""
                    onChange={onChangeCantidadFecha}
                    locale={locale}
                  />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item label="Fecha Fin" name="fechaFin">
                  <DatePicker
                    disabled={true}
                    format="DD/MM/YYYY"
                    placeholder=""
                    locale={locale}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span="12">
                <Form.Item label="Estado" name="estadoEmbargoId">
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    disabled={state.id === 0}
                  >
                    {estados.map((m) => (
                      <Option key={m.value} value={m.value}>
                        {m.text}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item label="CBU" name="cbu">
                  <Input disabled={!GUARDAR} />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item label="Sucural" name="sucursalId" rules={[required]}>
                  <Select showSearch allowClear optionFilterProp="children">
                    {sucursales.map((m) => (
                      <Option key={m.value} value={m.value}>
                        {m.text}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="¿Descuento Concurso/Quiebra?"
                  valuePropName="checked"
                  name="descuentoConcursoQuiebra"
                >
                  <Switch checkedChildren="Si" unCheckedChildren="No" />
                </Form.Item>
              </Col>
            </Row>
            <div className="form-actions">
              <Link to="/novedades/liquidacion/embargo">
                <Button type="secondary">Volver</Button>
              </Link>
              {GUARDAR && (
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={state.loading}
                >
                  Guardar
                </Button>
              )}
            </div>
          </div>
        </Form>
        {state.id > 0 && state.historial && (
          <React.Fragment>
            <hr />
            <div className="section-header">
              <div className="button-export">
                <Button type="primary" icon={<FiPrinter />} onClick={onExport}>
                  Exportar
                </Button>
              </div>{" "}
              <div className="section-title">
                Historico de movimientos
                <FiActivity />
              </div>
              <div className="section-subtitle">Movimientos asociados.</div>
            </div>
            <Historial historial={state.historial} />
          </React.Fragment>
        )}
        {state.id > 0 && (
          <React.Fragment>
            <hr />
            <div className="section-header">
              <div className="section-title">
                Documentación
                <FiPaperclip />
              </div>
              <div className="section-subtitle">Documentación asociada.</div>
            </div>
            <Archivos embargo={state.id} />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default EmbargoForm;
