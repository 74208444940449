/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { Button, Form, DatePicker, Select, Statistic, Row, Col } from "antd";
import { NumberOutlined } from "@ant-design/icons";
import "moment/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";

import { ContextProvider } from "../../../../services/context";
import http from "../../../../services/http";
import { hasAction, actions } from "../../../../services/security";

import Filters from "../../../../components/Filters";
import FiltersButton from "../../../../components/FiltersButton";
import Header from "../../../../components/Header";
import Table from "../../../../components/Table";
import fileDownload from "js-file-download";
import { FiPrinter } from "react-icons/fi";
const { Option } = Select;

const AccidentesTrabajos = () => {
  const VER = hasAction(actions.REPORTE_NOVEDAD_ACCIDENTES);

  const [state, setState] = useState({
    historial: [],

    filters: {
      desdeAlta: null,
      desdeAccidente: null,
      hastaAccidente: null,
      hastaAlta: null,
      empleadoId: "",
      area: "",
      estado: "",
      tipoAccidente: "",
    },
    columns: [
      {
        id: 1,
        title: "Empleado",
        style: {},
        render: (item) => item.empleado,
      },
      {
        id: 2,
        title: "Área",
        style: {},
        render: (item) => item.area,
      },
      {
        id: 3,
        title: "Fecha Inicio",
        style: {},
        render: (item) => item.fechaInicio,
      },
      {
        id: 4,
        title: "Fecha Fin",
        style: {},
        render: (item) => item.fechaFin,
      },
      {
        id: 5,
        title: "Código",
        style: {},
        render: (item) => item.codigo,
      },
      {
        id: 6,
        title: "Cantidad días",
        style: {},
        render: (item) => item.cantidadDias,
      },
      {
        id: 7,
        title: "Situación Accidente",
        style: {},
        render: (item) => item.estado,
      },

      {
        id: 8,
        title: "Forma de Accidente",
        style: {},
        render: (item) => item.motivoAccidente,
      },
      {
        id: 9,
        title: "Tipo de Accidente",
        style: {},
        render: (item) => item.tipoAccidente,
      },
    ],
    loading: true,
    loaded: false,
    reload: true,
    loadingExport: false,
    list: [],
    total: 0,
    totalDias: 0,
    page: 1,
    size: 10,
  });

  const [empleados, setEmpleados] = useState([]);
  const [estados, setEstados] = useState([]);
  const [areas, setAreas] = useState([]);
  const [tipoAccidente, setTipoAccidente] = useState([]);

  const breadcrumb = [
    { id: 1, title: "Reportes" },
    {
      id: 2,
      title: "Novedades",
      url: "/reportes/novedad",
    },
    {
      id: 3,
      title: "Accidentes de Trabajos",
      url: "/reportes/novedad/accidentes",
    },
  ];

  const buttons = () => {
    return (
      <React.Fragment>
        <FiltersButton />
        <Button
          type="primary"
          icon={<FiPrinter />}
          onClick={onExport}
          loading={state.loadingExport}
        >
          Exportar
        </Button>
      </React.Fragment>
    );
  };

  const onExport = async () => {
    setState({ ...state, loadingExport: true });
    const rExport = await http.post(
      "Reportes/Novedad/AccidenteTrabajo/Export",
      state.filters,
      true
    );
    setState({ ...state, loadingExport: false });

    fileDownload(rExport.data, `AccidentesTrabajos${Date.now()}.xlsx`);
  };

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Desde" name="desde">
          <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
        </Form.Item>

        <Form.Item label="Hasta" name="hasta">
          <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
        </Form.Item>

        <Form.Item label="Empleado" name="empleado">
          <Select allowClear showSearch optionFilterProp="children">
            {empleados.map((x) => (
              <Option key={x.id} value={x.id}>
                {x.nombre}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Tipo Accidente" name="tipoAccidente">
          <Select allowClear showSearch optionFilterProp="children">
            {tipoAccidente.map((x) => (
              <Option key={x.value} value={x.value} disabled={x.disabled}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Situacion Accidente" name="estado">
          <Select allowClear showSearch optionFilterProp="children">
            {estados.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Área" name="area">
          <Select allowClear showSearch optionFilterProp="children">
            {areas.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </React.Fragment>
    );
  };

  useEffect(() => {
    async function getData() {
      const response = await http.get("AccidentesTrabajos/DataGeneric");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setEmpleados(data.empleados);
        setEstados(data.estados);
        setAreas(data.areas);
        setTipoAccidente(data.tipoAccidente);
      }
    }
    getData();
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="AccidentesTrabajos page">
      <ContextProvider value={[state, setState]}>
        <Header
          title="Accidentes de Trabajo"
          breadcrumb={breadcrumb}
          buttons={buttons}
        />
        <Filters filters={filters} />
        <Row gutter={16}>
          <Col span={6}>
            <Statistic
              title="Cantidad de licencias"
              value={state.total}
              prefix={<NumberOutlined />}
            />
          </Col>
          <Col span={6}>
            <Statistic
              title="Cantidad de días"
              value={state.totalDias}
              prefix={<NumberOutlined />}
            />
          </Col>
        </Row>
        ,
        <Table url="Reportes/Novedad/AccidenteTrabajo/List" />
      </ContextProvider>
    </div>
  );
};

export default AccidentesTrabajos;
