/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Form, Input, Button, Switch, Select } from "antd";
import { FiList } from "react-icons/fi";

import http from "./../../../../services/http";
import { showSuccess } from "./../../../../services/notificacion";
import { hasAction, actions } from "./../../../../services/security";
import { required } from "./../../../../services/validation";

import Header from "./../../../../components/Header";


const { Option } = Select;

const MotivosBajaForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const VER = hasAction(actions.MOTIVOSBAJA_VER);
  const CREAR = hasAction(actions.MOTIVOSBAJA_CREAR);
  const EDITAR = hasAction(actions.MOTIVOSBAJA_EDITAR);
  const GUARDAR = match.params.id === "nuevo" ? CREAR : EDITAR;

  const [tiposBaja, setTiposBaja] = useState([]);

  const [state, setState] = useState({
    title: match.params.id === "nuevo" ? "Crear" : EDITAR ? "Editar" : "Ver",
    id: match.params.id === "nuevo" ? 0 : parseInt(match.params.id),
    loading: false,
  });

  const breadcrumb = [
    {
      id: 1,
      title: "Parámetros",
    },
    {
      id: 2,
      title: "Generales",
      url: "/parametros/generales",
    },
    {
      id: 3,
      title: "Motivos de Baja",
      url: "/parametros/generales/motivos_baja",
    },
    { id: 4, title: state.title },
  ];

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    const result =
      state.id === 0
        ? await http.post("motivosBaja", values)
        : await http.put(`motivosBaja/${state.id}`, values);

    if (result && result.data.code === 0) {
      showSuccess("El motivo fue guardado exitosamente.");
      history.push("/parametros/generales/motivos_baja");
    } else {
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {

    async function getData() {
      const result = await http.get("motivosBaja/data");

      if (result && result.data.code === 0) {
        const data = result.data.data;
        setTiposBaja(data.tiposBaja);
      }
    }

    getData();


    async function getForm() {
      const rForm = await http.get(`motivosBaja/${state.id}`);
      if (rForm && rForm.data.code === 0) {
        const data = rForm.data.data;
        form.setFieldsValue(data);
      }
    }

    if (VER) {
      getForm();
    }
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="motivoBaja">
      <Header title={`${state.title} motivo de baja`} breadcrumb={breadcrumb} />

      <Form form={form} layout="vertical" onFinish={onSave}>
        <div className="form card">
          <div className="form-icon">
            <FiList />
          </div>

          <Form.Item label="Nombre" name="nombre" rules={[required]}>
            <Input maxLength={200} disabled={!GUARDAR} />
          </Form.Item>

          <Form.Item label="Código" name="codigo" rules={[required]}>
            <Input type="number" disabled={!GUARDAR} min={0} max={999999999} />
          </Form.Item>

          <Form.Item
            label="Indique si ocasiona el despido del empleado (Si/No)"
            name="despido"
            valuePropName="checked"
            rules={[required]}
          >
            <Switch checkedChildren="Si" unCheckedChildren="No" />
          </Form.Item>

          <Form.Item
            label="Indique si es un EGRESO (Si) o una LIC.SIN GOCE HABERES (No)"
            valuePropName="checked"
            name="egreso"
            rules={[required]}
          >
            <Switch checkedChildren="Si" unCheckedChildren="No" />
          </Form.Item>
          <Form.Item label="Tipo Baja" name="tipoBaja" >
          <Select allowClear showSearch optionFilterProp="children">
            {tiposBaja.map((x) => (
              <Option key={x.value} value={x.value} >
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>

          <div className="form-actions">
            <Link to="/parametros/generales/motivos_baja">
              <Button type="secondary">Volver</Button>
            </Link>
            {GUARDAR && (
              <Button type="primary" htmlType="submit" loading={state.loading}>
                Guardar
              </Button>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};

export default MotivosBajaForm;
