import React from "react";
import { Col, Row } from "antd";

import Header from "./../../../components/Header";
import MenuCard from "./../../../components/MenuCard";

const Seguridad = () => {
  const items = [
    {
      id: 1,
      titulo: "Asignación de Elementos",
      path: "/administracion/seguridad/asignacion_elementos",
      color: "primary",
      icono: "FiCheckCircle",
      entidad: "AsignacionElemento",
    },
  ];

  const breadcrumb = [
    { id: 1, title: "Administración" },
    { id: 2, title: "Seguridad Laboral", url: "/administracion/seguridad" },
  ];

  return (
    <div className="inicio">
      <Header
        title="Seguridad Laboral"
        breadcrumb={breadcrumb}
        buttons={() => {}}
      />

      <Row gutter={[32, 36]}>
        {items.map((i) => (
          <Col key={i.id} xs={12} md={8} xl={6} xxl={4}>
            <MenuCard item={i} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Seguridad;
