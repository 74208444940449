/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Form, Input, Button, Select, DatePicker } from "antd";
import { FiBookmark } from "react-icons/fi";

import moment from "moment";
import "moment/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";

import http from "../../../../services/http";
import { showSuccess } from "../../../../services/notificacion";
import { hasAction, actions } from "../../../../services/security";
import { required } from "../../../../services/validation";

import Header from "../../../../components/Header";

const { Option } = Select;

const AsignacionesElementosForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const VER = hasAction(actions.ASIGNACIONESELEMENTOS_VER);
  const CREAR = hasAction(actions.ASIGNACIONESELEMENTOS_CREAR);
  const EDITAR = hasAction(actions.ASIGNACIONESELEMENTOS_EDITAR);
  const GUARDAR = match.params.id === "nuevo" ? CREAR : EDITAR;

  const [state, setState] = useState({
    title: match.params.id === "nuevo" ? "Crear" : EDITAR ? "Editar" : "Ver",
    id: match.params.id === "nuevo" ? 0 : parseInt(match.params.id),
    loading: false,
  });

  const [elementos, setElementos] = useState([]);
  const [empleados, setEmpleados] = useState([]);

  const breadcrumb = [
    { id: 1, title: "Administracion" },
    {
      id: 2,
      title: "Seguridad Laboral",
      url: "/administracion/seguridad",
    },
    {
      id: 3,
      title: "Asignación de Elementos",
      url: "/administracion/seguridad/asignacion_elementos",
    },
    { id: 4, title: state.title },
  ];

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    const result =
      state.id === 0
        ? await http.post("AsignacionesElementos", values)
        : await http.put(`AsignacionesElementos/${state.id}`, values);

    if (result && result.data.code === 0) {
      showSuccess("La asignación fue guardada exitosamente.");
      history.push("/administracion/seguridad/asignacion_elementos");
    } else {
      setState({ ...state, loading: false });
    }
  };

  const onFechaChange = async (fecha) => {
    const caducidad = form.getFieldValue("caducidad");
    if (caducidad) {
      const vencimiento = moment(fecha).add(caducidad, "month");
      form.setFieldsValue({ fechaVencimiento: vencimiento });
    }
  };

  const onCaducidadChange = async (meses) => {
    const caducidad = form.getFieldValue("caducidad");
    const fecha = form.getFieldValue("fechaAsignacion");
    if (caducidad) {
      const vencimiento = moment(fecha).add(caducidad, "month");
      form.setFieldsValue({ fechaVencimiento: vencimiento });
    }
  };

  useEffect(() => {
    async function getData() {
      const response = await http.get("AsignacionesElementos/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setElementos(data.elementos);
        setEmpleados(data.empleados);
      }
    }

    getData();

    async function getForm() {
      const rForm = await http.get(`AsignacionesElementos/${state.id}`);
      if (rForm && rForm.data.code === 0) {
        const data = rForm.data.data;
        if (data.fechaAsignacion)
          data.fechaAsignacion = moment(data.fechaAsignacion);

        if (data.fechaVencimiento)
          data.fechaVencimiento = moment(data.fechaVencimiento);
        form.setFieldsValue(data);
      }
    }

    if (VER) {
      getForm();
    }
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="AsignacionElemento">
      <Header title={`${state.title} Asginación`} breadcrumb={breadcrumb} />

      <Form form={form} layout="vertical" onFinish={onSave}>
        <div className="form card">
          <div className="form-icon">
            <FiBookmark />
          </div>

          <Form.Item label="Elemento" name="elementoId" rules={[required]}>
            <Select showSearch allowClear optionFilterProp="children">
              {elementos.map((m) => (
                <Option key={m.value} value={m.value} disabled={m.disabled}>
                  {m.text}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Empleado" name="empleadoId" rules={[required]}>
            <Select showSearch allowClear optionFilterProp="children">
              {empleados.map((m) => (
                <Option key={m.value} value={m.value} disabled={m.disabled}>
                  {m.text}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Talle" name="talle" rules={[required]}>
            <Input maxLength={100} disabled={!GUARDAR} />
          </Form.Item>

          <Form.Item
            label="Fecha de asignación"
            name="fechaAsignacion"
            rules={[required]}
          >
            <DatePicker
              disabled={!GUARDAR}
              format="DD/MM/YYYY"
              placeholder=""
              locale={locale}
              onChange={(val) => onFechaChange(val)}
            />
          </Form.Item>

          <Form.Item label="Caducidad (meses)" name="caducidad" r>
            <Input
              type="number"
              disabled={!GUARDAR}
              onChange={(val) => onCaducidadChange(val)}
            />
          </Form.Item>

          <Form.Item label="Fecha de vencimiento" name="fechaVencimiento">
            <DatePicker
              disabled={!GUARDAR}
              format="DD/MM/YYYY"
              placeholder=""
              locale={locale}
            />
          </Form.Item>

          <Form.Item label="Descripción" name="descripcion">
            <Input maxLength={250} disabled={!GUARDAR} />
          </Form.Item>

          <div className="form-actions">
            <Link to="/administracion/seguridad/asignacion_elementos">
              <Button type="secondary">Volver</Button>
            </Link>
            {GUARDAR && (
              <Button type="primary" htmlType="submit" loading={state.loading}>
                Guardar
              </Button>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AsignacionesElementosForm;
