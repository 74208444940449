/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Form, Modal } from "antd";
import { FiDownload, FiCheckCircle, FiFile } from "react-icons/fi";
import "react-slidedown/lib/slidedown.css";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { ContextProvider } from "../../../../services/context";
import http from "../../../../services/http";
import { showSuccess } from "../../../../services/notificacion";
import { hasAction, actions } from "../../../../services/security";

import Header from "../../../../components/Header";
import Stepper from "../../../../components/Stepper";
import ImportacionDatos from "./ImportacionDatos";
import ImportacionPreview from "./ImportacionPreview";

const ImportacionForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const VER = hasAction(actions.IMPORTACION_NOVEDADES_VER);

  const [state, setState] = useState({
    loading: false,
    form: form,
    step: 0,
    tiposImportacion: [],
    tiposLiquidacion: [],
    conceptos: [],
    convenios: [],
    formatos: [],
    contenido: null,
    novedades: [],
  });

  const breadcrumb = [
    { id: 1, title: "Novedades" },
    {
      id: 2,
      title: "Liquidación",
      url: "/novedades/Liquidacion",
    },
    {
      id: 3,
      title: "Importación",
      url: "/novedades/Liquidacion/importar",
    },
  ];

  const steps = [
    {
      id: 1,
      icon: <FiFile />,
      title: "1. Formulario",
      description: "Seleccioná el archivo y completa los datos",
    },
    {
      id: 3,
      icon: <FiCheckCircle />,
      title: "2. Confirmar",
      description: "Confirmá y finalizá la importación",
    },
  ];

  const onSave = async (values) => {
    // datos
    if (state.step === 0) {
      values.contenido = state.contenido;
      setState({
        ...state,
        contenido: null,
        step: state.step + 1,
        values: { ...state.values, ...values },
      });
      return;
    }

    setState({ ...state, loading: true });
    if (state.novedades[0].error === true) {
      Modal.confirm({
        title: "Confirmar importación",
        icon: <ExclamationCircleOutlined />,
        content: "¿Alugunas lineas no se pudieron procesar, desea continuar?",
        okText: "Confirmar",
        cancelText: "Cancelar",
        onOk: async () => {
          const rSave = await http.post(
            `ImportacionNovedades/${state.values.tipo}/${state.values.archivo}`,
            state.novedades
          );
          if (rSave && rSave.data.code === 0) {
            showSuccess("La importación se ejecutó exitosamente.");
            setState({ ...state, step: state.step - 1 });
            state.form.resetFields();
            history.push("/novedades/liquidacion/importar");
          } else {
            setState({ ...state, loading: false, step: state.step - 1 });
          }
        },
        onCancel: async () => {
          setState({ ...state, loading: false });
        },
      });
    } else {
      const rSave = await http.post(
        `ImportacionNovedades/${state.values.tipo}/${state.values.archivo}`,
        state.novedades
      );
      if (rSave && rSave.data.code === 0) {
        showSuccess("La importación se ejecutó exitosamente.");
        setState({ ...state, step: state.step - 1 });
        state.form.resetFields();
        history.push("/novedades/liquidacion/importar");
      } else {
        setState({ ...state, loading: false, step: state.step - 1 });
      }
    }
  };

  useEffect(() => {
    async function getData() {
      const response = await http.get("ImportacionNovedades/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setState({
          ...state,
          tiposImportacion: data.tiposImportacion,
          conceptos: data.conceptos,
          convenios: data.convenios,
          formatos: data.formatos,
          tiposLiquidacion: data.tiposLiquidacion,
        });
      }
    }

    if (VER) {
      getData();
    }
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="liquidacion">
      <Header title="Importación de Novedades" breadcrumb={breadcrumb} />

      <Form form={state.form} layout="vertical" onFinish={onSave}>
        <div className="form px-0 card">
          <div className="form-icon">
            <FiDownload />
          </div>
          <ContextProvider value={[state, setState]}>
            <Stepper steps={steps} left />
            {state.step === 0 && <ImportacionDatos />}
            {state.step === 1 && <ImportacionPreview />}
          </ContextProvider>
        </div>
      </Form>
    </div>
  );
};

export default ImportacionForm;
