import { useContext, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Form, Row, Select, Input, Tooltip, DatePicker } from "antd";
import { FiUpload } from "react-icons/fi";
import { Context } from "../../../../services/context";
import { required } from "../../../../services/validation";
import { showWarning } from "../../../../services/notificacion";

import locale from "antd/es/date-picker/locale/es_ES";
import "moment/locale/es";



const ImportacionDatos = () => {
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useContext(Context);
  const [fHasta, setFHasta] = useState(false);

  const fileRef = useRef(null);

  const onAdd = () => {
    fileRef.current.click();
  };
  const onTipoChange = (value) => {
    if (value === "1") {
      state.form.setFieldsValue({ fechaHasta: false })
      setFHasta(false);
    }
    else {
      setFHasta(true);
    }
  };

  const onUpload = async (e) => {
    const files = e.target.files;

    if (files.length === 0) {
      return;
    }
    const file = files[0];
    if (file.type !== 'text/plain') {
      showWarning('El archivo debe ser de tipo texto plano');
      return;
    }
    const base64 = await toBase64(file);
    setState({ ...state, contenido: base64 });
    state.form.setFieldsValue({ archivo: file.name })

  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });



  return (
    <div className="form-section">
      <Form.Item label="Formato" name="formatoId" rules={[required]}>
        <Select showSearch allowClear optionFilterProp="children">
          {state.formatos.map((x) => (
            <Select.Option key={x.value} value={x.value}>
              {x.text}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Row gutter={20}>
        <Col span={8}>
          <Form.Item label="Tipo" name="tipo" rules={[required]}>
            <Select showSearch allowClear optionFilterProp="children"
              onChange={onTipoChange}
            >
              {state.tiposImportacion.map((x) => (
                <Select.Option key={x.value} value={x.value}>
                  {x.text}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Concepto" name="conceptoId" rules={[required]}>
            <Select showSearch allowClear optionFilterProp="children">
              {state.conceptos.map((x) => (
                <Select.Option key={x.value} value={x.value}>
                  {x.text}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Convenio" name="convenioId">
            <Select showSearch allowClear optionFilterProp="children">
              {state.convenios.map((x) => (
                <Select.Option key={x.value} value={x.value}>
                  {x.text}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col span="12">
          <Form.Item label="Fecha Desde (opcional)" name="fechaDesde">
            <DatePicker
              format="DD/MM/YYYY"
              placeholder=""
              locale={locale}
            />
          </Form.Item>
        </Col>
        {fHasta && (
          <Col span="12">
            <Form.Item label="Fecha Hasta (opcional)" name="fechaHasta">
              <DatePicker
                disab
                format="DD/MM/YYYY"
                placeholder=""
                locale={locale}
              />
            </Form.Item>
          </Col>
        )}

      </Row>
      <Form.Item label="Tipo Liquidación" name="tipoLiquidacion" rules={[required]}>
        <Select showSearch allowClear optionFilterProp="children"
          onChange={onTipoChange}
        >
          {state.tiposLiquidacion.map((x) => (
            <Select.Option key={x.value} value={x.value}>
              {x.text}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Row>
        <Col flex="auto">
          <input
            type="file"
            multiple={false}
            accept={'.txt'}
            ref={fileRef}
            onChange={onUpload}
          />
          <Form.Item label="Archivo" name="archivo" rules={[required]}>
            <Input disabled={true} />
          </Form.Item>
        </Col>
        <Col flex="45px">
          <Tooltip title="Subir Archivo">
            <div className="historial-trigger" onClick={onAdd}>
              <FiUpload />
            </div>
          </Tooltip>
        </Col>
      </Row>
      <div className="actions">
        <Link to="/novedades/liquidacion">
          <Button type="secondary">Volver</Button>
        </Link>
        <Button type="primary" htmlType="submit" loading={state.loading}>
          Siguiente
        </Button>
      </div>
    </div>
  );
};

export default ImportacionDatos;
