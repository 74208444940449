/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Dropdown, Menu, Modal, Select } from "antd";
import { FiEdit, FiMoreVertical, FiSearch, FiTrash2 } from "react-icons/fi";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Viewer from "react-viewer";
import Compressor from "compressorjs";
import { FileIcon, defaultStyles } from "react-file-icon";

import { Context } from "./../../../services/context";
import http from "./../../../services/http";
import { showSuccess } from "./../../../services/notificacion";
import { hasAction, actions } from "./../../../services/security";

import "./Archivos.scss";

const { Option } = Select;

const Archivos = ({ seccion, estudio, antecedente, familiar, evaluacion }) => {
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useContext(Context);
  const fileRef = useRef(null);

  const EDITAR = hasAction(actions.LEGAJOS_EDITAR);
  const GUARDAR = EDITAR && state.id > 0 && state.editing;

  const [reload, setReload] = useState(false);
  const [files, setFiles] = useState([]);
  const [previewUrl, setPreviewUrl] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);
  const [tipos, setTipos] = useState([]);

  const images = ["jpg", "jpeg", "bmp", "gif", "png"];

  const onAdd = () => {
    fileRef.current.click();
  };

  const onUpload = async (e) => {
    const files = e.target.files;
    if (files.length === 0) {
      return;
    }

    for (let i = 0; i < files.length; i++) {
      if (files[i].type.includes("image")) {
        await compress(files[i]);
      } else {
        await upload(files[i]);
      }
    }

    e.target.value = "";
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const compress = async (file) => {
    new Compressor(file, {
      quality: 0.6,
      async success(result) {
        await upload(result);
      },
      error(err) {
        console.log(err.message);
      },
    });
  };

  const upload = async (file) => {
    const base64 = await toBase64(file);

    const data = {
      nombre: file.name,
      tamanio: file.size.toString(),
      contenido: base64,
      seccion: seccion,
      empleadoId: state.id,
      empleadoEstudioId: estudio,
      empleadoAntecedenteId: antecedente,
      empleadoFamiliarId: familiar,
      empleadoEvaluacionId: evaluacion,
    };

    const rSave = await http.post(`legajos/documentacion`, data);
    if (rSave && rSave.data.code === 0) {
      setReload(!reload);
    }
  };

  const onTipoChange = async (value, file) => {
    const data = {
      id: file.id,
      tipo: value,
    };

    const rUpdate = await http.post(`legajos/documentacion/update`, data);
    if (rUpdate && rUpdate.data.code === 0) {
      setReload(!reload);
    }
  };

  const onPreview = (file) => {
    setPreviewUrl(`${process.env.REACT_APP_API_URL}/archivos/${file.id}`);
    setPreviewVisible(true);
  };

  const onDownload = (file) => {
    window.open(
      `${process.env.REACT_APP_API_URL}/archivos/${file.id}`,
      "_blank"
    );
  };

  const onDelete = (item) => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea eliminar este archivo?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const rDelete = await http.delete(`legajos/documentacion/${item.id}`);
        if (rDelete && rDelete.data.code === 0) {
          showSuccess("El archivo fue eliminado exitosamente.");
          setReload(!reload);
        }
      },
    });
  };

  const menu = (file) => {
    return (
      <Menu className="table-actions-menu">
        {images.indexOf(file.extension) > -1 && (
          <Menu.Item key="0" onClick={() => onPreview(file)}>
            <FiSearch />
            Ver
          </Menu.Item>
        )}
        <Menu.Item key="1" onClick={() => onDownload(file)}>
          <FiEdit />
          Descargar
        </Menu.Item>
        {GUARDAR && (
          <Menu.Item key="2" onClick={() => onDelete(file)}>
            <FiTrash2 />
            Eliminar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  useEffect(() => {
    async function getFiles() {
      const rFiles = await http.get(
        `legajos/documentacion?empleado=${state.id}&seccion=${seccion}&estudio=${estudio}&antecedente=${antecedente}&familiar=${familiar}&evaluacion=${evaluacion}`
      );
      if (rFiles && rFiles.data.code === 0) {
        const data = rFiles.data.data;
        setFiles(data.files);
        setTipos(data.tipos);
      }
    }

    getFiles();
  }, [state.id, state.reload, reload]);

  return (
    <div className="archivos">
      <table className="table-small">
        <thead>
          <tr>
            <th></th>
            <th>Nombre</th>
            <th style={{ width: "30%" }}>Tipo</th>
            <th style={{ textAlign: "center" }}>Fecha</th>
            <th style={{ textAlign: "center" }}>Tamaño</th>
          </tr>
        </thead>
        <tbody>
          {files.length === 0 && (
            <tr>
              <td className="table-empty" colSpan="6">
                Sin resultados
              </td>
            </tr>
          )}

          {files.map((x) => (
            <tr key={x.id}>
              <td className="table-actions">
                <Dropdown
                  key={x.id}
                  overlay={() => menu(x)}
                  trigger={["click"]}
                  placement="bottomLeft"
                  arrow
                >
                  <div>
                    <FiMoreVertical />
                  </div>
                </Dropdown>
              </td>
              <td className="td-icon">
                <div className="icon">
                  <FileIcon
                    extension={x.extension}
                    {...defaultStyles[x.extension]}
                  />
                </div>
                {x.nombre}
              </td>
              <td>
                <Select
                  defaultValue={x.tipo}
                  disabled={!GUARDAR}
                  bordered={false}
                  style={{ width: "100%", marginLeft: "-10px" }}
                  placeholder="Seleccione una opción..."
                  onChange={(e) => onTipoChange(e, x)}
                >
                  {tipos.map((x) => (
                    <Option key={x.value} value={x.value}>
                      {x.text}
                    </Option>
                  ))}
                </Select>
              </td>
              <td style={{ textAlign: "center" }}>{x.fecha}</td>
              <td style={{ textAlign: "center" }}>{x.tamanio}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {GUARDAR && (
        <div className="table-footer-actions">
          <input
            type="file"
            multiple={true}
            ref={fileRef}
            onChange={onUpload}
          />
          <Button type="primary" onClick={onAdd}>
            Subir
          </Button>
        </div>
      )}

      <Viewer
        visible={previewVisible}
        onClose={() => {
          setPreviewVisible(false);
        }}
        images={[{ src: previewUrl, alt: "" }]}
      />
    </div>
  );
};

export default Archivos;
