import React, { useContext, useState } from "react";
import { Dropdown, Menu, Button, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { FiMoreVertical, FiTrash2 } from "react-icons/fi";
import { Context } from "../../../../services/context";
import http from "../../../../services/http";
import { showSuccess } from "../../../../services/notificacion";
import { hasAction, actions } from "../../../../services/security";
import fileDownload from "js-file-download";

import DeduccionesForm from "./DeduccionesForm";
import "./Deducciones.scss";

const Deducciones = () => {
  const AGREGAR = hasAction(actions.DDJJ_VER);
  const ELIMINAR = hasAction(actions.DDJJ_ELIMINAR);

  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useContext(Context);
  const [formVisible, setFormVisible] = useState(false);

  const menu = (item) => {
    return (
      <Menu className="table-actions-menu">
        {ELIMINAR && (
          <Menu.Item key="2" onClick={() => onDelete(item)}>
            <FiTrash2 />
            Eliminar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const onAdd = () => {
    setFormVisible(true);
  };

  const onExport = async () => {
    const rExport = await http.post(
      "ddjj/ExportDeducciones/" + state.id,
      null,
      true
    );

    fileDownload(rExport.data, `Deducciones${Date.now()}.xlsx`);
  };
  const onDelete = (item) => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea eliminar esta deducción?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const rDelete = await http.delete(`ddjj/deducciones/${item.id}`);
        if (rDelete && rDelete.data.code === 0) {
          showSuccess("La deducción fue eliminado exitosamente.");
        }
      },
    });
  };

  return (
    <div className="deducciones">
      <div className="form-subsection-content">
        <table className="table-small">
          <thead>
            <tr>
              <th></th>
              <th>Deducción</th>
              <th>Fecha desde</th>
              <th>Fecha hasta</th>
              <th>Valor</th>
            </tr>
          </thead>
          <tbody>
            {state.deducciones.length === 0 && (
              <tr>
                <td className="table-empty" colSpan="6">
                  Sin resultados
                </td>
              </tr>
            )}

            {state.deducciones.map((x) => (
              <tr key={x.id}>
                <td className="table-actions">
                  <Dropdown
                    key={x.id}
                    overlay={() => menu(x)}
                    trigger={["click"]}
                    placement="bottomLeft"
                    arrow
                  >
                    <div>
                      <FiMoreVertical />
                    </div>
                  </Dropdown>
                </td>
                <td>{x.deduccionGanancia}</td>
                <td>{x.fechaDesdeStr}</td>
                <td>{x.fechaHastaStr}</td>
                <td>{x.valor}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="form-actions">
        {AGREGAR && (
          <Button type="primary" onClick={onAdd}>
            Agregar
          </Button>
        )}
        {state.deducciones.length > 0 && (
          <Button type="secondary" className="download" onClick={onExport}>
            Exportar
          </Button>
        )}
      </div>

      <Modal
        visible={formVisible}
        onCancel={() => setFormVisible(false)}
        footer={null}
        maskClosable={false}
        keyboard={false}
        destroyOnClose={true}
      >
        <DeduccionesForm onClose={() => setFormVisible(false)} />
      </Modal>
    </div>
  );
};

export default Deducciones;
