/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Col, Input, Modal, Row, Tooltip } from "antd";
import {
  FiBook,
  FiBriefcase,
  FiCalendar,
  FiCheckSquare,
  FiFolder,
  FiEdit2,
  FiPlus,
  FiTrash2,
  FiUser,
  FiUsers,
  FiCheckCircle,
} from "react-icons/fi";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { Context } from "./../../../services/context";
import http from "./../../../services/http";
import { showSuccess } from "./../../../services/notificacion";
import { hasAction, actions } from "./../../../services/security";

import Picture from "./Picture";

import "./Header.scss";

const { Search } = Input;

const Menu = ({ onUpdate }) => {
  const history = useHistory();

  const [state, setState] = useContext(Context);
  const [data, setData] = useState({
    foto: {
      raw: "",
      crop: false,
      cropped: "",
      size: "",
      name: "",
    },
    nombre: "Nombre",
    funcion: "Función",
    legajo: "",
    visible: false,
  });

  const CREAR = hasAction(actions.LEGAJOS_CREAR);
  const EDITAR = hasAction(actions.LEGAJOS_EDITAR);
  const ELIMINAR = hasAction(actions.LEGAJOS_ELIMINAR);

  const sections = [
    {
      id: 1,
      value: "personales",
      title: "Datos Personales",
      icon: <FiUser />,
      disabled: false,
    },
    {
      id: 2,
      value: "laborales",
      title: "Datos Laborales",
      icon: <FiBriefcase />,
      disabled: state.id === 0 && state.editing,
    },
    {
      id: 3,
      value: "administrativos",
      title: "Actos Administrativos",
      icon: <FiFolder />,
      disabled: state.id === 0 && state.editing,
    },
    {
      id: 4,
      value: "estudios",
      title: "Estudios y Formación",
      icon: <FiBook />,
      disabled: state.id === 0 && state.editing,
    },
    {
      id: 5,
      value: "antecedentes",
      title: "Antecedentes Disciplinarios",
      icon: <FiCalendar />,
      disabled: state.id === 0 && state.editing,
    },
    {
      id: 6,
      value: "evaluaciones",
      title: "Evaluaciones de Desempeño",
      icon: <FiCheckSquare />,
      disabled: state.id === 0 && state.editing,
    },
    {
      id: 7,
      value: "familiares",
      title: "Grupo Familiar",
      icon: <FiUsers />,
      disabled: state.id === 0 && state.editing,
    },
    {
      id: 8,
      value: "liquidaciones",
      title: "Liquidaciones",
      icon: <FiCheckCircle />,
      disabled: state.id === 0 && state.editing,
    },
  ];

  const onSearch = async (e) => {
    if (state.editing) {
      Modal.confirm({
        title: "Confirmar búsqueda",
        icon: <ExclamationCircleOutlined />,
        content: "¿Esta seguro que desea dejar de editar este legajo?",
        okText: "Confirmar",
        cancelText: "Cancelar",
        onOk: async () => {
          setState({
            ...state,
            id: e.target.value,
            title: "Ver",
            editing: false,
          });
        },
      });
    } else {
      setState({
        ...state,
        id: e.target.value,
        title: "Ver",
        editing: false,
      });
    }
  };

  const getSectionClasses = (section) => {
    let classes = "item";

    if (state.section === section.value) {
      classes += " active";
    }

    if (section.disabled) {
      classes += " disabled";
    }

    return classes;
  };

  const onEdit = () => {
    setState({
      ...state,
      id: state.id > 0 ? state.id : 0,
      title: state.id > 0 ? "Editar" : "Crear",
      editing: true,
    });
  };

  const onDelete = () => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content:
        "Al eliminar el legajo, este y toda la información relacionada al empleado se eliminara del sistema permanentemente. ¿Esta seguro que desea eliminar este legajo?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const rDelete = await http.delete(`legajos/${state.id}`);
        if (rDelete && rDelete.data.code === 0) {
          showSuccess("El legajo fue eliminado exitosamente.");
          history.push(`/administracion/legajos`);
        }
      },
    });
  };

  const onMenuClick = (item) => {
    if (!item.disabled && !state.loading) {
      onUpdate(item.value);
    }
  };

  useEffect(() => {
    async function getForm() {
      const rForm = await http.get(`legajos/header?id=${state.id}`);
      if (rForm && rForm.data.code === 0) {
        const response = rForm.data.data;

        setData({
          ...data,
          nombre:
            response.form.nombre !== undefined
              ? response.form.nombre
              : "Nombre",
          funcion:
            response.form.funcion !== undefined
              ? response.form.funcion
              : "Función",
          legajo:
            response.form.legajo !== undefined ? response.form.legajo : "",
          foto: {
            ...data.foto,
            cropped: response.form.foto === undefined ? "" : response.form.foto,
          },
        });
      }
    }

    getForm();
  }, [state.id, state.reload]);

  return (
    <div className="legajo-header">
      <div className="content">
        <Picture data={data} onDataUpdate={setData} />

        <div className="data">
          <Row>
            <Col span="16">
              <h4 className="legajo"># {data.legajo}</h4>
              <h2 className="nombre">{data.nombre}</h2>
              <h3 className="cargo"> {data.funcion}</h3>
            </Col>
            <Col span="8">
              <div className="search">
                <Search
                  defaultValue={state.id > 0 ? state.id : ""}
                  placeholder="Buscar..."
                  loading={state.loading}
                  enterButton
                  onPressEnter={onSearch}
                />
              </div>
              <div className="actions">
                {CREAR && state.id <= 0 && !state.editing && (
                  <Button type="secondary" icon={<FiPlus />} onClick={onEdit}>
                    Crear
                  </Button>
                )}
                {EDITAR && state.id > 0 && !state.editing && (
                  <Button type="secondary" icon={<FiEdit2 />} onClick={onEdit}>
                    Editar
                  </Button>
                )}
                {ELIMINAR && state.id > 0 && (
                  <Button type="danger" icon={<FiTrash2 />} onClick={onDelete}>
                    Eliminar
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="menu">
        {sections.map((x) => (
          <Tooltip key={x.id} placement="bottom" title={x.title}>
            <div
              className={getSectionClasses(x)}
              onClick={() => onMenuClick(x)}
            >
              {x.icon}
            </div>
          </Tooltip>
        ))}
      </div>
    </div>
  );
};

export default Menu;
