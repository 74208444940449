/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Form, Input, Button, Switch } from "antd";
import { FiShield } from "react-icons/fi";
import { SlideDown } from "react-slidedown";
import "react-slidedown/lib/slidedown.css";

import http from "./../../../services/http";
import { showSuccess } from "./../../../services/notificacion";
import { hasAction, actions } from "./../../../services/security";
import { required } from "./../../../services/validation";

import Header from "./../../../components/Header";

import "./PerfilesForm.scss";

const { TextArea } = Input;

const PerfilesForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const VER = hasAction(actions.PERFILES_VER);
  const CREAR = hasAction(actions.PERFILES_CREAR);
  const EDITAR = hasAction(actions.PERFILES_EDITAR);
  const GUARDAR = match.params.id === "nuevo" ? CREAR : EDITAR;

  const [state, setState] = useState({
    title: match.params.id === "nuevo" ? "Crear" : EDITAR ? "Editar" : "Ver",
    id: match.params.id === "nuevo" ? 0 : parseInt(match.params.id),
    loading: false,
  });
  const [modulos, setModulos] = useState([]);

  const breadcrumb = [
    { id: 1, title: "Entorno" },
    { id: 2, title: "Perfiles", url: "/entorno/perfiles" },
    { id: 3, title: state.title },
  ];

  const onToggleDisplay = (modulo) => {
    const nModulos = [...modulos];
    const index = nModulos.indexOf(modulo);
    nModulos[index].colapsado = !nModulos[index].colapsado;
    setModulos(nModulos);
  };

  const onToggleModulo = (modulo, checked, event) => {
    event.stopPropagation();
    if (GUARDAR) {
      const nModulos = [...modulos];
      const index = nModulos.indexOf(modulo);
      modulo.seleccionado = checked;
      modulo.acciones.map((a) => (a.seleccionado = checked));
      nModulos[index] = modulo;
      setModulos(nModulos);
    }
  };

  const onToggleAccion = (modulo, accion, checked, event) => {
    if (GUARDAR) {
      const nModulos = [...modulos];
      const index = nModulos.indexOf(modulo);
      const indexAction = nModulos[index].acciones.indexOf(accion);
      nModulos[index].acciones[indexAction].seleccionado = checked;
      nModulos[index].seleccionado =
        nModulos[index].acciones.filter((x) => x.seleccionado).length ===
        nModulos[index].acciones.length;
      setModulos(nModulos);
    }
  };

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    values.modulos = modulos;

    const rSave =
      state.id === 0
        ? await http.post("perfiles", values)
        : await http.put(`perfiles/${state.id}`, values);

    if (rSave && rSave.data.code === 0) {
      showSuccess("El perfil fue guardado exitosamente.");
      history.push("/entorno/perfiles");
    } else {
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {
    async function getForm() {
      const rForm = await http.get(`perfiles/${state.id}`);

      if (rForm && rForm.data.code === 0) {
        const data = rForm.data.data;
        setModulos(data.modulos);
        form.setFieldsValue(data);
      }
    }

    if (VER) {
      getForm();
    }
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="perfil">
      <Header title={`${state.title} Perfil`} breadcrumb={breadcrumb} />

      <Form form={form} layout="vertical" onFinish={onSave}>
        <div className="form card">
          <div className="form-icon">
            <FiShield />
          </div>

          <Form.Item label="Nombre" name="nombre" rules={[required]}>
            <Input maxLength={50} disabled={!GUARDAR} />
          </Form.Item>

          <Form.Item
            label="Descripción"
            name="descripcion"
            className="textarea"
          >
            <TextArea showCount maxLength={400} disabled={!GUARDAR} />
          </Form.Item>

          <label>Acciones</label>
          <div className="modules">
            {modulos.map((m) => (
              <div key={m.id} className="module">
                <div className="title" onClick={() => onToggleDisplay(m)}>
                  <span>{m.nombre}</span>
                  <Switch
                    checked={m.seleccionado}
                    onClick={(checked, event) =>
                      onToggleModulo(m, checked, event)
                    }
                  />
                </div>
                <SlideDown closed={m.colapsado}>
                  <div className="actions">
                    {m.acciones.map((a) => (
                      <div key={a.id} className="action">
                        <p>{a.nombre}</p>
                        <Switch
                          size="small"
                          checked={a.seleccionado}
                          onClick={(checked, event) =>
                            onToggleAccion(m, a, checked, event)
                          }
                        />
                      </div>
                    ))}
                  </div>
                </SlideDown>
              </div>
            ))}
          </div>

          <div className="form-actions">
            <Link to="/entorno/perfiles">
              <Button type="secondary">Volver</Button>
            </Link>
            {GUARDAR && (
              <Button type="primary" htmlType="submit" loading={state.loading}>
                Guardar
              </Button>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};

export default PerfilesForm;
