/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { Button, Form, DatePicker, Row, Col, Select, Statistic } from "antd";
import { NumberOutlined } from "@ant-design/icons";
import { FiPrinter } from "react-icons/fi";
import fileDownload from "js-file-download";

import "moment/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";

import { ContextProvider } from "../../../../services/context";
import http from "../../../../services/http";
import { hasAction, actions } from "../../../../services/security";

import Filters from "../../../../components/Filters";
import FiltersButton from "../../../../components/FiltersButton";
import Header from "../../../../components/Header";
import Table from "../../../../components/Table";

const { Option } = Select;

const Especiales = () => {
  const VER = hasAction(actions.REPORTE_NOVEDAD_ESPECIALES);

  const [state, setState] = useState({
    filters: {
      desde: "",
      hasta: "",
      empleado: "",
      area: "",
      estado: "",
      codigo: "",
    },
    columns: [
      {
        id: 1,
        title: "Empleado",
        style: {},
        render: (item) => item.empleado,
      },
      {
        id: 2,
        title: "Área",
        style: {},
        render: (item) => item.area,
      },
      {
        id: 3,
        title: "Fecha Inicio",
        style: {},
        render: (item) => item.fechaInicio,
      },
      {
        id: 4,
        title: "Fecha Fin",
        style: {},
        render: (item) => item.fechaFin,
      },
      {
        id: 5,
        title: "Código",
        style: {},
        render: (item) => item.codigo,
      },
      {
        id: 6,
        title: "Cantidad días",
        style: {},
        render: (item) => item.cantidadDias,
      },
      {
        id: 7,
        title: "Estado",
        style: {},
        render: (item) => item.estado,
      },
    ],
    loading: true,
    loaded: false,
    reload: true,
    loadingExport: false,
    list: [],
    total: 0,
    totalDias: 0,
    page: 1,
    size: 10,
  });

  const [empleados, setEmpleados] = useState([]);
  const [estados, setEstados] = useState([]);
  const [areas, setAreas] = useState([]);
  const [codigos, setCodigos] = useState([]);

  const breadcrumb = [
    { id: 1, title: "Reportes" },
    {
      id: 2,
      title: "Novedades",
      url: "/reportes/novedad",
    },
    {
      id: 3,
      title: "Especiales / Ausencias",
      url: "/reportes/novedad/especiales",
    },
  ];

  const buttons = () => {
    return (
      <React.Fragment>
        <FiltersButton />
        <Button type="primary" icon={<FiPrinter />} onClick={onExport}>
          Exportar
        </Button>
      </React.Fragment>
    );
  };

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Desde" name="desde">
          <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
        </Form.Item>

        <Form.Item label="Hasta" name="hasta">
          <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
        </Form.Item>

        <Form.Item label="Empleado" name="empleado">
          <Select allowClear showSearch optionFilterProp="children">
            {empleados.map((x) => (
              <Option key={x.id} value={x.id}>
                {x.nombre}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Estado" name="estado">
          <Select allowClear showSearch optionFilterProp="children">
            {estados.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Código" name="codigo">
          <Select allowClear showSearch optionFilterProp="children">
            {codigos.map((x) => (
              <Option key={x.id} value={x.id.toString()} disabled={x.disabled}>
                {x.codigo} - {x.detalle}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Área" name="area">
          <Select allowClear showSearch optionFilterProp="children">
            {areas.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </React.Fragment>
    );
  };
  const onExport = async () => {
    setState({ ...state, loadingExport: true });
    const rExport = await http.post(
      "Reportes/Novedad/licenciasespeciales/export",
      state.filters,
      true
    );
    setState({ ...state, loadingExport: false });
    fileDownload(rExport.data, `LicenciaEspecial_${Date.now()}.xlsx`);
  };

  useEffect(() => {
    async function getData() {
      const response = await http.get("licenciasespeciales/DataGeneric");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setEmpleados(data.empleados);
        setEstados(data.estados);
        setAreas(data.areas);
        setCodigos(data.codigos);
      }
    }

    async function getList() {
      setState({ ...state, loading: true });

      const rList = await http.post(
        "Reportes/Novedad/licenciasespeciales/list",
        {
          page: state.page,
          size: state.size,
          ...state.filters,
        }
      );

      if (rList && rList.data.code === 0) {
        const data = rList.data.data;
        setState({
          ...state,
          list: data.list,
          total: data.total,
          totalDias: data.totalDias,
          loading: false,
        });
      } else {
        setState({ ...state, loading: false });
      }
    }

    if (VER) {
      getData();
      getList();
    }
  }, [
    state.filters.desde,
    state.filters.hasta,
    state.filters.empleado,
    state.filters.estado,
    state.filters.area,
    state.filters.codigo,
    state.page,
    state.size,
  ]);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="especiales page">
      <ContextProvider value={[state, setState]}>
        <Header
          title="Licencias Especiales / Ausencias"
          breadcrumb={breadcrumb}
          buttons={buttons}
        />
        <Filters filters={filters} />
        <Row gutter={16}>
          <Col span={6}>
            <Statistic
              title="Cantidad de licencias"
              value={state.total}
              prefix={<NumberOutlined />}
            />
          </Col>
          <Col span={6}>
            <Statistic
              title="Cantidad de días"
              value={state.totalDias}
              prefix={<NumberOutlined />}
            />
          </Col>
        </Row>
        <Table columns={state.columns} />
      </ContextProvider>
    </div>
  );
};

export default Especiales;
