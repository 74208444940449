/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { Col, DatePicker, Form, Input, Row } from "antd";
// import MaskedInput from "antd-mask-input";
import { FiUser } from "react-icons/fi";
import locale from "antd/es/date-picker/locale/es_ES";
import "moment/locale/es";

import { Context } from "./../../../services/context";
import { parseToDate } from "./../../../services/forms";
import http from "./../../../services/http";
import InputMasked from "./../../../components/InputMasked";

const PersonalesResumen = () => {
  const [form] = Form.useForm();
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useContext(Context);

  useEffect(() => {
    async function getForm() {
      form.resetFields();

      const rForm = await http.get(`legajos/personales/${state.id}`);
      if (rForm && rForm.data.code === 0) {
        const data = rForm.data.data;
        data.form.fechaNacimiento = parseToDate(data.form.fechaNacimiento);
        form.setFieldsValue(data.form);
      }
    }

    getForm();
  }, [state.id, state.reload]);

  return (
    <div className="personales">
      <div className="section-header">
        <div className="section-title">
          Datos Personales
          <FiUser />
        </div>
        <div className="section-subtitle">
          Información personal del empleado
        </div>
      </div>

      <Form form={form} className="subsection-form" layout="vertical">
        <Row gutter={20}>
          <Col span="24">
            <Form.Item label="Nombre y Apellido" name="nombre">
              <Input disabled={true} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span="12">
            <Form.Item label="Documento" name="documento">
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item label="CUIL" name="cuil">
              <InputMasked mask="99 99999999 9" disabled={true} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span="24">
            <Form.Item label="Domicilio" name="domicilio">
              <Input disabled={true} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span="12">
            <Form.Item label="Fecha de Nacimiento" name="fechaNacimiento">
              <DatePicker
                format="DD/MM/YYYY"
                placeholder=""
                locale={locale}
                disabled={true}
              />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item label="Edad" name="edad">
              <Input disabled={true} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span="24">
            <Form.Item label="Teléfono Celular #1" name="celular1">
              <Input disabled={true} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default PersonalesResumen;
