import axios from "axios";
import { showWarning, showError } from "./notificacion";
import { getJwt } from "./security";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.response.use(null, (error) => {
  showError("Ups! Parece que hay problemas de conexión con el servidor.");
  return Promise.reject(error);
});

axios.interceptors.response.use((response) => {
  if (response.data.code === 1) {
    showError("Ups! Parece que ocurrió un error. Intenta nuevamente.");
  } else if (response.data.code === 2) {
    showWarning(response.data.message);
  } else if (response.data.code === 3) {
    if (response.data.message !== undefined) {
      showWarning(response.data.message);
    }
  }

  return response;
});

const httpGet = async (url, isFile = false) => {
  const config = {
    headers: { "x-auth-token": getJwt() },
    responseType: isFile ? "blob" : "json",
  };
  return await axios.get(url, config).catch(() => {});
};

const httpPost = async (url, data, isFile = false, timeout) => {
  const config = {
    headers: { "x-auth-token": getJwt() },
    responseType: isFile ? "blob" : "json",
  };
  if (timeout) {
    config.timeout = timeout;
  }
  return await axios.post(url, data, config).catch(() => {});
};

const httpPut = async (url, data) => {
  const config = { headers: { "x-auth-token": getJwt() } };
  return await axios.put(url, data, config).catch(() => {});
};

const httpDelete = async (url, data = null) => {
  const config = { headers: { "x-auth-token": getJwt() }, data: data };
  return await axios.delete(url, config).catch(() => {});
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get: httpGet,
  post: httpPost,
  put: httpPut,
  delete: httpDelete,
};
