/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Col, Form, Input, Popover, Radio, Row, Tag, Tooltip } from "antd";
import { FiSearch, FiX } from "react-icons/fi";
import { Scrollbars } from "react-custom-scrollbars";

import http from "./../services/http";
import { showWarning } from "../services/notificacion";

import "./FormulasForm.scss";

export const parseFormulas = (values) => {
  let formulas = [];

  for (const key in values) {
    if (key.startsWith("contenido-")) {
      const formula = {};
      formula.orden = key.split("-")[1];
      formula.contenido = values[`contenido-${formula.orden}`];
      formula.condicion = values[`condicion-${formula.orden}`];
      formulas.push(formula);
    }
  }

  return formulas;
};

export const validateFormulas = (formulas) => {
  const condicional = formulas.length > 1;
  const condicionesVacias = formulas.filter((x) => x.condicion === "").length;
  const condicionUltima = formulas[formulas.length - 1].condicion;
  const formulasVacias = formulas.filter((x) => x.contenido === "").length;

  if (formulasVacias > 0) {
    showWarning("No puede haber una formula vacía.");
    return false;
  }

  if (condicionesVacias > 1) {
    showWarning("No puede haber más de una condición vacía.");
    return false;
  }

  if (condicional && condicionesVacias === 1 && condicionUltima !== "") {
    showWarning("Solo se puede dejar la condición vacía en la última opción.");
    return false;
  }

  return true;
};

const FormulasForm = ({ form, formulas }) => {
  const [operadores, setOperadores] = useState([]);
  const [operandos, setOperandos] = useState([]);
  const [condicional, setCondicional] = useState();
  const [list, setList] = useState([]);
  const [index, setIndex] = useState(0);

  let focused = null;
  let position = null;

  useEffect(() => {
    async function getOperandos() {
      const result = await http.get("convenios/conceptos/data");

      if (result && result.data.code === 0) {
        const data = result.data.data;
        setOperandos(data.operandos);
      }
    }

    getOperandos();
    setOperadores([
      { id: 1, nombre: "Suma", token: "+", tipo: 1 },
      { id: 2, nombre: "Resta", token: "-", tipo: 1 },
      { id: 3, nombre: "Multiplicación", token: "*", tipo: 1 },
      { id: 4, nombre: "División", token: "/", tipo: 1 },
      { id: 5, nombre: "Paréntesis (Abrir)", token: "(", tipo: 1 },
      { id: 6, nombre: "Paréntesis (Cerrar)", token: ")", tipo: 1 },
      { id: 7, nombre: "Igual", token: "==", tipo: 2 },
      { id: 8, nombre: "Distinto", token: "!=", tipo: 2 },
      { id: 9, nombre: "Menor", token: "<", tipo: 2 },
      { id: 10, nombre: "Menor o igual", token: "<=", tipo: 2 },
      { id: 11, nombre: "Mayor", token: ">", tipo: 2 },
      { id: 12, nombre: "Mayor o igual", token: ">=", tipo: 2 },
      { id: 13, nombre: "Y", token: "&&", tipo: 2 },
      { id: 14, nombre: "O", token: "||", tipo: 2 },
    ]);
  }, []);

  useEffect(() => {
    const data = {};

    // Tipo
    if (formulas.length > 1 || formulas[0]?.condicion) {
      data.tipoFormula = "true";
      setCondicional(true);
    } else {
      data.tipoFormula = "false";
      setCondicional(false);
    }

    // Formulas
    let nList = [...formulas];
    if (nList.length === 0) {
      nList.push({
        contenido: "",
        condicion: "",
        orden: 1,
      });
    } else {
      for (let i = 0; i < nList.length; i++) {
        nList[i].orden = i + 1;
      }
    }
    setList(nList);
    setIndex(nList.length);

    for (let i = 0; i < nList.length; i++) {
      data[`contenido-${nList[i].orden}`] = nList[i].contenido;
      data[`condicion-${nList[i].orden}`] = nList[i].condicion;
    }

    form.setFieldsValue(data);
  }, [formulas]);

  const onTipoChange = (e) => {
    const value = e.target.value;

    if (value === "true") {
      setCondicional(true);
    } else {
      setCondicional(false);

      let nList = [...list.slice(0, 1)];
      nList[0].condicion = "";
      setList(nList);

      form.setFieldsValue({ "condicion-1": "" });
    }
  };

  const onInputClick = (event) => {
    focused = event.target;
    position = event.target.selectionStart;
  };

  const onInputKeyup = (event) => {
    focused = event.target;
    position = event.target.selectionStart;
  };

  const onFilterChange = (e) => {
    const value = e.target.value.toLowerCase() ?? "";

    let nOperandos = [...operandos];
    for (let i = 0; i < nOperandos.length; i++) {
      nOperandos[i].visible =
        value === "" ||
        nOperandos[i].nombre?.toLowerCase().indexOf(value) > -1 ||
        nOperandos[i].descripcion?.toLowerCase().indexOf(value) > -1 ||
        nOperandos[i].token?.toLowerCase().indexOf(value) > -1;
    }

    setOperandos(nOperandos);
  };

  const onTagClick = (tag) => {
    // Revisar si se seleccionó algun input
    if (!focused) {
      showWarning(
        "Debe seleccionar una caja de texto para poder insertar el valor."
      );
      return;
    }

    // Calcular nuevo valor
    let nValue = focused.value ?? "";
    nValue =
      nValue.slice(0, position) + tag.token + " " + nValue.slice(position);

    // Calcular nueva posicion del cursor
    const nPosition = position + tag.token.length + 1;
    position = nPosition;

    // Actualizar valor
    const data = {};
    data[focused.id] = nValue;
    form.setFieldsValue(data);

    // Establecer foco con el cursor en la position correcta
    focused.focus();
    setTimeout(() => {
      focused.setSelectionRange(nPosition, nPosition);
    }, 10);
  };

  const onFormulaAdd = () => {
    let nList = [...list];
    nList.push({
      contenido: "",
      condicion: "",
      orden: index + 1,
    });
    setList(nList);
    setIndex(index + 1);
  };

  const onFormulaDelete = (index) => {
    let nList = [...list];
    nList.splice(index, 1);
    setList(nList);
  };

  return (
    <div className="form-formulas">
      <Row>
        <Col span={6} className="formulas-sidebar">
          <div className="operadores">
            <h4>Operadores Aritméticos</h4>
            {operadores
              .filter((x) => x.tipo === 1)
              .map((x) => (
                <Tooltip
                  key={x.id}
                  placement="top"
                  title={x.nombre}
                  className="aqua"
                >
                  <Tag onClick={() => onTagClick(x)}>{x.token}</Tag>
                </Tooltip>
              ))}
          </div>

          <div className="operadores">
            <h4>Operadores Lógicos</h4>
            {operadores
              .filter((x) => x.tipo === 2)
              .map((x) => (
                <Tooltip
                  key={x.id}
                  placement="top"
                  title={x.nombre}
                  className="green"
                >
                  <Tag onClick={() => onTagClick(x)}>{x.token}</Tag>
                </Tooltip>
              ))}
          </div>

          <div className="operandos">
            <h4>Operandos</h4>
            <Input
              className="search"
              placeholder="Buscar..."
              suffix={<FiSearch />}
              onChange={onFilterChange}
            />

            <Scrollbars className="sidebar-scroll" autoHide>
              {/* Legajo */}
              {operandos.filter((x) => x.tipo === "1" && x.visible).length >
                0 && (
                <div className="operandos-section">
                  <p>Legajo</p>
                  {operandos
                    .filter((x) => x.tipo === "1" && x.visible)
                    .map((x) => (
                      <Popover
                        key={x.id}
                        content={x.descripcion}
                        title={x.nombre}
                      >
                        <Tag
                          className="operando purple"
                          onClick={() => onTagClick(x)}
                        >
                          {x.token}
                        </Tag>
                      </Popover>
                    ))}
                </div>
              )}

              {/* Parámetros */}
              {operandos.filter((x) => x.tipo === "3" && x.visible).length >
                0 && (
                <div className="operandos-section">
                  <p>Parámetros</p>
                  {operandos
                    .filter((x) => x.tipo === "3" && x.visible)
                    .map((x) => (
                      <Popover
                        key={x.id}
                        content={x.descripcion}
                        title={x.nombre}
                      >
                        <Tag
                          className="operando aqua"
                          onClick={() => onTagClick(x)}
                        >
                          {x.token}
                        </Tag>
                      </Popover>
                    ))}
                </div>
              )}

              {/* Calculos Auxiliares */}
              {operandos.filter((x) => x.tipo === "4" && x.visible).length >
                0 && (
                <div className="operandos-section">
                  <p>Cálculos Auxiliares</p>
                  {operandos
                    .filter((x) => x.tipo === "4" && x.visible)
                    .map((x) => (
                      <Popover
                        key={x.id}
                        content={x.descripcion}
                        title={x.nombre}
                      >
                        <Tag
                          className="operando green"
                          onClick={() => onTagClick(x)}
                        >
                          {x.token}
                        </Tag>
                      </Popover>
                    ))}
                </div>
              )}

              {/* Resultados */}
              {operandos.filter((x) => x.tipo === "6" && x.visible).length >
                0 && (
                <div className="operandos-section">
                  <p>Resultados</p>
                  {operandos
                    .filter((x) => x.tipo === "6" && x.visible)
                    .map((x) => (
                      <Popover
                        key={x.id}
                        content={x.descripcion}
                        title={x.nombre}
                      >
                        <Tag
                          className="operando purple"
                          onClick={() => onTagClick(x)}
                        >
                          {x.token}
                        </Tag>
                      </Popover>
                    ))}
                </div>
              )}

              {/* Totales */}
              {operandos.filter((x) => x.tipo === "5" && x.visible).length >
                0 && (
                <div className="operandos-section">
                  <p>Totales</p>
                  {operandos
                    .filter((x) => x.tipo === "5" && x.visible)
                    .map((x) => (
                      <Popover
                        key={x.id}
                        content={x.descripcion}
                        title={x.nombre}
                      >
                        <Tag
                          className="operando aqua"
                          onClick={() => onTagClick(x)}
                        >
                          {x.token}
                        </Tag>
                      </Popover>
                    ))}
                </div>
              )}

              {/* Tramos */}
              {operandos.filter((x) => x.tipo === "7" && x.visible).length >
                0 && (
                <div className="operandos-section">
                  <p>Tramos</p>
                  {operandos
                    .filter((x) => x.tipo === "7" && x.visible)
                    .map((x) => (
                      <Popover
                        key={x.id}
                        content={x.descripcion}
                        title={x.nombre}
                      >
                        <Tag
                          className="operando green"
                          onClick={() => onTagClick(x)}
                        >
                          {x.token}
                        </Tag>
                      </Popover>
                    ))}
                </div>
              )}

              {/* Deducciones */}
              {operandos.filter((x) => x.tipo === "8" && x.visible).length >
                0 && (
                <div className="operandos-section">
                  <p>Deducciones</p>
                  {operandos
                    .filter((x) => x.tipo === "8" && x.visible)
                    .map((x) => (
                      <Popover
                        key={x.id}
                        content={x.descripcion}
                        title={x.nombre}
                      >
                        <Tag
                          className="operando purple"
                          onClick={() => onTagClick(x)}
                        >
                          {x.token}
                        </Tag>
                      </Popover>
                    ))}
                </div>
              )}

              {/* Novedades */}
              {operandos.filter((x) => x.tipo === "2" && x.visible).length >
                0 && (
                <div className="operandos-section">
                  <p>Novedades</p>
                  {operandos
                    .filter((x) => x.tipo === "2" && x.visible)
                    .map((x) => (
                      <Popover
                        key={x.id}
                        content={x.descripcion}
                        title={x.nombre}
                      >
                        <Tag
                          className="operando aqua"
                          onClick={() => onTagClick(x)}
                        >
                          {x.token}
                        </Tag>
                      </Popover>
                    ))}
                </div>
              )}
            </Scrollbars>
          </div>
        </Col>

        <Col span={18} className="formulas-content">
          <div className="header-formulas">
            <Form.Item label="Tipo de Fórmula" name="tipoFormula">
              <Radio.Group onChange={onTipoChange}>
                <Radio.Button value="false">Fija</Radio.Button>
                <Radio.Button value="true">Condicional</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </div>

          <div className="formulas-list">
            {list.map((x, i) => (
              <div className="formulas-item" key={i}>
                <Row gutter={20}>
                  <Col span={condicional ? 14 : 22}>
                    <Form.Item label="Fórmula" name={`contenido-${x.orden}`}>
                      <Input onClick={onInputClick} onKeyUp={onInputKeyup} />
                    </Form.Item>
                  </Col>
                  {condicional && (
                    <Col span={8}>
                      <Form.Item
                        label="Condición"
                        name={`condicion-${x.orden}`}
                      >
                        <Input onClick={onInputClick} onKeyUp={onInputKeyup} />
                      </Form.Item>
                    </Col>
                  )}
                  {i > 0 && (
                    <Col span={2}>
                      <div
                        className="formulas-delete"
                        onClick={() => onFormulaDelete(i)}
                      >
                        <FiX />
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
            ))}
          </div>

          {condicional && (
            <div className="formulas-add" onClick={onFormulaAdd}>
              Agregar opción
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default FormulasForm;
