/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState, Fragment } from "react";
import { Dropdown, Form, Menu, Button, Modal, Switch } from "antd";
import { SlideDown } from "react-slidedown";
import {
  FiChevronDown,
  FiChevronUp,
  FiCopy,
  FiEdit,
  FiGrid,
  FiMoreVertical,
  FiPercent,
  FiTrash2,
} from "react-icons/fi";
import { BiCalculator } from "react-icons/bi";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { Context } from "./../../../../services/context";
import http from "./../../../../services/http";
import { showSuccess } from "./../../../../services/notificacion";
import { hasAction, actions } from "./../../../../services/security";

import CalcularForm from "./CalcularForm";
import ClonarForm from "./ClonarForm";
import ConceptosForm from "./ConceptosForm";
import FormulasForm, {
  parseFormulas,
  validateFormulas,
} from "./../../../../components/FormulasForm";

import "react-slidedown/lib/slidedown.css";

const Conceptos = () => {
  const [form] = Form.useForm();

  const CREAR = hasAction(actions.CONVENIOS_CONCEPTOS_CREAR);
  const EDITAR = hasAction(actions.CONVENIOS_CONCEPTOS_EDITAR);
  const ELIMINAR = hasAction(actions.CONVENIOS_CONCEPTOS_ELIMINAR);
  const ACTUALIZAR_FORMULAS = hasAction(actions.CONVENIOS_ACTUALIZAR_FORMULAS);

  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useContext(Context);

  const [conceptos, setConceptos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);

  const [current, setCurrent] = useState({});
  const [formulas, setFormulas] = useState([]);

  const [visible, setVisible] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const [formulasVisible, setFormulasVisible] = useState(false);
  const [testVisible, setTestVisible] = useState(false);
  const [clonarVisible, setClonarVisible] = useState(false);

  const menu = (item) => {
    return (
      <Menu className="table-actions-menu">
        {EDITAR && (
          <Menu.Item key="0" onClick={() => onEdit(item)}>
            <FiEdit />
            Editar
          </Menu.Item>
        )}
        {ACTUALIZAR_FORMULAS && !item.formulasNovedad && (
          <Fragment>
            <Menu.Divider />
            <Menu.Item key="1" onClick={() => onEditFormula(item)}>
              <FiPercent />
              Fórmulas
            </Menu.Item>
            <Menu.Item key="2" onClick={() => onTestFormula(item)}>
              <BiCalculator />
              Calcular
            </Menu.Item>
            <Menu.Item key="3" onClick={() => onCloneFormula(item)}>
              <FiCopy />
              Clonar
            </Menu.Item>
            <Menu.Divider />
          </Fragment>
        )}
        {ELIMINAR && (
          <Menu.Item key="4" onClick={() => onDelete(item)}>
            <FiTrash2 />
            Eliminar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  useEffect(() => {
    async function getList() {
      const response = await http.get(`convenios/${state.id}/conceptos`);
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setConceptos(data.list);
      }
    }

    getList();
  }, [reload]);

  const onAdd = () => {
    setCurrent({});
    setFormVisible(true);
  };

  const onEdit = (item) => {
    setCurrent(item);
    setFormVisible(true);
  };

  const onDisable = async (item) => {
    await http.put(`convenios/conceptos/${item.id}/disable`);
  };

  const onEditFormula = (item) => {
    setCurrent(item);
    let nFormulas = [...item.formulas];
    setFormulas(nFormulas);
    setFormulasVisible(true);
  };

  const onTestFormula = (item) => {
    setCurrent(item);
    setTestVisible(true);
  };

  const onCloneFormula = (item) => {
    setCurrent(item);
    setClonarVisible(true);
  };

  const onSaveFormulas = async (values) => {
    const nFormulas = parseFormulas(values);
    if (!validateFormulas(nFormulas)) {
      return;
    }

    setLoading(true);

    const rSave = await http.put(`convenios/conceptos/${current.id}/formulas`, {
      formulas: nFormulas,
    });

    if (rSave && rSave.data.code === 0) {
      setFormulasVisible(false);
    }

    setReload(!reload);
    setLoading(false);
  };

  const onDelete = (item) => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea eliminar este concepto?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const rDelete = await http.delete(`convenios/conceptos/${item.id}`);
        if (rDelete && rDelete.data.code === 0) {
          showSuccess("El concepto fue eliminado exitosamente.");
          setReload(!reload);
        }
      },
    });
  };

  return (
    <div className="section card">
      <div className="section-header" onClick={() => setVisible(!visible)}>
        <div className="icon">
          <FiGrid />
        </div>
        <div className="content">
          <div className="title">Conceptos</div>
          <div className="subtitle">{conceptos.length} elementos</div>
        </div>
        <div className="collapse">
          {visible ? <FiChevronUp /> : <FiChevronDown />}
        </div>
      </div>
      <SlideDown closed={!visible}>
        <div className="section-content">
          <table className="table-small">
            <thead>
              <tr>
                <th></th>
                <th>Detalle</th>
                <th>Liquidaciones</th>
                <th style={{ textAlign: "center" }}>Secuencia</th>
                <th style={{ textAlign: "center" }}>Habilitado</th>
              </tr>
            </thead>
            <tbody>
              {conceptos.map((x) => (
                <tr key={x.id}>
                  <td className="table-actions">
                    <Dropdown
                      key={x.id}
                      overlay={() => menu(x)}
                      trigger={["click"]}
                      placement="bottomLeft"
                      arrow
                    >
                      <div>
                        <FiMoreVertical />
                      </div>
                    </Dropdown>
                  </td>
                  <td>{x.concepto}</td>
                  <td>{x.liquidacionesTexto}</td>
                  <td style={{ textAlign: "center" }}>{x.secuencia}</td>
                  <td style={{ textAlign: "center" }}>
                    <Switch
                      size="small"
                      disabled={!EDITAR}
                      defaultChecked={x.habilitado}
                      onChange={() => onDisable(x)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {CREAR && (
            <div className="table-footer-actions">
              <Button type="primary" onClick={onAdd}>
                Agregar
              </Button>
            </div>
          )}
        </div>
      </SlideDown>

      <Modal
        visible={formVisible}
        onCancel={() => setFormVisible(false)}
        footer={null}
        maskClosable={false}
        keyboard={false}
        destroyOnClose={true}
      >
        <ConceptosForm
          item={current}
          onSave={() => setReload(!reload)}
          onClose={() => setFormVisible(false)}
        />
      </Modal>

      <Modal
        title="Fórmulas"
        visible={formulasVisible}
        onCancel={() => setFormulasVisible(false)}
        footer={null}
        maskClosable={false}
        keyboard={false}
        destroyOnClose={true}
        width="1000px"
      >
        <Form form={form} layout="vertical" onFinish={onSaveFormulas}>
          <FormulasForm form={form} formulas={formulas} />
          <div className="modal-actions">
            <Button type="secondary" onClick={() => setFormulasVisible(false)}>
              Volver
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              Guardar
            </Button>
          </div>
        </Form>
      </Modal>

      <Modal
        title="Calcular"
        visible={testVisible}
        onCancel={() => setTestVisible(false)}
        footer={null}
        maskClosable={false}
        keyboard={false}
        destroyOnClose={true}
        width="600px"
      >
        <CalcularForm
          conceptos={conceptos}
          item={current}
          onClose={() => setTestVisible(false)}
        />
      </Modal>

      <Modal
        visible={clonarVisible}
        onCancel={() => setClonarVisible(false)}
        footer={null}
        maskClosable={false}
        keyboard={false}
        destroyOnClose={true}
      >
        <ClonarForm
          type="concepto"
          item={current}
          onClose={() => setClonarVisible(false)}
        />
      </Modal>
    </div>
  );
};

export default Conceptos;
