import React from "react";

import construction from "./../assets/images/construction.svg";

import "./NotFound.scss";

const NotFound = () => {
  return (
    <div className="not-found">
      <img src={construction} alt="imagen" />

      <h1>Sitio en Construcción</h1>
      <p>
        Este módulo aún esta siendo desarrollado, te avisaremos cuando este
        disponible.
      </p>
    </div>
  );
};

export default NotFound;
