/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import {
  Dropdown,
  Menu,
  Modal,
  Spin,
  Button,
  Col,
  DatePicker,
  Form,
  Select,
  Row,
} from "antd";
import { FiEdit, FiMoreVertical, FiTrash2 } from "react-icons/fi";
import { ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons";

import { Context } from "./../../../services/context";
import http from "./../../../services/http";
import { showSuccess } from "./../../../services/notificacion";
import { hasAction, actions } from "./../../../services/security";

import locale from "antd/es/date-picker/locale/es_ES";
import "moment/locale/es";
import * as moment from "moment";
import fileDownload from "js-file-download";

const { Option } = Select;

const AdministrativosList = ({ onCurrentChange }) => {
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useContext(Context);
  const [form] = Form.useForm();

  const EDITAR =
    state.editing && hasAction(actions.LEGAJOS_ADMINISTRATIVOS_EDITAR);
  const ELIMINAR =
    state.editing && hasAction(actions.LEGAJOS_ADMINISTRATIVOS_ELIMINAR);

  const [list, setList] = useState([]);
  const [motivos, setMotivos] = useState([]);
  const [loading, setLoading] = useState(true);

  const menu = (item) => {
    return (
      <Menu className="table-actions-menu">
        {EDITAR && (
          <Menu.Item key="0" onClick={() => onEdit(item)}>
            <FiEdit />
            Editar
          </Menu.Item>
        )}
        {ELIMINAR && (
          <Menu.Item key="1" onClick={() => onDelete(item)}>
            <FiTrash2 />
            Eliminar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const onEdit = (item) => {
    onCurrentChange(item.id);
  };

  const onDelete = (item) => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea eliminar este acto administrativo?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const rDelete = await http.delete(`legajos/administrativos/${item.id}`);

        if (rDelete && rDelete.data.code === 0) {
          showSuccess("El acto administrativo se elimino exitosamente.");
        }
      },
    });
  };
  const onSearch = async () => {
    setLoading(true);
    const desde = form.getFieldValue("desde")
      ? moment(form.getFieldValue("desde")).toISOString().slice(0, 10)
      : "";
    const hasta = form.getFieldValue("hasta")
      ? moment(form.getFieldValue("hasta")).toISOString().slice(0, 10)
      : "";
    const motivo = form.getFieldValue("motivo") ?? "";
    const rList = await http.get(
      `legajos/administrativos/list?empleado=${state.id}&desde=${desde}&hasta=${hasta}&motivo=${motivo}`
    );

    if (rList && rList.data.code === 0) {
      const data = rList.data.data;
      setList(data);
    }

    setLoading(false);
  };

  const onExport = async () => {
    setLoading(true);
    const desde = form.getFieldValue("desde")
      ? moment(form.getFieldValue("desde")).toISOString().slice(0, 10)
      : "";
    const hasta = form.getFieldValue("hasta")
      ? moment(form.getFieldValue("hasta")).toISOString().slice(0, 10)
      : "";
    const motivo = form.getFieldValue("motivo") ?? "";
    const rList = await http.get(
      `legajos/administrativos/export/list?empleado=${state.id}&desde=${desde}&hasta=${hasta}&motivo=${motivo}`,
      true
    );
    fileDownload(rList.data, `Administrativos_${Date.now()}.xlsx`);

    setLoading(false);
  };

  useEffect(() => {
    async function getData() {
      const response = await http.get(`legajos/administrativos/Data`);

      if (response && response.data.code === 0) {
        const data = response.data.data;
        setMotivos(data.motivos);
      }
    }
    async function getList() {
      setLoading(true);

      const rList = await http.get(
        `legajos/administrativos/list?empleado=${state.id}`
      );

      if (rList && rList.data.code === 0) {
        const data = rList.data.data;
        setList(data);
      }

      setLoading(false);
    }
    getData();
    getList();
  }, [state.reload]);

  return (
    <div className="administrativos-list">
      <Form form={form} className="subsection-form" layout="vertical">
        <Row gutter={20}>
          <Col span="8">
            <Form.Item label="Motivo" name="motivo">
              <Select allowClear showSearch optionFilterProp="children">
                {motivos.map((x) => (
                  <Option key={x.value} value={x.value}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item label="Fecha Desde" name="desde">
              <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item label="Fecha Hasta" name="hasta">
              <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
            </Form.Item>
          </Col>
        </Row>

        <div className="form-actions">
          <Button type="primary" onClick={onSearch}>
            Buscar
          </Button>

          <Button type="primary" onClick={onExport}>
            Exportar
          </Button>
        </div>
      </Form>

      {loading && (
        <div className="subsection-loading">
          <Spin
            indicator={
              <LoadingOutlined
                style={{ fontSize: 40, color: "#04bebe" }}
                spin
              />
            }
          />
          Cargando...
        </div>
      )}

      {!loading && (
        <table className="subsection-table">
          <thead>
            <tr>
              <th></th>
              <th>Motivo</th>
              <th>Fecha</th>
              <th>Valor</th>
              <th>Instrumento</th>
              <th>N°</th>
              <th>Expediente</th>
              <th>Usuario</th>
            </tr>
          </thead>
          <tbody>
            {list.length === 0 && (
              <tr>
                <td className="table-empty" colSpan="8">
                  Sin resultados
                </td>
              </tr>
            )}

            {list.map((x) => (
              <tr key={x.id}>
                <td className="table-actions">
                  <Dropdown
                    key={x.id}
                    overlay={() => menu(x)}
                    trigger={["click"]}
                    placement="bottomLeft"
                    arrow
                  >
                    <div>
                      <FiMoreVertical />
                    </div>
                  </Dropdown>
                </td>
                <td>{x.motivoMovimiento}</td>
                <td>{x.fecha}</td>
                <td>{x.comentarios}</td>
                <td>{x.instrumento}</td>
                <td>{x.instrumentoNumero}</td>
                <td>{x.expediente}</td>
                <td>{x.usuario}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AdministrativosList;
