/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Form, Input, Button, Row, Col, Select, Switch } from "antd";
import { FiGrid } from "react-icons/fi";

import http from "../../../../services/http";
import { showSuccess } from "../../../../services/notificacion";
import { hasAction, actions } from "../../../../services/security";
import { required } from "../../../../services/validation";

import Header from "../../../../components/Header";
const { Option } = Select;

const ConceptosForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const VER = hasAction(actions.CONCEPTOS_VER);
  const CREAR = hasAction(actions.CONCEPTOS_CREAR);
  const EDITAR = hasAction(actions.CONCEPTOS_EDITAR);
  const GUARDAR = match.params.id === "nuevo" ? CREAR : EDITAR;

  const [state, setState] = useState({
    title: match.params.id === "nuevo" ? "Crear" : EDITAR ? "Editar" : "Ver",
    id: match.params.id === "nuevo" ? 0 : parseInt(match.params.id),
    loading: false,
  });

  const [ganancias, setGanancias] = useState([]);
  const [tipos, setTipos] = useState([]);
  const [tiposConceptos, setTiposConceptos] = useState([]);
  const [tiposCalculos, setTiposCalculos] = useState([]);
  const [totalizadores, setTotalizadores] = useState([]);

  const breadcrumb = [
    {
      id: 1,
      title: "Parámetros",
    },
    {
      id: 2,
      title: "Liquidación",
      url: "/parametros/liquidacion",
    },
    {
      id: 3,
      title: "Conceptos",
      url: "/parametros/liquidacion/conceptos",
    },
    { id: 4, title: state.title },
  ];

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    const result =
      state.id === 0
        ? await http.post("conceptos", values)
        : await http.put(`conceptos/${state.id}`, values);

    if (result && result.data.code === 0) {
      showSuccess("El concepto fue guardado exitosamente.");
      history.push("/parametros/liquidacion/conceptos");
    } else {
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {
    async function getForm() {
      const response = await http.get("conceptos/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setTipos(data.tipos);
        setTiposCalculos(data.tiposCalculos);
        setTiposConceptos(data.tiposConceptos);
        setTotalizadores(
          data.totalizadores.filter((d) => d.value !== state.id)
        );
        setGanancias(data.ganancias);
      }

      const rForm = await http.get(`conceptos/${state.id}`);
      if (rForm && rForm.data.code === 0) {
        const data = rForm.data.data;
        form.setFieldsValue(data);
      }
    }

    if (VER) {
      getForm();
    }
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="concepto">
      <Header title={`${state.title} concepto`} breadcrumb={breadcrumb} />

      <Form form={form} layout="vertical" onFinish={onSave}>
        <div className="form card">
          <div className="form-icon">
            <FiGrid />
          </div>
          <Row gutter={20}>
            <Col span="12">
              <Form.Item label="Código" name="codigo" rules={[required]}>
                <Input type="number" maxLength={20} disabled={!GUARDAR} />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Abreviatura"
                name="abreviatura"
                rules={[required]}
              >
                <Input maxLength={50} disabled={!GUARDAR} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Detalle" name="detalle" rules={[required]}>
            <Input maxLength={500} disabled={!GUARDAR} />
          </Form.Item>

          <Row gutter={20}>
            <Col span="8">
              <Form.Item label="Tipo" name="tipoConceptoId" rules={[required]}>
                <Select allowClear showSearch optionFilterProp="children">
                  {tiposConceptos.map((x) => (
                    <Option key={x.value} value={x.value}>
                      {x.text}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span="8">
              <Form.Item label="Tipo Valor" name="tipo" rules={[required]}>
                <Select showSearch optionFilterProp="children">
                  {tipos.map((x) => (
                    <Option key={x.value} value={x.value}>
                      {x.text}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span="8">
              <Form.Item label="Tipo Calculo" name="tipoCalculoId">
                <Select allowClear showSearch optionFilterProp="children">
                  {tiposCalculos.map((x) => (
                    <Option key={x.value} value={x.value}>
                      {x.text}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span="8">
              <Form.Item label="Unidad de Medida" name="unidadMedida">
                <Input type="number" disabled={!GUARDAR} />
              </Form.Item>
            </Col>
            <Col span="8">
              <Form.Item label="Limite" name="limite">
                <Input type="number" disabled={!GUARDAR} />
              </Form.Item>
            </Col>
            <Col span="8">
              <Form.Item
                label="Retroactivo"
                name="retroactivo"
                valuePropName="checked"
              >
                <Switch checkedChildren="Si" unCheckedChildren="No" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={20}>
            <Col span="12">
              <Form.Item label="Ganancias" name="acumuladosIGId">
                <Select allowClear showSearch optionFilterProp="children">
                  {ganancias.map((x) => (
                    <Option key={x.value} value={x.value}>
                      {x.text}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item label="Totalizador" name="totalizador">
                <Select allowClear showSearch optionFilterProp="children">
                  {totalizadores.map((x) => (
                    <Option key={x.value} value={x.value}>
                      {x.text}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={20}>
            <Col span="24">
              <Form.Item label="Fórmulas por Novedad" name="formulasNovedad">
                <Select>
                  <Option value="1">Si</Option>
                  <Option value="0">No</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <div className="form-actions">
            <Link to="/parametros/liquidacion/conceptos">
              <Button type="secondary">Volver</Button>
            </Link>
            {GUARDAR && (
              <Button type="primary" htmlType="submit" loading={state.loading}>
                Guardar
              </Button>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};

export default ConceptosForm;
