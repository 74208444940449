/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { Button, Form, Select, Input, DatePicker } from "antd";
import { FiPrinter } from "react-icons/fi";

import fileDownload from "js-file-download";

import moment from "moment";
import locale from "antd/es/date-picker/locale/es_ES";
import "moment/locale/es";

import { ContextProvider } from "../../../../../services/context";
import http from "../../../../../services/http";
import { hasAction, actions } from "../../../../../services/security";

import Filters from "../../../../../components/Filters";
import FiltersButton from "../../../../../components/FiltersButton";
import Header from "../../../../../components/Header";
import Table from "../../../../../components/Table";

const { Option } = Select;

const Consolidado = () => {
  const VER = hasAction(actions.CONSOLIDADO_VER);

  const [state, setState] = useState({
    filters: {
      regimenSalarial: "",
      agrupamiento: "",
      tramo: "",
      subTramo: "",
      periodo: moment(),
      claseId: "",
    },
    columns: [
      {
        id: 1,
        title: "Regimen Salarial",
        style: {},
        render: (item) => item.regimenSalarial,
      },
      {
        id: 2,
        title: "Agrup.",
        style: {},
        render: (item) => item.agrupamiento,
      },
      {
        id: 3,
        title: "Tramo",
        style: {},
        render: (item) => item.tramo,
      },
      {
        id: 4,
        title: "Subt.",
        style: {},
        render: (item) => item.subTramo,
      },
      {
        id: 5,
        title: "Denominación",
        style: {},
        render: (item) => item.denominacion,
      },
      {
        id: 6,
        title: "Categoría.",
        style: {},
        render: (item) => item.categoria,
      },
      {
        id: 7,
        title: "Cant. De Cargos",
        style: {},
        render: (item) => item.cantidadCargos,
      },
      {
        id: 8,
        title: "Cant. De Cargos Pagados",
        style: {},
        render: (item) => item.cantidadCargosPagados,
      },
      {
        id: 9,
        title: "Cant. De Cargos Disponibles",
        style: {},
        render: (item) => item.cantidadCargosDisponibles,
      },
    ],
    loading: true,
    loaded: false,
    reload: true,
    list: [],
    total: 0,
    page: 1,
    size: 10,
  });

  const [clases, setClases] = useState([]);

  const breadcrumb = [
    { id: 1, title: "Administración" },
    {
      id: 2,
      title: "Estructura",
      url: "/administracion/estructura",
    },
    {
      id: 3,
      title: "Ejecutivo",
      url: "/administracion/estructura/ejecutivo",
    },
    {
      id: 4,
      title: "Consolidado",
      url: "/administracion/estructura/ejecutivo/consolidado",
    },
  ];

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Regimen Salarial" name="regimenSalarial">
          <Input type="number" />
        </Form.Item>
        <Form.Item label="Agrup." name="agrupamiento">
          <Input type="number" />
        </Form.Item>
        <Form.Item label="Tramo" name="tramo">
          <Input type="number" />
        </Form.Item>
        <Form.Item label="SubTramo" name="subTramo">
          <Input type="number" />
        </Form.Item>

        <Form.Item label="Categoría" name="claseId">
          <Select showSearch allowClear optionFilterProp="children">
            {clases.map((m) => (
              <Option key={m.value} value={m.value}>
                {m.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Periodo" name="periodo">
          <DatePicker
            format="MM/YYYY"
            placeholder=""
            picker="month"
            defaultValue={moment()}
            locale={locale}
          />
        </Form.Item>
      </React.Fragment>
    );
  };

  const onExport = async () => {
    const rExport = await http.post(
      "organigramaConsolidado/export/1",
      state.filters,
      true
    );

    fileDownload(rExport.data, `OrganigramaConsolidado_${Date.now()}.xlsx`);
  };

  const buttons = () => {
    return (
      <React.Fragment>
        <FiltersButton />
        <Button type="primary" icon={<FiPrinter />} onClick={onExport}>
          Exportar
        </Button>
      </React.Fragment>
    );
  };

  useEffect(() => {
    async function getData() {
      const response = await http.get("organigramaConsolidado/data/1");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setClases(data.clases);
      }
    }

    getData();
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="funcional page">
      <ContextProvider value={[state, setState]}>
        <Header title="Consolidado" breadcrumb={breadcrumb} buttons={buttons} />
        <Filters filters={filters} />
        <Table url="organigramaConsolidado/list/1" />
      </ContextProvider>
    </div>
  );
};

export default Consolidado;
