/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import http from '../../services/http';

import './LiquidacionLegajo.scss';

const LiquidacionLegajo = ({ item }) => {
  const [state, setState] = useState({
    id: '',
    nombre: '',
    conceptos: [],
    haberesSujetos: '',
    haberesNoSujetos: '',
    asignaciones: '',
    retenciones: '',
    contribucionPatronal: '',
    neto: '',
  });
  const [orderColumn, setOrderColumn] = useState('codigo');
  const [orderAscending, setOrderAscending] = useState(true);

  useEffect(() => {
    async function getData() {
      const response = await http.get(
        `liquidaciones/empleados/${item?.id}/preview`
      );
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setState(data);
      }
    }

    if (item) {
      getData();
    }
  }, [item]);

  const onChangeOrder = (newColumn) => {
    let nOrderColumn = orderColumn;
    let nOrderAscending = orderAscending;

    if (newColumn === nOrderColumn) {
      nOrderAscending = !nOrderAscending;
    } else {
      nOrderColumn = newColumn;
      nOrderAscending = true;
    }

    setOrderColumn(nOrderColumn);
    setOrderAscending(nOrderAscending);
    setState({
      ...state,
      conceptos: state.conceptos.sort((a, b) =>
        nOrderAscending
          ? a[nOrderColumn] - b[nOrderColumn]
          : b[nOrderColumn] - a[nOrderColumn]
      ),
    });
  };

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th
              className={`column-sort ${
                orderColumn === 'secuencia' ? 'active' : ''
              }`}
              onClick={() => onChangeOrder('secuencia')}
            >
              #
            </th>
            <th
              className={`column-sort ${
                orderColumn === 'codigo' ? 'active' : ''
              }`}
              onClick={() => onChangeOrder('codigo')}
            >
              Concepto
            </th>
            <th>Cantidad</th>
            <th>Haberes Sujetos</th>
            <th>Haberes No Sujetos</th>
            <th>Asignaciones Familiares</th>
            <th>Retenciones</th>
            <th style={{ paddingRight: '20px' }}>Contribución Patronal</th>
          </tr>
        </thead>
        <tbody>
          {state.conceptos.map((x) => (
            <tr key={x.codigo}>
              <td>{x.secuencia}</td>
              <td>
                {x.codigo} - {x.detalle}
              </td>
              <td>{x.cantidad}</td>
              <td>{x.haberesSujetosTexto}</td>
              <td>{x.haberesNoSujetosTexto}</td>
              <td>{x.asignacionesTexto}</td>
              <td>{x.retencionesTexto}</td>
              <td style={{ paddingRight: '20px' }}>
                {x.contribucionPatronalTexto}
              </td>
            </tr>
          ))}
          <tr style={{ fontWeight: 600 }}>
            <td></td>
            <td>Totales</td>
            <td></td>
            <td>{state.haberesSujetos}</td>
            <td>{state.haberesNoSujetos}</td>
            <td>{state.asignaciones}</td>
            <td>{state.retenciones}</td>
            <td style={{ paddingRight: '20px' }}>
              {state.contribucionPatronal}
            </td>
          </tr>

          <tr style={{ fontWeight: 600 }}>
            <td></td>
            <td>Neto a Pagar</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{state.neto}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default LiquidacionLegajo;
