/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Button, Form, Input, Spin, Select } from "antd";
import { FiPrinter } from "react-icons/fi";
import { LoadingOutlined } from "@ant-design/icons";

import { ContextProvider } from "../../../../services/context";
import { formatCurrency, formatNumber } from "../../../../services/forms";
import http from "../../../../services/http";
import { hasAction, actions } from "../../../../services/security";

import Filters from "../../../../components/Filters";
import FiltersButton from "../../../../components/FiltersButton";
import Header from "../../../../components/Header";
import fileDownload from "js-file-download";

import "moment/locale/es";
import * as moment from "moment";

import "./Table.scss";

const { Option } = Select;

const SinteticoConcepto = () => {
  const history = useHistory();

  const VER = hasAction(actions.REPORTE_LIQUIDACION_SINTETICO);

  const [loadingExport, setLoadingExport] = useState(false);
  const [conceptos, setConceptos] = useState([]);
  const [convenios, setConvenios] = useState([]);
  const [agrupamientos, setAgrupamientos] = useState([]);
  const [direcciones, setDirecciones] = useState([]);
  const [reparticiones, setReparticiones] = useState([]);
  const [tiposLiquidacion, setTiposLiquidacion] = useState([]);

  const [state, setState] = useState({
    filters: {
      mes: (moment().month() + 1).toString(),
      anio: moment().year().toString(),
      tipoLiquidacion: "",
      banco: "",
      concepto: [],
      convenio: [],
      agrupamiento: "",
      reparticion: "",
      direccion: "",
    },
    loading: true,
    loaded: false,
    reload: true,
    list: [],
    totales: null,
    total: 0,
    page: 1,
    size: 10,
  });

  const breadcrumb = [
    {
      id: 1,
      title: "Reportes",
    },
    {
      id: 2,
      title: "Liquidación",
      url: "/reportes/liquidacion",
    },
    {
      id: 3,
      title: "Sintentico por conceptos",
      url: "/reportes/liquidacion/sintetico",
    },
  ];

  const onExport = async () => {
    setLoadingExport(true);
    const rExport = await http.post(
      "Reportes/Liquidacion/SinteticoConcepto/export/List",
      state.filters,
      true
    );
    setLoadingExport(false);

    fileDownload(rExport.data, `SintenticoPorConceptos${Date.now()}.xlsx`);
  };

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Año" name="anio">
          <Input />
        </Form.Item>
        <Form.Item label="Mes" name="mes">
          <Select allowClear>
            <Option value="1">Enero</Option>
            <Option value="2">Febrero</Option>
            <Option value="3">Marzo</Option>
            <Option value="4">Abril</Option>
            <Option value="5">Mayo</Option>
            <Option value="6">Junio</Option>
            <Option value="7">Julio</Option>
            <Option value="8">Agosto</Option>
            <Option value="9">Septiembre</Option>
            <Option value="10">Octubre</Option>
            <Option value="11">Noviembre</Option>
            <Option value="12">Diciembre</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Tipo de Liquidación" name="tipoLiquidacion">
          <Select allowClear>
            {tiposLiquidacion.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Concepto" name="concepto">
          <Select
            mode="multiple"
            allowClear
            showSearch
            optionFilterProp="children"
            maxTagCount={0}
          >
            {conceptos.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Convenio" name="convenio">
          <Select
            mode="multiple"
            allowClear
            showSearch
            optionFilterProp="children"
            maxTagCount={0}
          >
            {convenios.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Repartición" name="reparticion">
          <Select allowClear showSearch optionFilterProp="children">
            {reparticiones.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Agrupamiento" name="agrupamiento">
          <Select allowClear showSearch optionFilterProp="children">
            {agrupamientos.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Dirección" name="direccion">
          <Select allowClear showSearch optionFilterProp="children">
            {direcciones.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Banco" name="banco">
          <Input />
        </Form.Item>
      </React.Fragment>
    );
  };

  useEffect(() => {
    async function getData() {
      const response = await http.get(
        "reportes/Liquidacion/SinteticoConcepto/Data"
      );
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setAgrupamientos(data.agrupamientos);
        setConceptos(data.conceptos);
        setConvenios(data.convenios);
        setDirecciones(data.direcciones);
        setReparticiones(data.reparticiones);
        setTiposLiquidacion(data.tiposLiquidacion);
      }
    }

    getData();
  }, []);

  useEffect(() => {
    async function getList() {
      if (!state.loaded) {
        return;
      }

      if (!state.loading) {
        setState({ ...state, loading: true });
      }

      const response = await http.post(
        "Reportes/Liquidacion/SinteticoConcepto/List/",
        {
          ...state.filters,
        }
      );

      if (response && response.data.code === 0) {
        const data = response.data.data;
        setState({
          ...state,
          list: data.list,
          total: data.total,
          loading: false,
        });
      } else {
        setState({ ...state, loading: false });
      }

      localStorage.setItem(
        history.location.pathname,
        JSON.stringify({
          filters: state.filters,
          page: state.page,
          size: state.size,
        })
      );
    }

    getList();
  }, [state.loaded, state.reload, state.page, state.size]);

  if (!VER) {
    return <Redirect to="/" />;
  }
  const buttons = () => {
    return (
      <React.Fragment>
        <FiltersButton />
        <Button
          type="primary"
          icon={<FiPrinter />}
          onClick={onExport}
          loading={loadingExport}
        >
          Exportar
        </Button>
      </React.Fragment>
    );
  };

  return (
    <div className="sintetico page">
      <ContextProvider value={[state, setState]}>
        <Header
          title="Sintetico por conceptos"
          breadcrumb={breadcrumb}
          buttons={buttons}
        />
        <Filters filters={filters} />

        <div className="card-table">
          <div className="table">
            {state.loading === true && (
              <div className="loading">
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 40, color: "#04bebe" }}
                      spin
                    />
                  }
                />
                Cargando...
              </div>
            )}

            {state.loading === false && (
              <div className="table-wrapper">
                <table>
                  <thead className="ant-table-thead">
                    <tr>
                      <th
                        rowSpan="2"
                        className="ant-table-cell"
                        style={{ textAlign: "center" }}
                      >
                        Código
                      </th>
                      <th rowSpan="2" className="ant-table-cell">
                        Concepto
                      </th>
                      <th
                        colSpan="2"
                        className="ant-table-cell"
                        style={{ textAlign: "center" }}
                      >
                        Efectivos
                      </th>
                      <th
                        colSpan="2"
                        className="ant-table-cell"
                        style={{ textAlign: "center" }}
                      >
                        Contratados
                      </th>
                      <th
                        rowSpan="2"
                        className="ant-table-cell"
                        style={{ textAlign: "center" }}
                      >
                        Total
                      </th>
                    </tr>
                    <tr>
                      <th
                        className="ant-table-cell"
                        style={{ textAlign: "center" }}
                      >
                        Cantidad
                      </th>
                      <th
                        className="ant-table-cell"
                        style={{ textAlign: "center" }}
                      >
                        Importes
                      </th>
                      <th
                        className="ant-table-cell"
                        style={{ textAlign: "center" }}
                      >
                        Cantidad
                      </th>
                      <th
                        className="ant-table-cell"
                        style={{ textAlign: "center" }}
                      >
                        Importes
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.list
                      .filter((d) => d.tipo === 1)
                      .map((i, index) => (
                        <tr key={index} className="values">
                          <td>{i.codigo}</td>
                          <td>{i.concepto}</td>
                          <td>{formatNumber(i.unidadEfectivos)}</td>
                          <td>{formatCurrency(i.importeEfectivos)}</td>
                          <td>{formatNumber(i.unidadContratados)}</td>
                          <td>{formatCurrency(i.importeContratados)}</td>
                          <td>{formatCurrency(i.total)}</td>
                        </tr>
                      ))}
                    {state.totales && (
                      <tr key={"haberes"}>
                        <td></td>
                        <td>
                          <b>TOTAL HABERES SUJETOS</b>
                        </td>
                        <td></td>
                        <td>{state.totales.totalHaberesSujetosEfectivos}</td>
                        <td></td>
                        <td>{state.totales.totalHaberesSujetosContratados}</td>
                        <td>{state.totales.totalHaberesSujetos}</td>
                      </tr>
                    )}

                    {state.list
                      .filter((d) => d.tipo === 2)
                      .map((i, index) => (
                        <tr key={index} className="values">
                          <td>{i.codigo}</td>
                          <td>{i.concepto}</td>
                          <td>{formatNumber(i.unidadEfectivos)}</td>
                          <td>{formatCurrency(i.importeEfectivos)}</td>
                          <td>{formatNumber(i.unidadContratados)}</td>
                          <td>{formatCurrency(i.importeContratados)}</td>
                          <td>{formatCurrency(i.total)}</td>
                        </tr>
                      ))}
                    {state.totales && (
                      <tr className="rowTable" key={"haberesNoSujetos"}>
                        <td></td>
                        <td>
                          <b>TOTAL HABERES NO SUJETOS</b>
                        </td>
                        <td></td>
                        <td>{state.totales.totalHaberesNoSujetosEfectivos}</td>
                        <td></td>
                        <td>
                          {state.totales.totalHaberesNoSujetosContratados}
                        </td>
                        <td>{state.totales.totalHaberesNoSujetos}</td>
                      </tr>
                    )}
                    {state.list
                      .filter((d) => d.tipo === 3)
                      .map((i, index) => (
                        <tr key={index} className="values">
                          <td>{i.codigo}</td>
                          <td>{i.concepto}</td>
                          <td>{formatNumber(i.unidadEfectivos)}</td>
                          <td>{formatCurrency(i.importeEfectivos)}</td>
                          <td>{formatNumber(i.unidadContratados)}</td>
                          <td>{formatCurrency(i.importeContratados)}</td>
                          <td>{formatCurrency(i.total)}</td>
                        </tr>
                      ))}
                    {state.totales && (
                      <tr key={"asignaciones"}>
                        <td></td>
                        <td>
                          <b>TOTAL ASIGNACIONES</b>
                        </td>
                        <td></td>
                        <td>{state.totales.totalAsignacionesEfectivos}</td>
                        <td></td>
                        <td>{state.totales.totalAsignacionesContratados}</td>
                        <td>{state.totales.totalAsignaciones}</td>
                      </tr>
                    )}
                    {state.totales && (
                      <tr key={"totalhaberes"}>
                        <td></td>
                        <td>
                          <b>TOTAL HABERES</b>
                        </td>
                        <td></td>
                        <td>{state.totales.totalHaberesEfectivos}</td>
                        <td></td>
                        <td>{state.totales.totalHaberesContratados}</td>
                        <td>{state.totales.totalHaberes}</td>
                      </tr>
                    )}
                    {state.list
                      .filter((d) => d.tipo === 4)
                      .map((i, index) => (
                        <tr key={index} className="values">
                          <td>{i.codigo}</td>
                          <td>{i.concepto}</td>
                          <td>{formatNumber(i.unidadEfectivos)}</td>
                          <td>{formatCurrency(i.importeEfectivos)}</td>
                          <td>{formatNumber(i.unidadContratados)}</td>
                          <td>{formatCurrency(i.importeContratados)}</td>
                          <td>{formatCurrency(i.total)}</td>
                        </tr>
                      ))}
                    {state.totales && (
                      <tr key={"retenciones"}>
                        <td></td>
                        <td>
                          <b>TOTAL RETENCIONES</b>
                        </td>
                        <td></td>
                        <td>
                          {state.totales.totalImportesRetenecionesEfectivos}
                        </td>
                        <td></td>
                        <td>
                          {state.totales.totalImportesRetenecionesContratados}
                        </td>
                        <td>{state.totales.totalImportesReteneciones}</td>
                      </tr>
                    )}
                    {state.totales && (
                      <tr key={"neto"}>
                        <td></td>
                        <td>
                          <b>NETO LIQUIDADO</b>
                        </td>
                        <td></td>
                        <td>{state.totales.netoLiquidadoEfectivos}</td>
                        <td></td>
                        <td>{state.totales.netoLiquidadoContratados}</td>
                        <td>{state.totales.netoLiquidado}</td>
                      </tr>
                    )}

                    {state.list
                      .filter((d) => d.tipo === 5)
                      .map((i, index) => (
                        <tr key={index} className="values">
                          <td>{i.codigo}</td>
                          <td>{i.concepto}</td>
                          <td>{formatNumber(i.unidadEfectivos)}</td>
                          <td>{formatCurrency(i.importeEfectivos)}</td>
                          <td>{formatNumber(i.unidadContratados)}</td>
                          <td>{formatCurrency(i.importeContratados)}</td>
                          <td>{formatCurrency(i.total)}</td>
                        </tr>
                      ))}
                    {state.totales && (
                      <tr key={"contribucionPatronal"}>
                        <td></td>
                        <td>
                          <b>TOTAL CONTRIBUCIONES PATRONALES</b>
                        </td>
                        <td></td>
                        <td>
                          {state.totales.totalContribucionPatronalEfectivos}
                        </td>
                        <td></td>
                        <td>
                          {state.totales.totalContribucionPatronalContratados}
                        </td>
                        <td>{state.totales.totalContribucionPatronal}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </ContextProvider>
    </div>
  );
};

export default SinteticoConcepto;
