/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import { FiCheckSquare, FiPaperclip } from "react-icons/fi";
import locale from "antd/es/date-picker/locale/es_ES";
import "moment/locale/es";

import { Context } from "../../../services/context";
import { parseDate, parseToDate } from "../../../services/forms";
import http from "../../../services/http";
import { showSuccess } from "../../../services/notificacion";
import { required } from "../../../services/validation";

import Archivos from "./Archivos";
import EvaluacionesCriteriosList from "./EvaluacionesCriterios";

const { TextArea } = Input;
const { Option } = Select;

const EvaluacionesForm = ({ current, onCurrentChange }) => {
  const [form] = Form.useForm();
  const [state, setState] = useContext(Context);
  const [criterios, setCriterios] = useState([]);

  const onBack = () => {
    onCurrentChange(null);
  };

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    values.empleadoId = state.id;
    values.fecha = parseDate(values.fecha);
    values.criterios = criterios;

    const rSave =
      current === 0
        ? await http.post("legajos/evaluaciones", values)
        : await http.put(`legajos/evaluaciones/${current}`, values);

    if (rSave && rSave.data.code === 0) {
      showSuccess("La evaluación fue guardada exitosamente.");
      setState({ ...state, loading: false });
      onCurrentChange(null);
    } else {
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {
    async function getForm() {
      form.resetFields();

      const rForm = await http.get(`legajos/evaluaciones/${current}`);
      if (rForm && rForm.data.code === 0) {
        const data = rForm.data.data;
        setCriterios(data.form.criterios);
        data.form.fecha = parseToDate(data.form.fecha);
        form.setFieldsValue(data.form);
      }
    }

    getForm();
  }, [state.id, state.reload]);

  return (
    <div className="evaluaciones-form ">
      <Form
        form={form}
        className="subsection-form"
        layout="vertical"
        onFinish={onSave}
      >
        <Row gutter={20}>
          <Col span="16">
            <Form.Item label="Tipo" name="tipoEvaluacion" rules={[required]}>
              <Select allowClear showSearch optionFilterProp="children">
                {state.tiposEvaluacion.map((x) => (
                  <Option key={x.value} value={x.value}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item label="Fecha" name="fecha" rules={[required]}>
              <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span="16">
            <Form.Item label="Evaluador" name="evaluador" rules={[required]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item
              label="Calificación"
              name="calificacion"
              rules={[required]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Comentarios" name="comentarios" className="textarea">
          <TextArea maxLength={2000} showCount rows={4} />
        </Form.Item>

        <div className="form-actions">
          <Button type="secondary" onClick={onBack}>
            Volver
          </Button>
          <Button type="primary" htmlType="submit" loading={state.loading}>
            Guardar
          </Button>
        </div>
      </Form>

      <hr />

      <div className="section-header">
        <div className="section-title">
          Criterios de Evaluación
          <FiCheckSquare />
        </div>
        <div className="section-subtitle">
          Criterios que son tenidos en cuenta durante esta evaluación.
        </div>
      </div>
      <EvaluacionesCriteriosList list={criterios} onUpdate={setCriterios} />

      {current > 0 && (
        <React.Fragment>
          <hr />
          <div className="section-header">
            <div className="section-title">
              Documentación
              <FiPaperclip />
            </div>
            <div className="section-subtitle">
              Documentación asociada con el familiar.
            </div>
          </div>
          <Archivos seccion="6" evaluacion={current} />
        </React.Fragment>
      )}
    </div>
  );
};

export default EvaluacionesForm;
