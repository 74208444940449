/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { AnimatedSwitch } from "react-router-transition";
import { Scrollbars } from "react-custom-scrollbars";
import { validateToken } from "./../services/security";
import NavBar from "./../components/NavBar";
import SideBar from "./../components/SideBar";
import Inicio from "./Inicio";
import NotFound from "./NotFound";

import "./Master.scss";

////// Administracion
//// Legajos
import Legajos from "./Administracion/Legajos/Legajos";
import LegajosForm from "./Administracion/Legajos/LegajosForm";

//// Estructura
import Estructura from "./Administracion/Estructura/Estructura";

// Ejecutivo
import Ejecutivo from "./Administracion/Estructura/Ejecutivo/Ejecutivo";
import EjecutivoTradicional from "./Administracion/Estructura/Ejecutivo/Tradicional/Tradicional";
import EjecutivoFuncional from "./Administracion/Estructura/Ejecutivo/Funcional/Funcional";
import EjecutivoConsolidado from "./Administracion/Estructura/Ejecutivo/Consolidado/Consolidado";

// HCD
import HCD from "./Administracion/Estructura/HCD/HCD";
import HCDTradicional from "./Administracion/Estructura/HCD/Tradicional/Tradicional";
import HCDFuncional from "./Administracion/Estructura/HCD/Funcional/Funcional";
import HCDConsolidado from "./Administracion/Estructura/HCD/Consolidado/Consolidado";

// SEOS
import SEOS from "./Administracion/Estructura/SEOS/SEOS";
import SEOSTradicional from "./Administracion/Estructura/SEOS/Tradicional/Tradicional";
import SEOSFuncional from "./Administracion/Estructura/SEOS/Funcional/Funcional";
import SEOSConsolidado from "./Administracion/Estructura/SEOS/Consolidado/Consolidado";

// Medicina Laboral
import MedicinaLaboral from "./Administracion/Medicina/MedicinaLaboral";
import MedicinaLaboralForm from "./Administracion/Medicina/MedicinaLaboralForm";

// Seguridad
import Seguridad from "./Administracion/Seguridad/Seguridad";
import AsignacionesElementos from "./Administracion/Seguridad/AsignacionesElementos/AsignacionesElementos";
import AsignacionesElementosForm from "./Administracion/Seguridad/AsignacionesElementos/AsignacionesElementosForm";

// Liquidacion
import Liquidacion from "./Liquidacion/Liquidacion";
import LiquidacionForm from "./Liquidacion/LiquidacionForm";
import LiquidacionDetail from "./Liquidacion/LiquidacionDetail";
import LiquidacionBuscador from "./Liquidacion/Buscador/Buscador";

//// Parametros
// Parametros - Estructura
import ParametrosEstructura from "./Parametros/Estructura/Estructura";
import Agrupamientos from "./Parametros/Estructura/Agrupamientos/Agrupamientos";
import AgrupamientosForm from "./Parametros/Estructura/Agrupamientos/AgrupamientosForm";
import AgrupamientosClases from "./Parametros/Estructura/AgrupamientosClases/AgrupamientosClases";
import AgrupamientosClasesForm from "./Parametros/Estructura/AgrupamientosClases/AgrupamientosClasesForm";
import CargosSindicales from "./Parametros/Estructura/CargosSindacales/CargosSindicales";
import CargosSindicalesForm from "./Parametros/Estructura/CargosSindacales/CargosSindicalesForm";
import Direcciones from "./Parametros/Estructura/Direcciones/Direcciones";
import DireccionesForm from "./Parametros/Estructura/Direcciones/DireccionesForm";
import RelojesBiometricos from "./Parametros/Estructura/RelojesBiometricos/RelojesBiometricos";
import RelojesBiometricosForm from "./Parametros/Estructura/RelojesBiometricos/RelojesBiometricosForm";
import LugaresTrabajo from "./Parametros/Estructura/LugaresTrabajo/LugaresTrabajo";
import LugaresTrabajoForm from "./Parametros/Estructura/LugaresTrabajo/LugaresTrabajoForm";
import Secretarias from "./Parametros/Estructura/Secretarias/Secretarias";
import SecretariasForm from "./Parametros/Estructura/Secretarias/SecretariasForm";
import Subdirecciones from "./Parametros/Estructura/Subdirecciones/Subdirecciones";
import SubdireccionesForm from "./Parametros/Estructura/Subdirecciones/SubdireccionesForm";

// Parametros - Generales
import ParametrosGenerales from "./Parametros/Generales/Generales";
import Afjp from "./Parametros/Generales/Afjp/Afjp";
import AfjpForm from "./Parametros/Generales/Afjp/AfjpForm";
import Art from "./Parametros/Generales/Art/Art";
import ArtForm from "./Parametros/Generales/Art/ArtForm";
import AsignacionesTitulo from "./Parametros/Generales/AsignacionesTitulo/AsignacionesTitulo";
import AsignacionesTituloForm from "./Parametros/Generales/AsignacionesTitulo/AsignacionesTituloForm";
import Bancos from "./Parametros/Generales/Bancos/Bancos";
import BancosForm from "./Parametros/Generales/Bancos/BancosForm";
import Clases from "./Parametros/Generales/Clases/Clases";
import ClasesForm from "./Parametros/Generales/Clases/ClasesForm";
import Convenios from "./Parametros/Generales/Convenios/Convenios";
import ConveniosForm from "./Parametros/Generales/Convenios/ConveniosForm";
import CriteriosEvaluacion from "./Parametros/Generales/CriteriosEvaluacion/CriteriosEvaluacion";
import CriteriosEvaluacionForm from "./Parametros/Generales/CriteriosEvaluacion/CriteriosEvaluacionForm";
import Escalafon from "./Parametros/Generales/Escalafon/Escalafon";
import EscalafonForm from "./Parametros/Generales/Escalafon/EscalafonForm";
import TareasForm from "./Parametros/Generales/Tareas/TareasForm";
import Tareas from "./Parametros/Generales/Tareas/Tareas";
import Funciones from "./Parametros/Generales/Funciones/Funciones";
import FuncionesForm from "./Parametros/Generales/Funciones/FuncionesForm";
import GruposSanguineosForm from "./Parametros/Generales/GruposSanguineos/GruposSanguineosForm";
import GruposSanguineos from "./Parametros/Generales/GruposSanguineos/GruposSanguineos";
import Idiomas from "./Parametros/Generales/Idiomas/Idiomas";
import IdiomasForm from "./Parametros/Generales/Idiomas/IdiomasForm";
import Motivos from "./Parametros/Generales/Motivos/Motivos";
import MotivosForm from "./Parametros/Generales/Motivos/MotivosForm";
import MotivosBaja from "./Parametros/Generales/MotivosBaja/MotivosBaja";
import MotivosBajaForm from "./Parametros/Generales/MotivosBaja/MotivosBajaForm";
import MotivosMovimiento from "./Parametros/Generales/MotivosMovimiento/MotivosMovimiento";
import MotivosMovimientoForm from "./Parametros/Generales/MotivosMovimiento/MotivosMovimientoForm";
import NivelesEstudio from "./Parametros/Generales/NivelesEstudio/NivelesEstudio";
import NivelesEstudioForm from "./Parametros/Generales/NivelesEstudio/NivelesEstudioForm";
import Paises from "./Parametros/Generales/Paises/Paises";
import PaisesForm from "./Parametros/Generales/Paises/PaisesForm";
import PartidasPresupuestarias from "./Parametros/Generales/PartidasPresupuestarias/PartidasPresupuestarias";
import PartidasPresupuestariasForm from "./Parametros/Generales/PartidasPresupuestarias/PartidasPresupuestariasForm";
import Provincias from "./Parametros/Generales/Provincias/Provincias";
import ProvinciasForm from "./Parametros/Generales/Provincias/ProvinciasForm";
import Sindicatos from "./Parametros/Generales/Sindicatos/Sindicatos";
import SindicatosForm from "./Parametros/Generales/Sindicatos/SindicatosForm";
import TiposSancion from "./Parametros/Generales/TiposSancion/TiposSancion";
import TiposSancionForm from "./Parametros/Generales/TiposSancion/TiposSancionForm";
import Titulos from "./Parametros/Generales/Titulos/Titulos";
import TitulosForm from "./Parametros/Generales/Titulos/TitulosForm";

// Parametros - Medicina
import ParametrosMedicina from "./Parametros/Medicina/Medicina";
import TiposAccidentes from "./Parametros/Medicina/TiposAccidentes/TiposAccidentes";
import TiposAccidentesForm from "./Parametros/Medicina/TiposAccidentes/TiposAccidentesForm";
import MotivoAccidente from "./Parametros/Medicina/MotivoAccidente/MotivoAccidente";
import MotivoAccidenteForm from "./Parametros/Medicina/MotivoAccidente/MotivoAccidenteForm";
import Diagnostico from "./Parametros/Medicina/Diagnostico/Diagnostico";
import DiagnosticoForm from "./Parametros/Medicina/Diagnostico/DiagnosticoForm";

// Parametros - Liquidacion
import ParametrosLiquidacion from "./Parametros/Liquidacion/Liquidacion";
import Conceptos from "./Parametros/Liquidacion/Conceptos/Conceptos";
import ConceptosForm from "./Parametros/Liquidacion/Conceptos/ConceptosForm";
import Formatos from "./Parametros/Liquidacion/Formatos/Formatos";
import FormatosForm from "./Parametros/Liquidacion/Formatos/FormatosForm";
import TipoEmbargo from "./Parametros/Liquidacion/TipoEmbargo/TipoEmbargo";
import TipoEmbargoForm from "./Parametros/Liquidacion/TipoEmbargo/TipoEmbargoForm";
import AgenteEmbargo from "./Parametros/Liquidacion/AgenteEmbargo/AgenteEmbargo";
import AgenteEmbargoForm from "./Parametros/Liquidacion/AgenteEmbargo/AgenteEmbargoForm";
import RegimenesLicencia from "./Parametros/Liquidacion/RegimenesLicencia/RegimenesLicencia";
import RegimenesLicenciaForm from "./Parametros/Liquidacion/RegimenesLicencia/RegimenesLicenciaForm";
import LicenciaCodigos from "./Parametros/Liquidacion/LicenciaCodigos/LicenciaCodigos";
import LicenciaCodigosForm from "./Parametros/Liquidacion/LicenciaCodigos/LicenciaCodigosForm";
import OperandosForm from "./Parametros/Liquidacion/Operandos/OperandosForm";
import Operandos from "./Parametros/Liquidacion/Operandos/Operandos";

// Parametros - Seguridad
import ParametrosSeguridad from "./Parametros/Seguridad/Seguridad";
import TiposElementos from "./Parametros/Seguridad/TiposElementos/TiposElementos";
import TiposElementosForm from "./Parametros/Seguridad/TiposElementos/TiposElementosForm";
import Elementos from "./Parametros/Seguridad/Elementos/Elementos";
import ElementosForm from "./Parametros/Seguridad/Elementos/ElementosForm";

//// Novedades
import Novedades from "./Novedades/Novedades";
// Licencias
import LicenciasMenu from "./Novedades/Licencias/Licencias";
// Licencias - Accidentes por Trabajo
import Accidentes from "./Novedades/Licencias/Accidentes/Accidentes";
import AccidentesForm from "./Novedades/Licencias/Accidentes/AccidentesForm";
// Licencias - Asistencias
import Asistencias from "./Novedades/Licencias/Asistencias/Asistencias";
import AsistenciasForm from "./Novedades/Licencias/Asistencias/AsistenciasForm";
// Licencias - Licencias por Enfermedad
import Enfermedades from "./Novedades/Licencias/Enfermedades/Enfermedades";
import EnfermedadesForm from "./Novedades/Licencias/Enfermedades/EnfermedadesForm";
// Licencias - Licencias Especiales
import Especiales from "./Novedades/Licencias/Especiales/Especiales";
import EspecialesForm from "./Novedades/Licencias/Especiales/EspecialesForm";
// Licencias - Licencias Especiales
import Vacaciones from "./Novedades/Licencias/Vacaciones/Vacaciones";
import VacacionesForm from "./Novedades/Licencias/Vacaciones/VacacionesForm";

// Liquidacion
import LiquidacionMenu from "./Novedades/Liquidacion/Liquidacion";
// Liquidacion - Concursos/Quiebras
import ConcursosQuiebras from "./Novedades/Liquidacion/ConcursosQuiebras/ConcursosQuiebras";
import ConcursosQuiebrasForm from "./Novedades/Liquidacion/ConcursosQuiebras/ConcursosQuiebrasForm";
// Liquidacion - Cuotas Alimentarias
import CuotaAlimentaria from "./Novedades/Liquidacion/CuotaAlimentaria/CuotaAlimentaria";
import CuotaAlimentariaForm from "./Novedades/Liquidacion/CuotaAlimentaria/CuotaAlimentariaForm";
// Liquidacion - Embargos
import Embargo from "./Novedades/Liquidacion/Embargo/Embargo";
import EmbargoForm from "./Novedades/Liquidacion/Embargo/EmbargoForm";
// Liquidacion - Fijas
import NovedadFija from "./Novedades/Liquidacion/NovedadFija/NovedadFija";
import NovedadFijaForm from "./Novedades/Liquidacion/NovedadFija/NovedadFijaForm";
// Liquidacion - Variables
import NovedadVariable from "./Novedades/Liquidacion/NovedadVariable/NovedadVariable";
import NovedadVariableForm from "./Novedades/Liquidacion/NovedadVariable/NovedadVariableForm";
// Liquidacion - ServiciosExtraordinarios
import ServicioExtraordinario from "./Novedades/Liquidacion/ServicioExtraordinario/ServicioExtraordinario";
import ServicioExtraordinarioForm from "./Novedades/Liquidacion/ServicioExtraordinario/ServicioExtraordinarioForm";
// Liquidacion Importacion
import ImportacionForm from "./Novedades/Liquidacion/Importacion/ImportacionForm";

//Ganancias
import Ganancias from "./Liquidacion/Ganancias/Ganancias";
//Tramos
import TramosForm from "./Liquidacion/Ganancias/Tramos/TramosForm";
import Tramos from "./Liquidacion/Ganancias/Tramos/Tramos";

import DeduccionesForm from "./Liquidacion/Ganancias/Deducciones/DeduccionesForm";
import Deducciones from "./Liquidacion/Ganancias/Deducciones/Deducciones";

import DDJJForm from "./Liquidacion/Ganancias/DDJJ/DDJJForm";
import DDJJ from "./Liquidacion/Ganancias/DDJJ/DDJJ";

////// Entorno
// Auditoria
import AuditoriaForm from "./Entorno/Auditoria/AuditoriaForm";
import Auditoria from "./Entorno/Auditoria/Auditoria";

// Perfiles
import PerfilesForm from "./Entorno/Perfiles/PerfilesForm";
import Perfiles from "./Entorno/Perfiles/Perfiles";

// Usuarios
import UsuariosForm from "./Entorno/Usuarios/UsuariosForm";
import Usuarios from "./Entorno/Usuarios/Usuarios";

// Municipios
import MunicipioForm from "./Entorno/Municipio/MunicipioForm";
import Municipio from "./Entorno/Municipio/Municipio";

//// Reportes
// Legajos
import ReporteLegajo from "./Reportes/Legajo/Legajo";
import ControlAsignacion from "./Reportes/Legajo/ControlAsigancion/ControlAsignacion";
import ControlEscolaridad from "./Reportes/Legajo/ControlEscolaridad/ControlEscolaridad";
import ReporteTitulo from "./Reportes/Legajo/Titulo/Titulo";
import Asistencia from "./Reportes/Legajo/Asistencia/Asistencia";

// Liquidaciones
import ReporteLiquidaciones from "./Reportes/Liquidacion/Liquidacion";
import SueldosCaidos from "./Reportes/Liquidacion/SueldosCaidos/SueldosCaidos";
import SinteticoConcepto from "./Reportes/Liquidacion/SinteticoConcepto/SinteticoConcepto";
import FacturacionEntidades from "./Reportes/Liquidacion/FacturacionEntidades/FacturacionEntidades";
import FinalidadFuncion from "./Reportes/Liquidacion/FinalidadFuncion/FinalidadFuncion";

// Novedades
import ReporteNovedades from "./Reportes/Novedades/Novedad";
import Ausentismo from "./Reportes/Novedades/Ausentismo/Ausentismo";
import ReportesAccidentes from "./Reportes/Novedades/Accidentes/Accidentes";
import ReportesEnfermedades from "./Reportes/Novedades/Enfermedades/Enfermedades";
import ReportesEspeciales from "./Reportes/Novedades/Especiales/Especiales";
import ReportesVacaciones from "./Reportes/Novedades/Vacaciones/Vacaciones";
import ReportesVacacionesPendientes from "./Reportes/Novedades/VacacionesPendientes/VacacionesPendientes";

import Acumulado from "./Liquidacion/Acumulado/Acumulado";

const Master = () => {
  const history = useHistory();
  const minWidth = 992;

  const [visible, setVisible] = useState(false);
  const [collapsed, setCollapsed] = useState(window.innerWidth < minWidth);

  const refScroll = useRef(null);

  useEffect(() => {
    if (refScroll.current) {
      refScroll.current.scrollToTop();
    }
  }, [history.location.pathname]);

  useEffect(() => {
    function windowResize() {
      setCollapsed(window.innerWidth < minWidth);
    }

    async function validate() {
      const validToken = await validateToken();

      if (!validToken) {
        localStorage.clear();
        history.push("/login");
      } else {
        setVisible(true);
      }
    }

    window.addEventListener("resize", windowResize);
    validate();
  }, []);

  if (!visible) {
    return <div></div>;
  }

  return (
    <div className="master">
      <SideBar collapsed={collapsed} onCollapse={setCollapsed} />
      <Scrollbars
        className="master-scroll"
        style={{ width: "100%", height: "100vh" }}
        autoHide
        ref={refScroll}
      >
        <main className="content">
          <NavBar collapsed={collapsed} onCollapse={setCollapsed} />
          <AnimatedSwitch
            atEnter={{ opacity: 0 }}
            atLeave={{ opacity: 0 }}
            atActive={{ opacity: 1 }}
            className="switch-wrapper"
          >
            {/* Administracion */}
            {/* Legajos */}
            <Route path="/administracion/legajos/:id" component={LegajosForm} />
            <Route path="/administracion/legajos" component={Legajos} exact />

            {/* Estructura */}
            <Route
              path="/administracion/estructura"
              exact
              component={Estructura}
            />
            <Route
              path="/administracion/estructura/ejecutivo"
              exact
              component={Ejecutivo}
            />
            <Route
              path="/administracion/estructura/ejecutivo/tradicional"
              component={EjecutivoTradicional}
            />

            <Route
              path="/administracion/estructura/ejecutivo/funcional"
              component={EjecutivoFuncional}
            />

            <Route
              path="/administracion/estructura/ejecutivo/consolidado"
              component={EjecutivoConsolidado}
            />
            <Route
              path="/administracion/estructura/hcd"
              exact
              component={HCD}
            />
            <Route
              path="/administracion/estructura/hcd/tradicional"
              component={HCDTradicional}
            />

            <Route
              path="/administracion/estructura/hcd/consolidado"
              component={HCDConsolidado}
            />

            <Route
              path="/administracion/estructura/hcd/funcional"
              component={HCDFuncional}
            />
            <Route
              path="/administracion/estructura/seos"
              exact
              component={SEOS}
            />
            <Route
              path="/administracion/estructura/seos/tradicional"
              component={SEOSTradicional}
            />

            <Route
              path="/administracion/estructura/seos/consolidado"
              component={SEOSConsolidado}
            />
            <Route
              path="/administracion/estructura/seos/funcional"
              component={SEOSFuncional}
            />

            {/* Medicina */}
            <Route
              path="/administracion/medicina"
              exact
              component={MedicinaLaboral}
            />
            <Route
              path="/administracion/medicina/:id"
              component={MedicinaLaboralForm}
            />

            {/* Seguridad */}
            <Route
              path="/administracion/seguridad"
              exact
              component={Seguridad}
            />
            <Route
              path="/administracion/seguridad/asignacion_elementos/:id"
              component={AsignacionesElementosForm}
            />
            <Route
              path="/administracion/seguridad/asignacion_elementos"
              exact
              component={AsignacionesElementos}
            />

            {/* Novedades */}
            <Route path="/novedades" exact component={Novedades} />
            {/* Licencias */}
            <Route
              path="/novedades/licencias"
              exact
              component={LicenciasMenu}
            />
            <Route
              path="/novedades/licencias/accidentes/:id"
              component={AccidentesForm}
            />
            <Route
              path="/novedades/licencias/accidentes"
              exact
              component={Accidentes}
            />
            <Route
              path="/novedades/licencias/asistencias/:id"
              component={AsistenciasForm}
            />
            <Route
              path="/novedades/licencias/asistencias"
              exact
              component={Asistencias}
            />
            <Route
              path="/novedades/licencias/enfermedades/:id"
              component={EnfermedadesForm}
            />
            <Route
              path="/novedades/licencias/enfermedades"
              exact
              component={Enfermedades}
            />
            <Route
              path="/novedades/licencias/especiales/:id"
              component={EspecialesForm}
            />
            <Route
              path="/novedades/licencias/especiales"
              exact
              component={Especiales}
            />
            <Route
              path="/novedades/licencias/vacaciones/:id"
              component={VacacionesForm}
            />
            <Route
              path="/novedades/licencias/vacaciones"
              exact
              component={Vacaciones}
            />

            {/* Liquidacion */}
            <Route
              path="/novedades/liquidacion"
              exact
              component={LiquidacionMenu}
            />
            <Route
              path="/novedades/liquidacion/concursos_quiebras/:id"
              component={ConcursosQuiebrasForm}
            />
            <Route
              path="/novedades/liquidacion/concursos_quiebras"
              exact
              component={ConcursosQuiebras}
            />
            <Route
              path="/novedades/liquidacion/cuotaalimentaria/:id"
              component={CuotaAlimentariaForm}
            />
            <Route
              path="/novedades/liquidacion/cuotaalimentaria"
              exact
              component={CuotaAlimentaria}
            />
            <Route
              path="/novedades/liquidacion/embargo/:id"
              component={EmbargoForm}
            />
            <Route
              path="/novedades/liquidacion/embargo"
              exact
              component={Embargo}
            />

            <Route
              path="/novedades/liquidacion/novedadFija/:id"
              component={NovedadFijaForm}
            />
            <Route
              path="/novedades/liquidacion/novedadFija"
              exact
              component={NovedadFija}
            />

            <Route
              path="/novedades/liquidacion/novedadVariable/:id"
              component={NovedadVariableForm}
            />
            <Route
              path="/novedades/liquidacion/novedadVariable"
              exact
              component={NovedadVariable}
            />

            <Route
              path="/novedades/liquidacion/servicios_extraordinarios/:id"
              component={ServicioExtraordinarioForm}
            />
            <Route
              path="/novedades/liquidacion/servicios_extraordinarios"
              exact
              component={ServicioExtraordinario}
            />

            <Route
              path="/novedades/liquidacion/importar"
              component={ImportacionForm}
            />

            {/* Liquidacion */}
            <Route path="/acumulado" exact component={Acumulado} />
            <Route
              path="/liquidaciones/nuevo"
              exact
              component={LiquidacionForm}
            />
            <Route
              path="/liquidacion/buscador"
              exact
              component={LiquidacionBuscador}
            />
            <Route path="/liquidaciones/:id" component={LiquidacionDetail} />
            <Route path="/liquidaciones" exact component={Liquidacion} />

            {/* Ganancias */}
            <Route path="/liquidacion/ganancias" exact component={Ganancias} />

            <Route
              path="/liquidacion/ganancias/tramos/:id"
              component={TramosForm}
            />
            <Route
              path="/liquidacion/ganancias/tramos"
              exact
              component={Tramos}
            />

            <Route
              path="/liquidacion/ganancias/deducciones/:id"
              component={DeduccionesForm}
            />
            <Route
              path="/liquidacion/ganancias/deducciones"
              exact
              component={Deducciones}
            />

            <Route
              path="/liquidacion/ganancias/ddjj/:id"
              component={DDJJForm}
            />
            <Route path="/liquidacion/ganancias/ddjj" exact component={DDJJ} />

            {/* Reportes */}
            {/* Legajos */}
            <Route path="/reportes/legajo" exact component={ReporteLegajo} />
            <Route
              path="/reportes/legajo/asignaciones"
              exact
              component={ControlAsignacion}
            />
            <Route
              path="/reportes/legajo/escolaridad"
              exact
              component={ControlEscolaridad}
            />
            <Route
              path="/reportes/legajo/titulos"
              exact
              component={ReporteTitulo}
            />
            <Route
              path="/reportes/legajo/asistencia"
              exact
              component={Asistencia}
            />

            {/* Liquidacion */}
            <Route
              path="/reportes/liquidacion"
              exact
              component={ReporteLiquidaciones}
            />
            <Route
              path="/reportes/liquidacion/sueldos"
              exact
              component={SueldosCaidos}
            />
            <Route
              path="/reportes/liquidacion/sintetico"
              exact
              component={SinteticoConcepto}
            />
            <Route
              path="/reportes/liquidacion/facturacion"
              exact
              component={FacturacionEntidades}
            />
            <Route
              path="/reportes/liquidacion/finalidad-funcion"
              exact
              component={FinalidadFuncion}
            />
            {/* Novedades */}
            <Route
              path="/reportes/novedad"
              exact
              component={ReporteNovedades}
            />
            <Route
              path="/reportes/novedad/vacaciones"
              exact
              component={ReportesVacaciones}
            />
            <Route
              path="/reportes/novedad/vacaciones-pendientes"
              exact
              component={ReportesVacacionesPendientes}
            />
            <Route
              path="/reportes/novedad/especiales"
              exact
              component={ReportesEspeciales}
            />
            <Route
              path="/reportes/novedad/enfermedades"
              exact
              component={ReportesEnfermedades}
            />
            <Route
              path="/reportes/novedad/accidentes"
              exact
              component={ReportesAccidentes}
            />
            <Route
              path="/reportes/novedad/ausentismo"
              exact
              component={Ausentismo}
            />

            {/* Parametros */}
            {/* Estructura */}
            <Route
              path="/parametros/estructura"
              exact
              component={ParametrosEstructura}
            />
            <Route
              path="/parametros/estructura/agrupamientos/:id"
              component={AgrupamientosForm}
            />
            <Route
              path="/parametros/estructura/agrupamientos"
              exact
              component={Agrupamientos}
            />
            <Route
              path="/parametros/estructura/agrupamientos_clases/:id"
              component={AgrupamientosClasesForm}
            />
            <Route
              path="/parametros/estructura/agrupamientos_clases"
              exact
              component={AgrupamientosClases}
            />
            <Route
              path="/parametros/estructura/cargos_sindicales/:id"
              component={CargosSindicalesForm}
            />
            <Route
              path="/parametros/estructura/cargos_sindicales"
              exact
              component={CargosSindicales}
            />
            <Route
              path="/parametros/estructura/direcciones/:id"
              component={DireccionesForm}
            />
            <Route
              path="/parametros/estructura/direcciones"
              exact
              component={Direcciones}
            />
            <Route
              path="/parametros/estructura/lugares_trabajo/:id"
              component={LugaresTrabajoForm}
            />
            <Route
              path="/parametros/estructura/lugares_trabajo"
              exact
              component={LugaresTrabajo}
            />
            <Route
              path="/parametros/estructura/relojes_biometricos/:id"
              component={RelojesBiometricosForm}
            />
            <Route
              path="/parametros/estructura/relojes_biometricos"
              exact
              component={RelojesBiometricos}
            />
            <Route
              path="/parametros/estructura/secretarias/:id"
              component={SecretariasForm}
            />
            <Route
              path="/parametros/estructura/secretarias"
              exact
              component={Secretarias}
            />
            <Route
              path="/parametros/estructura/subdirecciones/:id"
              component={SubdireccionesForm}
            />
            <Route
              path="/parametros/estructura/subdirecciones"
              exact
              component={Subdirecciones}
            />

            {/* Generales */}
            <Route
              path="/parametros/generales"
              exact
              component={ParametrosGenerales}
            />
            <Route path="/parametros/generales/afjp/:id" component={AfjpForm} />
            <Route path="/parametros/generales/afjp" exact component={Afjp} />
            <Route path="/parametros/generales/art/:id" component={ArtForm} />
            <Route path="/parametros/generales/art" exact component={Art} />
            <Route
              path="/parametros/generales/asignaciones/:id"
              component={AsignacionesTituloForm}
            />
            <Route
              path="/parametros/generales/asignaciones"
              exact
              component={AsignacionesTitulo}
            />
            <Route
              path="/parametros/generales/bancos/:id"
              component={BancosForm}
            />
            <Route
              path="/parametros/generales/bancos"
              exact
              component={Bancos}
            />
            <Route
              path="/parametros/generales/clases/:id"
              component={ClasesForm}
            />
            <Route
              path="/parametros/generales/clases"
              exact
              component={Clases}
            />
            <Route
              path="/parametros/generales/convenios/:id"
              component={ConveniosForm}
            />
            <Route
              path="/parametros/generales/convenios"
              exact
              component={Convenios}
            />
            <Route
              path="/parametros/generales/criterios/:id"
              component={CriteriosEvaluacionForm}
            />
            <Route
              path="/parametros/generales/criterios"
              exact
              component={CriteriosEvaluacion}
            />
            <Route
              path="/parametros/generales/escalafon/:id"
              component={EscalafonForm}
            />
            <Route
              path="/parametros/generales/escalafon"
              exact
              component={Escalafon}
            />
            <Route
              path="/parametros/generales/funciones/:id"
              component={FuncionesForm}
            />
            <Route
              path="/parametros/generales/funciones"
              exact
              component={Funciones}
            />
            <Route
              path="/parametros/generales/tareas/:id"
              component={TareasForm}
            />
            <Route
              path="/parametros/generales/tareas"
              exact
              component={Tareas}
            />
            <Route
              path="/parametros/generales/grupos_sanguineos/:id"
              component={GruposSanguineosForm}
            />
            <Route
              path="/parametros/generales/grupos_sanguineos"
              exact
              component={GruposSanguineos}
            />
            <Route
              path="/parametros/generales/idiomas/:id"
              component={IdiomasForm}
            />
            <Route
              path="/parametros/generales/idiomas"
              exact
              component={Idiomas}
            />
            <Route
              path="/parametros/generales/motivos/:id"
              component={MotivosForm}
            />
            <Route
              path="/parametros/generales/motivos"
              exact
              component={Motivos}
            />
            <Route
              path="/parametros/generales/motivos_baja/:id"
              component={MotivosBajaForm}
            />
            <Route
              path="/parametros/generales/motivos_baja"
              exact
              component={MotivosBaja}
            />
            <Route
              path="/parametros/generales/motivos_movimiento/:id"
              component={MotivosMovimientoForm}
            />
            <Route
              path="/parametros/generales/motivos_movimiento"
              exact
              component={MotivosMovimiento}
            />
            <Route
              path="/parametros/generales/niveles_estudio/:id"
              component={NivelesEstudioForm}
            />
            <Route
              path="/parametros/generales/niveles_estudio"
              exact
              component={NivelesEstudio}
            />
            <Route
              path="/parametros/generales/paises/:id"
              component={PaisesForm}
            />
            <Route
              path="/parametros/generales/paises"
              exact
              component={Paises}
            />
            <Route
              path="/parametros/generales/partidas-presupuestarias/:id"
              component={PartidasPresupuestariasForm}
            />
            <Route
              path="/parametros/generales/partidas-presupuestarias"
              exact
              component={PartidasPresupuestarias}
            />
            <Route
              path="/parametros/generales/provincias/:id"
              component={ProvinciasForm}
            />
            <Route
              path="/parametros/generales/provincias"
              exact
              component={Provincias}
            />
            <Route
              path="/parametros/generales/sindicatos/:id"
              component={SindicatosForm}
            />
            <Route
              path="/parametros/generales/sindicatos"
              exact
              component={Sindicatos}
            />
            <Route
              path="/parametros/generales/tipos_sancion/:id"
              component={TiposSancionForm}
            />
            <Route
              path="/parametros/generales/tipos_sancion"
              exact
              component={TiposSancion}
            />
            <Route
              path="/parametros/generales/titulos/:id"
              component={TitulosForm}
            />
            <Route
              path="/parametros/generales/titulos"
              exact
              component={Titulos}
            />

            {/* Medicina */}
            <Route
              path="/parametros/medicina"
              exact
              component={ParametrosMedicina}
            />
            <Route
              path="/parametros/medicina/tipos_accidentes/:id"
              component={TiposAccidentesForm}
            />
            <Route
              path="/parametros/medicina/tipos_accidentes"
              exact
              component={TiposAccidentes}
            />
            <Route
              path="/parametros/medicina/motivos_accidentes/:id"
              component={MotivoAccidenteForm}
            />
            <Route
              path="/parametros/medicina/motivos_accidentes"
              exact
              component={MotivoAccidente}
            />
            <Route
              path="/parametros/medicina/diagnosticos/:id"
              component={DiagnosticoForm}
            />
            <Route
              path="/parametros/medicina/diagnosticos"
              exact
              component={Diagnostico}
            />

            {/* Liquidacion */}
            <Route
              path="/parametros/liquidacion"
              exact
              component={ParametrosLiquidacion}
            />
            <Route
              path="/parametros/liquidacion/conceptos/:id"
              component={ConceptosForm}
            />
            <Route
              path="/parametros/liquidacion/conceptos"
              exact
              component={Conceptos}
            />
            <Route
              path="/parametros/liquidacion/formatos/:id"
              component={FormatosForm}
            />
            <Route
              path="/parametros/liquidacion/formatos"
              exact
              component={Formatos}
            />
            <Route
              path="/parametros/liquidacion/tipoEmbargo/:id"
              component={TipoEmbargoForm}
            />
            <Route
              path="/parametros/liquidacion/tipoEmbargo"
              exact
              component={TipoEmbargo}
            />
            <Route
              path="/parametros/liquidacion/agenteEmbargo/:id"
              component={AgenteEmbargoForm}
            />
            <Route
              path="/parametros/liquidacion/agenteEmbargo"
              exact
              component={AgenteEmbargo}
            />
            <Route
              path="/parametros/liquidacion/regimenes/:id"
              component={RegimenesLicenciaForm}
            />
            <Route
              path="/parametros/liquidacion/regimenes"
              exact
              component={RegimenesLicencia}
            />
            <Route
              path="/parametros/liquidacion/licencias_codigos/:id"
              component={LicenciaCodigosForm}
            />
            <Route
              path="/parametros/liquidacion/licencias_codigos"
              exact
              component={LicenciaCodigos}
            />
            <Route
              path="/parametros/liquidacion/operandos/:id"
              component={OperandosForm}
            />
            <Route
              path="/parametros/liquidacion/operandos"
              exact
              component={Operandos}
            />

            {/* Seguridad */}
            <Route
              path="/parametros/seguridad"
              exact
              component={ParametrosSeguridad}
            />
            <Route
              path="/parametros/seguridad/elementos/:id"
              component={ElementosForm}
            />
            <Route
              path="/parametros/seguridad/elementos"
              exact
              component={Elementos}
            />
            <Route
              path="/parametros/seguridad/tipos_elementos/:id"
              component={TiposElementosForm}
            />
            <Route
              path="/parametros/seguridad/tipos_elementos"
              exact
              component={TiposElementos}
            />

            {/* Entorno */}
            {/* Perfiles */}
            <Route path="/entorno/perfiles/:id" component={PerfilesForm} />
            <Route path="/entorno/perfiles" component={Perfiles} />

            {/* Usuarios */}
            <Route path="/entorno/usuarios/:id" component={UsuariosForm} />
            <Route path="/entorno/usuarios" component={Usuarios} />

            {/* Auditoria */}
            <Route path="/entorno/auditoria/:id" component={AuditoriaForm} />
            <Route path="/entorno/auditoria" component={Auditoria} />

            {/* ParametroGeneral */}
            <Route path="/entorno/municipio/:id" component={MunicipioForm} />
            <Route path="/entorno/municipio" component={Municipio} />

            <Route path="/not-found" component={NotFound} />
            <Route path="/" exact component={Inicio} />
            <Redirect to="/not-found" />
          </AnimatedSwitch>
        </main>
      </Scrollbars>
    </div>
  );
};

export default Master;
