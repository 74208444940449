/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import { FiCheckSquare } from "react-icons/fi";

import { Context } from "../../../services/context";
import { required } from "../../../services/validation";

const { Option } = Select;

const EvaluacionesCriteriosForm = ({ item, onSubmit, onCancel }) => {
  const [form] = Form.useForm();
  const [state, setState] = useContext(Context);

  form.setFieldsValue(item);

  return (
    <Modal
      className="evaluaciones-form "
      visible={item !== null}
      onCancel={onCancel}
      footer={null}
      maskClosable={false}
      keyboard={false}
      destroyOnClose={true}
    >
      <Form
        form={form}
        className="subsection-form"
        layout="vertical"
        onFinish={onSubmit}
      >
        <div className="form">
          <div className="form-icon">
            <FiCheckSquare />
          </div>

          <Row gutter={20}>
            <Col span="24">
              <Form.Item label="Criterio" name="criterio" rules={[required]}>
                <Select allowClear showSearch optionFilterProp="children">
                  {state.criterios.map((x) => (
                    <Option key={x.value} value={x.value} disabled={x.disabled}>
                      {x.text}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span="24">
              <Form.Item
                label="Calificación"
                name="calificacion"
                rules={[required]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>

          <div className="form-actions">
            <Button type="secondary" onClick={onCancel}>
              Volver
            </Button>
            <Button type="primary" htmlType="submit">
              Guardar
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default EvaluacionesCriteriosForm;
