/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import {
  Button,
  Form,
  Menu,
  Modal,
  Select,
  Tooltip,
  Input,
  DatePicker,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { FiEdit, FiPlus, FiTrash2, FiPrinter } from "react-icons/fi";
import fileDownload from "js-file-download";

import moment from "moment";
import locale from "antd/es/date-picker/locale/es_ES";
import "moment/locale/es";

import { ContextProvider } from "../../../../services/context";
import { formatNumber } from "../../../../services/forms";
import http from "../../../../services/http";
import { showSuccess } from "../../../../services/notificacion";
import { hasAction, actions } from "../../../../services/security";

import Filters from "../../../../components/Filters";
import FiltersButton from "../../../../components/FiltersButton";
import Header from "../../../../components/Header";
import Table from "../../../../components/Table";

const { Option } = Select;

const NovedadFija = () => {
  const history = useHistory();

  const VER = hasAction(actions.NOVEDADFIJA_VER);
  const CREAR = hasAction(actions.NOVEDADFIJA_CREAR);
  const EDITAR = hasAction(actions.NOVEDADFIJA_EDITAR);
  const ELIMINAR = hasAction(actions.NOVEDADFIJA_ELIMINAR);

  const [state, setState] = useState({
    filters: {
      empleadoId: "",
      conceptoId: "",
      convenio: "",
      tipoLiquidacion: "",
      periodo: null,
      archivo: "",
      empleadoEstado: "",
    },
    columns: [
      {
        id: 1,
        title: "Legajo",
        style: {},
        render: (item) => item.empleadoId,
      },
      {
        id: 2,
        title: "Nombre",
        style: {},
        render: (item) => item.empleado,
      },
      {
        id: 3,
        title: "Fecha",
        style: { textAlign: "center" },
        render: (item) => moment(item.fecha).format("DD/MM/YYYY"),
      },
      {
        id: 4,
        title: "Vencimiento",
        style: { textAlign: "center" },
        render: (item) =>
          item.vencimiento ? moment(item.vencimiento).format("DD/MM/YYYY") : "",
      },
      {
        id: 5,
        title: "Tipo Liquidación",
        style: { textAlign: "center" },
        render: (item) => item.tipoLiquidacion,
      },
      {
        id: 6,
        title: "Concepto",
        style: {},
        render: (item) => item.concepto,
      },
      {
        id: 7,
        title: "Unidades",
        style: { textAlign: "right" },
        render: (item) => formatNumber(item.unidades),
        totalProp: "totalUnidades",
      },
      {
        id: 8,
        title: "Importe",
        style: { textAlign: "right" },
        render: (item) => formatNumber(item.importe),
        totalProp: "totalImporte",
      },
      {
        id: 9,
        title: "Porcentaje",
        style: { textAlign: "right" },
        render: (item) => formatNumber(item.porcentaje),
        totalProp: "totalPorcentaje",
      },
    ],
    loading: true,
    loaded: false,
    reload: true,
    list: [],
    selected: [],
    total: 0,
    page: 1,
    size: 10,
  });

  const [empleados, setEmpleados] = useState([]);
  const [conceptos, setConceptos] = useState([]);
  const [convenios, setConvenios] = useState([]);
  const [tiposLiquidacion, setTiposLiquidacion] = useState([]);

  const breadcrumb = [
    { id: 1, title: "Novedades" },
    {
      id: 2,
      title: "Liquidación",
      url: "/novedades/Liquidacion",
    },
    {
      id: 3,
      title: "Novedades Fijas",
      url: "/novedades/Liquidacion/novedadfija",
    },
  ];

  const buttons = () => {
    return (
      <React.Fragment>
        {state.selected.length > 0 && (
          <React.Fragment>
            <Button
              type="danger"
              icon={<FiTrash2 />}
              onClick={onDeleteMultiple}
            >
              Eliminar
            </Button>
          </React.Fragment>
        )}
        <Button type="secondary" icon={<FiPrinter />} onClick={onExport}>
          Exportar
        </Button>
        <FiltersButton />
        {CREAR && (
          <Button type="primary" icon={<FiPlus />} onClick={onAdd}>
            Nuevo
          </Button>
        )}
        {ELIMINAR && state.filters.archivo && (
          <Tooltip
            className="cursor-pointer"
            placement="left"
            title={"Eliminar novedades del archivo " + state.filters.archivo}
          >
            <Button type="danger" icon={<FiTrash2 />} onClick={onDeleteArchivo}>
              Eliminar
            </Button>
          </Tooltip>
        )}
      </React.Fragment>
    );
  };

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Covenio" name="convenio">
          <Select showSearch allowClear optionFilterProp="children">
            {convenios.map((m) => (
              <Option key={m.value} value={m.value}>
                {m.text}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Empleado" name="empleadoId">
          <Select showSearch allowClear optionFilterProp="children">
            {empleados.map((m) => (
              <Option key={m.value} value={m.value}>
                {m.text}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Concepto" name="conceptoId">
          <Select showSearch allowClear optionFilterProp="children">
            {conceptos.map((m) => (
              <Option key={m.value} value={m.value}>
                {m.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Periodo" name="periodo">
          <DatePicker
            format="MM/YYYY"
            placeholder=""
            picker="month"
            locale={locale}
          />
        </Form.Item>

        <Form.Item label="Archivo" name="archivo">
          <Input />
        </Form.Item>

        <Form.Item label="Tipo Liquidación" name="tipoLiquidacion">
          <Select showSearch allowClear optionFilterProp="children">
            {tiposLiquidacion.map((m) => (
              <Option key={m.value} value={m.value}>
                {m.text}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Estado Empleado" name="empleadoEstado">
          <Select>
            <Option value="1">Habilitado</Option>
            <Option value="0">Dado de baja</Option>
          </Select>
        </Form.Item>
      </React.Fragment>
    );
  };

  const menu = (item) => {
    return (
      <Menu className="table-actions-menu">
        {EDITAR && (
          <Menu.Item key="0" onClick={() => onEdit(item)}>
            <FiEdit />
            Editar
          </Menu.Item>
        )}
        {ELIMINAR && (
          <Menu.Item key="1" onClick={() => onDelete(item)}>
            <FiTrash2 />
            Eliminar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const onAdd = () => {
    history.push("/novedades/liquidacion/novedadfija/nuevo");
  };

  const onEdit = (item) => {
    history.push(`/novedades/liquidacion/novedadfija/${item.id}`);
  };

  const onDeleteArchivo = () => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea eliminar las novedades de este archivo?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const rDelete = await http.delete(
          `NovedadFija/Archivo/${state.filters.archivo}`
        );
        if (rDelete && rDelete.data.code === 0) {
          showSuccess("Las novedades fueron eliminadas exitosamente.");
          setState({
            ...state,
            total: 0,
            filters: {
              empleadoId: state.empleadoId,
              conceptoId: state.conceptoId,
              archivo: "",
            },
          });
        }
      },
    });
  };

  const onExport = async () => {
    const rExport = await http.post("NovedadFija/export", state.filters, true);

    fileDownload(rExport.data, `NovedadesFijas_${Date.now()}.xlsx`);
  };

  const onDelete = (item) => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea eliminar esta novedad?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const url = "novedadfija";
        const data = { ids: [item.id] };
        const response = await http.delete(url, data);

        if (response && response.data.code === 0) {
          showSuccess("El elemento fue eliminado exitosamente.");
          setState({ ...state, reload: !state.reload });
        }
      },
    });
  };

  const onDeleteMultiple = () => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea eliminar las novedades seleccionadas?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const url = "novedadfija";
        const data = { ids: [...state.selected] };
        const response = await http.delete(url, data);

        if (response && response.data.code === 0) {
          showSuccess("Las novedades fueron eliminadas exitosamente.");
          setState({ ...state, reload: !state.reload });
        }
      },
    });
  };

  useEffect(() => {
    async function getData() {
      const response = await http.get("NovedadFija/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setEmpleados(data.empleados);
        setConceptos(data.conceptos);
        setConvenios(data.convenios);
        setTiposLiquidacion(data.tiposLiquidacion);
      }
    }

    getData();
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="NovedadFija page">
      <ContextProvider value={[state, setState]}>
        <Header
          title="Novedades Fijas"
          breadcrumb={breadcrumb}
          buttons={buttons}
        />
        <Filters filters={filters} />
        <Table url="NovedadFija/list" menu={menu} checkbox totals />
      </ContextProvider>
    </div>
  );
};

export default NovedadFija;
