/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Form, Input, Button, Select, DatePicker, Row, Col } from "antd";
import { FiFolderPlus, FiPaperclip } from "react-icons/fi";

import moment from "moment";
import locale from "antd/es/date-picker/locale/es_ES";
import "moment/locale/es";

import http from "../../../services/http";
import { showSuccess } from "../../../services/notificacion";
import { hasAction, actions } from "../../../services/security";
import { required } from "../../../services/validation";

import Header from "../../../components/Header";
import TextArea from "antd/lib/input/TextArea";
import Archivos from "./Archivos";

const { Option } = Select;

const MedicinaLaboralForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const VER = hasAction(actions.MEDICINALABORAL_VER);
  const CREAR = hasAction(actions.MEDICINALABORAL_CREAR);
  const EDITAR = hasAction(actions.MEDICINALABORAL_EDITAR);
  const GUARDAR = match.params.id === "nuevo" ? CREAR : EDITAR;

  const [state, setState] = useState({
    title:
      match.params.id === "nuevo" ? "Registrar" : EDITAR ? "Editar" : "Ver",
    id: match.params.id === "nuevo" ? 0 : parseInt(match.params.id),
    loading: false,
  });

  const [empleados, setEmpleados] = useState([]);
  const [resultados, setResultados] = useState([]);
  const [tipos, setTipos] = useState([]);

  const breadcrumb = [
    { id: 1, title: "Administración" },
    {
      id: 2,
      title: "Medicina Laboral",
      url: "/administracion/medicina",
    },
    { id: 3, title: state.title },
  ];

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    const result =
      state.id === 0
        ? await http.post("MedicinaLaboral", values)
        : await http.put(`MedicinaLaboral/${state.id}`, values);

    if (result && result.data.code === 0) {
      showSuccess("El elemento fue guardado exitosamente.");
      history.push("/administracion/medicina");
    } else {
      setState({ ...state, loading: false });
    }
  };
  const onEmpleadoChange = async (legajo) => {
    getEmpleado(legajo);
  };

  const getEmpleado = async (legajo) => {
    const rGetEmpleado = await http.get(`medicinaLaboral/empleado/${legajo}`);
    if (rGetEmpleado && rGetEmpleado.data.code === 0) {
      const data = rGetEmpleado.data.data;
      form.setFieldsValue({
        lugarTrabajo: data.lugarTrabajo,
        funcion: data.funcion,
        categoria: data.categoria,
      });
    }
  };

  useEffect(() => {
    async function getData() {
      const response = await http.get("MedicinaLaboral/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setEmpleados(data.empleados);
        setTipos(data.tipos);
        setResultados(data.resultados);
      }
    }

    getData();

    async function getForm() {
      const rForm = await http.get(`MedicinaLaboral/${state.id}`);
      if (rForm && rForm.data.code === 0) {
        const data = rForm.data.data;
        if (data.fechaExamen) data.fechaExamen = moment(data.fechaExamen);
        if (data.empleadoId >= 0) {
          getEmpleado(data.empleadoId);
        }
        form.setFieldsValue(data);
      }
    }

    if (VER) {
      getForm();
    }
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="Medicina">
      <Header
        title={`${state.title} Medicina Laboral`}
        breadcrumb={breadcrumb}
      />
      <div className="form card">
        <Form form={form} layout="vertical" onFinish={onSave}>
          <div className="">
            <div className="form-icon">
              <FiFolderPlus />
            </div>

            <Form.Item label="Empleado" name="empleadoId" rules={[required]}>
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                onChange={(e) => onEmpleadoChange(e)}
              >
                {empleados.map((m) => (
                  <Option key={m.value} value={m.value} disabled={m.disabled}>
                    {m.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Row gutter={20}>
              <Col span="8">
                <Form.Item label="Función" name="funcion">
                  <Input disabled className="disabled" />
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item label="Categoría" name="categoria">
                  <Input disabled className="disabled" />
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item label="Lugar de Trabajo" name="lugarTrabajo">
                  <Input disabled className="disabled" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="Tipo Examen" name="tipoExamen">
              <Select showSearch allowClear optionFilterProp="children">
                {tipos.map((m) => (
                  <Option key={m.value} value={m.value}>
                    {m.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Resultado" name="resultado">
              <Select showSearch allowClear optionFilterProp="children">
                {resultados.map((m) => (
                  <Option key={m.value} value={m.value}>
                    {m.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Fecha" name="fechaExamen">
              <DatePicker
                disabled={!GUARDAR}
                format="DD/MM/YYYY"
                placeholder=""
                locale={locale}
              />
            </Form.Item>

            <Form.Item label="Comentarios" name="comentarios">
              <TextArea maxLength={4000} disabled={!GUARDAR} />
            </Form.Item>

            <div className="form-actions">
              <Link to="/administracion/medicina">
                <Button type="secondary">Volver</Button>
              </Link>
              {GUARDAR && (
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={state.loading}
                >
                  Guardar
                </Button>
              )}
            </div>
          </div>
        </Form>
        {state.id > 0 && (
          <React.Fragment>
            <hr />
            <div className="section-header">
              <div className="section-title">
                Documentación
                <FiPaperclip />
              </div>
              <div className="section-subtitle">Documentación asociada.</div>
            </div>
            <Archivos medicinaLaboral={state.id} />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default MedicinaLaboralForm;
