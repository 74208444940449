/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Button, Form, DatePicker, Input, Menu, Modal, Select } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { FiEdit, FiPlus, FiTrash2, FiPrinter } from "react-icons/fi";

import "moment/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";

import { ContextProvider } from "../../../../services/context";
import http from "../../../../services/http";
import { showSuccess } from "../../../../services/notificacion";
import { hasAction, actions } from "../../../../services/security";

import Filters from "../../../../components/Filters";
import FiltersButton from "../../../../components/FiltersButton";
import Header from "../../../../components/Header";
import Table from "../../../../components/Table";
import { downloadBase64 } from "../../../../services/forms";

const { Option } = Select;

const Asistencias = () => {
  const history = useHistory();

  const VER = hasAction(actions.ASISTENCIAS_VER);
  const CREAR = hasAction(actions.ASISTENCIAS_CREAR);
  const EDITAR = hasAction(actions.ASISTENCIAS_EDITAR);
  const ELIMINAR = hasAction(actions.ASISTENCIAS_ELIMINAR);

  const [state, setState] = useState({
    filters: {
      reloj: "",
      empleado: "",
      desde: "",
      hasta: "",
      direccion: "",
    },
    columns: [
      {
        id: 1,
        title: "Fecha",
        style: {},
        render: (item) => item.fecha,
      },
      {
        id: 2,
        title: "Hora",
        style: {},
        render: (item) => item.hora,
      },
      {
        id: 3,
        title: "Reloj",
        style: {},
        render: (item) => item.reloj,
      },
      {
        id: 4,
        title: "Legajo",
        style: {},
        render: (item) => item.empleado,
      },
      {
        id: 5,
        title: "Dirección",
        style: {},
        render: (item) => item.direccion,
      },
    ],
    loading: true,
    loaded: false,
    reload: true,
    list: [],
    total: 0,
    page: 1,
    size: 10,
  });

  const [direcciones, setDirecciones] = useState([]);
  const [empleados, setEmpleados] = useState([]);

  const [loadingExport, setLoadingExport] = useState(false);

  const breadcrumb = [
    { id: 1, title: "Novedades" },
    {
      id: 2,
      title: "Licencias",
      url: "/novedades/licencias",
    },
    {
      id: 3,
      title: "Asistencias",
      url: "/novedades/licencias/asistencias",
    },
  ];

  const buttons = () => {
    return (
      <React.Fragment>
        <FiltersButton />
        <Button
          type="primary"
          icon={!loadingExport && <FiPrinter />}
          onClick={onExport}
          loading={loadingExport}
        >
          {!loadingExport && "Exportar"}
        </Button>
        {CREAR && (
          <Button type="primary" icon={<FiPlus />} onClick={onAdd}>
            Nuevo
          </Button>
        )}
      </React.Fragment>
    );
  };

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Reloj" name="reloj">
          <Input />
        </Form.Item>

        <Form.Item label="Legajo" name="empleado">
          <Select allowClear showSearch optionFilterProp="children">
            {empleados.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Desde" name="desde">
          <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
        </Form.Item>

        <Form.Item label="Hasta" name="hasta">
          <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
        </Form.Item>

        <Form.Item label="Direccion" name="direccion">
          <Select allowClear showSearch optionFilterProp="children">
            {direcciones.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </React.Fragment>
    );
  };

  const onExport = async () => {
    setLoadingExport(true);
    const response = await http.post("asistencias/export", state.filters);
    setLoadingExport(false);

    if (response && response.data.code === 0) {
      const data = response.data.data;
      downloadBase64(data.filename, data.content);
    }
  };

  const menu = (item) => {
    return (
      <Menu className="table-actions-menu">
        {EDITAR && (
          <Menu.Item key="0" onClick={() => onEdit(item)}>
            <FiEdit />
            Editar
          </Menu.Item>
        )}
        {ELIMINAR && (
          <Menu.Item key="1" onClick={() => onDelete(item)}>
            <FiTrash2 />
            Eliminar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const onAdd = () => {
    history.push("/novedades/licencias/asistencias/nuevo");
  };

  const onEdit = (item) => {
    history.push(`/novedades/licencias/asistencias/${item.id}`);
  };

  const onDelete = (item) => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea eliminar esta asistencia?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const rDelete = await http.delete(`asistencias/${item.id}`);
        if (rDelete && rDelete.data.code === 0) {
          showSuccess("La asistencia fue eliminada exitosamente.");
          setState({ ...state, reload: !state.reload });
        }
      },
    });
  };

  useEffect(() => {
    async function getData() {
      const response = await http.get("asistencias/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setDirecciones(data.direcciones);
        setEmpleados(data.empleados);
      }
    }

    getData();
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="asistencias page">
      <ContextProvider value={[state, setState]}>
        <Header title="Asistencias" breadcrumb={breadcrumb} buttons={buttons} />
        <Filters filters={filters} />
        <Table url="asistencias/list" menu={menu} />
      </ContextProvider>
    </div>
  );
};

export default Asistencias;
