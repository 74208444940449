/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Dropdown,
  Input,
  Menu,
  Modal,
  Form,
  Row,
  Col,
  Tooltip,
} from "antd";
import {
  FiDownload,
  FiFileText,
  FiMoreVertical,
  FiTrash2,
  FiUpload,
} from "react-icons/fi";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { Context } from "../../../services/context";
import http from "../../../services/http";
import { showSuccess } from "../../../services/notificacion";
import { hasAction, actions } from "../../../services/security";
import { required } from "../../../services/validation";

const DDJJCargoList = ({ onCurrentChange }) => {
  const [state, setState] = useContext(Context);
  const fileRef = useRef(null);

  const [form] = Form.useForm();

  const ELIMINAR = state.editing && hasAction(actions.LEGAJOS_EDITAR);

  const [list, setList] = useState([]);
  const [addDDJJ, setAdd] = useState(false);
  const [contenido, setContenido] = useState(null);

  const menu = (item) => {
    return (
      <Menu className="table-actions-menu">
        {ELIMINAR && (
          <Menu.Item key="1" onClick={() => onDelete(item)}>
            <FiTrash2 />
            Eliminar
          </Menu.Item>
        )}
        <Menu.Item key="2" onClick={() => onDownload(item)}>
          <FiDownload />
          Descargar
        </Menu.Item>
      </Menu>
    );
  };

  const onDownload = (file) => {
    window.open(
      `${process.env.REACT_APP_API_URL}/archivos/${file.archivoId}`,
      "_blank"
    );
  };
  const onAdd = () => {
    setContenido(null);
    form.resetFields();
    setAdd(true);
  };

  const onAddFile = () => {
    fileRef.current.click();
  };

  const onUpload = async (e) => {
    const files = e.target.files;
    if (files.length === 0) {
      return;
    }
    const file = files[0];

    const base64 = await toBase64(file);
    setState({ ...state, contenido: base64 });
    setContenido(base64);
    form.setFieldsValue({ archivo: file.name });
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const OnAddDDJJCargo = async (values) => {
    setState({ ...state, loading: true });
    values.contenido = contenido;
    const result = await http.post(`legajos/ddjjCargo/${state.id}`, values);
    setState({ ...state, loading: false, reload: !state.reload });

    if (result && result.data.code === 0) {
      showSuccess("La ddjj fue guardada exitosamente.");
    } else {
    }
    setAdd(false);
  };

  const onDelete = (item) => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea eliminar este ddjj?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const rDelete = await http.delete(`legajos/ddjjcargo/${item.id}`);

        if (rDelete && rDelete.data.code === 0) {
          showSuccess("El familiar se elimino exitosamente.");
        }
      },
    });
  };

  useEffect(() => {
    async function getList() {
      const rList = await http.get(
        `legajos/ddjjcargo/list?empleado=${state.id}`
      );

      if (rList && rList.data.code === 0) {
        const data = rList.data.data;
        setList(data);
      }
    }

    getList();
  }, [state.reload]);

  return (
    <div className="ddjjcargo-list">
      <div className="table-wrapper">
        <table className="subsection-table">
          <thead>
            <tr>
              <th></th>
              <th style={{ textAlign: "center" }}>Fecha</th>
              <th style={{ textAlign: "center" }}>Usuario</th>
              <th style={{ textAlign: "center" }}>Año</th>
              <th style={{ textAlign: "center" }}>Archivo</th>
            </tr>
          </thead>
          <tbody>
            {list.length === 0 && (
              <tr>
                <td className="table-empty" colSpan="9">
                  Sin resultados
                </td>
              </tr>
            )}

            {list.map((x) => (
              <tr key={x.id}>
                <td className="table-actions">
                  <Dropdown
                    key={x.id}
                    overlay={() => menu(x)}
                    trigger={["click"]}
                    placement="bottomLeft"
                    arrow
                  >
                    <div>
                      <FiMoreVertical />
                    </div>
                  </Dropdown>
                </td>
                <td style={{ textAlign: "center" }}>{x.fecha}</td>
                <td style={{ textAlign: "center" }}>{x.usuario}</td>
                <td style={{ textAlign: "center" }}>{x.año}</td>
                <td style={{ textAlign: "center" }}>{x.archivo}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="table-footer-actions">
        <Button type="primary" onClick={onAdd} loading={state.loading}>
          Agregar
        </Button>
      </div>
      <Modal
        visible={addDDJJ}
        forceRender
        footer={null}
        maskClosable={false}
        keyboard={false}
        destroyOnClose={true}
        onCancel={() => setAdd(null)}
      >
        <Form form={form} layout="vertical" onFinish={OnAddDDJJCargo}>
          <div className="form">
            <div className="form-icon">
              <FiFileText />
            </div>
            <Form.Item label="Año" name="año" rules={[required]}>
              <Input />
            </Form.Item>
            <Row>
              <Col flex="auto">
                <input
                  type="file"
                  multiple={false}
                  accept={".*"}
                  ref={fileRef}
                  onChange={onUpload}
                />
                <Form.Item label="Archivo" name="archivo">
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              <Col flex="45px">
                <Tooltip title="Subir Archivo">
                  <div className="historial-trigger" onClick={onAddFile}>
                    <FiUpload />
                  </div>
                </Tooltip>
              </Col>
            </Row>
            <div className="form-actions">
              <Button type="secondary" onClick={() => setAdd(null)}>
                Cancelar
              </Button>
              <Button type="primary" htmlType="submit" loading={state.loading}>
                Guardar
              </Button>
            </div>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default DDJJCargoList;
