/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { Button, DatePicker, Form, Select } from "antd";
import { FiPrinter } from "react-icons/fi";

import { ContextProvider } from "../../../../services/context";
import http from "../../../../services/http";
import { hasAction, actions } from "../../../../services/security";

import Filters from "../../../../components/Filters";
import FiltersButton from "../../../../components/FiltersButton";
import Header from "../../../../components/Header";
import fileDownload from "js-file-download";

import "moment/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";

import "moment/locale/es";
import * as moment from "moment";
import TableSimple from "../../../../components/TableSimple";
const { Option } = Select;

const Ausentismo = () => {
  const VER = hasAction(actions.REPORTE_NOVEDAD_AUSENTISMO);
  const [loadingExport, setLoadingExport] = useState(false);
  const [empleados, setEmpleados] = useState([]);

  const [state, setState] = useState({
    filters: {
      desde: moment().add(-1, "M"),
      hasta: moment(),
      Empelado: "",
      visible: true,
    },
    columns: [
      {
        id: 1,
        title: "Legajo",
        style: {},
        render: (item) => item.id,
      },
      {
        id: 2,
        title: "Empleado",
        style: {},
        render: (item) => item.nombre,
      },
      {
        id: 3,
        title: "Cantidad de días",
        style: {},
        render: (item) => item.cantidadDias,
      },

      {
        id: 4,
        title: "Motivo",
        style: {},
        render: (item) => item.motivo,
      },
    ],
    loading: true,
    loaded: false,
    reload: true,
    list: [],
    totalTable: 0,
  });

  const breadcrumb = [
    {
      id: 1,
      title: "Reportes",
    },
    {
      id: 2,
      title: "Novedades",
      url: "/reportes/novedad",
    },
    {
      id: 3,
      title: "Ausentismo",
      url: "/reportes/novedad/ausentisomo",
    },
  ];

  const onExport = async () => {
    setLoadingExport(true);
    const rExport = await http.post(
      "Reportes/Novedad/Ausentismo/export/List",
      state.filters,
      true
    );
    setLoadingExport(false);

    fileDownload(rExport.data, `Ausentismo${Date.now()}.xlsx`);
  };

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item
          label="Desde"
          name="desde"
          initialValue={moment().add(-1, "M")}
        >
          <DatePicker
            format="DD/MM/YYYY"
            placeholder=""
            locale={locale}
            allowClear={false}
          />
        </Form.Item>

        <Form.Item label="Hasta" name="hasta" initialValue={moment()}>
          <DatePicker
            format="DD/MM/YYYY"
            placeholder=""
            locale={locale}
            allowClear={false}
          />
        </Form.Item>
        <Form.Item label="Empleado" name="empleado">
          <Select allowClear showSearch optionFilterProp="children">
            {empleados.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </React.Fragment>
    );
  };

  useEffect(() => {
    async function getData() {
      const response = await http.get("Reportes/Novedad/Ausentismo/Data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setEmpleados(data.empleados);
      }
    }

    async function getList() {
      setState({ ...state, loading: true });
      const rList = await http.post("Reportes/Novedad/Ausentismo/List/", {
        page: state.page,
        size: state.size,
        ...state.filters,
      });
      if (rList && rList.data.code === 0) {
        const data = rList.data.data;
        setState({
          ...state,
          list: data.list,
          totalTable: data.total,
          loading: false,
        });
      } else {
        setState({ ...state, loading: false });
      }
    }

    if (VER) {
      getData();
      getList();
    }
  }, [
    state.filters.desde,
    state.filters.hasta,
    state.filters.empleado,
    state.page,
    state.size,
  ]);

  if (!VER) {
    return <Redirect to="/" />;
  }
  const buttons = () => {
    return (
      <React.Fragment>
        <FiltersButton />
        <Button
          type="primary"
          icon={<FiPrinter />}
          onClick={onExport}
          loading={loadingExport}
        >
          Exportar
        </Button>
      </React.Fragment>
    );
  };

  return (
    <div className="ausentismo page">
      <ContextProvider value={[state, setState]}>
        <Header title="Ausentismo" breadcrumb={breadcrumb} buttons={buttons} />
        <Filters filters={filters} />
        <TableSimple columns={state.columns} />
      </ContextProvider>
    </div>
  );
};

export default Ausentismo;
