/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Form, Select } from 'antd';
import { FiPrinter } from 'react-icons/fi';

import { ContextProvider } from '../../../../services/context';
import http from '../../../../services/http';
import { hasAction, actions } from '../../../../services/security';

import Filters from '../../../../components/Filters';
import FiltersButton from '../../../../components/FiltersButton';
import Header from '../../../../components/Header';
import Table from '../../../../components/Table';
import fileDownload from 'js-file-download';

const { Option } = Select;

const ControlAsignacion = () => {
  const VER = hasAction(actions.REPORTE_LEGAJO_CONTROLASIGNACION);

  const [state, setState] = useState({
    filters: {
      legajo: '',
      vinculo: '',
    },
    columns: [
      {
        id: 1,
        title: 'Legajo',
        style: {},
        render: (item) => item.empleadoId,
      },
      {
        id: 2,
        title: 'Nombre',
        style: {},
        render: (item) => item.nombre,
      },
      {
        id: 3,
        title: 'Asignación',
        style: {},
        render: (item) => item.asignacion,
      },
      {
        id: 11,
        title: 'Vinculo',
        style: {},
        render: (item) => item.vinculo,
      },
      {
        id: 4,
        title: 'Fecha de Nacimiento',
        style: {},
        render: (item) => item.fechaNacimiento,
      },
      {
        id: 5,
        title: 'Edad',
        style: {},
        render: (item) => item.edad,
      },
      {
        id: 6,
        title: 'Sexo',
        style: {},
        render: (item) => item.sexo,
      },

      {
        id: 7,
        title: 'Incapacitado',
        style: {},
        render: (item) => item.incapacitado,
      },

      {
        id: 8,
        title: 'Escolaridad',
        style: {},
        render: (item) => item.escolaridad,
      },

      {
        id: 9,
        title: 'Seguro',
        style: {},
        render: (item) => item.seguroMutual,
      },

      {
        id: 10,
        title: 'OSEP',
        style: {},
        render: (item) => item.aporteObraSocial,
      },
    ],
    loading: true,
    loaded: false,
    reload: true,
    list: [],
    total: 0,
    page: 1,
    size: 10,
  });
  const [empleados, setEmpleados] = useState([]);
  const [vinculos, setVinculos] = useState([]);
  const breadcrumb = [
    {
      id: 1,
      title: 'Reportes',
    },
    {
      id: 2,
      title: 'Legajos',
      url: '/reportes/legajo',
    },
    {
      id: 3,
      title: 'Control Asignaciones',
      url: '/reportes/legajo/asignaciones',
    },
  ];

  const onExport = async () => {
    const rExport = await http.post(
      'Reportes/Familiares/export/List',
      state.filters,
      true
    );

    fileDownload(rExport.data, `ControlAsignacion${Date.now()}.xlsx`);
  };

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Legajo" name="legajo">
          <Select allowClear showSearch optionFilterProp="children">
            {empleados.map((x) => (
              <Option key={x.id} value={x.id}>
                {x.nombre}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Vinculo" name="vinculo">
          <Select allowClear showSearch optionFilterProp="children">
            {vinculos.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Dados de baja" name="baja">
          <Select defaultValue="-1">
            <Select.Option value="-1">Indistinto</Select.Option>
            <Select.Option value="1">Si</Select.Option>
            <Select.Option value="0">No</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Mayor de edad" name="mayorEdad">
          <Select defaultValue="-1">
            <Select.Option value="-1">Indistinto</Select.Option>
            <Select.Option value="1">Si</Select.Option>
            <Select.Option value="0">No</Select.Option>
          </Select>
        </Form.Item>
      </React.Fragment>
    );
  };

  useEffect(() => {
    async function getData() {
      const response = await http.get('reportes/familiares/Data');
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setEmpleados(data.empleados);
        setVinculos(data.vinculos);
      }
    }

    getData();
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }
  const buttons = () => {
    return (
      <React.Fragment>
        <FiltersButton />
        <Button type="primary" icon={<FiPrinter />} onClick={onExport}>
          Exportar
        </Button>
      </React.Fragment>
    );
  };

  return (
    <div className="controlAsignacion page">
      <ContextProvider value={[state, setState]}>
        <Header
          title="Control Asignación"
          breadcrumb={breadcrumb}
          buttons={buttons}
        />
        <Filters filters={filters} />
        <Table url="Reportes/Familiares/List" />
      </ContextProvider>
    </div>
  );
};

export default ControlAsignacion;
