import React from "react";
import { Col, Row } from "antd";

import { hasAction, actions } from "./../../../services/security";

import Header from "../../../components/Header";
import MenuCard from "./../../../components/MenuCard";

const Liquidacion = () => {
  const breadcrumb = [
    {
      id: 1,
      title: "Reportes",
    },
    {
      id: 2,
      title: "Liquidaciones",
      url: "/reportes/liquidacion",
    },
  ];

  const items = [
    {
      id: 1,
      titulo: "Sueldos caidos",
      path: "/reportes/liquidacion/sueldos",
      color: "primary",
      icono: "FiFileText",
      visible: hasAction(actions.REPORTE_LIQUIDACION_SUELDOSCAIDOS),
    },
    {
      id: 2,
      titulo: "Sintetico por concepto",
      path: "/reportes/liquidacion/sintetico",
      color: "primary",
      icono: "FiFileText",
      visible: hasAction(actions.REPORTE_LIQUIDACION_SINTETICO),
    },
    {
      id: 3,
      titulo: "Facturación Entidades",
      path: "/reportes/liquidacion/facturacion",
      color: "primary",
      icono: "FiFileText",
      visible: hasAction(actions.REPORTE_LIQUIDACION_FACTURACIONENTIDADES),
    },

    {
      id: 4,
      titulo: "Finalidad y Función",
      path: "/reportes/liquidacion/finalidad-funcion",
      color: "primary",
      icono: "FiFileText",
      visible: hasAction(actions.REPORTE_LIQUIDACION_FINALIDAD_FUNCION),
    },
  ];

  return (
    <div className="inicio">
      <Header title="Liquidación" breadcrumb={breadcrumb} buttons={() => {}} />

      <Row gutter={[32, 36]}>
        {items.map((i) => (
          <Col key={i.id} xs={12} md={8} xl={6} xxl={4}>
            <MenuCard item={i} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Liquidacion;
