/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Alert, Form } from 'antd';
import { FiCalendar, FiCheckCircle, FiSearch } from 'react-icons/fi';
import 'react-slidedown/lib/slidedown.css';

import { ContextProvider } from '../../services/context';
import http from '../../services/http';
import { showSuccess, showWarning } from '../../services/notificacion';
import { hasAction, actions } from '../../services/security';

import Header from '../../components/Header';
import LiquidacionFiltros from './LiquidacionFiltros';
import LiquidacionPeriodo from './LiquidacionPeriodo';
import LiquidacionPreview from './LiquidacionPreview';
import Stepper from '../../components/Stepper';
import { parseYear } from './../../services/forms';

const LiquidacionForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const VER = hasAction(actions.LIQUIDACION_VER);

  const [state, setState] = useState({
    loading: false,
    calculating: false,
    form: form,
    step: 0,
    convenios: [],
    criterios: [],
    direcciones: [],
    empleados: [],
    estados: [],
    tipos: [],
    values: {},
  });

  const breadcrumb = [
    { id: 1, title: 'Liquidación' },
    { id: 2, title: 'Liquidaciones', url: '/liquidaciones/' },
    { id: 3, title: 'Crear', url: '/liquidaciones/nuevo' },
  ];

  const steps = [
    {
      id: 1,
      icon: <FiCalendar />,
      title: '1. Periodo',
      description: 'Seleccioná el periodo a liquidar',
    },
    {
      id: 2,
      icon: <FiSearch />,
      title: '2. Legajos',
      description: 'Buscá que legajos se van a incluir',
    },
    {
      id: 3,
      icon: <FiCheckCircle />,
      title: '3. Confirmar',
      description: 'Confirmá y finalizá la liquidación',
    },
  ];

  useEffect(() => {
    async function getData() {
      const response = await http.get('liquidaciones/data');
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setState({
          ...state,
          convenios: data.convenios,
          criterios: data.criterios,
          direcciones: data.direcciones,
          empleados: data.empleados,
          estados: data.estados,
          tipos: data.tipos,
        });
      }
    }

    if (VER) {
      getData();
    }
  }, []);

  const onSave = async (values) => {
    if (state.step < 2) {
      // Periodo
      if (state.step === 0) {
        const date = new Date(parseYear(values.anio), values.mes - 1, 1);

        if (date > new Date()) {
          showWarning(
            'El periodo seleccionado no puede ser mayor a la fecha actual.'
          );
          return;
        }
      }

      // Tipo
      if (state.step === 1) {
        if (Array.isArray(values.filtro)) {
          values.filtro = values.filtro.join();
        }

        const data = {
          mes: state.values.mes,
          anio: parseYear(state.values.anio),
          tipo: values.tipo,
          criterio: values.criterio,
          filtro: values.filtro,
        };

        const rValidate = await http.post('liquidaciones/validate', data);
        if (
          rValidate &&
          rValidate.data.code === 0 &&
          rValidate.data.data.repetida
        ) {
          showWarning(
            'Ya existe una liquidación de este tipo para el periodo seleccionado.'
          );
          return;
        }
      }

      setState({
        ...state,
        step: state.step + 1,
        values: { ...state.values, ...values },
      });
      return;
    }

    // Liquidar
    let nValues = { ...state.values };
    nValues.anio = parseYear(nValues.anio);

    setState({ ...state, calculating: true, error: undefined });

    const rSave = await http.post('liquidaciones', nValues, false, 0);

    if (rSave && rSave.data.code === 0) {
      const data = rSave.data.data;

      if (!rSave.data.error) {
        showSuccess('La liquidacion se creó exitosamente.');
        history.push('/liquidaciones');
      } else {
        let title = `Error de Liquidación`;
        let description = (
          <ul style={{ marginBottom: '0px', fontSize: '12px' }}>
            <li>
              Empleado: <b>{data.empleado}</b>
            </li>
            <li>
              Concepto: <b>{data.concepto}</b>
            </li>
            {data.operando && (
              <li>
                Operando: <b>{data.operando}</b>
              </li>
            )}
            {data.query && (
              <li>
                Query: <b>{data.query}</b>
              </li>
            )}
            <li>
              Detalle: <small>{data.message}</small>
            </li>
          </ul>
        );

        setState({
          ...state,
          calculating: false,
          error: {
            title: title,
            description: description,
          },
        });
      }
    } else {
      setState({ ...state, calculating: false });
    }
  };

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="liquidacion">
      <Header title="Crear Liquidación" breadcrumb={breadcrumb} />

      <Form form={state.form} layout="vertical" onFinish={onSave}>
        <div className="form px-0 card">
          <div className="form-icon">
            <FiCheckCircle />
          </div>
          <ContextProvider value={[state, setState]}>
            <Stepper steps={steps} left />

            {state.error && (
              <Alert
                type="error"
                message={state.error.title}
                description={state.error.description}
                showIcon
              />
            )}

            {state.step === 0 && <LiquidacionPeriodo />}
            {state.step === 1 && <LiquidacionFiltros />}
            {state.step === 2 && <LiquidacionPreview />}
          </ContextProvider>
        </div>
      </Form>
    </div>
  );
};

export default LiquidacionForm;
