import React from "react";
import { Col, Row } from "antd";

import { hasAction, actions } from "./../../../services/security";

import Header from "../../../components/Header";
import MenuCard from "../../../components/MenuCard";

const Ganancias = () => {
  const items = [
    {
      id: 1,
      titulo: "Tramos",
      path: "/liquidacion/ganancias/tramos",
      color: "primary",
      icono: "FiAlignJustify",
      entidad: "TramoGanancia",
      visible: hasAction(actions.TRAMOS_VER),
    },
    {
      id: 2,
      titulo: "Deducciones",
      path: "/liquidacion/ganancias/deducciones",
      color: "primary",
      icono: "FiRotateCcw",
      entidad: "DeduccionGanancia",
      visible: hasAction(actions.DEDUCCIONES_VER),
    },
    {
      id: 3,
      titulo: "DDJJ",
      path: "/liquidacion/ganancias/ddjj",
      color: "primary",
      icono: "FiFileText",
      entidad: "DDJJ",
      visible: hasAction(actions.DDJJ_VER),
    },
  ];

  const breadcrumb = [
    { id: 1, title: "Liquidación" },
    { id: 2, title: "Ganancias", url: "/liquidacion/ganancias" },
  ];

  return (
    <div className="inicio">
      <Header title="Ganancias" breadcrumb={breadcrumb} buttons={() => {}} />
      <Row gutter={[32, 36]}>
        {items.map((i) => (
          <Col key={i.id} xs={12} md={8} xl={6} xxl={4}>
            <MenuCard item={i} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Ganancias;
