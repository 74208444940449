/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { Button, Form, Input, Spin, Select } from "antd";
import { FiPrinter } from "react-icons/fi";
import { LoadingOutlined } from "@ant-design/icons";

import { ContextProvider } from "../../../../services/context";
import http from "../../../../services/http";
import { hasAction, actions } from "../../../../services/security";

import Filters from "../../../../components/Filters";
import FiltersButton from "../../../../components/FiltersButton";
import Header from "../../../../components/Header";
import fileDownload from "js-file-download";

import "moment/locale/es";
import * as moment from "moment";

import "./Table.scss";
// import Table from "../../../../components/Table";
const { Option } = Select;

const FacturacionEntidades = () => {
  const VER = hasAction(actions.REPORTE_LIQUIDACION_FACTURACIONENTIDADES);
  const [loadingExport, setLoadingExport] = useState(false);
  const [conceptos, setConceptos] = useState([]);
  const [convenios, setConvenios] = useState([]);

  const [state, setState] = useState({
    filters: {
      mes: moment().month() + 1,
      anio: moment().year(),
      concepto: "",
      convenio: "",
    },
    loading: true,
    loaded: false,
    reload: true,
    list: [],
    totales: null,
    total: 0,
    page: 1,
    size: 10,
  });

  const breadcrumb = [
    {
      id: 1,
      title: "Reportes",
    },
    {
      id: 2,
      title: "Liquidación",
      url: "/reportes/liquidacion",
    },
    {
      id: 3,
      title: "Facturacion de Entidades",
      url: "/reportes/liquidacion/facturacion",
    },
  ];

  const onExport = async () => {
    setLoadingExport(true);
    const rExport = await http.post(
      "Reportes/Liquidacion/FacturacionEntidades/export/List",
      state.filters,
      true
    );
    setLoadingExport(false);

    fileDownload(rExport.data, `FacturacionEntidades${Date.now()}.xlsx`);
  };

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Año" name="anio" initialValue={moment().year()}>
          <Input />
        </Form.Item>
        <Form.Item label="Mes" name="mes" initialValue={moment().month() + 1}>
          <Input />
        </Form.Item>
        <Form.Item label="Concepto" name="concepto">
          <Select
            mode="multiple"
            allowClear
            showSearch
            optionFilterProp="children"
          >
            {conceptos.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Convenio" name="convenio">
          <Select
            mode="multiple"
            allowClear
            showSearch
            optionFilterProp="children"
          >
            {convenios.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </React.Fragment>
    );
  };

  useEffect(() => {
    async function getData() {
      const response = await http.get(
        "reportes/Liquidacion/FacturacionEntidades/Data"
      );
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setConceptos(data.conceptos);
        setConvenios(data.convenios);
      }
    }

    async function getList() {
      setState({ ...state, loading: true });
      const rList = await http.post(
        "Reportes/Liquidacion/FacturacionEntidades/List/",
        {
          page: state.page,
          size: state.size,
          ...state.filters,
        }
      );
      if (rList && rList.data.code === 0) {
        const data = rList.data.data;
        setState({
          ...state,
          list: data.list,
          totales: data.totales,
          loading: false,
        });
      } else {
        setState({ ...state, loading: false });
      }
    }

    if (VER) {
      getData();
      getList();
    }
  }, [
    state.filters.anio,
    state.filters.mes,
    state.filters.concepto,
    state.filters.convenio,
    state.page,
    state.size,
  ]);

  if (!VER) {
    return <Redirect to="/" />;
  }
  const buttons = () => {
    return (
      <React.Fragment>
        <FiltersButton />
        <Button
          type="primary"
          icon={<FiPrinter />}
          onClick={onExport}
          loading={loadingExport}
        >
          Exportar
        </Button>
      </React.Fragment>
    );
  };

  return (
    <div className="sintetico page">
      <ContextProvider value={[state, setState]}>
        <Header
          title="Facturación Entidades"
          breadcrumb={breadcrumb}
          buttons={buttons}
        />
        <Filters filters={filters} />

        <div className="card-table">
          <div className="table">
            {state.loading && (
              <div className="loading">
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 40, color: "#04bebe" }}
                      spin
                    />
                  }
                />
                Cargando...
              </div>
            )}
            {!state.loading && (
              <div className="table-wrapper">
                <table>
                  <thead className="ant-table-thead">
                    <tr>
                      <th className="ant-table-cell">Código</th>
                      <th className="ant-table-cell">Detalle</th>
                      <th className="ant-table-cell">Cant. Reg</th>
                      <th className="ant-table-cell">Importe Total Planta</th>
                      <th className="ant-table-cell">Importe Total SEOS</th>
                      <th className="ant-table-cell">Importe Total</th>
                      <th className="ant-table-cell">Tasa Servicio (2%)</th>
                      <th className="ant-table-cell">
                        SERV. PROC. $30,00 POR AG.
                      </th>
                      <th className="ant-table-cell">
                        SOPOR. MAG ($460 + $50 POR C/100 AG.)
                      </th>
                      <th className="ant-table-cell">IMPRES. $100,00</th>
                      <th className="ant-table-cell">DEVOL AGENTE</th>
                      <th className="ant-table-cell">TOTAL RETENCION</th>
                      <th className="ant-table-cell">
                        DIFERENCIA IMPORTE TOTAL RETENCION
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {state.list.map((i, index) => (
                      <tr key={index}>
                        <td>{i.codigo}</td>
                        <td>{i.concepto}</td>
                        <td>{i.cantidadReg}</td>
                        <td>{i.importeTotalPlanta}</td>

                        <td>{i.importeTotalSEOS}</td>
                        <td>{i.importeTotal}</td>
                        <td>{i.tasaServicio}</td>
                        <td>{i.servProcAG}</td>
                        <td>{i.soporteMagnetico}</td>
                        <td>{i.impresion}</td>
                        <td>{i.devolucionAgente}</td>
                        <td>{i.totalRetencion}</td>
                        <td>{i.diferenciaTotalRetencion}</td>
                      </tr>
                    ))}
                    {state.totales && (
                      <tr key={"totales"}>
                        <td></td>
                        <td></td>
                        <td>
                          <b>TOTAL</b>
                        </td>
                        <td>{state.totales.totalImporteTotalPlanta}</td>
                        <td>{state.totales.totalImporteTotalSEOS}</td>
                        <td>{state.totales.totalImporteTotal}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <b>TOTAL</b>
                        </td>
                        <td>{state.totales.totalTotalRetencion}</td>
                        <td>
                          {state.totales.totalDiferenciaImporteTotalRetencion}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </ContextProvider>
    </div>
  );
};

export default FacturacionEntidades;
