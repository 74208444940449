/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Row,
  Col,
  TimePicker,
} from "antd";
import { FiCalendar } from "react-icons/fi";

import locale from "antd/es/date-picker/locale/es_ES";
import "moment/locale/es";

import {
  parseDate,
  parseTime,
  parseToDate,
  parseToTime,
} from "./../../../../services/forms";
import http from "./../../../../services/http";
import { showSuccess } from "./../../../../services/notificacion";
import { hasAction, actions } from "./../../../../services/security";
import { required } from "./../../../../services/validation";

import Header from "./../../../../components/Header";
import { ContextProvider } from "../../../../services/context";

const AsistenciasForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const VER = hasAction(actions.ASISTENCIAS_VER);
  const CREAR = hasAction(actions.ASISTENCIAS_CREAR);
  const EDITAR = hasAction(actions.ASISTENCIAS_EDITAR);
  const GUARDAR = match.params.id === "nuevo" ? CREAR : EDITAR;

  const [state, setState] = useState({
    title: match.params.id === "nuevo" ? "Crear" : EDITAR ? "Editar" : "Ver",
    id: match.params.id === "nuevo" ? 0 : parseInt(match.params.id),
    loading: false,
  });

  const [empleados, setEmpleados] = useState([]);

  const breadcrumb = [
    { id: 1, title: "Novedades" },
    {
      id: 2,
      title: "Licencias",
      url: "/novedades/licencias",
    },
    {
      id: 3,
      title: "Asistencias",
      url: "/novedades/licencias/asistencias",
    },
    { id: 4, title: state.title },
  ];

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    values.fecha = parseDate(values.fecha);
    values.hora = parseTime(values.hora);

    const rGuardar =
      state.id === 0
        ? await http.post("asistencias", values)
        : await http.put(`asistencias/${state.id}`, values);

    if (rGuardar && rGuardar.data.code === 0) {
      showSuccess("La asistencia fue guardada exitosamente.");
      history.push("/novedades/licencias/asistencias");
    } else {
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {
    async function getForm() {
      const response = await http.get("asistencias/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setEmpleados(data.empleados);
      }

      const rForm = await http.get(`asistencias/${state.id}`);
      if (rForm && rForm.data.code === 0) {
        const data = rForm.data.data;
        data.fecha = parseToDate(data.fecha);
        data.hora = parseToTime(data.hora);
        form.setFieldsValue(data);
      }
    }

    if (VER) {
      getForm();
    }
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <ContextProvider value={[state, setState]}>
      <div className="asistencia">
        <Header title={`${state.title} Asistencia`} breadcrumb={breadcrumb} />
        <div className="form card">
          <Form form={form} layout="vertical" onFinish={onSave}>
            <div className="form-icon">
              <FiCalendar />
            </div>

            <Row gutter={20}>
              <Col span="12">
                <Form.Item label="Fecha" name="fecha" rules={[required]}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    placeholder=""
                    locale={locale}
                    disabled={!GUARDAR}
                  />
                </Form.Item>
              </Col>

              <Col span="12">
                <Form.Item label="Hora" name="hora" rules={[required]}>
                  <TimePicker
                    format="HH:mm"
                    placeholder=""
                    locale={locale}
                    disabled={!GUARDAR}
                  />
                </Form.Item>
              </Col>

              <Col span="24">
                <Form.Item label="Reloj" name="reloj" rules={[required]}>
                  <Input disabled={!GUARDAR} />
                </Form.Item>
              </Col>

              <Col span="24">
                <Form.Item label="Empleado" name="empleado" rules={[required]}>
                  <Select
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    disabled={!GUARDAR}
                  >
                    {empleados.map((x) => (
                      <Select.Option
                        key={x.value}
                        value={x.value}
                        disabled={x.disabled}
                      >
                        {x.text}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <div className="form-actions">
              <Link to="/novedades/licencias/asistencias">
                <Button type="secondary">Volver</Button>
              </Link>
              {GUARDAR && (
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={state.loading}
                >
                  Guardar
                </Button>
              )}
            </div>
          </Form>
        </div>
      </div>
    </ContextProvider>
  );
};

export default AsistenciasForm;
