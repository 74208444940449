/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
} from "antd";
import { FiAlignJustify } from "react-icons/fi";

import http from "../../../../services/http";
import { showSuccess } from "../../../../services/notificacion";
import { hasAction, actions } from "../../../../services/security";
import { required } from "../../../../services/validation";

import Header from "../../../../components/Header";
import { ContextProvider } from "../../../../services/context";

const TramosForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const VER = hasAction(actions.TRAMOS_VER);
  const CREAR = hasAction(actions.TRAMOS_CREAR);
  const EDITAR = hasAction(actions.TRAMOS_EDITAR);
  const GUARDAR = match.params.id === "nuevo" ? CREAR : EDITAR;

  const [state, setState] = useState({
    title: match.params.id === "nuevo" ? "Crear" : EDITAR ? "Editar" : "Ver",
    id: match.params.id === "nuevo" ? 0 : parseInt(match.params.id),
    loading: false,
  });

  const breadcrumb = [
    { id: 1, title: "Liquidación" },
    {
      id: 2,
      title: "Ganancias",
      url: "/liquidacion/ganancias",
    },
    {
      id: 3,
      title: "Tramos",
      url: "/liquidacion/ganancias/tramos",
    },
    { id: 4, title: state.title },
  ];

  const onSave = async (values) => {
    setState({ ...state, loading: true });
    const rGuardar =
      state.id === 0
        ? await http.post("tramos", values)
        : await http.put(`tramos/${state.id}`, values);

    if (rGuardar && rGuardar.data.code === 0) {
      showSuccess("El tramo fue guardado exitosamente.");
      history.push("/liquidacion/ganancias/tramos");
    } else {
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {
    async function getForm() {
      const rForm = await http.get(`tramos/${state.id}`);
      if (rForm && rForm.data.code === 0) {
        const data = rForm.data.data;
        form.setFieldsValue(data);
      }
    }

    if (VER) {
      getForm();
    }
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <ContextProvider value={[state, setState]}>
      <div className="tramo">
        <Header title={`${state.title} Tramo`} breadcrumb={breadcrumb} />
        <div className="form card">
          <Form form={form} layout="vertical" onFinish={onSave}>
            <div className="form-icon">
              <FiAlignJustify />
            </div>

            <Row gutter={20}>
              <Col span="12">
                <Form.Item label="Importe Desde" name="importeDesde" rules={[required]}>
                  <Input type="number" step="0.01" disabled={!GUARDAR} />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item label="Código Desde" name="codigoDesde" rules={[required]}>
                  <Input type="number" disabled={!GUARDAR} />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item label="Importe Hasta" name="importeHasta" rules={[required]}>
                  <Input type="number" step="0.01" disabled={!GUARDAR} />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item label="Código Hasta" name="codigoHasta" rules={[required]}>
                  <Input type="number" disabled={!GUARDAR} />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item label="Porcentaje" name="porcentaje" rules={[required]}>
                  <Input type="number" step="0.01" disabled={!GUARDAR} />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item label="Código Porcentaje" name="codigoPorcentaje" rules={[required]}>
                  <Input type="number" disabled={!GUARDAR} />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item label="Pagarán Fijo" name="pagaranFijo" rules={[required]}>
                  <Input type="number" step="0.01" disabled={!GUARDAR} />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item label="Código Pagarán Fijo" name="codigoPagaranFijo" rules={[required]}>
                  <Input type="number" disabled={!GUARDAR} />
                </Form.Item>
              </Col>
            </Row>

            <div className="form-actions">
              <Link to="/liquidacion/ganancias/tramos">
                <Button type="secondary">Volver</Button>
              </Link>
              {GUARDAR && (
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={state.loading}
                >
                  Guardar
                </Button>
              )}
            </div>
          </Form>
        </div>
      </div>
    </ContextProvider>
  );
};

export default TramosForm;
