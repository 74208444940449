import React from "react";
import { Col, Row } from "antd";

import { hasAction, actions } from "./../../../services/security";

import Header from "../../../components/Header";
import MenuCard from "./../../../components/MenuCard";

const Licencias = () => {
  const items = [
    {
      id: 1,
      titulo: "Accidentes de Trabajo",
      path: "/novedades/licencias/accidentes",
      color: "primary",
      icono: "FiAlertTriangle",
      entidad: "AccidenteTrabajo",
      visible: hasAction(actions.ACCIDENTESTRABAJOS_VER),
    },
    {
      id: 2,
      titulo: "Asistencias",
      path: "/novedades/licencias/asistencias",
      color: "primary",
      icono: "FiCalendar",
      entidad: "Asistencia",
      visible: hasAction(actions.ASISTENCIAS_VER),
    },
    {
      id: 3,
      titulo: "Licencias por Enfermedad",
      path: "/novedades/licencias/enfermedades",
      color: "primary",
      icono: "FiCalendar",
      entidad: "LicenciaEnfermedad",
      visible: hasAction(actions.LICENCIASENFERMEDAD_VER),
    },
    {
      id: 4,
      titulo: "Licencias Especiales / Ausencias",
      path: "/novedades/licencias/especiales",
      color: "primary",
      icono: "FiBookmark",
      entidad: "LicenciaEspecial",
      visible: hasAction(actions.LICENCIAS_ESPECIALES_VER),
    },
    {
      id: 5,
      titulo: "Licencias por Vacaciones",
      path: "/novedades/licencias/vacaciones",
      color: "primary",
      icono: "FiClock",
      entidad: "LicenciaVacaciones",
      visible: hasAction(actions.LICENCIAS_VACACIONES_VER),
    },
  ];

  const breadcrumb = [
    { id: 1, title: "Novedades" },
    { id: 2, title: "Licencias", url: "/novedades/licencias" },
  ];

  return (
    <div className="inicio">
      <Header title="Licencias" breadcrumb={breadcrumb} buttons={() => {}} />
      <Row gutter={[32, 36]}>
        {items.map((i) => (
          <Col key={i.id} xs={12} md={8} xl={6} xxl={4}>
            <MenuCard item={i} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Licencias;
