/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Button, Checkbox } from "antd";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import fileDownload from "js-file-download";

import { Context } from "../../../services/context";
import http from "../../../services/http";

import "./LiquidacionesList.scss";

const LiquidacionesList = () => {
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useContext(Context);

  const [year, setYear] = useState(new Date().getFullYear());
  const [list, setList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getList() {
      const rList = await http.get(
        `legajos/liquidaciones?empleado=${state.id}&anio=${year}`
      );

      if (rList && rList.data.code === 0) {
        const data = rList.data.data;
        setList(data.list);
      }
    }

    getList();
  }, [state.reload, year]);

  const onCheckboxClick = (item, event) => {
    const checked = event.target.checked;
    let nSelected = [...selected.filter((x) => x !== item.id)];

    if (checked) {
      nSelected.push(item.id);
    }

    setSelected(nSelected);
  };

  const onDownloadClick = async () => {
    const data = { ids: selected };
    setLoading(true);

    const rDownload = await http.post(
      "legajos/liquidaciones/download",
      data,
      true
    );

    setLoading(false);
    fileDownload(rDownload.data, `Liquidaciones_${Date.now()}.zip`);
  };

  return (
    <div className="liquidaciones-list">
      <table className="subsection-table">
        <thead>
          <tr>
            <th colSpan="6" style={{ padding: "0" }}>
              <div className="year">
                <div className="prev" onClick={() => setYear(year - 1)}>
                  <FiArrowLeft />
                </div>
                <div>{year}</div>
                <div className="next" onClick={() => setYear(year + 1)}>
                  <FiArrowRight />
                </div>
              </div>
            </th>
          </tr>
          <tr>
            <th></th>
            <th>Tipo</th>
            <th style={{ textAlign: "center" }}>Año</th>
            <th style={{ textAlign: "center" }}>Mes</th>
            <th style={{ textAlign: "center" }}>Fecha</th>
            <th style={{ textAlign: "right", paddingRight: "30px" }}>Neto</th>
          </tr>
        </thead>
        <tbody>
          {list.length === 0 && (
            <tr>
              <td className="table-empty" colSpan="6">
                Sin resultados
              </td>
            </tr>
          )}

          {list.map((x) => (
            <tr key={x.id}>
              <td style={{ textAlign: "center" }}>
                <Checkbox
                  defaultChecked={selected.indexOf(x.id) > -1}
                  onChange={(e) => onCheckboxClick(x, e)}
                />
              </td>
              <td>{x.tipo}</td>
              <td style={{ textAlign: "center" }}>{x.anio}</td>
              <td style={{ textAlign: "center" }}>{x.mes}</td>
              <td style={{ textAlign: "center" }}>{x.fecha}</td>
              <td style={{ textAlign: "right", paddingRight: "30px" }}>
                {x.neto}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="table-footer-actions">
        <Button
          type="primary"
          onClick={onDownloadClick}
          disabled={selected.length === 0}
          loading={loading}
        >
          Descargar
        </Button>
      </div>
    </div>
  );
};

export default LiquidacionesList;
