/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { Button, Form, Select } from "antd";
import { FiPrinter } from "react-icons/fi";
import fileDownload from "js-file-download";

import { ContextProvider } from "../../../../services/context";
import http from "../../../../services/http";
import { hasAction, actions } from "../../../../services/security";

import Filters from "../../../../components/Filters";
import FiltersButton from "../../../../components/FiltersButton";
import Header from "../../../../components/Header";
import Table from "../../../../components/Table";

const { Option } = Select;

const VacacionesPendientes = () => {
  const VER = hasAction(actions.REPORTE_NOVEDAD_VACACIONES_PENDIENTES);

  const [state, setState] = useState({
    filters: {
      empleado: "",
    },
    columns: [
      {
        title: "Legajo",
        style: {},
        render: (item) => item.legajo,
      },
      {
        title: "Nombre",
        style: {},
        render: (item) => item.nombre,
      },
      {
        title: new Date().getFullYear() - 2,
        style: {},
        render: (item) => item.anio1,
      },
      {
        title: new Date().getFullYear() - 1,
        style: {},
        render: (item) => item.anio2,
      },
      {
        title: new Date().getFullYear(),
        style: {},
        render: (item) => item.anio3,
      },
    ],
    loading: true,
    loaded: false,
    reload: true,
    loadingExport: false,
    list: [],
    total: 0,
    page: 1,
    size: 10,
  });

  const [empleados, setEmpleados] = useState([]);

  const breadcrumb = [
    { id: 1, title: "Reportes" },
    {
      id: 2,
      title: "Novedades",
      url: "/reportes/novedad",
    },
    {
      id: 3,
      title: "Vacaciones Pendientes",
      url: "/reportes/novedad/vacaciones-pendientes",
    },
  ];

  const buttons = () => {
    return (
      <React.Fragment>
        <FiltersButton />
        <Button
          type="primary"
          icon={<FiPrinter />}
          onClick={onExport}
          loading={state.loadingExport}
        >
          Exportar
        </Button>
      </React.Fragment>
    );
  };

  const onExport = async () => {
    setState({ ...state, loadingExport: true });

    const rExport = await http.post(
      "reportes/novedades/vacacionespendientes/export",
      state.filters,
      true
    );

    setState({ ...state, loadingExport: false });

    fileDownload(rExport.data, `VacacionesPendientes_${Date.now()}.xlsx`);
  };

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Empleado" name="empleado">
          <Select allowClear showSearch optionFilterProp="children">
            {empleados.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </React.Fragment>
    );
  };

  useEffect(() => {
    async function getData() {
      const response = await http.get(
        "reportes/novedades/vacacionespendientes/data"
      );
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setEmpleados(data.empleados);
      }
    }

    getData();
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="vacaciones-pendientes page">
      <ContextProvider value={[state, setState]}>
        <Header
          title="Licencias por Vacaciones"
          breadcrumb={breadcrumb}
          buttons={buttons}
        />
        <Filters filters={filters} />
        <Table url="reportes/novedades/vacacionespendientes/list" />
      </ContextProvider>
    </div>
  );
};

export default VacacionesPendientes;
