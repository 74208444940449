import React, { useCallback, useContext, useState, useRef } from "react";
import { Modal } from "antd";
import ReactCrop from "react-image-crop";
import { FiCamera } from "react-icons/fi";

import { Context } from "./../../../services/context";
import http from "./../../../services/http";

import placeholder from "./../../../assets/images/profile.png";
import "./Picture.scss";
import 'react-image-crop/dist/ReactCrop.css';
// or scss:
const Picture = ({ data, onDataUpdate }) => {
  const fileRef = useRef(null);
  const imgRef = useRef(null);

  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useContext(Context);

  const [crop, setCrop] = useState({ unit: "%", width: 30, aspect: 1 });
  const [completedCrop, setCompletedCrop] = useState(null);

  const onFileClick = () => {
    if (state.editing) {
      fileRef.current.click();
    }
  };

  const onFileChange = (e) => {
    const files = e.currentTarget.files;
    if (files.length === 0) {
      return;
    }
    const file = files[0];

    const reader = new FileReader();
    reader.addEventListener("load", () => {
      onDataUpdate({
        ...data,
        foto: {
          ...data.foto,
          raw: reader.result,
          crop: true,
          size: file.size,
          name: file.name,
        },
      });
    });
    reader.readAsDataURL(e.target.files[0]);

    e.currentTarget.value = "";
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const onSave = async () => {
    const image = imgRef.current;
    const canvas = document.createElement("canvas");

    const crop = completedCrop;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const request = {
      nombre: data.foto.name,
      tamanio: data.foto.size,
      contenido: canvas.toDataURL("image/jpeg"),
    };

    const rSave = await http.put(`legajos/header/${state.id}`, request);

    if (rSave && rSave.data.code === 0) {
      onDataUpdate({
        ...data,
        foto: {
          ...data.foto,
          crop: false,
          cropped: request.contenido,
        },
      });
    } else {
      onDataUpdate({
        ...data,
        foto: {
          ...data.foto,
          crop: false,
          cropped: "",
        },
      });
    }
  };

  const onCancel = () => {
    onDataUpdate({
      ...data,
      foto: { ...data.foto, fotoPreview: false, fotoRaw: "", foto: "" },
    });
  };

  return (
    <div className={`foto ${state.id > 0 && state.editing && "editable"}`}>
      <div
        className="preview"
        style={{
          backgroundImage: `url(${
            data.foto.cropped === "" ? placeholder : data.foto.cropped
          })`,
        }}
        onClick={onFileClick}
      >
        <div className="overlay">
          <FiCamera />
        </div>
      </div>
      <input
        type="file"
        accept="image/*"
        ref={fileRef}
        onChange={onFileChange}
      />

      <Modal
        className="picture"
        visible={data.foto.crop}
        title="Subir imagen"
        okText="Subir"
        onOk={onSave}
        cancelText="Cancelar"
        onCancel={onCancel}
      >
        <ReactCrop
          src={data.foto.raw}
          crop={crop}
          circularCrop
          onImageLoaded={onLoad}
          onChange={(c) => setCrop(c)}
          onComplete={(c) => setCompletedCrop(c)}
        />
      </Modal>
    </div>
  );
};

export default Picture;
