/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, DatePicker, Form, Input, Row, Select, Modal } from "antd";
import { FiFileText, FiPaperclip, FiUser, FiFolder } from "react-icons/fi";
import locale from "antd/es/date-picker/locale/es_ES";
import "moment/locale/es";

import { Context } from "./../../../services/context";
import { parseDate, parseToDate } from "./../../../services/forms";
import http from "./../../../services/http";
import { showSuccess } from "./../../../services/notificacion";
import { hasAction, actions } from "./../../../services/security";
import { required } from "./../../../services/validation";

import Archivos from "./Archivos";
import DDJJCargoList from "./DdjjCargoList";
import InputMasked from "./../../../components/InputMasked";

const { Option } = Select;

const Personales = () => {
  const [form] = Form.useForm();
  const [state, setState] = useContext(Context);
  const [formActo] = Form.useForm();

  const CREAR = hasAction(actions.LEGAJOS_CREAR);
  const EDITAR = hasAction(actions.LEGAJOS_EDITAR);
  const GUARDAR =
    state.id < 0
      ? false
      : state.id === 0
      ? CREAR && state.editing
      : EDITAR && state.editing;

  const [provincia, setProvincia] = useState("");
  const [departamento, setDepartamento] = useState("");
  const [ddjjDomicilio, setDDJJDomicilio] = useState(false);
  const [currentActo, setCurrentActo] = useState();
  const [valuesForm, setValuesForm] = useState([]);

  useEffect(() => {
    async function getForm() {
      form.resetFields();

      const rForm = await http.get(`legajos/personales/${state.id}`);
      if (rForm && rForm.data.code === 0) {
        const data = rForm.data.data;

        data.form.fechaNacimiento = parseToDate(data.form.fechaNacimiento);
        data.form.domicilioDdjjFecha = parseToDate(
          data.form.domicilioDdjjFecha
        );
        data.form.licenciaVencimiento = parseToDate(
          data.form.licenciaVencimiento
        );

        setProvincia(data.form.provincia);
        setDepartamento(data.form.departamento);
        onChangeDDJJDomicilio(data.form.domicilioDdjj);
        form.setFieldsValue(data.form);
      }
    }

    getForm();
  }, [state.id, state.reload]);

  const onConfirmActo = async (valuesActo) => {
    valuesActo.fecha = parseDate(valuesActo.fecha);
    valuesActo.propiedad = currentActo;
    const values = valuesForm;

    setCurrentActo(undefined);
    setState({ ...state, loading: true });
    values.fechaNacimiento = parseDate(values.fechaNacimiento);
    values.domicilioDdjjFecha = parseDate(values.domicilioDdjjFecha);
    values.licenciaVencimiento = parseDate(values.licenciaVencimiento);
    values.fechaActo = valuesActo.fecha;
    values.instrumentoActo = valuesActo.instrumento;
    values.instrumentoNumeroActo = valuesActo.instrumentoNumero;
    values.expedienteActo = valuesActo.expediente;

    const rSave = await http.post("legajos/personales", values);

    if (rSave && rSave.data.code === 0) {
      const data = rSave.data.data;
      showSuccess("El legajo fue guardado exitosamente.");

      if (state.id === 0) {
        setState({
          ...state,
          title: "Editar",
          id: data.id,
          loading: false,
          legajo: data.id,
        });
      } else {
        setState({ ...state, loading: false });
      }
    } else {
      setState({ ...state, loading: false });
    }
  };

  const onCancelActo = () => {
    setCurrentActo(undefined);
  };

  const calculateCuil = (documento, sexo, prefijo = "") => {
    documento = documento.length === 7 ? documento.padStart(8, "0") : documento;

    if (prefijo === "") {
      prefijo = sexo === "1" ? "20" : sexo === "2" ? "27" : "30";
    }

    var factores = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
    var cuit = prefijo + documento;
    var valores = cuit.split("");
    var acumulado = 0;

    for (var i = 0; i < factores.length; i++) {
      var valor = parseInt(valores[i]);
      acumulado += valor * factores[i];
    }

    var digito = acumulado % 11;

    if (digito === 1) {
      if (sexo === "3") {
        return calculateCuil(documento, sexo, "33");
      } else {
        return calculateCuil(documento, sexo, "23");
      }
    } else if (digito !== 0) {
      digito = 11 - digito;
    }

    return prefijo + documento + digito.toString();
  };

  const onNombreChange = (e) => {
    setState({ ...state, nombre: e.currentTarget.value });
  };

  const onCuilChange = () => {
    const tipo = form.getFieldValue("documentoTipo");
    const documento = form.getFieldValue("documento");
    const sexo = form.getFieldValue("sexo");

    if (
      tipo !== "1" ||
      !documento ||
      documento === "" ||
      documento.length < 7 ||
      !sexo
    ) {
      form.setFieldsValue({ cuil: "" });
      return;
    }

    let cuil = calculateCuil(documento, sexo);
    form.setFieldsValue({ cuil: cuil });
  };

  const onProvinciaChange = (value) => {
    setProvincia(value);
    form.setFieldsValue({
      departamento: null,
      distrito: null,
      codigoPostal: null,
    });
  };

  const onDepartamentoChange = (value) => {
    setDepartamento(value);
    form.setFieldsValue({ distrito: null, codigoPostal: null });
  };

  const onDistritoChange = (value) => {
    const distrito = state.distritos.find((x) => x.value === value);
    form.setFieldsValue({
      codigoPostal: distrito !== undefined ? distrito.extra : "",
    });
  };

  const onChangeDDJJDomicilio = (value) => {
    const req = value === "1" ? true : false;
    setDDJJDomicilio(req);
  };

  const onSave = async (values) => {
    if (state.id === 0) {
      setValuesForm(values);
      setCurrentActo("new");
    } else {
      setState({ ...state, loading: true });
      const rSave = await http.put(`legajos/personales/${state.id}`, values);

      if (rSave && rSave.data.code === 0) {
        const data = rSave.data.data;
        showSuccess("El legajo fue guardado exitosamente.");

        if (state.id === 0) {
          setState({
            ...state,
            title: "Editar",
            id: data.id,
            loading: false,
            legajo: data.id,
          });
        } else {
          setState({ ...state, loading: false });
        }
      } else {
        setState({ ...state, loading: false });
      }
    }
  };

  return (
    <div className="personales">
      <div className="section-header">
        <div className="section-title">
          Datos Personales
          <FiUser />
        </div>
        <div className="section-subtitle">
          Información personal del empleado
        </div>
      </div>

      <Form
        form={form}
        className="subsection-form"
        layout="vertical"
        onFinish={onSave}
      >
        <Row gutter={20}>
          <Col span="16">
            <Form.Item
              label="Nombre y Apellido"
              name="nombre"
              rules={[required]}
            >
              <Input onChange={onNombreChange} disabled={!GUARDAR} />
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item label="Legajo" name="legajo">
              <Input disabled={state.id > 0} />
            </Form.Item>
          </Col>

          <Col span="8">
            <Form.Item
              label="Tipo de Documento"
              name="documentoTipo"
              rules={[required]}
            >
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                disabled={!GUARDAR}
                onChange={onCuilChange}
              >
                {state.documentos.map((x) => (
                  <Option key={x.value} value={x.value}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item label="Documento" name="documento" rules={[required]}>
              <Input disabled={!GUARDAR} onChange={onCuilChange} />
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item label="Sexo" name="sexo" rules={[required]}>
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                disabled={!GUARDAR}
                onChange={onCuilChange}
              >
                {state.sexos.map((x) => (
                  <Option key={x.value} value={x.value}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span="12">
            <Form.Item label="CUIL" name="cuil">
              <InputMasked mask="99 99999999 9" disabled={!GUARDAR} />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item label="Fecha de Nacimiento" name="fechaNacimiento">
              <DatePicker
                format="DD/MM/YYYY"
                placeholder=""
                locale={locale}
                disabled={!GUARDAR}
              />
            </Form.Item>
          </Col>

          <Col span="16">
            <Form.Item label="Calle" name="calle">
              <Input disabled={!GUARDAR} />
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item label="Provincia" name="provincia">
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                onChange={onProvinciaChange}
                disabled={!GUARDAR}
              >
                {state.provincias.map((x) => (
                  <Option key={x.value} value={x.value} disabled={x.disabled}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span="8">
            <Form.Item label="Barrio" name="barrio">
              <Input disabled={!GUARDAR} />
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item label="Manzana" name="manzana">
              <Input disabled={!GUARDAR} />
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item label="Casa" name="casa">
              <Input disabled={!GUARDAR} />
            </Form.Item>
          </Col>

          <Col span="8">
            <Form.Item label="Departamento" name="departamento">
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                onChange={onDepartamentoChange}
                disabled={!GUARDAR}
              >
                {state.departamentos
                  .filter((x) => x.parent === provincia)
                  .map((x) => (
                    <Option key={x.value} value={x.value}>
                      {x.text}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item label="Distrito" name="distrito">
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                onChange={onDistritoChange}
                disabled={!GUARDAR}
              >
                {state.distritos
                  .filter((x) => x.parent === departamento)
                  .map((x) => (
                    <Option key={x.value} value={x.value}>
                      {x.text}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item label="Código Postal" name="codigoPostal">
              <Input disabled={!GUARDAR} />
            </Form.Item>
          </Col>

          <Col span="12">
            <Form.Item
              label="Declaración Jurada del Domicilio"
              name="domicilioDdjj"
            >
              <Select disabled={!GUARDAR} onChange={onChangeDDJJDomicilio}>
                <Option value="0">No</Option>
                <Option value="1">Si</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span="12">
            <Form.Item
              label="Fecha de Entrega"
              name="domicilioDdjjFecha"
              rules={[
                {
                  required: ddjjDomicilio,
                  message: "Este campo es obligatorio",
                },
              ]}
            >
              <DatePicker
                format="DD/MM/YYYY"
                placeholder=""
                locale={locale}
                disabled={!GUARDAR}
              />
            </Form.Item>
          </Col>

          <Col span="8">
            <Form.Item label="Teléfono Particular" name="telefono">
              <Input disabled={!GUARDAR} />
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item label="Teléfono Celular #1" name="celular1">
              <Input disabled={!GUARDAR} />
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item label="Teléfono Celular #2" name="celular2">
              <Input disabled={!GUARDAR} />
            </Form.Item>
          </Col>

          <Col span="24">
            <Form.Item label="Correo Electrónico Personal" name="email">
              <Input disabled={!GUARDAR} />
            </Form.Item>
          </Col>

          <Col span="12">
            <Form.Item label="Lugar de nacimiento" name="lugarNacimiento">
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                disabled={!GUARDAR}
              >
                {state.provincias.map((x) => (
                  <Option key={x.value} value={x.value} disabled={x.disabled}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item label="Nacionalidad" name="nacionalidad">
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                disabled={!GUARDAR}
              >
                {state.paises.map((x) => (
                  <Option key={x.value} value={x.value} disabled={x.disabled}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span="24">
            <Form.Item label="Estado Civil" name="estadoCivil">
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                disabled={!GUARDAR}
              >
                {state.estadosCiviles.map((x) => (
                  <Option key={x.value} value={x.value}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span="8">
            <Form.Item label="Licencia de Conducir" name="licencia">
              <Select disabled={!GUARDAR}>
                <Option value="0">No</Option>
                <Option value="1">Si</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item label="Categoría" name="licenciaConducir">
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                disabled={!GUARDAR}
              >
                {state.licenciasConducir.map((x) => (
                  <Option key={x.value} value={x.value}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item label="Vencimiento" name="licenciaVencimiento">
              <DatePicker
                format="DD/MM/YYYY"
                placeholder=""
                locale={locale}
                disabled={!GUARDAR}
              />
            </Form.Item>
          </Col>

          <Col span="12">
            <Form.Item label="Cert. Buena Conducta" name="buenaConducta">
              <Select disabled={!GUARDAR}>
                <Option value="0">Sin Antecedentes</Option>
                <Option value="1">Con Antecedentes</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item label="Grupo Sanguíneo" name="grupoSanguineo">
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                disabled={!GUARDAR}
              >
                {state.gruposSanguineos.map((x) => (
                  <Option key={x.value} value={x.value} disabled={x.disabled}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {GUARDAR && (
          <div className="form-actions">
            <Button type="primary" htmlType="submit" loading={state.loading}>
              Guardar
            </Button>
          </div>
        )}
      </Form>
      {state.id > 0 && (
        <React.Fragment>
          <hr />
          <div className="section-header">
            <div className="section-title">
              DDJJ Cargos
              <FiFileText />
            </div>
          </div>
          <DDJJCargoList />
        </React.Fragment>
      )}
      {state.id > 0 && (
        <React.Fragment>
          <hr />
          <div className="section-header">
            <div className="section-title">
              Documentación
              <FiPaperclip />
            </div>
            <div className="section-subtitle">
              Documentación asociada con la información personal del empleado
            </div>
          </div>
          <Archivos seccion="1" />
        </React.Fragment>
      )}

      <Modal
        visible={currentActo}
        footer={null}
        closable={false}
        maskClosable={false}
        keyboard={false}
        destroyOnClose={true}
      >
        <Form
          form={formActo}
          className="subsection-form"
          layout="vertical"
          onFinish={onConfirmActo}
        >
          <div className="form">
            <div className="form-icon">
              <FiFolder />
            </div>

            <Row gutter={20}>
              <Col span="24">
                <Form.Item label="Fecha" name="fecha" rules={[required]}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    placeholder=""
                    locale={locale}
                  />
                </Form.Item>
              </Col>
              <Col span="24">
                <Form.Item label="Instrumento" name="instrumento">
                  <Select allowClear showSearch optionFilterProp="children">
                    {state.instrumentos.map((x) => (
                      <Option key={x.value} value={x.value}>
                        {x.text}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span="24">
                <Form.Item label="N°" name="instrumentoNumero">
                  <Input />
                </Form.Item>
              </Col>
              <Col span="24">
                <Form.Item label="Expediente" name="expediente">
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <div className="form-actions">
              <Button type="secondary" onClick={onCancelActo}>
                Cancelar
              </Button>
              <Button type="primary" htmlType="submit">
                Confirmar
              </Button>
            </div>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default Personales;
