/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { FiCheckCircle } from "react-icons/fi";

import LiquidacionesList from "./LiquidacionesList";

const Liquidaciones = () => {
  return (
    <div className="liquidaciones">
      <div className="section-header">
        <div className="section-title">
          Liquidaciones
          <FiCheckCircle />
        </div>
        <div className="section-subtitle">
          Lista de liquidaciones en las que fue incluido el empleado.
        </div>
      </div>

      <LiquidacionesList />
    </div>
  );
};

export default Liquidaciones;
