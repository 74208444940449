/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { Button, Col, Form, Input, Row, Select } from "antd";
import { FiPaperclip } from "react-icons/fi";

import { Context } from "./../../../services/context";
import http from "./../../../services/http";
import { showSuccess } from "./../../../services/notificacion";
import { required } from "./../../../services/validation";

import Archivos from "./Archivos";

const { TextArea } = Input;
const { Option } = Select;

const EstudiosForm = ({ current, onCurrentChange }) => {
  const [form] = Form.useForm();
  const [state, setState] = useContext(Context);

  const onBack = () => {
    onCurrentChange(null, null);
  };

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    values.empleadoId = state.id;

    const rSave =
      current === 0
        ? await http.post("legajos/estudios", values)
        : await http.put(`legajos/estudios/${current}`, values);

    if (rSave && rSave.data.code === 0) {
      showSuccess("El estudio fue guardado exitosamente.");
      setState({ ...state, loading: false });
      onCurrentChange(null, null);
    } else {
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {
    async function getForm() {
      form.resetFields();

      const rForm = await http.get(`legajos/estudios/${current}`);
      if (rForm && rForm.data.code === 0) {
        const data = rForm.data.data;
        form.setFieldsValue(data.form);
      }
    }

    getForm();
  }, [state.id, state.reload]);

  return (
    <div className="estudios-form ">
      <Form
        form={form}
        className="subsection-form"
        layout="vertical"
        onFinish={onSave}
      >
        <Row gutter={20}>
          <Col span="16">
            <Form.Item label="Título" name="titulo" rules={[required]}>
              <Select allowClear showSearch optionFilterProp="children">
                {state.titulos.map((x) => (
                  <Option key={x.value} value={x.value} disabled={x.disabled}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item
              label="Nivel de Estudio"
              name="nivelEstudio"
              rules={[required]}
            >
              <Select allowClear showSearch optionFilterProp="children">
                {state.nivelesEstudio.map((x) => (
                  <Option key={x.value} value={x.value} disabled={x.disabled}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label="Establecimiento"
          name="establecimiento"
          rules={[required]}
        >
          <Input />
        </Form.Item>

        <Row gutter={20}>
          <Col span="8">
            <Form.Item label="Periodo Desde" name="desde" rules={[required]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item label="Periodo Hasta" name="hasta">
              <Input />
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item label="Estado" name="estado" rules={[required]}>
              <Select allowClear showSearch optionFilterProp="children">
                {state.estudioEstados.map((x) => (
                  <Option key={x.value} value={x.value}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span="12">
            <Form.Item
              label="Título Inherente a Función"
              name="tituloInherente"
            >
              <Select allowClear showSearch optionFilterProp="children">
                <Option value="0">No</Option>
                <Option value="1">Si</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item label="Tipo Capacitación" name="tipoCapacitacion">
              <Select allowClear showSearch optionFilterProp="children">
                {state.estudioTipos.map((x) => (
                  <Option key={x.value} value={x.value}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Acto Administrativo" name="actoAdministrativo">
          <Input />
        </Form.Item>

        <Form.Item
          label="Expediente o Nota de Asignación Títulos"
          name="expediente"
        >
          <Input />
        </Form.Item>

        <Form.Item label="Comentarios" name="comentarios" className="textarea">
          <TextArea maxLength={2000} showCount rows={4} />
        </Form.Item>

        <div className="form-actions">
          <Button type="secondary" onClick={onBack}>
            Volver
          </Button>
          <Button type="primary" htmlType="submit" loading={state.loading}>
            Guardar
          </Button>
        </div>
      </Form>

      {current > 0 && (
        <React.Fragment>
          <hr />
          <div className="section-header">
            <div className="section-title">
              Documentación
              <FiPaperclip />
            </div>
            <div className="section-subtitle">
              Documentación asociada con el estudio / capacitación.
            </div>
          </div>
          <Archivos seccion="3" estudio={current} />
        </React.Fragment>
      )}
    </div>
  );
};

export default EstudiosForm;
