/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Button, Form, Input, Menu, Modal, Select, Switch } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { FiEdit, FiPlus, FiTrash2 } from "react-icons/fi";

import { ContextProvider } from "./../../../../services/context";
import http from "./../../../../services/http";
import { showSuccess } from "./../../../../services/notificacion";
import { hasAction, actions } from "./../../../../services/security";

import Filters from "./../../../../components/Filters";
import FiltersButton from "./../../../../components/FiltersButton";
import Header from "./../../../../components/Header";
import Table from "./../../../../components/Table";

const { Option } = Select;

const Elementos = () => {
  const history = useHistory();

  const VER = hasAction(actions.ELEMENTOS_VER);
  const CREAR = hasAction(actions.ELEMENTOS_CREAR);
  const EDITAR = hasAction(actions.ELEMENTOS_EDITAR);
  const ELIMINAR = hasAction(actions.ELEMENTOS_ELIMINAR);
  const DESHABILITAR = hasAction(actions.ELEMENTOS_DESHABILITAR);

  const [state, setState] = useState({
    filters: {
      nombre: "",
      codigo: "",
      tipoElementoId: "",
      habilitado: "1",
    },
    columns: [
      {
        id: 1,
        title: "Código",
        style: {},
        render: (item) => item.codigo,
      },
      {
        id: 2,
        title: "Nombre",
        style: {},
        render: (item) => item.nombre,
      },
      {
        id: 3,
        title: "Descripción",
        style: {},
        render: (item) => item.descripcion,
      },
      {
        id: 4,
        title: "Tipo",
        style: {},
        render: (item) => item.tipoElemento,
      },
      {
        id: 5,
        title: "Habilitado",
        style: { width: "150px", textAlign: "center" },
        render: (item) => (
          <Switch
            size="small"
            disabled={!DESHABILITAR}
            defaultChecked={item.habilitado}
            onChange={() => onDisable(item)}
          />
        ),
      },
    ],
    loading: true,
    loaded: false,
    reload: true,
    list: [],
    total: 0,
    page: 1,
    size: 10,
  });

  const [tipos, setTipos] = useState([]);

  const breadcrumb = [
    {
      id: 1,
      title: "Parámetros",
    },
    {
      id: 2,
      title: "Seguridad",
      url: "/parametros/seguridad",
    },
    {
      id: 3,
      title: "Elementos",
      url: "/parametros/seguridad/elementos",
    },
  ];

  const buttons = () => {
    return (
      <React.Fragment>
        <FiltersButton />
        {CREAR && (
          <Button type="primary" icon={<FiPlus />} onClick={onAdd}>
            Nuevo
          </Button>
        )}
      </React.Fragment>
    );
  };

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Nombre" name="nombre">
          <Input />
        </Form.Item>
        <Form.Item label="Código" name="codigo">
          <Input />
        </Form.Item>

        <Form.Item label="Tipo de elemento" name="tipoElementoId">
          <Select showSearch allowClear optionFilterProp="children">
            {tipos.map((m) => (
              <Option key={m.value} value={m.value}>
                {m.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Habilitado" name="habilitado">
          <Select defaultValue="1">
            <Select.Option value="-1">Indistinto</Select.Option>
            <Select.Option value="1">Si</Select.Option>
            <Select.Option value="0">No</Select.Option>
          </Select>
        </Form.Item>
      </React.Fragment>
    );
  };

  const menu = (item) => {
    return (
      <Menu className="table-actions-menu">
        {EDITAR && (
          <Menu.Item key="0" onClick={() => onEdit(item)}>
            <FiEdit />
            Editar
          </Menu.Item>
        )}
        {ELIMINAR && (
          <Menu.Item key="1" onClick={() => onDelete(item)}>
            <FiTrash2 />
            Eliminar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const onAdd = () => {
    history.push("/parametros/seguridad/elementos/nuevo");
  };

  const onEdit = (item) => {
    history.push(`/parametros/seguridad/elementos/${item.id}`);
  };

  const onDisable = async (item) => {
    await http.put(`Elementos/disable/${item.id}`);
  };

  const onDelete = (item) => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea eliminar este elemento?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const rDelete = await http.delete(`Elementos/${item.id}`);
        if (rDelete && rDelete.data.code === 0) {
          showSuccess("El elemento fue eliminado exitosamente.");
          setState({ ...state, reload: !state.reload });
        }
      },
    });
  };

  useEffect(() => {
    async function getData() {
      const response = await http.get("elementos/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setTipos(data.tipos);
      }
    }

    getData();
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="Elementos page">
      <ContextProvider value={[state, setState]}>
        <Header title="Elementos" breadcrumb={breadcrumb} buttons={buttons} />
        <Filters filters={filters} />
        <Table url="Elementos/list" menu={menu} />
      </ContextProvider>
    </div>
  );
};

export default Elementos;
