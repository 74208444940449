/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { Dropdown, Menu, Select } from "antd";
import {
  FiArrowLeft,
  FiArrowRight,
  FiEdit,
  FiMoreVertical,
} from "react-icons/fi";

import { formatCurrency } from "./../../../services/forms";
import http from "./../../../services/http";
import { hasAction, actions } from "./../../../services/security";

import Header from "./../../../components/Header";
import AcumuladoForm from "./AcumuladoForm";

import "./Acumulado.scss";

const Acumulado = () => {
  const VER = hasAction(actions.ACUMULADO_VER);
  const EDITAR = hasAction(actions.ACUMULADO_EDITAR);

  const breadcrumb = [
    { id: 1, title: "Liquidación" },
    { id: 2, title: "Acumulado SAC", url: "/acumulado" },
  ];

  const [empleados, setEmpleados] = useState([]);
  const [selected, setSelected] = useState();
  const [reload, setReload] = useState(false);

  const [empleado, setEmpleado] = useState();
  const [anio, setAnio] = useState(new Date().getFullYear());
  const [totalMonto, setTotalMonto] = useState();
  const [totalDias, setTotalDias] = useState();
  const [totalDiasIngresados, setTotalDiasIngresados] = useState();
  const [periodos, setPeriodos] = useState([]);

  const menu = (item) => {
    return (
      <Menu className="table-actions-menu">
        {EDITAR && (
          <Menu.Item key="0" onClick={() => onClickEdit(item)}>
            <FiEdit />
            Editar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  useEffect(() => {
    async function getData() {
      const response = await http.get("acumulado/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setEmpleados(data.empleados);
      }
    }

    getData();
  }, []);

  useEffect(() => {
    async function getTable() {
      const response = await http.get(
        `acumulado?empleadoId=${empleado}&anio=${anio}`
      );
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setTotalMonto(data.haberes);
        setTotalDias(data.dias);
        setTotalDiasIngresados(data.diasIngresados);
        setPeriodos(data.periodos);
      }
    }

    getTable();
  }, [anio, empleado, reload]);

  const onChangeEmpleado = (value) => {
    setEmpleado(value);
  };

  const onClickPrev = () => {
    setAnio(anio - 1);
  };
  const onClickNext = () => {
    setAnio(anio + 1);
  };

  const onClickEdit = (item) => {
    const nEmpleado = empleados.find((x) => x.value === empleado);

    if (!nEmpleado) {
      return;
    }

    item.empleadoId = empleado;
    item.empleado = nEmpleado.text;
    item.anio = anio;
    setSelected(item);
  };

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="acumulado page">
      <Header title="Acumulado SAC" breadcrumb={breadcrumb} />
      <div className="card">
        <table className="table-small table-clases">
          <thead>
            <tr>
              <th colSpan={5}>
                <Select
                  placeholder="Seleccionar un legajo"
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  onChange={onChangeEmpleado}
                  style={{ width: "100%" }}
                >
                  {empleados.map((item, index) => (
                    <Select.Option key={index} value={item.value}>
                      {item.text}
                    </Select.Option>
                  ))}
                </Select>
              </th>
            </tr>
            <tr>
              <th colSpan={5} style={{ padding: 0 }}>
                <div className="anio">
                  <div className="navigation" onClick={onClickPrev}>
                    <FiArrowLeft />
                  </div>
                  <div>{anio}</div>
                  <div className="navigation" onClick={onClickNext}>
                    <FiArrowRight />
                  </div>
                </div>
              </th>
            </tr>
            <tr>
              <th></th>
              <th>Período</th>
              <th>Haberes Sujetos</th>
              <th>Días Trabajados</th>
              <th>Días Ingresados</th>
            </tr>
          </thead>
          <tbody>
            {periodos.map((item, index) => (
              <tr key={index}>
                <td className="table-actions">
                  <Dropdown
                    overlay={() => menu(item)}
                    trigger={["click"]}
                    placement="bottomLeft"
                    arrow
                  >
                    <div>
                      <FiMoreVertical />
                    </div>
                  </Dropdown>
                </td>
                <td>{item.periodo}</td>
                <td>{formatCurrency(item.haberes)}</td>
                <td>{item.dias ?? "-"}</td>
                <td>{item.diasIngresados ?? "-"}</td>
              </tr>
            ))}
            <tr>
              <td></td>
              <td className="total">Total</td>
              <td className="total">{formatCurrency(totalMonto)}</td>
              <td className="total">{totalDias}</td>
              <td className="total">{totalDiasIngresados}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <AcumuladoForm
        item={selected}
        onSave={() => setReload(!reload)}
        onClose={() => setSelected(undefined)}
      />
    </div>
  );
};

export default Acumulado;
