/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { Button, Form, Select, Input } from "antd";
import { FiPrinter } from "react-icons/fi";

import { ContextProvider } from "../../../../services/context";
import http from "../../../../services/http";
import { hasAction, actions } from "../../../../services/security";

import Filters from "../../../../components/Filters";
import FiltersButton from "../../../../components/FiltersButton";
import Header from "../../../../components/Header";
import Table from "../../../../components/Table";
import fileDownload from "js-file-download";

const { Option } = Select;

const Titulo = () => {
  const VER = hasAction(actions.REPORTE_LEGAJO_TITULO);

  const [state, setState] = useState({
    filters: {
      legajo: "",
      vinculo: "",
      nivel: "",
      estado: "",
      titulo: "",
      establecimiento: "",
      desde: "",
      hasta: "",
    },
    columns: [
      {
        id: 1,
        title: "Legajo",
        style: {},
        render: (item) => item.empleadoId,
      },
      {
        id: 2,
        title: "Nombre",
        style: {},
        render: (item) => item.empleado,
      },
      {
        id: 3,
        title: "Título",
        style: {},
        render: (item) => item.titulo,
      },
      {
        id: 11,
        title: "Nivel",
        style: {},
        render: (item) => item.nivelEstudio,
      },
      {
        id: 4,
        title: "Periodo",
        style: {},
        render: (item) => item.periodo,
      },
      {
        id: 5,
        title: "Estado",
        style: {},
        render: (item) => item.estado,
      },
      {
        id: 6,
        title: "Establecimiento",
        style: {},
        render: (item) => item.establecimiento,
      },

      {
        id: 7,
        title: "Duración",
        style: {},
        render: (item) => item.duracion,
      },
      {
        id: 9,
        title: "Expediente o Nota de Asignación Títulos",
        style: {},
        render: (item) => item.expediente,
      },
    ],
    loading: true,
    loaded: false,
    reload: true,
    list: [],
    total: 0,
    page: 1,
    size: 10,
  });
  const [empleados, setEmpleados] = useState([]);
  const [niveles, setNiveles] = useState([]);
  const [estados, setEstados] = useState([]);
  const [titulos, setTitulos] = useState([]);
  const [loadingExport, setLoadingExport] = useState(false);
  const breadcrumb = [
    {
      id: 1,
      title: "Reportes",
    },
    {
      id: 2,
      title: "Legajos",
      url: "/reportes/legajo",
    },
    {
      id: 3,
      title: "Títulos",
      url: "/reportes/legajo/titulos",
    },
  ];

  const onExport = async () => {
    setLoadingExport(true);
    const rExport = await http.post(
      "Reportes/Legajos/Titulo/export/List",
      state.filters,
      true
    );
    setLoadingExport(false);
    fileDownload(rExport.data, `ControlAsignacion${Date.now()}.xlsx`);
  };

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Legajo" name="legajo">
          <Select allowClear showSearch optionFilterProp="children">
            {empleados.map((x) => (
              <Option key={x.id} value={x.id}>
                {x.nombre}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Nivel" name="nivel">
          <Select allowClear showSearch optionFilterProp="children">
            {niveles.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Estado" name="estado">
          <Select allowClear showSearch optionFilterProp="children">
            {estados.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Título" name="titulo">
          <Select allowClear showSearch optionFilterProp="children">
            {titulos.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Periodo Desde" name="desde">
          <Input />
        </Form.Item>

        <Form.Item label="Periodo Hasta" name="hasta">
          <Input />
        </Form.Item>
        <Form.Item label="Establecimiento" name="establecimiento">
          <Input />
        </Form.Item>
      </React.Fragment>
    );
  };

  useEffect(() => {
    async function getData() {
      const response = await http.get("reportes/Legajos/Titulo/Data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setEmpleados(data.empleados);
        setNiveles(data.niveles);
        setTitulos(data.titulos);
        setEstados(data.estados);
      }
    }

    getData();
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }
  const buttons = () => {
    return (
      <React.Fragment>
        <FiltersButton />
        <Button
          type="primary"
          icon={<FiPrinter />}
          onClick={onExport}
          loading={loadingExport}
        >
          Exportar
        </Button>
      </React.Fragment>
    );
  };

  return (
    <div className="Títulos page">
      <ContextProvider value={[state, setState]}>
        <Header title="Títulos" breadcrumb={breadcrumb} buttons={buttons} />
        <Filters filters={filters} />
        <Table url="Reportes/Legajos/Titulo/list" />
      </ContextProvider>
    </div>
  );
};

export default Titulo;
