/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Modal } from "antd";
import { FiDownload, FiCheckCircle, FiFile } from "react-icons/fi";
import "react-slidedown/lib/slidedown.css";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { ContextProvider } from "../../../../services/context";
import http from "../../../../services/http";
import { showSuccess } from "../../../../services/notificacion";
import Stepper from "../../../../components/Stepper";
import EliminacionDatos from "./EliminacionDatos";
import EliminacionPreview from "./EliminacionPreview";

const EliminacionForm = ({ onClose }) => {
    const history = useHistory();
    const [form] = Form.useForm();


    const [state, setState] = useState({
        loading: false,
        form: form,
        step: 0,
        tiposImportacion: [],
        conceptos: [],
        formatos: [],
        contenido: null,
        novedades: [],
    });



    const steps = [
        {
            id: 1,
            icon: <FiFile />,
            title: "1. Formulario",
            description: "Seleccioná el archivo y completa los datos",
        },
        {
            id: 3,
            icon: <FiCheckCircle />,
            title: "2. Confirmar",
            description: "Confirmá y finalizá la eliminación",
        },
    ];

    const onSave = async (values) => {

        // datos
        if (state.step === 0) {
            values.contenido = state.contenido;
            setState({
                ...state,
                contenido: null,
                step: state.step + 1,
                values: { ...state.values, ...values },
            });
            return;
        }

        setState({ ...state, loading: true });
        if (state.novedades[0].error === true) {
            Modal.confirm({
                title: "Confirmar eliminación",
                icon: <ExclamationCircleOutlined />,
                content: "¿Alugunas lineas no se pudieron procesar, desea continuar?",
                okText: "Confirmar",
                cancelText: "Cancelar",
                onOk: async () => {
                    const rSave = await http.post(`NovedadVariable/1`, state.novedades);
                    if (rSave && rSave.data.code === 0) {
                        showSuccess("La eliminación se ejecutó exitosamente.");
                        setState({ ...state, step: state.step - 1 });
                        state.form.resetFields();
                        history.push("/novedades/liquidacion/novedadVariable");
                        onClose();
                    } else {
                        setState({ ...state, loading: false });
                    }
                },
                onCancel: async () => {
                    setState({ ...state, loading: false });

                }
            });
        }
        else {
            const rSave = await http.post(`NovedadVariable/1`, state.novedades);
            if (rSave && rSave.data.code === 0) {
                showSuccess("La eliminación se ejecutó exitosamente.");
                setState({ ...state, step: state.step - 1 });
                state.form.resetFields();
                history.push("/novedades/liquidacion/novedadVariable");
                onClose();
            } else {
                setState({ ...state, loading: false });
            }
        }

    };




    return (
        <div className="liquidacion">
            <Form form={state.form} layout="vertical" onFinish={onSave}>
                <div className="form px-0 card">
                    <div className="form-icon">
                        <FiDownload />
                    </div>
                    <ContextProvider value={[state, setState]}>
                        <Stepper steps={steps} left />
                        {state.step === 0 && <EliminacionDatos />}
                        {state.step === 1 && <EliminacionPreview />}
                    </ContextProvider>
                </div>
            </Form>
        </div>
    );
};

export default EliminacionForm;
