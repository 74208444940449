/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Form, Select, Button, Input, DatePicker } from "antd";
import { FiLogIn } from "react-icons/fi";

import { Context } from "../../../../services/context";
import http from "../../../../services/http";
import { required } from "../../../../services/validation";

import locale from "antd/es/date-picker/locale/es_ES";
import "moment/locale/es";

const { Option } = Select;

const DeduccionesForm = ({ item, onClose }) => {
  const [form] = Form.useForm();

  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [deduccionesList, setDeduccionesList] = useState([]);

  const onSave = async (values) => {
    setLoading(true);

    const rSave = await http.post("ddjj/deducciones/" + state.id, values);

    if (rSave && rSave.data.code === 0) {
      form.resetFields();

      setLoading(false);
      onClose();
    } else {
      setLoading(false);
    }
  };

  function onChangeDeduccion(item) {
    if (item) {
      const valor = deduccionesList.find((d) => d.id === item).valor;
      form.setFieldsValue({ valor: valor });
    }
  }

  useEffect(() => {
    async function getData() {
      const response = await http.get(`DDJJ/deducciones/data`);

      if (response && response.data.code === 0) {
        const data = response.data.data;
        setDeduccionesList(data.deducciones);
      }
    }
    getData();
  }, []);

  return (
    <Form
      form={form}
      name="form-deducciones"
      layout="vertical"
      onFinish={onSave}
    >
      <div className="form">
        <div className="form-icon">
          <FiLogIn />
        </div>

        <div style={{ position: "relative" }}>
          <Form.Item
            label="Deducción"
            name="deduccionGananciaId"
            rules={[required]}
          >
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              onChange={onChangeDeduccion}
            >
              {deduccionesList.map((x) => (
                <Option key={x.id} value={x.id}>
                  {x.codigo} - {x.nombre}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Valor" name="valor" rules={[required]}>
            <Input />
          </Form.Item>
          <Form.Item label="Fecha Desde" name="fechaDesde" rules={[required]}>
            <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
          </Form.Item>
          <Form.Item label="Fecha Hasta" name="fechaHasta">
            <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
          </Form.Item>
        </div>

        <div className="form-actions">
          <Button type="secondary" onClick={onClose}>
            Volver
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            Guardar
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default DeduccionesForm;
