import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Button } from "antd";
import { LoginContext } from "./LoginContext";
import http from "./../../services/http";
import { showSuccess } from "./../../services/notificacion";
import { setData } from "./../../services/security";
import { compare, required } from "./../../services/validation";
import "./UpdateForm.scss";

const UpdateForm = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [login, setLogin] = useContext(LoginContext);

  const onSave = async (values) => {
    setLogin({ ...login, loading: true });

    values.username = login.username;
    const result = await http.post("auth/update", values);

    if (result && result.data.code === 0) {
      showSuccess("Tu contraseña fue actualizada exitosamente.");
      setData(login.data);
      history.push("/");
    }

    setLogin({ ...login, loading: false });
  };

  return (
    <Form
      form={form}
      name="login"
      layout="vertical"
      initialValues={login}
      className="form-update"
      onFinish={onSave}
    >
      <h2 className="title">Actualiza tu contraseña</h2>

      <Form.Item label="Contraseña" name="password" rules={[required]}>
        <Input.Password autoComplete="off" />
      </Form.Item>

      <Form.Item
        label="Confirmar contraseña"
        name="confirm"
        rules={[
          required,
          compare("password", "Las contraseñas ingresadas no coinciden"),
        ]}
      >
        <Input.Password autoComplete="off" />
      </Form.Item>

      <div className="form-actions">
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={login.loading}>
            Actualizar
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};

export default UpdateForm;
