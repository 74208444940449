import http from "./http";

export const actions = {
  PERFILES_VER: 100,
  PERFILES_CREAR: 101,
  PERFILES_EDITAR: 102,
  PERFILES_ELIMINAR: 103,

  USUARIOS_VER: 200,
  USUARIOS_CREAR: 201,
  USUARIOS_EDITAR: 202,
  USUARIOS_ELIMINAR: 203,

  AUDITORIA_VER: 304,

  PROVINCIAS_VER: 400,
  PROVINCIAS_CREAR: 401,
  PROVINCIAS_EDITAR: 402,
  PROVINCIAS_ELIMINAR: 403,
  PROVINCIAS_DESHABILITAR: 404,

  ART_VER: 500,
  ART_CREAR: 501,
  ART_EDITAR: 502,
  ART_ELIMINAR: 503,
  ART_DESHABILITAR: 504,

  AFJP_VER: 600,
  AFJP_CREAR: 601,
  AFJP_EDITAR: 602,
  AFJP_ELIMINAR: 603,
  AFJP_DESHABILITAR: 604,

  NIVELESTUDIO_VER: 700,
  NIVELESTUDIO_CREAR: 701,
  NIVELESTUDIO_EDITAR: 702,
  NIVELESTUDIO_ELIMINAR: 703,
  NIVELESTUDIO_DESHABILITAR: 704,

  TITULO_VER: 800,
  TITULO_CREAR: 801,
  TITULO_EDITAR: 802,
  TITULO_ELIMINAR: 803,
  TITULO_DESHABILITAR: 804,

  ASIGNACIONTITULO_VER: 900,
  ASIGNACIONTITULO_CREAR: 901,
  ASIGNACIONTITULO_EDITAR: 902,
  ASIGNACIONTITULO_ELIMINAR: 903,
  ASIGNACIONTITULO_DESHABILITAR: 904,

  PAISES_VER: 1000,
  PAISES_CREAR: 1001,
  PAISES_EDITAR: 1002,
  PAISES_ELIMINAR: 1003,
  PAISES_DESHABILITAR: 1004,

  IDIOMAS_VER: 1100,
  IDIOMAS_CREAR: 1101,
  IDIOMAS_EDITAR: 1102,
  IDIOMAS_ELIMINAR: 1103,
  IDIOMAS_DESHABILITAR: 1104,

  SINDICATOS_VER: 1200,
  SINDICATOS_CREAR: 1201,
  SINDICATOS_EDITAR: 1202,
  SINDICATOS_ELIMINAR: 1203,
  SINDICATOS_DESHABILITAR: 1204,

  BANCOS_VER: 1300,
  BANCOS_CREAR: 1301,
  BANCOS_EDITAR: 1302,
  BANCOS_ELIMINAR: 1303,
  BANCOS_SUCURSALES_CREAR: 1304,
  BANCOS_SUCURSALES_EDITAR: 1305,
  BANCOS_SUCURSALES_ELIMINAR: 1306,
  BANCOS_DESHABILITAR: 1307,

  ESCALAFON_VER: 1400,
  ESCALAFON_CREAR: 1401,
  ESCALAFON_EDITAR: 1402,
  ESCALAFON_ELIMINAR: 1403,
  ESCALAFON_DESHABILITAR: 1404,

  TIPOS_SANCION_VER: 1500,
  TIPOS_SANCION_CREAR: 1501,
  TIPOS_SANCION_EDITAR: 1502,
  TIPOS_SANCION_ELIMINAR: 1503,
  TIPOS_SANCION_DESHABILITAR: 1504,

  MOTIVOS_VER: 1600,
  MOTIVOS_CREAR: 1601,
  MOTIVOS_EDITAR: 1602,
  MOTIVOS_ELIMINAR: 1603,
  MOTIVOS_DESHABILITAR: 1604,

  MOTIVOSBAJA_VER: 1700,
  MOTIVOSBAJA_CREAR: 1701,
  MOTIVOSBAJA_EDITAR: 1702,
  MOTIVOSBAJA_ELIMINAR: 1703,
  MOTIVOSBAJA_DESHABILITAR: 1704,

  CONVENIOS_VER: 1800,
  CONVENIOS_CREAR: 1801,
  CONVENIOS_EDITAR: 1802,
  CONVENIOS_ELIMINAR: 1803,
  CONVENIOS_DESHABILITAR: 1804,
  CONVENIOS_ACTUALIZAR_FORMULAS: 1805,
  CONVENIOS_CLASES_CREAR: 1806,
  CONVENIOS_CLASES_EDITAR: 1807,
  CONVENIOS_CLASES_ELIMINAR: 1808,
  CONVENIOS_CONCEPTOS_CREAR: 1809,
  CONVENIOS_CONCEPTOS_EDITAR: 1810,
  CONVENIOS_CONCEPTOS_ELIMINAR: 1811,

  FUNCIONES_VER: 1900,
  FUNCIONES_CREAR: 1901,
  FUNCIONES_EDITAR: 1902,
  FUNCIONES_ELIMINAR: 1903,
  FUNCIONES_DESHABILITAR: 1904,

  LUGARTRABAJOS_VER: 2000,
  LUGARTRABAJOS_CREAR: 2001,
  LUGARTRABAJOS_EDITAR: 2002,
  LUGARTRABAJOS_ELIMINAR: 2003,
  LUGARTRABAJOS_DESHABILITAR: 2004,

  DIRECCIONES_VER: 2100,
  DIRECCIONES_CREAR: 2101,
  DIRECCIONES_EDITAR: 2102,
  DIRECCIONES_ELIMINAR: 2103,
  DIRECCIONES_DESHABILITAR: 2104,

  CLASES_VER: 2200,
  CLASES_CREAR: 2201,
  CLASES_EDITAR: 2202,
  CLASES_ELIMINAR: 2203,
  CLASES_DESHABILITAR: 2204,

  LEGAJOS_VER: 2300,
  LEGAJOS_CREAR: 2301,
  LEGAJOS_EDITAR: 2302,
  LEGAJOS_ELIMINAR: 2303,
  LEGAJOS_ADMINISTRATIVOS_CREAR: 2304,
  LEGAJOS_ADMINISTRATIVOS_EDITAR: 2305,
  LEGAJOS_ADMINISTRATIVOS_ELIMINAR: 2306,
  LEGAJOS_ESTUDIOS_CREAR: 2307,
  LEGAJOS_ESTUDIOS_EDITAR: 2308,
  LEGAJOS_ESTUDIOS_ELIMINAR: 2309,
  LEGAJOS_ANTECEDENTES_CREAR: 2310,
  LEGAJOS_ANTECEDENTES_EDITAR: 2311,
  LEGAJOS_ANTECEDENTES_ELIMINAR: 2312,
  LEGAJOS_FAMILIARES_CREAR: 2313,
  LEGAJOS_FAMILIARES_EDITAR: 2314,
  LEGAJOS_FAMILIARES_ELIMINAR: 2315,
  LEGAJOS_EVALUACIONES_CREAR: 2316,
  LEGAJOS_EVALUACIONES_EDITAR: 2317,
  LEGAJOS_EVALUACIONES_ELIMINAR: 2318,
  LEGAJOS_EXPERIENCIAS_CREAR: 2319,
  LEGAJOS_EXPERIENCIAS_EDITAR: 2320,
  LEGAJOS_EXPERIENCIAS_ELIMINAR: 2321,
  LEGAJOS_VER_TODOS: 2322,
  LEGAJOS_VER_VISIBLES: 2323,

  MOTIVOSMOVIMIENTOS_VER: 2400,
  MOTIVOSMOVIMIENTOS_CREAR: 2401,
  MOTIVOSMOVIMIENTOS_EDITAR: 2402,
  MOTIVOSMOVIMIENTOS_ELIMINAR: 2403,
  MOTIVOSMOVIMIENTOS_DESHABILITAR: 2404,

  AGRUPAMIENTOS_VER: 2500,
  AGRUPAMIENTOS_CREAR: 2501,
  AGRUPAMIENTOS_EDITAR: 2502,
  AGRUPAMIENTOS_ELIMINAR: 2503,
  AGRUPAMIENTOS_DESHABILITAR: 2504,

  SECRETARIAS_VER: 2600,
  SECRETARIAS_CREAR: 2601,
  SECRETARIAS_EDITAR: 2602,
  SECRETARIAS_ELIMINAR: 2603,
  SECRETARIAS_DESHABILITAR: 2604,

  SUBDIRECCIONES_VER: 2700,
  SUBDIRECCIONES_CREAR: 2701,
  SUBDIRECCIONES_EDITAR: 2702,
  SUBDIRECCIONES_ELIMINAR: 2703,
  SUBDIRECCIONES_DESHABILITAR: 2704,

  CARGOSSINDICALES_VER: 2800,
  CARGOSSINDICALES_CREAR: 2801,
  CARGOSSINDICALES_EDITAR: 2802,
  CARGOSSINDICALES_ELIMINAR: 2803,
  CARGOSSINDICALES_DESHABILITAR: 2804,

  CARGOS_VER: 2900,
  CARGOS_CREAR: 2901,
  CARGOS_EDITAR: 2902,
  CARGOS_ELIMINAR: 2903,

  RELOJESBIOMETRICOS_VER: 3000,
  RELOJESBIOMETRICOS_CREAR: 3001,
  RELOJESBIOMETRICOS_EDITAR: 3002,
  RELOJESBIOMETRICOS_ELIMINAR: 3003,
  RELOJESBIOMETRICOS_DESHABILITAR: 3004,

  TIPOSELEMENTOS_VER: 3100,
  TIPOSELEMENTOS_CREAR: 3101,
  TIPOSELEMENTOS_EDITAR: 3102,
  TIPOSELEMENTOS_ELIMINAR: 3103,
  TIPOSELEMENTOS_DESHABILITAR: 3104,

  ELEMENTOS_VER: 3200,
  ELEMENTOS_CREAR: 3201,
  ELEMENTOS_EDITAR: 3202,
  ELEMENTOS_ELIMINAR: 3203,
  ELEMENTOS_DESHABILITAR: 3204,

  ORGANIGRAMA_VER: 3300,
  ORGANIGRAMA_CREAR: 3301,
  ORGANIGRAMA_EDITAR: 3302,
  ORGANIGRAMA_ELIMINAR: 3303,

  ASIGNACIONESELEMENTOS_VER: 3400,
  ASIGNACIONESELEMENTOS_CREAR: 3401,
  ASIGNACIONESELEMENTOS_EDITAR: 3402,
  ASIGNACIONESELEMENTOS_ELIMINAR: 3403,

  CRITERIOS_EVALUACION_VER: 3500,
  CRITERIOS_EVALUACION_CREAR: 3501,
  CRITERIOS_EVALUACION_EDITAR: 3502,
  CRITERIOS_EVALUACION_ELIMINAR: 3503,
  CRITERIOS_EVALUACION_DESHABILITAR: 3504,

  TIPOSACCIDENTES_VER: 3600,
  TIPOSACCIDENTES_CREAR: 3601,
  TIPOSACCIDENTES_EDITAR: 3602,
  TIPOSACCIDENTES_ELIMINAR: 3603,
  TIPOSACCIDENTES_DESHABILITAR: 3604,

  MOTIVOACCIDENTE_VER: 3700,
  MOTIVOACCIDENTE_CREAR: 3701,
  MOTIVOACCIDENTE_EDITAR: 3702,
  MOTIVOACCIDENTE_ELIMINAR: 3703,
  MOTIVOACCIDENTE_DESHABILITAR: 3704,

  ACCIDENTESTRABAJOS_VER: 3800,
  ACCIDENTESTRABAJOS_CREAR: 3801,
  ACCIDENTESTRABAJOS_EDITAR: 3802,
  ACCIDENTESTRABAJOS_ELIMINAR: 3803,

  LICENCIASENFERMEDAD_VER: 3900,
  LICENCIASENFERMEDAD_CREAR: 3901,
  LICENCIASENFERMEDAD_EDITAR: 3902,
  LICENCIASENFERMEDAD_ELIMINAR: 3903,

  AGRUPAMIENTOSCLASES_VER: 4000,
  AGRUPAMIENTOSCLASES_CREAR: 4001,
  AGRUPAMIENTOSCLASES_EDITAR: 4002,
  AGRUPAMIENTOSCLASES_ELIMINAR: 4003,
  AGRUPAMIENTOSCLASES_DESHABILITAR: 4004,

  FUNCIONAL_VER: 4100,

  CONSOLIDADO_VER: 4200,

  MEDICINALABORAL_VER: 4300,
  MEDICINALABORAL_CREAR: 4301,
  MEDICINALABORAL_EDITAR: 4302,
  MEDICINALABORAL_ELIMINAR: 4303,

  TAREAS_VER: 4400,
  TAREAS_CREAR: 4401,
  TAREAS_EDITAR: 4402,
  TAREAS_ELIMINAR: 4403,
  TAREAS_DESHABILITAR: 4404,

  GRUPOS_SANGUINEOS_VER: 4500,
  GRUPOS_SANGUINEOS_CREAR: 4501,
  GRUPOS_SANGUINEOS_EDITAR: 4502,
  GRUPOS_SANGUINEOS_ELIMINAR: 4503,
  GRUPOS_SANGUINEOS_DESHABILITAR: 4504,

  CONCEPTOS_VER: 4600,
  CONCEPTOS_CREAR: 4601,
  CONCEPTOS_EDITAR: 4602,
  CONCEPTOS_ELIMINAR: 4603,
  CONCEPTOS_DESHABILITAR: 4604,

  CUOTAALIMENTARIA_VER: 4700,
  CUOTAALIMENTARIA_CREAR: 4701,
  CUOTAALIMENTARIA_EDITAR: 4702,
  CUOTAALIMENTARIA_ELIMINAR: 4703,

  TIPOEMBARGO_VER: 4800,
  TIPOEMBARGO_CREAR: 4801,
  TIPOEMBARGO_EDITAR: 4802,
  TIPOEMBARGO_ELIMINAR: 4803,
  TIPOEMBARGO_DESHABILITAR: 4804,

  AGENTEEMBARGO_VER: 4900,
  AGENTEEMBARGO_CREAR: 4901,
  AGENTEEMBARGO_EDITAR: 4902,
  AGENTEEMBARGO_ELIMINAR: 4903,
  AGENTEEMBARGO_DESHABILITAR: 4904,

  REGIMENES_LICENCIAS_VER: 5000,
  REGIMENES_LICENCIAS_CREAR: 5001,
  REGIMENES_LICENCIAS_EDITAR: 5002,
  REGIMENES_LICENCIAS_ELIMINAR: 5003,
  REGIMENES_LICENCIAS_DESHABILITAR: 5004,

  LICENCIAS_CODIGOS_VER: 5100,
  LICENCIAS_CODIGOS_CREAR: 5101,
  LICENCIAS_CODIGOS_EDITAR: 5102,
  LICENCIAS_CODIGOS_ELIMINAR: 5103,
  LICENCIAS_CODIGOS_DESHABILITAR: 5104,

  EMBARGO_VER: 5200,
  EMBARGO_CREAR: 5201,
  EMBARGO_EDITAR: 5202,
  EMBARGO_ELIMINAR: 5203,

  NOVEDADFIJA_VER: 5300,
  NOVEDADFIJA_CREAR: 5301,
  NOVEDADFIJA_EDITAR: 5302,
  NOVEDADFIJA_ELIMINAR: 5303,

  LICENCIAS_VACACIONES_VER: 5400,
  LICENCIAS_VACACIONES_CREAR: 5401,
  LICENCIAS_VACACIONES_EDITAR: 5402,
  LICENCIAS_VACACIONES_ELIMINAR: 5403,
  LICENCIAS_VACACIONES_AUTORIZAR: 5404,

  LICENCIAS_ESPECIALES_VER: 5500,
  LICENCIAS_ESPECIALES_CREAR: 5501,
  LICENCIAS_ESPECIALES_EDITAR: 5502,
  LICENCIAS_ESPECIALES_ELIMINAR: 5503,
  LICENCIAS_ESPECIALES_AUTORIZAR: 5504,

  NOVEDADVARIABLE_VER: 5600,
  NOVEDADVARIABLE_CREAR: 5601,
  NOVEDADVARIABLE_EDITAR: 5602,
  NOVEDADVARIABLE_ELIMINAR: 5603,

  MUNICIPIO_VER: 5700,
  MUNICIPIO_CREAR: 5701,
  MUNICIPIO_EDITAR: 5702,
  MUNICIPIO_ELIMINAR: 5703,

  OPERANDO_VER: 5800,
  OPERANDO_CREAR: 5801,
  OPERANDO_EDITAR: 5802,
  OPERANDO_ELIMINAR: 5803,
  OPERANDO_DESHABILITAR: 5804,

  LIQUIDACION_VER: 5900,
  LIQUIDACION_CREAR: 5901,
  LIQUIDACION_RECALCULAR: 5902,
  LIQUIDACION_APROBAR: 5903,
  LIQUIDACION_CERRAR: 5904,
  LIQUIDACION_ELIMINAR: 5905,
  LIQUIDACION_BUSCADOR_VER: 5906,
  LIQUIDACION_BUSCADOR_VER_TODOS: 5907,
  LIQUIDACION_EMPLEADO_ELIMINAR: 5908,

  IMPORTACION_FORMATOS_VER: 6000,
  IMPORTACION_FORMATOS_CREAR: 6001,
  IMPORTACION_FORMATOS_EDITAR: 6002,
  IMPORTACION_FORMATOS_ELIMINAR: 6003,

  ASISTENCIAS_VER: 6100,
  ASISTENCIAS_CREAR: 6101,
  ASISTENCIAS_EDITAR: 6102,
  ASISTENCIAS_ELIMINAR: 6103,

  CONCURSOS_QUIEBRAS_VER: 6200,
  CONCURSOS_QUIEBRAS_CREAR: 6201,
  CONCURSOS_QUIEBRAS_EDITAR: 6202,
  CONCURSOS_QUIEBRAS_ELIMINAR: 6203,

  IMPORTACION_NOVEDADES_VER: 6300,
  IMPORTACION_NOVEDADES_CREAR: 6301,

  TRAMOS_VER: 6400,
  TRAMOS_CREAR: 6401,
  TRAMOS_EDITAR: 6402,
  TRAMOS_ELIMINAR: 6403,

  DEDUCCIONES_VER: 6500,
  DEDUCCIONES_CREAR: 6501,
  DEDUCCIONES_EDITAR: 6502,
  DEDUCCIONES_ELIMINAR: 6503,

  DDJJ_VER: 6600,
  DDJJ_CREAR: 6601,
  DDJJ_EDITAR: 6602,
  DDJJ_ELIMINAR: 6603,

  DIAGNOSTICO_VER: 6700,
  DIAGNOSTICO_CREAR: 6701,
  DIAGNOSTICO_EDITAR: 6702,
  DIAGNOSTICO_ELIMINAR: 6703,
  DIAGNOSTICO_DESHABILITAR: 6704,

  REPORTE_LIQUIDACION_SUELDOSCAIDOS: 6800,
  REPORTE_LIQUIDACION_IMPORTACIONDELGASTO: 6801,
  REPORTE_LIQUIDACION_SINTETICO: 6802,
  REPORTE_LIQUIDACION_FACTURACIONENTIDADES: 6803,
  REPORTE_LEGAJO_TITULO: 6804,
  REPORTE_LEGAJO_CONTROLESCOLARIDAD: 6805,
  REPORTE_LEGAJO_CONTROLASIGNACION: 6806,
  REPORTE_NOVEDAD_VACACIONES: 6807,
  REPORTE_NOVEDAD_ACCIDENTES: 6808,
  REPORTE_NOVEDAD_ENFERMEDADES: 6809,
  REPORTE_NOVEDAD_ESPECIALES: 6810,
  REPORTE_NOVEDAD_AUSENTISMO: 6811,
  REPORTE_LIQUIDACION_FINALIDAD_FUNCION: 6812,
  REPORTE_NOVEDAD_VACACIONES_PENDIENTES: 6813,
  REPORTE_LEGAJO_ASISTENCIA: 6814,

  SERVICIO_EXTRAORDINARIO_VER: 6900,
  SERVICIO_EXTRAORDINARIO_CREAR: 6901,
  SERVICIO_EXTRAORDINARIO_EDITAR: 6902,
  SERVICIO_EXTRAORDINARIO_ELIMINAR: 6903,
  SERVICIO_EXTRAORDINARIO_VER_TODOS: 6904,
  SERVICIO_EXTRAORDINARIO_CERRAR: 6905,

  ACUMULADO_VER: 7000,
  ACUMULADO_VER_TODOS: 7001,
  ACUMULADO_EDITAR: 7002,

  NOVEDADES_VER: 7100,

  PARTIDAS_PRESUPUESTARIAS_VER: 7200,
  PARTIDAS_PRESUPUESTARIAS_CREAR: 7201,
  PARTIDAS_PRESUPUESTARIAS_EDITAR: 7202,
  PARTIDAS_PRESUPUESTARIAS_ELIMINAR: 7203,
};

export const setData = (data) => {
  localStorage.setItem("token", data.token);
  localStorage.setItem("user", JSON.stringify(data.user));
};

export const getUser = () => {
  const user = localStorage.getItem("user");
  return user === null ? { nombre: "", sexo: "" } : JSON.parse(user);
};

export const getJwt = () => {
  return localStorage.getItem("token");
};

export const validateToken = async () => {
  const result = await http.post("auth/validate", {});
  return result && result.data.code === 0;
};

export const hasAction = (action) => {
  try {
    const user = JSON.parse(localStorage.getItem("user"));
    const permisos = JSON.parse(atob(user.permisos));
    return permisos.indexOf(action) > -1;
  } catch (error) {
    return false;
  }
};
