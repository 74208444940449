import { Fragment } from "react";
import { Col, Modal, Row, Tooltip } from "antd";
import { FiInfo } from "react-icons/fi";

import "./Historial.scss";

const DataInfo = ({ children, visible, modalContent, onOpen, onClose, title, className }) => {
  return (
    <Fragment>
      <Row>
        <Col flex="auto" className="historial-content">
          {children}
        </Col>
        <Col flex="45px">
          <Tooltip  title={"Ver " + title}>
            <div className="historial-trigger" onClick={onOpen}>
              <FiInfo />
            </div>
          </Tooltip>
        </Col>
      </Row>

      <Modal
        className="modal-historial"
        title={title}
        visible={visible}
        onCancel={onClose}
        footer={null}
        maskClosable={false}
        keyboard={false}
        destroyOnClose={true}
        width={700}
      >
        {modalContent}
      </Modal>
    </Fragment>
  );
};

export default DataInfo;
