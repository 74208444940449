/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import {
  Button,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  Select,
  Tag,
  Tooltip,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  FiAlertTriangle,
  FiBook,
  FiBookmark,
  FiBox,
  FiCheckCircle,
  FiPlus,
  FiPrinter,
  FiRepeat,
  FiSave,
  FiSearch,
  FiTrash2,
} from "react-icons/fi";
import { SiMicrosoftexcel } from "react-icons/si";
import "moment/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";
import { DownOutlined } from "@ant-design/icons";

import { ContextProvider } from "./../../services/context";
import { downloadBase64 } from "./../../services/forms";
import http from "./../../services/http";
import { showSuccess } from "./../../services/notificacion";
import { hasAction, actions } from "./../../services/security";

import Header from "./../../components/Header";
import Filters from "./../../components/Filters";
import FiltersButton from "./../../components/FiltersButton";
import Table from "./../../components/Table";
import { required } from "../../services/validation";
import moment from "moment";
import LiquidacionDetalle from "./LiquidacionDetalle";

const { Option } = Select;

export const renderTagEstado = (estado) => {
  switch (estado) {
    case "1":
      return <Tag color="gold">Pendiente</Tag>;
    case "2":
      return <Tag color="green">Aprobada</Tag>;
    case "3":
      return <Tag color="cyan">Cerrada</Tag>;
    default:
      return null;
  }
};

const Liquidacion = () => {
  const history = useHistory();
  const [formDetalle] = Form.useForm();
  const [formExport] = Form.useForm();
  const [formExportInterfaz] = Form.useForm();
  const [formExportBanco] = Form.useForm();

  const VER = hasAction(actions.LIQUIDACION_VER);
  const CREAR = hasAction(actions.LIQUIDACION_CREAR);
  const RECALCULAR = hasAction(actions.LIQUIDACION_RECALCULAR);
  const APROBAR = hasAction(actions.LIQUIDACION_APROBAR);
  const CERRAR = hasAction(actions.LIQUIDACION_CERRAR);
  const ELIMINAR = hasAction(actions.LIQUIDACION_ELIMINAR);

  const [state, setState] = useState({
    filters: {
      visible: false,
      desde: "",
      hasta: "",
      mes: (moment().month() + 1).toString(),
      anio: moment().year().toString(),
      tipo: "",
      criterio: "",
      estado: "",
      legajo: "",
    },
    columns: [
      {
        id: 1,
        title: "Fecha",
        style: {},
        render: (item) => item.fecha,
      },
      {
        id: 2,
        title: "Mes",
        style: { textAlign: "center" },
        render: (item) => item.mes,
      },
      {
        id: 3,
        title: "Año",
        style: { textAlign: "center" },
        render: (item) => item.anio,
      },
      {
        id: 4,
        title: "Tipo",
        style: { textAlign: "center" },
        render: (item) => item.tipo,
      },
      {
        id: 5,
        title: "Filtro",
        style: {},
        render: (item) => {
          let filtro = `${item.criterio}: ${item.filtro}`;

          if (filtro.length > 80) {
            let resumido = filtro.substring(0, 80) + "...";

            return (
              <Tooltip placement="top" title={filtro}>
                {resumido}
              </Tooltip>
            );
          }

          return filtro;
        },
      },
      {
        id: 6,
        title: "Cantidad",
        style: { textAlign: "center" },
        render: (item) => item.cantidad,
      },
      {
        id: 7,
        title: "Estado",
        style: { textAlign: "center" },
        render: (item) => renderTagEstado(item.estado),
      },
    ],
    loading: true,
    loaded: false,
    reload: true,
    selected: [],
    list: [],
    total: 0,
    page: 1,
    size: 10,
  });

  const [banco, setBanco] = useState();

  const [bancos, setBancos] = useState([]);
  const [bancosSucursales, setBancosSucursales] = useState([]);
  const [conceptos, setConceptos] = useState([]);
  const [criterios, setCriterios] = useState([]);
  const [estados, setEstados] = useState([]);
  const [tipos, setTipos] = useState([]);

  const [current, setCurrent] = useState();
  const [itemDetalle, setItemDetalle] = useState();
  const [exportInterface, setExportInterface] = useState();
  const [buscarDetalle, setBuscarDetalle] = useState(false);
  const [modalExport, setModalExport] = useState(false);
  const [exportBanco, setExportBanco] = useState();

  const [loadingExport, setLoadingExport] = useState(false);
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [loadingClose, setLoadingClose] = useState(false);

  const breadcrumb = [
    { id: 1, title: "Liquidación" },
    { id: 2, title: "Liquidaciones", url: "/liquidaciones" },
  ];

  const buttons = () => {
    return (
      <React.Fragment>
        {state.selected.length > 0 && (
          <React.Fragment>
            <Button
              icon={<FiCheckCircle />}
              loading={loadingApprove}
              onClick={() => onApprove(state.selected)}
            >
              {!loadingApprove && "Aprobar"}
            </Button>
            <Button
              icon={<FiSave />}
              loading={loadingClose}
              onClick={() => onClose(state.selected)}
            >
              {!loadingClose && "Cerrar"}
            </Button>
            <Dropdown overlay={exportMenu}>
              <Button loading={loadingExport}>
                {!loadingExport && (
                  <React.Fragment>
                    Exportar <DownOutlined />
                  </React.Fragment>
                )}
              </Button>
            </Dropdown>
          </React.Fragment>
        )}
        <FiltersButton />
        {CREAR && (
          <Button type="primary" icon={<FiPlus />} onClick={onAdd}>
            Nuevo
          </Button>
        )}
      </React.Fragment>
    );
  };

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Desde" name="desde">
          <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
        </Form.Item>
        <Form.Item label="Hasta" name="hasta">
          <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
        </Form.Item>
        <Form.Item label="Mes" name="mes">
          <Select allowClear>
            <Option value="1">Enero</Option>
            <Option value="2">Febrero</Option>
            <Option value="3">Marzo</Option>
            <Option value="4">Abril</Option>
            <Option value="5">Mayo</Option>
            <Option value="6">Junio</Option>
            <Option value="7">Julio</Option>
            <Option value="8">Agosto</Option>
            <Option value="9">Septiembre</Option>
            <Option value="10">Octubre</Option>
            <Option value="11">Noviembre</Option>
            <Option value="12">Diciembre</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Año" name="anio" initialValue={moment().year()}>
          <Input />
        </Form.Item>
        <Form.Item label="Tipo" name="tipo">
          <Select allowClear>
            {tipos.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Criterio" name="criterio">
          <Select allowClear>
            {criterios.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Estado" name="estado">
          <Select allowClear>
            {estados.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Legajo" name="legajo">
          <Input />
        </Form.Item>
      </React.Fragment>
    );
  };

  const menu = (item) => {
    return (
      <Menu className="table-actions-menu">
        {VER && (
          <Menu.Item key="0" onClick={() => onEdit(item)}>
            <FiSearch />
            Ver
          </Menu.Item>
        )}
        {RECALCULAR && item.estado !== "3" && (
          <Menu.Item key="1" onClick={() => onRecalculate(item)}>
            <FiRepeat />
            Recalcular
          </Menu.Item>
        )}
        {VER && (
          <Menu.Item key="2" onClick={() => onExport(item)}>
            <SiMicrosoftexcel />
            Exportar
          </Menu.Item>
        )}
        {VER && (
          <Menu.Item key="3" onClick={() => onBuscarDetalle(item)}>
            <FiSearch />
            Buscar detalle...
          </Menu.Item>
        )}
        {APROBAR && item.estado === "1" && (
          <React.Fragment>
            <Menu.Divider />
            <Menu.Item key="4" onClick={() => onApprove([item.id])}>
              <FiCheckCircle />
              Aprobar
            </Menu.Item>
          </React.Fragment>
        )}
        {CERRAR && item.estado === "2" && (
          <React.Fragment>
            <Menu.Divider />
            <Menu.Item key="5" onClick={() => onClose([item.id])}>
              <FiSave />
              Cerrar
            </Menu.Item>
          </React.Fragment>
        )}
        {ELIMINAR && item.estado !== "3" && (
          <React.Fragment>
            <Menu.Divider />
            <Menu.Item key="6" onClick={() => onDelete(item)}>
              <FiTrash2 />
              Eliminar
            </Menu.Item>
          </React.Fragment>
        )}
      </Menu>
    );
  };

  const exportMenu = () => (
    <Menu>
      <Menu.Item key="1" icon={<SiMicrosoftexcel />} onClick={onExportList}>
        Excel
      </Menu.Item>
      <Menu.Item key="2" icon={<FiPrinter />} onClick={onExportInterface}>
        Interfaces
      </Menu.Item>
      <Menu.Item key="3" icon={<FiBookmark />} onClick={onExportBanco}>
        Bancos
      </Menu.Item>
      <Menu.Item key="4" icon={<FiBook />} onClick={onExportAcreditaciones}>
        Acreditaciones
      </Menu.Item>
      <Menu.Item key="5" icon={<FiBox />} onClick={onExportEmbargos}>
        Embargos
      </Menu.Item>
      <Menu.Item key="6" icon={<FiAlertTriangle />} onClick={onExportCuotas}>
        Cuotas Alimentarias
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    async function getData() {
      const response = await http.get("liquidaciones/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setBancos(data.bancos);
        setBancosSucursales(data.bancosSucursales);
        setConceptos(data.conceptos);
        setCriterios(data.criterios);
        setEstados(data.estados);
        setTipos(data.tipos);
      }
    }

    getData();
  }, []);

  const onAdd = () => {
    history.push("/liquidaciones/nuevo");
  };

  const onEdit = (item) => {
    history.push(`/liquidaciones/${item.id}`);
  };

  const onRecalculate = (item) => {
    Modal.confirm({
      title: "Confirmar acción",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea recalcular esta liquidación?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const rRecalculate = await http.put("liquidaciones/recalculate", {
          id: item.id,
        });
        if (rRecalculate && rRecalculate.data.code === 0) {
          showSuccess("La liquidación fue recalculada exitosamente.");
        }
      },
    });
  };

  const onApprove = (list) => {
    Modal.confirm({
      title: "Confirmar acción",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea aprobar las liquidaciones?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        setLoadingApprove(true);
        const rApprove = await http.put("liquidaciones/approve", {
          ids: list,
        });
        if (rApprove && rApprove.data.code === 0) {
          setLoadingApprove(false);
          showSuccess("Las liquidaciones fueron aprobadas exitosamente.");
          setState({ ...state, reload: !state.reload });
        }
      },
    });
  };

  const onClose = (list) => {
    Modal.confirm({
      title: "Confirmar acción",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea cerrar estas liquidaciones?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        setLoadingClose(true);
        const rClose = await http.put("liquidaciones/close", {
          ids: list,
        });
        if (rClose && rClose.data.code === 0) {
          setLoadingClose(false);
          showSuccess("Las liquidaciones fueron cerradas exitosamente.");
          setState({ ...state, reload: !state.reload });
        }
      },
    });
  };

  const onExport = async (item) => {
    formExport.resetFields();
    setState({ ...state, selected: [item.id] });
    setModalExport(true);
  };

  const onBuscarDetalle = async (item) => {
    formDetalle.resetFields();
    setExportInterface(null);
    setBuscarDetalle(true);
    setCurrent(item);
  };

  const onExportList = async () => {
    formExport.resetFields();
    setModalExport(true);
  };

  const onExportInterface = async () => {
    formExportInterfaz.resetFields();
    setCurrent(null);
    setExportInterface(state.selected[0]);
  };

  const onExportBanco = async () => {
    formExportBanco.resetFields();
    formExportBanco.setFieldsValue({ generacion: moment() });
    setCurrent(null);
    setExportBanco(true);
  };

  const onExportEmbargos = async () => {
    const values = {
      ids: state.selected.join(","),
    };

    setLoadingExport(true);

    const rExport = await http.post(`liquidaciones/export/embargos`, values);
    if (rExport) {
      const data = rExport.data.data;
      downloadBase64(data.filename, data.content);
    }

    setLoadingExport(false);
  };

  const onExportCuotas = async () => {
    const values = {
      ids: state.selected.join(","),
    };

    setLoadingExport(true);

    const rExport = await http.post(`liquidaciones/export/cuotas`, values);
    if (rExport) {
      const data = rExport.data.data;
      downloadBase64(data.filename, data.content);
    }

    setLoadingExport(false);
  };

  const onBuscarDetalleConfirm = async (values) => {
    setItemDetalle({ legajo: values.legajo, liquidacion: current.id });
  };

  const onExportConfirm = async (values) => {
    setLoadingExport(true);

    const data = {
      ids: state.selected,
      conceptos: values.concepto,
    };

    const response = await http.post(`liquidaciones/export`, data);
    if (response) {
      const data = response.data.data;
      downloadBase64(data.filename, data.content);
    }

    setLoadingExport(false);
    setModalExport(false);
  };

  const onExportConfirmInterface = async (values) => {
    values.tipo = values.tipo ?? "0";

    const data = { ids: state.selected };

    setLoadingExport(true);

    const response = await http.post(
      `liquidaciones/export/interfaces/${values.tipo}`,
      data
    );

    setLoadingExport(false);

    if (response && response.data.code === 0) {
      const data = response.data.data;
      downloadBase64(data.filename, data.content);
    }
  };

  const onBancoChange = (value) => {
    setBanco(value);
  };

  const onConfirmExportBanco = async (values) => {
    values.ids = state.selected.join(",");
    values.acreditacion = values.acreditacion.format("DD/MM/YYYY");
    values.generacion = values.generacion.format("DD/MM/YYYY");

    setLoadingExport(true);

    const rExport = await http.post(`liquidaciones/export/bancos`, values);
    if (rExport) {
      const data = rExport.data.data;
      downloadBase64(data.filename, data.content);
    }

    setLoadingExport(false);
  };

  const onExportAcreditaciones = async () => {
    const values = {
      ids: state.selected.join(","),
    };

    setLoadingExport(true);

    const response = await http.post(
      `liquidaciones/export/acreditaciones`,
      values
    );
    if (response) {
      const data = response.data.data;
      downloadBase64(data.filename, data.content);
    }

    setLoadingExport(false);
  };

  const onDelete = (item) => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea eliminar esta liquidación?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const rDelete = await http.delete(`liquidaciones/${item.id}`);
        if (rDelete && rDelete.data.code === 0) {
          showSuccess("La liquidación fue eliminada exitosamente.");
          setState({ ...state, reload: !state.reload });
        }
      },
    });
  };

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="liquidacion page">
      <ContextProvider value={[state, setState]}>
        <Header
          title="Liquidaciones"
          breadcrumb={breadcrumb}
          buttons={buttons}
        />
        <Filters filters={filters} />
        <Table url="liquidaciones/list" menu={menu} checkbox />

        <Modal
          visible={modalExport}
          forceRender
          footer={null}
          maskClosable={false}
          keyboard={false}
          destroyOnClose={true}
          onCancel={() => setModalExport(false)}
        >
          <Form form={formExport} layout="vertical" onFinish={onExportConfirm}>
            <div className="form">
              <div className="form-icon">
                <SiMicrosoftexcel />
              </div>
              <Form.Item label="Concepto adicional" name="concepto">
                <Select
                  mode="multiple"
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  maxTagCount="responsive"
                  placeholder="Opcional"
                >
                  {conceptos.map((x) => (
                    <Option key={x.value} value={x.value}>
                      {x.text}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <div className="form-actions">
                <Button type="secondary" onClick={() => setModalExport(false)}>
                  Cancelar
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loadingExport}
                >
                  {!loadingExport && "Exportar"}
                </Button>
              </div>
            </div>
          </Form>
        </Modal>

        <Modal
          visible={buscarDetalle}
          forceRender
          footer={null}
          maskClosable={false}
          keyboard={false}
          destroyOnClose={true}
          onCancel={() => setBuscarDetalle(false)}
        >
          <Form
            form={formDetalle}
            layout="vertical"
            onFinish={onBuscarDetalleConfirm}
          >
            <div className="form">
              <div className="form-icon">
                <FiSearch />
              </div>

              <Form.Item label="Legajo" name="legajo">
                <Input />
              </Form.Item>

              <div className="form-actions">
                <Button
                  type="secondary"
                  onClick={() => setBuscarDetalle(false)}
                >
                  Cancelar
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={loadingExport}
                >
                  Buscar
                </Button>
              </div>
            </div>
          </Form>
        </Modal>

        <Modal
          className="liquidacion-empleado"
          title={`Legajo: ${itemDetalle?.legajo}`}
          visible={itemDetalle}
          onCancel={() => setItemDetalle(undefined)}
          footer={null}
          maskClosable={false}
          keyboard={false}
          destroyOnClose={true}
          width={1100}
        >
          <LiquidacionDetalle item={itemDetalle} onCancel={setItemDetalle} />
        </Modal>

        <Modal
          visible={exportInterface}
          forceRender
          footer={null}
          maskClosable={false}
          keyboard={false}
          destroyOnClose={true}
          onCancel={() => setExportInterface(null)}
        >
          <Form
            form={formExportInterfaz}
            layout="vertical"
            onFinish={onExportConfirmInterface}
          >
            <div className="form">
              <div className="form-icon">
                <SiMicrosoftexcel />
              </div>

              <Form.Item
                label="Tipo de interfaz"
                name="tipo"
                rules={[required]}
              >
                <Select
                  placeholder="Seleccione"
                  allowClear
                  showSearch
                  optionFilterProp="children"
                >
                  <Option key={1} value={1}>
                    Seguro Mutual 30002
                  </Option>
                  <Option key={2} value={2}>
                    Seguro Mutual 40007
                  </Option>
                  <Option key={3} value={3}>
                    DPI
                  </Option>
                  <Option key={4} value={4}>
                    OSEP
                  </Option>

                  <Option key={5} value={5}>
                    SICORE
                  </Option>
                </Select>
              </Form.Item>

              <div className="form-actions">
                <Button
                  type="secondary"
                  onClick={() => setExportInterface(null)}
                >
                  Cancelar
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={loadingExport}
                >
                  {!loadingExport && "Exportar"}
                </Button>
              </div>
            </div>
          </Form>
        </Modal>

        <Modal
          visible={exportBanco}
          forceRender
          footer={null}
          maskClosable={false}
          keyboard={false}
          destroyOnClose={true}
          onCancel={() => setExportBanco(null)}
        >
          <Form
            form={formExportBanco}
            layout="vertical"
            onFinish={onConfirmExportBanco}
          >
            <div className="form">
              <div className="form-icon">
                <FiBookmark />
              </div>

              <Form.Item label="Banco" name="banco" rules={[required]}>
                <Select
                  placeholder="Seleccione"
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  onChange={onBancoChange}
                >
                  {bancos.map((x) => (
                    <Option key={x.value} value={x.value}>
                      {x.text}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Sucursales"
                name="sucursales"
                rules={[required]}
              >
                <Select
                  placeholder="Seleccione"
                  mode="multiple"
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  maxTagCount={1}
                >
                  {bancosSucursales
                    .filter((x) => banco && x.parent === banco)
                    .map((x) => (
                      <Option key={x.value} value={x.value}>
                        {x.text}
                      </Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Fecha de Acreditación"
                name="acreditacion"
                rules={[required]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  placeholder=""
                  locale={locale}
                />
              </Form.Item>

              <Form.Item
                label="Fecha de Generación"
                name="generacion"
                rules={[required]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  placeholder=""
                  locale={locale}
                />
              </Form.Item>

              <div className="form-actions">
                <Button type="secondary" onClick={() => setExportBanco(null)}>
                  Cancelar
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loadingExport}
                >
                  {loadingExport ? "Exportando" : "Exportar"}
                </Button>
              </div>
            </div>
          </Form>
        </Modal>
      </ContextProvider>
    </div>
  );
};

export default Liquidacion;
