/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from "react";
import { Button, Dropdown, Menu, Modal } from "antd";
import { FiEdit, FiMoreVertical, FiTrash2 } from "react-icons/fi";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { Context } from "../../../services/context";
import { hasAction, actions } from "../../../services/security";
import EvaluacionesCriteriosForm from "./EvaluacionesCriteriosForm";

const EvaluacionesCriteriosList = ({ list, onUpdate }) => {
  const [state, setState] = useContext(Context);
  const [criterio, setCriterio] = useState(null);

  const EDITAR =
    state.editing && hasAction(actions.LEGAJOS_EVALUACIONES_EDITAR);

  const menu = (item) => {
    return (
      <Menu className="table-actions-menu">
        {EDITAR && (
          <Menu.Item key="0" onClick={() => onEdit(item)}>
            <FiEdit />
            Editar
          </Menu.Item>
        )}
        {EDITAR && (
          <Menu.Item key="1" onClick={() => onDelete(item)}>
            <FiTrash2 />
            Eliminar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const onAdd = () => {
    setCriterio({});
  };

  const onEdit = (item) => {
    setCriterio({ ...item, index: list.indexOf(item) });
  };

  const onDelete = (item) => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea eliminar este criterio?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: () => {
        let nList = [...list];
        nList.splice(item.index, 1);
        for (let i = 0; i < nList.length; i++) {
          nList[i].index = i;
        }
        onUpdate(nList);
      },
    });
  };

  const onSubmit = (values) => {
    let nList = [...list];

    values.criterioNombre = state.criterios
      .find((x) => x.value === values.criterio)
      .text.split(" - ")[1];

    if (criterio.index === undefined) {
      values.index = list.length;
      nList.push(values);
    } else {
      nList[criterio.index] = values;
    }

    setCriterio(null);
    onUpdate(nList);
  };

  const onCancel = () => {
    setCriterio(null);
  };

  return (
    <div className="evaluaciones-list">
      <div className="table-wrapper" style={{ marginTop: "0px" }}>
        <table className="subsection-table" style={{ marginTop: "0px" }}>
          <thead>
            <tr>
              <th></th>
              <th>Criterio</th>
              <th style={{ textAlign: "center" }}>Calificación</th>
            </tr>
          </thead>
          <tbody>
            {list.length === 0 && (
              <tr>
                <td className="table-empty" colSpan="9">
                  Sin resultados
                </td>
              </tr>
            )}

            {list.map((x) => (
              <tr key={x.index}>
                <td className="table-actions">
                  <Dropdown
                    overlay={() => menu(x)}
                    trigger={["click"]}
                    placement="bottomLeft"
                    arrow
                  >
                    <div>
                      <FiMoreVertical />
                    </div>
                  </Dropdown>
                </td>
                <td>{x.criterioNombre}</td>
                <td style={{ textAlign: "center" }}>{x.calificacion}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {EDITAR && list.length < 5 && (
        <div className="table-footer-actions">
          <Button type="primary" onClick={onAdd}>
            Agregar
          </Button>
        </div>
      )}

      {criterio !== null && (
        <EvaluacionesCriteriosForm
          item={criterio}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      )}
    </div>
  );
};

export default EvaluacionesCriteriosList;
