import { useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Form, Row, Input, Tooltip, DatePicker } from "antd";
import { FiUpload } from "react-icons/fi";
import { Context } from "../../../../services/context";
import { required } from "../../../../services/validation";
import { showWarning } from "../../../../services/notificacion";

import locale from "antd/es/date-picker/locale/es_ES";
import "moment/locale/es";



const EliminacionDatos = () => {
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useContext(Context);

  const fileRef = useRef(null);

  const onAdd = () => {
    fileRef.current.click();
  };


  const onUpload = async (e) => {
    const files = e.target.files;

    if (files.length === 0) {
      return;
    }
    const file = files[0];
    if (file.type !== 'text/plain') {
      showWarning('El archivo debe ser de tipo texto plano');
      return;
    }
    const base64 = await toBase64(file);
    setState({ ...state, contenido: base64 });
    state.form.setFieldsValue({ archivo: file.name })

  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });



  return (
    <div className="form-section">
      <Form.Item label="Periodo" name="periodo">
        <DatePicker
          format="MM/YYYY"
          placeholder=""
          picker="month"
          locale={locale}
        />
      </Form.Item>

      <Row>
        <Col flex="auto">
          <input
            type="file"
            multiple={false}
            accept={'.txt'}
            ref={fileRef}
            onChange={onUpload}
          />
          <Form.Item label="Archivo" name="archivo" rules={[required]}>
            <Input disabled={true} />
          </Form.Item>
        </Col>
        <Col >
          <Tooltip title="Subir Archivo">
            <div className="historial-trigger"  onClick={onAdd}>
              <FiUpload />
            </div>
          </Tooltip>
        </Col>
      </Row>
      <div className="actions">
        <Link to="/novedades/liquidacion">
          <Button type="secondary">Volver</Button>
        </Link>
        <Button type="primary" htmlType="submit" loading={state.loading}>
          Siguiente
        </Button>
      </div>
    </div>
  );
};

export default EliminacionDatos;
