import React from "react";
import { Col, Row } from "antd";

import { hasAction, actions } from "./../../../services/security";

import Header from "./../../../components/Header";
import MenuCard from "./../../../components/MenuCard";

const Seguridad = () => {
  const breadcrumb = [
    {
      id: 1,
      title: "Parámetros",
    },
    {
      id: 2,
      title: "Seguridad",
      url: "/parametros/seguridad",
    },
  ];

  const items = [
    {
      id: 1,
      titulo: "Elementos",
      path: "/parametros/seguridad/elementos",
      color: "primary",
      icono: "FiLayers",
      entidad: "Elemento",
      visible: hasAction(actions.ELEMENTOS_VER),
    },
    {
      id: 2,
      titulo: "Tipos de Elementos",
      path: "/parametros/seguridad/tipos_elementos",
      color: "primary",
      icono: "FiGrid",
      entidad: "TipoElemento",
      visible: hasAction(actions.TIPOSELEMENTOS_VER),
    },
  ];

  return (
    <div className="inicio">
      <Header title="Seguridad" breadcrumb={breadcrumb} buttons={() => {}} />

      <Row gutter={[32, 36]}>
        {items.map((i) => (
          <Col key={i.id} xs={12} md={8} xl={6} xxl={4}>
            <MenuCard item={i} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Seguridad;
