import React, { Fragment, useContext } from "react";
import { FiCheck, FiChevronRight } from "react-icons/fi";

import { Context } from "./../services/context";

import "./Stepper.scss";

const Stepper = ({ steps, left, top }) => {
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useContext(Context);

  return (
    <div className="stepper">
      {steps.map((step, index) => (
        <Fragment key={index}>
          <div
            key={step.id}
            className={`step ${state.step === index ? "active" : ""} 
            ${state.step > index ? "completed" : ""}`}
          >
            <div className="icon">
              {state.step > index && <FiCheck />}
              {state.step <= index && step.icon}
            </div>
            <div>
              <div className="title">{step.title}</div>
              <div className="description">{step.description}</div>
            </div>
          </div>
          {index + 1 < steps.length && (
            <div className="separator">
              <FiChevronRight />
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default Stepper;
