import React, { useContext } from "react";
import { Dropdown, Menu, Modal } from "antd";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

import { Context } from "./../services/context";
import http from "./../services/http";
import { showSuccess } from "./../services/notificacion";
import { hasAction, actions } from "./../services/security";

import "./OrganigramaNode.scss";

const propTypes = {
  nodeData: PropTypes.object.isRequired,
};

const OrganigramaNode = ({ nodeData }) => {
  const [state, setState] = useContext(Context);

  const CREAR = hasAction(actions.ORGANIGRAMA_CREAR);
  const ELIMINAR = hasAction(actions.ORGANIGRAMA_ELIMINAR);

  const menu = () => {
    return (
      <Menu className="table-actions-menu">
        {CREAR && (
          <Menu.Item key="0" onClick={() => onAdd(nodeData)}>
            <FiPlus />
            Agregar
          </Menu.Item>
        )}

        {ELIMINAR && nodeData.children.length === 0 && (
          <Menu.Item key="2" onClick={() => onDelete(nodeData)}>
            <FiTrash2 />
            Eliminar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const onAdd = (item) => {
    const nItem = { ...item, padre: item.funcion, funcion: null };
    setState({ ...state, current: nItem, form: true });
  };

  const onDelete = (item) => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea eliminar esta función?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const rDelete = await http.delete(`organigrama/${item.id}`);
        if (rDelete && rDelete.data.code === 0) {
          showSuccess("La función fue eliminada exitosamente.");
          setState({
            ...state,
            reload: !state.reload,
          });
        }
      },
    });
  };

  if (nodeData.id === undefined) {
    return <div></div>;
  }

  return (
    <Dropdown overlay={menu} trigger="click" placement="bottomCenter" arrow>
      <div className={`content ${nodeData.clase}`}>
        <div className="cargo">{nodeData.funcionTexto}</div>
        <div className="empleados">{nodeData.empleados}</div>
      </div>
    </Dropdown>
  );
};

OrganigramaNode.propTypes = propTypes;

export default OrganigramaNode;
