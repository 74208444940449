/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Button, Col, Form, Input, Row, Select } from "antd";
import { FiBook } from "react-icons/fi";

import http from "../../../../services/http";
import { showSuccess } from "../../../../services/notificacion";
import { hasAction, actions } from "../../../../services/security";
import { required } from "../../../../services/validation";

import Header from "../../../../components/Header";

const RegimenesLicenciaForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const VER = hasAction(actions.REGIMENES_LICENCIAS_VER);
  const CREAR = hasAction(actions.REGIMENES_LICENCIAS_CREAR);
  const EDITAR = hasAction(actions.REGIMENES_LICENCIAS_EDITAR);
  const GUARDAR = match.params.id === "nuevo" ? CREAR : EDITAR;

  const [state, setState] = useState({
    title: match.params.id === "nuevo" ? "Crear" : EDITAR ? "Editar" : "Ver",
    id: match.params.id === "nuevo" ? 0 : parseInt(match.params.id),
    loading: false,
  });
  const [formas, setFormas] = useState([]);

  const breadcrumb = [
    {
      id: 1,
      title: "Parámetros",
    },
    {
      id: 2,
      title: "Liquidación",
      url: "/parametros/liquidacion",
    },
    {
      id: 3,
      title: "Regimenes de Licencia",
      url: "/parametros/liquidacion/regimenes",
    },
    { id: 4, title: state.title },
  ];

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    const rSave =
      state.id === 0
        ? await http.post("regimeneslicencia", values)
        : await http.put(`regimeneslicencia/${state.id}`, values);

    if (rSave && rSave.data.code === 0) {
      showSuccess("El régimen de licencia fue guardado exitosamente.");
      history.push("/parametros/liquidacion/regimenes");
    } else {
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {
    async function getForm() {
      const response = await http.get(`regimeneslicencia/data`);
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setFormas(data.formas);
      }

      const rForm = await http.get(`regimeneslicencia/${state.id}`);
      if (rForm && rForm.data.code === 0) {
        const data = rForm.data.data;
        form.setFieldsValue(data);
      }
    }

    if (VER) {
      getForm();
    }
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="regimen">
      <Header
        title={`${state.title} Régimen de Licencia`}
        breadcrumb={breadcrumb}
      />

      <Form form={form} layout="vertical" onFinish={onSave}>
        <div className="form card">
          <div className="form-icon">
            <FiBook />
          </div>

          <Row gutter={20}>
            <Col span="24">
              <Form.Item label="Detalle" name="detalle" rules={[required]}>
                <Input maxLength={200} disabled={!GUARDAR} />
              </Form.Item>
            </Col>

            <Col span="12">
              <Form.Item label="Días de Extensión" name="diasExtension">
                <Input type="number" maxLength={10} disabled={!GUARDAR} />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item label="Forma" name="forma">
                <Select allowClear disabled={!GUARDAR}>
                  {formas.map((x) => (
                    <Select.Option
                      key={x.value}
                      value={x.value}
                      disabled={x.disabled}
                    >
                      {x.text}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span="8">
              <Form.Item label="Ley" name="ley">
                <Input type="number" maxLength={10} disabled={!GUARDAR} />
              </Form.Item>
            </Col>
            <Col span="8">
              <Form.Item label="Título" name="titulo">
                <Input type="number" maxLength={10} disabled={!GUARDAR} />
              </Form.Item>
            </Col>
            <Col span="8">
              <Form.Item label="Capítulo" name="capitulo">
                <Input type="number" maxLength={10} disabled={!GUARDAR} />
              </Form.Item>
            </Col>

            <Col span="8">
              <Form.Item label="Articulo" name="articulo">
                <Input type="number" maxLength={10} disabled={!GUARDAR} />
              </Form.Item>
            </Col>
            <Col span="8">
              <Form.Item label="Inciso" name="inciso">
                <Input type="number" maxLength={10} disabled={!GUARDAR} />
              </Form.Item>
            </Col>
            <Col span="8">
              <Form.Item label="Apartado" name="apartado">
                <Input type="number" maxLength={10} disabled={!GUARDAR} />
              </Form.Item>
            </Col>
          </Row>

          <div className="form-actions">
            <Link to="/parametros/liquidacion/regimenes">
              <Button type="secondary">Volver</Button>
            </Link>
            {GUARDAR && (
              <Button type="primary" htmlType="submit" loading={state.loading}>
                Guardar
              </Button>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};

export default RegimenesLicenciaForm;
