import * as moment from "moment";

export const parseYear = (value) => {
  return value === undefined || value === null
    ? undefined
    : value.format("YYYY");
};

export const parseToYear = (value) => {
  return value === undefined || value === null
    ? undefined
    : moment(value, "YYYY");
};

export const parseDate = (value) => {
  return value === undefined || value === null
    ? undefined
    : value.format("DD/MM/YYYY");
};

export const parseToDate = (value) => {
  return value === undefined || value === null
    ? undefined
    : moment(value, "DD/MM/YYYY");
};

export const parseTime = (value) => {
  return value === undefined || value === null
    ? undefined
    : value.format("HH:mm");
};

export const parseToTime = (value) => {
  return value === undefined || value === null
    ? undefined
    : moment(value, "HH:mm");
};

export const maxLength = (max, str) => {
  return str.substring(0, max);
};

export const downloadBase64 = (filename, content) => {
  const linkSource = `data:application/octet-stream;base64,${content}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = filename;
  downloadLink.click();
};

export const formatCurrency = (value) => {
  var isNegative = value && value < 0;

  if (isNegative) {
    value = value * -1;
    return "- $" + value.toLocaleString("es-AR", { minimumFractionDigits: 2 });
  }

  return value
    ? "$" + value.toLocaleString("es-AR", { minimumFractionDigits: 2 })
    : "-";
};

export const formatNumber = (value) => {
  if (!value) {
    return "";
  }

  if (isNaN(value)) {
    value = value.replace(",", ".");
    value = parseFloat(value);
  }

  return value > 0
    ? value.toLocaleString("es-AR", { minimumFractionDigits: 2 })
    : "";
};

export const formatMonth = (value) => {
  switch (value) {
    case 1:
      return "Enero";
    case 2:
      return "Febrero";
    case 3:
      return "Marzo";
    case 4:
      return "Abril";
    case 5:
      return "Mayo";
    case 6:
      return "Junio";
    case 7:
      return "Julio";
    case 8:
      return "Agosto";
    case 9:
      return "Septiembre";
    case 10:
      return "Octubre";
    case 11:
      return "Noviembre";
    case 12:
      return "Diciembre";
    default:
      return "";
  }
};
