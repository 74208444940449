/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Form, Input, Button, Row, Col, Select } from "antd";
import { FiCode } from "react-icons/fi";

import http from "../../../../services/http";
import { showSuccess } from "../../../../services/notificacion";
import { hasAction, actions } from "../../../../services/security";
import { required } from "../../../../services/validation";

import FormulasForm, {
  parseFormulas,
  validateFormulas,
} from "./../../../../components/FormulasForm";
import Header from "../../../../components/Header";
import Historial from "./../../../../components/Historial";
import OperandoHistorial from "./OperandoHistorial";

const OperandosForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const VER = hasAction(actions.OPERANDO_VER);
  const CREAR = hasAction(actions.OPERANDO_CREAR);
  const EDITAR = hasAction(actions.OPERANDO_EDITAR);
  const GUARDAR = match.params.id === "nuevo" ? CREAR : EDITAR;

  const [state, setState] = useState({
    title: match.params.id === "nuevo" ? "Crear" : EDITAR ? "Editar" : "Ver",
    id: match.params.id === "nuevo" ? 0 : parseInt(match.params.id),
    loading: false,
    formulas: [],
    historial: [],
  });

  const [historialVisible, setHistorialVisible] = useState(false);
  const [tipos, setTipos] = useState([]);
  const [tipo, setTipo] = useState();

  const breadcrumb = [
    {
      id: 1,
      title: "Parámetros",
    },
    {
      id: 2,
      title: "Liquidación",
      url: "/parametros/liquidacion",
    },
    {
      id: 3,
      title: "Operandos",
      url: "/parametros/liquidacion/operandos",
    },
    { id: 4, title: state.title },
  ];

  const onTipoChange = (value) => {
    setTipo(value);
  };

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    if (values.tipo === "4") {
      const formulas = parseFormulas(values);
      if (!validateFormulas(formulas)) {
        return;
      }
      values.formulas = formulas;
    }

    const result =
      state.id === 0
        ? await http.post("operandos", values)
        : await http.put(`operandos/${state.id}`, values);

    if (result && result.data.code === 0) {
      showSuccess("El operando fue guardado exitosamente.");
      history.push("/parametros/liquidacion/operandos");
    } else {
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {
    async function getData() {
      const response = await http.get("operandos/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setTipos(data.tipos);
      }
    }

    getData();

    async function getForm() {
      const rForm = await http.get(`operandos/${state.id}`);
      if (rForm && rForm.data.code === 0) {
        const data = rForm.data.data;
        setTipo(data.tipo);
        setState({
          ...state,
          formulas: data.formulas,
          historial: data.historial,
        });
        form.setFieldsValue(data);
      }
    }

    if (VER) {
      getForm();
    }
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="operando">
      <Header title={`${state.title} Operando`} breadcrumb={breadcrumb} />

      <Form form={form} layout="vertical" onFinish={onSave}>
        <div className="form card">
          <div className="form-icon">
            <FiCode />
          </div>
          <Row gutter={20}>
            <Col span="24">
              <Form.Item label="Nombre" name="nombre" rules={[required]}>
                <Input maxLength={500} disabled={!GUARDAR} />
              </Form.Item>
            </Col>

            <Col span="12">
              <Form.Item label="Tipo" name="tipo" rules={[required]}>
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  onChange={onTipoChange}
                >
                  {tipos.map((x) => (
                    <Select.Option key={x.value} value={x.value}>
                      {x.text}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item label="Código" name="codigo" rules={[required]}>
                <Input type="number" disabled={!GUARDAR} />
              </Form.Item>
            </Col>

            <Col span="24">
              <Form.Item
                label="Descripción"
                name="descripcion"
                className="textarea"
              >
                <Input.TextArea maxLength={500} showCount rows={4} />
              </Form.Item>
            </Col>

            <Col span="24">
              <Form.Item label="Grupo" name="grupo">
                <Input maxLength={200} disabled={!GUARDAR} />
              </Form.Item>
            </Col>

            {(tipo === "1" || tipo === "2" || tipo === "7" || tipo === "8") && (
              <Col span="24">
                <Form.Item
                  label="Contenido"
                  name="contenido"
                  className="textarea"
                >
                  <Input.TextArea maxLength={2000} showCount rows={4} />
                </Form.Item>
              </Col>
            )}

            {tipo === "3" && (
              <Col span="24">
                <Historial
                  visible={historialVisible}
                  modalContent={<OperandoHistorial items={state.historial} />}
                  onOpen={() => setHistorialVisible(true)}
                  onClose={() => setHistorialVisible(false)}
                >
                  <Form.Item label="Valor" name="contenido">
                    <Input maxLength={2000} />
                  </Form.Item>
                </Historial>
              </Col>
            )}

            {tipo === "4" && (
              <Col span="24">
                <h3>Fórmulas</h3>
                <FormulasForm form={form} formulas={state.formulas} />
              </Col>
            )}
          </Row>

          <div className="form-actions">
            <Link to="/parametros/liquidacion/operandos">
              <Button type="secondary">Volver</Button>
            </Link>
            {GUARDAR && (
              <Button type="primary" htmlType="submit" loading={state.loading}>
                Guardar
              </Button>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};

export default OperandosForm;
