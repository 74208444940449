/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Menu, Modal } from 'antd';
import { FiDownload, FiRepeat, FiSearch } from 'react-icons/fi';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import fileDownload from 'js-file-download';
import moment from 'moment';
import 'moment/locale/es';

import { ContextProvider } from './../../../services/context';
import http from '../../../services/http';
import { showSuccess } from './../../../services/notificacion';
import { hasAction, actions } from './../../../services/security';

import Header from './../../../components/Header';
import Table from './../../../components/Table';
import LiquidacionLegajo from './../LiquidacionLegajo';

const LiquidacionBuscador = () => {
  const history = useHistory();

  const VER = hasAction(actions.LIQUIDACION_BUSCADOR_VER);
  const RECALCULAR = hasAction(actions.LIQUIDACION_RECALCULAR);

  const [state, setState] = useState({
    filters: {
      mes: (moment().month() + 1).toString(),
      anio: moment().year().toString(),
      legajo: '',
    },
    columns: [
      {
        id: 1,
        title: 'Legajo',
        name: 'legajo',
        filter: true,
        style: { width: '120px', paddingLeft: '20px' },
        render: (item) => item.legajo,
      },
      {
        id: 2,
        title: 'Nombre',
        name: 'nombre',
        filter: true,
        style: { paddingLeft: '20px' },
        render: (item) => item.nombre,
      },
      {
        id: 3,
        title: 'Tipo',
        style: {},
        render: (item) => item.tipo,
      },
      {
        id: 4,
        title: 'Mes',
        name: 'mes',
        filter: true,
        style: { width: '120px', paddingLeft: '20px' },
        render: (item) => item.mes,
      },
      {
        id: 5,
        title: 'Año',
        name: 'anio',
        filter: true,
        style: { width: '120px', paddingLeft: '20px' },
        render: (item) => item.anio,
      },
      {
        id: 6,
        title: 'Neto',
        style: { textAlign: 'right', paddingRight: '40px' },
        render: (item) => item.netoTexto,
      },
    ],
    loading: true,
    loaded: false,
    reload: true,
    selected: [],
    list: [],
    total: 0,
    page: 1,
    size: 10,
  });

  const [preview, setPreview] = useState();

  const breadcrumb = [
    { id: 1, title: 'Liquidación' },
    { id: 2, title: 'Buscador', url: '/liquidacion/buscador' },
  ];

  const menu = (item) => {
    return (
      <Menu className="table-actions-menu">
        <Menu.Item key="0" onClick={() => onPreview(item)}>
          <FiSearch />
          Ver
        </Menu.Item>
        <Menu.Item key="1" onClick={() => onDowload(item)}>
          <FiDownload />
          Descargar
        </Menu.Item>
        {RECALCULAR && item.estado !== '3' && (
          <Menu.Item key="2" onClick={() => onRecalculate(item)}>
            <FiRepeat />
            Recalcular
          </Menu.Item>
        )}
      </Menu>
    );
  };

  useEffect(() => {
    const serialized = localStorage.getItem(history.location.pathname);

    if (serialized) {
      const table = JSON.parse(serialized);
      setState({
        ...state,
        filters: table.filters,
        page: table.page,
        size: table.size,
        loaded: true,
      });
    } else {
      setState({
        ...state,
        loaded: true,
      });
    }
  }, []);

  useEffect(() => {
    if (state.list.length === 1) {
      setPreview(state.list[0]);
    }
  }, [state.list]);

  const onPreview = (item) => {
    setPreview(item);
  };

  const onDowload = async (item) => {
    const rDownload = await http.get(
      `liquidaciones/empleados/${item.id}/print`,
      true
    );

    fileDownload(rDownload.data, `BonoSueldo_${Date.now()}.pdf`);
  };

  const onRecalculate = (item) => {
    Modal.confirm({
      title: 'Confirmar acción',
      icon: <ExclamationCircleOutlined />,
      content: '¿Esta seguro que desea recalcular esta liquidación?',
      okText: 'Confirmar',
      cancelText: 'Cancelar',
      onOk: async () => {
        const response = await http.put(
          `liquidaciones/empleados/${item.id}/recalculate`
        );
        if (response && response.data.code === 0) {
          showSuccess('La liquidación fue recalculada exitosamente.');
        }
      },
    });
  };

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="buscador page">
      <ContextProvider value={[state, setState]}>
        <Header title="Buscador de Liquidaciones" breadcrumb={breadcrumb} />
        <Table url="liquidaciones/buscador" menu={menu} filters />

        <Modal
          className="liquidacion-empleado"
          title={`${preview?.legajo} - ${preview?.nombre}`}
          visible={preview}
          onCancel={() => setPreview(undefined)}
          footer={null}
          maskClosable={false}
          keyboard={false}
          destroyOnClose={true}
          width={1100}
        >
          <LiquidacionLegajo item={preview} />
        </Modal>
      </ContextProvider>
    </div>
  );
};

export default LiquidacionBuscador;
