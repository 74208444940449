/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Form, Input, Button, Select } from "antd";
import { FiGrid } from "react-icons/fi";

import http from "./../../../../services/http";
import { showSuccess } from "./../../../../services/notificacion";
import { hasAction, actions } from "./../../../../services/security";
import { required } from "./../../../../services/validation";

import Header from "./../../../../components/Header";

const { Option } = Select;

const AgrupamientosClasesForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const VER = hasAction(actions.AGRUPAMIENTOSCLASES_VER);
  const CREAR = hasAction(actions.AGRUPAMIENTOSCLASES_CREAR);
  const EDITAR = hasAction(actions.AGRUPAMIENTOSCLASES_EDITAR);
  const GUARDAR = match.params.id === "nuevo" ? CREAR : EDITAR;

  const [state, setState] = useState({
    title: match.params.id === "nuevo" ? "Crear" : EDITAR ? "Editar" : "Ver",
    id: match.params.id === "nuevo" ? 0 : parseInt(match.params.id),
    loading: false,
  });

  const [agrupamientos, setAgrupamientos] = useState([]);
  const [clases, setClases] = useState([]);
  const [clasesAgrupamientos, setClasesAgrupamientos] = useState([]);

  const breadcrumb = [
    {
      id: 1,
      title: "Parámetros",
    },
    {
      id: 2,
      title: "Estructura",
      url: "/parametros/estructura",
    },
    {
      id: 3,
      title: "Cantidad de Cargos",
      url: "/parametros/estructura/agrupamientos_clases",
    },
    { id: 4, title: state.title },
  ];

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    const result =
      state.id === 0
        ? await http.post("agrupamientosClases", values)
        : await http.put(`agrupamientosClases/${state.id}`, values);

    if (result && result.data.code === 0) {
      showSuccess("La cantidad de cargos fue guardado exitosamente.");
      history.push("/parametros/estructura/agrupamientos_clases");
    } else {
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {
    async function getData() {
      const response = await http.get("agrupamientosClases/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setAgrupamientos(data.agrupamientos);
        setClases(data.clases);
        updateClases(
          data.agrupamientos,
          data.clases,
          form.getFieldValue("agrupamientoId")
        );
      }
    }

    getData();

    async function getForm() {
      const rForm = await http.get(`agrupamientosClases/${state.id}`);
      if (rForm && rForm.data.code === 0) {
        const data = rForm.data.data;
        form.setFieldsValue(data);
      }
    }

    if (VER) {
      getForm();
    }
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }
  const onAgrupamientoChange = (value) => {
    form.setFieldsValue({ claseId: undefined });

    const item = agrupamientos.find((x) => x.id === parseInt(value));
    if (item && item.tipo)
      setClasesAgrupamientos(clases.filter((c) => c.tipo === item.tipo));
    else setClasesAgrupamientos([]);
  };

  const updateClases = (agrupamientosList, clasesList, value) => {
    const item = agrupamientosList.find((x) => x.id === parseInt(value));
    if (item && item.tipo)
      setClasesAgrupamientos(clasesList.filter((c) => c.tipo === item.tipo));
    else setClasesAgrupamientos([]);
  };

  return (
    <div className="agrupamiento">
      <Header
        title={`${state.title} Cantidad de Cargos`}
        breadcrumb={breadcrumb}
      />

      <Form form={form} layout="vertical" onFinish={onSave}>
        <div className="form card">
          <div className="form-icon">
            <FiGrid />
          </div>

          <Form.Item
            label="Agrupamiento"
            name="agrupamientoId"
            rules={[required]}
          >
            <Select
              showSearch
              allowClear
              disabled={state.id !== 0}
              optionFilterProp="children"
              onChange={onAgrupamientoChange}
            >
              {agrupamientos.map((m) => (
                <Option key={m.id.toString()} value={m.id.toString()}>
                  {m.nombre}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Clase" name="claseId" rules={[required]}>
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              disabled={state.id !== 0}
            >
              {clasesAgrupamientos.map((m) => (
                <Option key={m.id.toString()} value={m.id.toString()}>
                  {m.detalle}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Cantidad" name="cantidad" rules={[required]}>
            <Input type="number" disabled={!GUARDAR} />
          </Form.Item>

          <div className="form-actions">
            <Link to="/parametros/estructura/agrupamientos_clases">
              <Button type="secondary">Volver</Button>
            </Link>
            {GUARDAR && (
              <Button type="primary" htmlType="submit" loading={state.loading}>
                Guardar
              </Button>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AgrupamientosClasesForm;
