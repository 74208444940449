/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Form, Input, Button } from "antd";
import { FiSettings } from "react-icons/fi";

import http from "../../../services/http";
import { showSuccess } from "../../../services/notificacion";
import { hasAction, actions } from "../../../services/security";
import { required } from "../../../services/validation";

import Header from "../../../components/Header";

const MunicipioForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const VER = hasAction(actions.MUNICIPIO_VER);
  const CREAR = hasAction(actions.MUNICIPIO_CREAR);
  const EDITAR = hasAction(actions.MUNICIPIO_EDITAR);
  const GUARDAR = match.params.id === "nuevo" ? CREAR : EDITAR;

  const [state, setState] = useState({
    title: match.params.id === "nuevo" ? "Crear" : EDITAR ? "Editar" : "Ver",
    id: match.params.id === "nuevo" ? 0 : parseInt(match.params.id),
    loading: false,
  });

  const breadcrumb = [
    { id: 1, title: "Entorno" },
    { id: 2, title: "Municipio", url: "/entorno/municipio" },
    { id: 3, title: state.title },
  ];

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    const rSave =
      state.id === 0
        ? await http.post("municipio", values)
        : await http.put(`municipio/${state.id}`, values);

    if (rSave && rSave.data.code === 0) {
      showSuccess("El parámetro fue guardado exitosamente.");
      history.push("/entorno/municipio");
    } else {
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {
    async function getForm() {
      const rForm = await http.get(`municipio/${state.id}`);
      if (rForm && rForm.data.code === 0) {
        const data = rForm.data.data;

        form.setFieldsValue(data);
      }
    }

    if (VER) {
      getForm();
    }
  }, []);

  return (
    <div className="municipio">
      <Header title={`${state.title} Parámetro`} breadcrumb={breadcrumb} />

      <Form form={form} layout="vertical" onFinish={onSave}>
        <div className="form card">
          <div className="form-icon">
            <FiSettings />
          </div>

          <Form.Item label="Nombre" name="nombre" rules={[required]}>
            <Input maxLength={200} disabled={!GUARDAR} />
          </Form.Item>

          <Form.Item label="Código" name="codigo" rules={[required]}>
            <Input type="number" disabled={!GUARDAR} min={0} max={999999999} />
          </Form.Item>
          <Form.Item label="Valor" name="valor">
            <Input maxLength={200} disabled={!GUARDAR} />
          </Form.Item>

          <Form.Item label="Descripción" name="descripcion">
            <Input maxLength={200} disabled={!GUARDAR} />
          </Form.Item>

          <div className="form-actions">
            <Link to="/entorno/municipio">
              <Button type="secondary">Volver</Button>
            </Link>
            {GUARDAR && (
              <Button type="primary" htmlType="submit" loading={state.loading}>
                Guardar
              </Button>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};

export default MunicipioForm;
