/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Button, Form, Input, Menu, Modal, Switch, Select } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { FiEdit, FiPlus, FiTrash2 } from "react-icons/fi";

import { ContextProvider } from "./../../../../services/context";
import http from "./../../../../services/http";
import { showSuccess } from "./../../../../services/notificacion";
import { hasAction, actions } from "./../../../../services/security";

import Filters from "./../../../../components/Filters";
import FiltersButton from "./../../../../components/FiltersButton";
import Header from "./../../../../components/Header";
import Table from "./../../../../components/Table";

const Sindicatos = () => {
  const history = useHistory();

  const VER = hasAction(actions.SINDICATOS_VER);
  const CREAR = hasAction(actions.SINDICATOS_CREAR);
  const EDITAR = hasAction(actions.SINDICATOS_EDITAR);
  const ELIMINAR = hasAction(actions.SINDICATOS_ELIMINAR);
  const DESHABILITAR = hasAction(actions.SINDICATOS_DESHABILITAR);

  const [state, setState] = useState({
    filters: {
      detalle: "",
      codigo: "",
      inscripcion: "",
      habilitado: "1",
    },
    columns: [
      {
        id: 1,
        title: "Detalle",
        style: {},
        render: (item) => item.detalle,
      },
      {
        id: 2,
        title: "Código",
        style: {},
        render: (item) => item.codigo,
      },
      {
        id: 3,
        title: "Inscripción",
        style: {},
        render: (item) => item.inscripcion,
      },
      {
        id: 4,
        title: "Habilitado",
        style: { width: "150px", textAlign: "center" },
        render: (item) => (
          <Switch
            size="small"
            disabled={!DESHABILITAR}
            defaultChecked={item.habilitado}
            onChange={() => onDisable(item)}
          />
        ),
      },
    ],
    loading: true,
    loaded: false,
    reload: true,
    list: [],
    total: 0,
    page: 1,
    size: 10,
  });

  const breadcrumb = [
    {
      id: 1,
      title: "Parámetros",
    },
    {
      id: 2,
      title: "Generales",
      url: "/parametros/generales",
    },
    {
      id: 3,
      title: "Sindicatos",
      url: "/parametros/generales/sindicatos",
    },
  ];

  const buttons = () => {
    return (
      <React.Fragment>
        <FiltersButton />
        {CREAR && (
          <Button type="primary" icon={<FiPlus />} onClick={onAdd}>
            Nuevo
          </Button>
        )}
      </React.Fragment>
    );
  };

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Detalle" name="detalle">
          <Input />
        </Form.Item>
        <Form.Item label="Código" name="codigo">
          <Input />
        </Form.Item>
        <Form.Item label="Habilitado" name="habilitado">
          <Select defaultValue="1">
            <Select.Option value="-1">Indistinto</Select.Option>
            <Select.Option value="1">Si</Select.Option>
            <Select.Option value="0">No</Select.Option>
          </Select>
        </Form.Item>
      </React.Fragment>
    );
  };

  const menu = (item) => {
    return (
      <Menu className="table-actions-menu">
        {EDITAR && (
          <Menu.Item key="0" onClick={() => onEdit(item)}>
            <FiEdit />
            Editar
          </Menu.Item>
        )}
        {ELIMINAR && (
          <Menu.Item key="1" onClick={() => onDelete(item)}>
            <FiTrash2 />
            Eliminar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const onAdd = () => {
    history.push("/parametros/generales/sindicatos/nuevo");
  };

  const onEdit = (item) => {
    history.push(`/parametros/generales/sindicatos/${item.id}`);
  };

  const onDisable = async (item) => {
    await http.put(`sindicatos/disable/${item.id}`);
  };

  const onDelete = (item) => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea eliminar este sindicato?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const rDelete = await http.delete(`sindicatos/${item.id}`);
        if (rDelete && rDelete.data.code === 0) {
          showSuccess("El sindicato fue eliminado exitosamente.");
          setState({ ...state, reload: !state.reload });
        }
      },
    });
  };

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="sindicatos page">
      <ContextProvider value={[state, setState]}>
        <Header title="Sindicatos" breadcrumb={breadcrumb} buttons={buttons} />
        <Filters filters={filters} />
        <Table url="sindicatos/list" menu={menu} />
      </ContextProvider>
    </div>
  );
};

export default Sindicatos;
