/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Button, Form, DatePicker, Menu, Modal, Select } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { FiEdit, FiPlus, FiTrash2 } from "react-icons/fi";

import "moment/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";

import { ContextProvider } from "./../../../services/context";
import http from "./../../../services/http";
import { showSuccess } from "./../../../services/notificacion";
import { hasAction, actions } from "./../../../services/security";

import Filters from "./../../../components/Filters";
import FiltersButton from "./../../../components/FiltersButton";
import Header from "./../../../components/Header";
import Table from "./../../../components/Table";

const { Option } = Select;

const MedicinaLaboral = () => {
  const history = useHistory();

  const VER = hasAction(actions.MEDICINALABORAL_VER);
  const CREAR = hasAction(actions.MEDICINALABORAL_CREAR);
  const EDITAR = hasAction(actions.MEDICINALABORAL_EDITAR);
  const ELIMINAR = hasAction(actions.MEDICINALABORAL_ELIMINAR);

  const [state, setState] = useState({
    filters: {
      desde: null,
      hasta: null,
      empleadoId: "",
      tipoExamen: "",
      resultado: "",
    },
    columns: [
      {
        id: 1,
        title: "Empleado",
        style: {},
        render: (item) => item.empleado,
      },
      {
        id: 2,
        title: "Tipo de Examen",
        style: {},
        render: (item) => item.tipoExamen,
      },
      {
        id: 3,
        title: "Fecha",
        style: {},
        render: (item) => item.fechaExamenStr,
      },
      {
        id: 4,
        title: "Resultado",
        style: {},
        render: (item) => item.resultado,
      },
    ],
    loading: true,
    loaded: false,
    reload: true,
    list: [],
    total: 0,
    page: 1,
    size: 10,
  });

  const [empleados, setEmpleados] = useState([]);
  const [resultados, setResultados] = useState([]);
  const [tipos, setTipos] = useState([]);

  const breadcrumb = [
    { id: 1, title: "Administración" },
    {
      id: 2,
      title: "Medicina Laboral",
      url: "/administracion/medicina",
    },
  ];

  const buttons = () => {
    return (
      <React.Fragment>
        <FiltersButton />
        {CREAR && (
          <Button type="primary" icon={<FiPlus />} onClick={onAdd}>
            Nuevo
          </Button>
        )}
      </React.Fragment>
    );
  };

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Fecha Desde" name="desde">
          <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
        </Form.Item>

        <Form.Item label="Fecha Hasta" name="hasta">
          <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
        </Form.Item>

        <Form.Item label="Empleado" name="empleadoId">
          <Select showSearch allowClear optionFilterProp="children">
            {empleados.map((m) => (
              <Option key={m.value} value={m.value}>
                {m.text}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Tipo de Examen" name="tipoExamen">
          <Select showSearch allowClear optionFilterProp="children">
            {tipos.map((m) => (
              <Option key={m.value} value={m.value}>
                {m.text}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Resultado" name="resultado">
          <Select showSearch allowClear optionFilterProp="children">
            {resultados.map((m) => (
              <Option key={m.value} value={m.value}>
                {m.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </React.Fragment>
    );
  };

  const menu = (item) => {
    return (
      <Menu className="table-actions-menu">
        {EDITAR && (
          <Menu.Item key="0" onClick={() => onEdit(item)}>
            <FiEdit />
            Editar
          </Menu.Item>
        )}
        {ELIMINAR && (
          <Menu.Item key="1" onClick={() => onDelete(item)}>
            <FiTrash2 />
            Eliminar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const onAdd = () => {
    history.push("/administracion/medicina/nuevo");
  };

  const onEdit = (item) => {
    history.push(`/administracion/medicina/${item.id}`);
  };

  const onDelete = (item) => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea eliminar este elemento?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const rDelete = await http.delete(`MedicinaLaboral/${item.id}`);
        if (rDelete && rDelete.data.code === 0) {
          showSuccess("El elemento fue eliminado exitosamente.");
          setState({ ...state, reload: !state.reload });
        }
      },
    });
  };

  useEffect(() => {
    async function getData() {
      const response = await http.get("medicinalaboral/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setEmpleados(data.empleados);
        setTipos(data.tipos);
        setResultados(data.resultados);
      }
    }

    getData();
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="MedicinaLaboral page">
      <ContextProvider value={[state, setState]}>
        <Header
          title="Medicina Laboral"
          breadcrumb={breadcrumb}
          buttons={buttons}
        />
        <Filters filters={filters} />
        <Table url="medicinalaboral/list" menu={menu} />
      </ContextProvider>
    </div>
  );
};

export default MedicinaLaboral;
