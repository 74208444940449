/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Form, Input, Button, Select, DatePicker, Row, Col } from "antd";
import { FiAlertTriangle } from "react-icons/fi";

import moment from "moment";
import locale from "antd/es/date-picker/locale/es_ES";
import "moment/locale/es";

import http from "../../../../services/http";
import { showSuccess } from "../../../../services/notificacion";
import { hasAction, actions } from "../../../../services/security";
import { required } from "../../../../services/validation";

import Header from "../../../../components/Header";

const { Option } = Select;

const NovedadFijaForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const VER = hasAction(actions.NOVEDADFIJA_VER);
  const CREAR = hasAction(actions.NOVEDADFIJA_CREAR);
  const EDITAR = hasAction(actions.NOVEDADFIJA_EDITAR);
  const GUARDAR = match.params.id === "nuevo" ? CREAR : EDITAR;

  const [state, setState] = useState({
    title:
      match.params.id === "nuevo" ? "Registrar" : EDITAR ? "Editar" : "Ver",
    id: match.params.id === "nuevo" ? 0 : parseInt(match.params.id),
    loading: false,
  });

  const [tipo, setTipo] = useState("Importe");
  const [conceptos, setConceptos] = useState([]);
  const [empleados, setEmpleados] = useState([]);
  const [tiposLiquidacion, setTiposLiquidacion] = useState([]);

  const breadcrumb = [
    { id: 1, title: "Novedades" },
    {
      id: 2,
      title: "Liquidación",
      url: "/novedades/liquidacion",
    },
    {
      id: 3,
      title: "Novedades Fijas",
      url: "/novedades/liquidacion/novedadfija",
    },
    { id: 4, title: state.title },
  ];

  const onConceptoChange = async (value) => {
    if (!value) {
      return;
    }

    const response = await http.get(`Conceptos/${value}/Tipo`);
    if (response && response.data.code === 0) {
      const data = response.data.data;
      setTipo(data.tipo);
    }
  };

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    const result =
      state.id === 0
        ? await http.post("NovedadFija", values)
        : await http.put(`NovedadFija/${state.id}`, values);

    if (result && result.data.code === 0) {
      showSuccess("La novedad fue guardada exitosamente.");
      history.push("/novedades/liquidacion/novedadfija");
    } else {
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {
    async function getForm() {
      const response = await http.get("NovedadFija/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setConceptos(data.conceptos);
        setEmpleados(data.empleados);
        setTiposLiquidacion(data.tiposLiquidacion);
      }

      const rForm = await http.get(`NovedadFija/${state.id}`);
      if (rForm && rForm.data.code === 0) {
        const data = rForm.data.data;
        if (data.fecha) data.fecha = moment(data.fecha);
        if (data.vencimiento) data.vencimiento = moment(data.vencimiento);

        onConceptoChange(data.conceptoId);

        form.setFieldsValue(data);
      }
    }

    if (VER) {
      getForm();
    }
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="novedades">
      <Header title={`${state.title} Novedad Fija`} breadcrumb={breadcrumb} />
      <div className="form card">
        <Form form={form} layout="vertical" onFinish={onSave}>
          <div className="">
            <div className="form-icon">
              <FiAlertTriangle />
            </div>
            {state.id !== 0 && (
              <Form.Item label="Empleado" name="empleadoId" rules={[required]}>
                <Select showSearch allowClear optionFilterProp="children">
                  {empleados.map((m) => (
                    <Option key={m.value} value={m.value}>
                      {m.text}
                      {state.id}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {state.id === 0 && (
              <Form.Item
                label="Empleado (Se creara una novedad por cada empleado seleccionado)"
                name="empleados"
                rules={[required]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  autoFocus
                  defaultOpen
                  disabled={!GUARDAR}
                >
                  {empleados.map((x) => (
                    <Option key={x.value} value={x.value} disabled={x.disabled}>
                      {x.text}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <Form.Item label="Concepto" name="conceptoId" rules={[required]}>
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                onChange={onConceptoChange}
              >
                {conceptos.map((m) => (
                  <Option key={m.value} value={m.value}>
                    {m.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Tipo Liquidación" name="tipoLiquidacion">
              <Select showSearch allowClear optionFilterProp="children">
                {tiposLiquidacion.map((m) => (
                  <Option key={m.value} value={m.value}>
                    {m.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Row gutter={20}>
              <Col span="12">
                <Form.Item label="Fecha" name="fecha">
                  <DatePicker
                    disabled={!GUARDAR}
                    format="DD/MM/YYYY"
                    placeholder=""
                    locale={locale}
                  />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item label="Vencimiento" name="vencimiento">
                  <DatePicker
                    disabled={!GUARDAR}
                    format="DD/MM/YYYY"
                    placeholder=""
                    locale={locale}
                  />
                </Form.Item>
              </Col>

              <Col span="24">
                <Form.Item label={tipo} name="valor" rules={[required]}>
                  <Input type="number" disabled={!GUARDAR} />
                </Form.Item>
              </Col>
            </Row>
            <div className="form-actions">
              <Link to="/novedades/liquidacion/novedadfija">
                <Button type="secondary">Volver</Button>
              </Link>
              {GUARDAR && (
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={state.loading}
                >
                  Guardar
                </Button>
              )}
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default NovedadFijaForm;
