import React from "react";
import { Col, Row } from "antd";

import Header from "./../../../../components/Header";
import MenuCard from "./../../../../components/MenuCard";

const HCD = () => {
  const breadcrumb = [
    { id: 1, title: "Administración" },
    {
      id: 2,
      title: "Estructura",
      url: "/administracion/estructura",
    },
    {
      id: 3,
      title: "HCD",
      url: "/administracion/estructura/hcd",
    },
  ];

  const items = [
    {
      id: 1,
      titulo: "Organigrama Tradicional",
      path: "/administracion/estructura/hcd/tradicional",
      color: "primary",
      icono: "FiGitPullRequest",
    },
    {
      id: 2,
      titulo: "Consolidado",
      path: "/administracion/estructura/hcd/consolidado",
      color: "primary",
      icono: "FiFilter",
    },
    {
      id: 3,
      titulo: "Planta Funcional",
      path: "/administracion/estructura/hcd/funcional",
      color: "primary",
      icono: "FiPocket",
    },
  ];

  return (
    <div className="inicio">
      <Header title="H.C.D" breadcrumb={breadcrumb} buttons={() => {}} />

      <Row gutter={[32, 36]}>
        {items.map((i) => (
          <Col key={i.id} xs={12} md={8} xl={6} xxl={4}>
            <MenuCard item={i} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default HCD;
