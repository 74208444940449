/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Checkbox,
  Dropdown,
  Form,
  Input,
  Pagination,
  Select,
  Spin,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { FiMoreVertical } from "react-icons/fi";

import { Context } from "./../services/context";
import { formatNumber } from "./../services/forms";
import http from "./../services/http";

import empty from "./../assets/images/empty.svg";

import "./Table.scss";

const Table = ({ url, menu, checkbox, checked, filters, totals }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const [state, setState] = useContext(Context);

  const [filter, setFilter] = useState();

  useEffect(() => {
    async function getList() {
      if (!state.loaded) {
        return;
      }

      form.setFieldsValue(state.filters);

      setState({ ...state, loading: true });

      const response = await http.post(url, {
        page: state.page,
        size: state.size,
        ...state.filters,
      });

      if (response && response.data.code === 0) {
        const data = response.data.data;

        if (data.list.length === 0 && state.page > 1) {
          setState({ ...state, page: 1 });
          return;
        }

        setState({
          ...state,
          ...data,
          loading: false,
        });
      } else {
        setState({ ...state, loading: false });
      }

      localStorage.setItem(
        history.location.pathname,
        JSON.stringify({
          filters: state.filters,
          page: state.page,
          size: state.size,
        })
      );
    }

    getList();
  }, [state.page, state.size, state.loaded, state.reload]);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (!filter) {
        return;
      }

      let nState = { ...state, reload: !state.reload };
      nState.filters[filter.name] = filter.value;
      setState(nState);
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [filter]);

  const getFooter = () => {
    let start = state.page * state.size - state.size + 1;
    start = start < state.total ? start : state.total;

    let end = state.page * state.size;
    end = end < state.total ? end : state.total;

    return `Mostrando ${start} - ${end} de ${state.total} resultados`;
  };

  const onPageChange = (value) => {
    setState({ ...state, page: value });
  };

  const onSizeChange = (value) => {
    setState({ ...state, size: parseInt(value) });
  };

  const onCheckAll = (checked) => {
    const ids = state.list.map((x) => x.id);
    let nSelected = [...state.selected];

    if (checked) {
      nSelected = [...nSelected, ...ids];
      nSelected = nSelected.filter(
        (item, pos) => nSelected.indexOf(item) === pos
      );
    } else {
      nSelected = nSelected.filter((x) => !ids.includes(x));
    }

    setState({ ...state, selected: nSelected });
  };

  const onCheck = (item, checked) => {
    let nSelected = [...state.selected.filter((x) => x !== item.id)];

    if (checked) {
      nSelected.push(item.id);
    }

    setState({ ...state, selected: nSelected });
  };

  const onFilterChange = (name, value) => {
    setFilter({ name: name, value: value });
  };

  return (
    <div className="card-table">
      <Form form={form}>
        <div className="table-wrapper">
          <table className="table">
            <thead>
              <tr>
                {menu && <th></th>}
                {checkbox && (
                  <th className="col-checkbox">
                    <Checkbox onChange={(e) => onCheckAll(e.target.checked)} />
                  </th>
                )}
                {state.columns.map((c, index) => (
                  <th key={index} style={c.style}>
                    {c.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filters && (
                <tr>
                  <td></td>
                  {checkbox && <td></td>}
                  {state.columns.map((c, index) => (
                    <td key={index}>
                      {c.filter && (
                        <Form.Item name={c.name}>
                          <Input
                            placeholder="Filtrar..."
                            autoFocus={index === 0}
                            onChange={(e) =>
                              onFilterChange(c.name, e.target.value)
                            }
                          />
                        </Form.Item>
                      )}
                    </td>
                  ))}
                </tr>
              )}

              {state.loading && (
                <tr>
                  <td colSpan={state.columns.length + (checkbox ? 2 : 1)}>
                    <div className="loading">
                      <Spin
                        indicator={
                          <LoadingOutlined
                            style={{
                              fontSize: 50,
                              color: "#04bebe",
                              marginBottom: "10px",
                            }}
                            spin
                          />
                        }
                      />
                      Cargando...
                    </div>
                  </td>
                </tr>
              )}

              {!state.loading && state.total === 0 && (
                <tr>
                  <td colSpan={state.columns.length + (checkbox ? 2 : 1)}>
                    <div className="empty">
                      <img src={empty} alt="empty" />
                      Sin resultados
                    </div>
                  </td>
                </tr>
              )}

              {!state.loading &&
                state.total > 0 &&
                state.list.map((i, index) => (
                  <tr key={index}>
                    {menu && (
                      <td className="table-actions">
                        <Dropdown
                          key={i.id}
                          overlay={() => menu(i)}
                          trigger={["click"]}
                          placement="bottomLeft"
                          arrow
                        >
                          <div>
                            <FiMoreVertical />
                          </div>
                        </Dropdown>
                      </td>
                    )}
                    {checkbox && (
                      <td className="col-checkbox">
                        <Checkbox
                          checked={state.selected.indexOf(i.id) > -1}
                          onChange={(e) => onCheck(i, e.target.checked)}
                        />
                      </td>
                    )}
                    {state.columns.map((c, index) => (
                      <td key={index} style={c.style}>
                        {c.render(i)}
                      </td>
                    ))}
                  </tr>
                ))}

              {!state.loading && state.total > 0 && totals && (
                <tr className="table-totals">
                  {menu && <td></td>}
                  {checkbox && <td></td>}
                  {state.columns.map((c, index) => (
                    <React.Fragment key={index}>
                      {index === 0 && <td>Totales</td>}
                      {index > 0 && (
                        <td style={c.style}>
                          {formatNumber(state[c.totalProp])}
                        </td>
                      )}
                    </React.Fragment>
                  ))}
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="footer">
          <div className="count">{getFooter()}</div>
          <div className="right">
            <Select
              defaultValue={state.size.toString()}
              style={{ width: 160 }}
              bordered={false}
              onChange={onSizeChange}
            >
              <Select.Option value="10">10 resultados</Select.Option>
              <Select.Option value="20">20 resultados</Select.Option>
              <Select.Option value="50">50 resultados</Select.Option>
              <Select.Option value="100">100 resultados</Select.Option>
              <Select.Option value="1000">1000 resultados</Select.Option>
            </Select>
            <Pagination
              defaultPageSize={state.size}
              current={state.page}
              total={state.total}
              showSizeChanger={false}
              onChange={onPageChange}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default Table;
