/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { Form, Input, Button, Select, DatePicker, Row, Col } from 'antd';
import { FiAlertTriangle, FiPaperclip } from 'react-icons/fi';

import moment from 'moment';
import locale from 'antd/es/date-picker/locale/es_ES';
import 'moment/locale/es';

import http from '../../../../services/http';
import { showSuccess } from '../../../../services/notificacion';
import { hasAction, actions } from '../../../../services/security';
import { required } from '../../../../services/validation';

import Header from '../../../../components/Header';
import TextArea from 'antd/lib/input/TextArea';
import Archivos from './Archivos';
import { parseToDate } from '../../../../services/forms';
import LicenciasForm from './LicenciasForm';

const { Option } = Select;

const AccidentesForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const VER = hasAction(actions.ACCIDENTESTRABAJOS_VER);
  const CREAR = hasAction(actions.ACCIDENTESTRABAJOS_CREAR);
  const EDITAR = hasAction(actions.ACCIDENTESTRABAJOS_EDITAR);
  const GUARDAR = match.params.id === 'nuevo' ? CREAR : EDITAR;

  const [state, setState] = useState({
    title:
      match.params.id === 'nuevo' ? 'Registrar' : EDITAR ? 'Editar' : 'Ver',
    id: match.params.id === 'nuevo' ? 0 : parseInt(match.params.id),
    loading: false,
  });

  const [lugares, setLugares] = useState([]);
  const [gravedades, setGravedades] = useState([]);
  const [tipos, setTipos] = useState([]);
  const [motivos, setMotivos] = useState([]);
  const [resoluciones, setResoluciones] = useState([]);
  const [dataForm, setDataForm] = useState(null);

  const breadcrumb = [
    { id: 1, title: 'Novedades' },
    {
      id: 2,
      title: 'Licencias',
      url: '/novedades/licencias',
    },
    {
      id: 3,
      title: 'Accidentes de Trabajos',
      url: '/novedades/licencias/accidentes',
    },
    { id: 4, title: state.title },
  ];

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    const result =
      state.id === 0
        ? await http.post('AccidentesTrabajos', values)
        : await http.put(`AccidentesTrabajos/${state.id}`, values);

    if (result && result.data.code === 0) {
      showSuccess('El accidente fue guardado exitosamente.');
      history.push('/novedades/licencias/accidentes');
    } else {
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {
    async function getForm() {
      let response = await http.get('AccidentesTrabajos/data');
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setLugares(data.lugares);
        setGravedades(data.gravedades);
        setTipos(data.tipos);
        setMotivos(data.motivos);
        setResoluciones(data.resoluciones);
      }

      response = await http.get(`AccidentesTrabajos/${state.id}`);
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setDataForm(data);

        if (data.fecha) {
          data.fecha = parseToDate(data.fecha);
        }

        if (data.fechaInicioD) {
          data.fechaInicio = moment(data.fechaInicioD);
        }

        if (data.fechaFinD) {
          data.fechaFin = moment(data.fechaFinD);
        }

        if (data.fechaAltaMedica) {
          data.fechaAltaMedica = parseToDate(data.fechaAltaMedica);
        }

        form.setFieldsValue(data);
      }
    }

    if (VER) {
      getForm();
    }
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="AccidenteTrabajo">
      <Header
        title={`${state.title} Accidente de Trabajo`}
        breadcrumb={breadcrumb}
      />
      <div className="form card">
        <Form form={form} layout="vertical" onFinish={onSave}>
          <div className="">
            <div className="form-icon">
              <FiAlertTriangle />
            </div>

            <LicenciasForm form={form} dataForm={dataForm} match={match} />
            <Row gutter={20}>
              <Col span="8">
                <Form.Item label="Lugar de Trabajo" name="lugarTrabajoId">
                  <Select showSearch allowClear optionFilterProp="children">
                    {lugares.map((m) => (
                      <Option
                        key={m.value}
                        value={m.value}
                        disabled={m.disabled}
                      >
                        {m.text}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item label="Lugar del Accidente" name="lugarAccidente">
                  <Input maxLength={500} disabled={!GUARDAR} />
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item
                  label="Nro siniestro"
                  name="nroSiniestro"
                  rules={[required]}
                >
                  <Input disabled={!GUARDAR} />
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item label="Tipo de Accidente" name="tipoAccidenteId">
                  <Select showSearch allowClear optionFilterProp="children">
                    {tipos.map((m) => (
                      <Option
                        key={m.value}
                        value={m.value}
                        disabled={m.disabled}
                      >
                        {m.text}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item label="Forma de Accidente" name="motivoAccidenteId">
                  <Select showSearch allowClear optionFilterProp="children">
                    {motivos.map((m) => (
                      <Option
                        key={m.value}
                        value={m.value}
                        disabled={m.disabled}
                      >
                        {m.text}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item label="Resolución" name="resolucion">
                  <Select showSearch allowClear optionFilterProp="children">
                    {resoluciones.map((m) => (
                      <Option key={m.value} value={m.value}>
                        {m.text}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={20}>
              <Col span="8">
                <Form.Item
                  label="Razon Social del Prestador"
                  name="razonSocialPrestador"
                >
                  <Input disabled={!GUARDAR} />
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item
                  label="Telefono del Prestador"
                  name="telefonoPrestador"
                >
                  <Input disabled={!GUARDAR} />
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item
                  label="Domicilio del Prestador"
                  name="domicilioPrestador"
                >
                  <Input disabled={!GUARDAR} />
                </Form.Item>
              </Col>

              <Col span="12">
                <Form.Item label="Fecha del Alta Medica" name="fechaAltaMedica">
                  <DatePicker
                    disabled={!GUARDAR}
                    format="DD/MM/YYYY"
                    placeholder=""
                    locale={locale}
                  />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item label="Gravedad" name="gravedad">
                  <Select showSearch allowClear optionFilterProp="children">
                    {gravedades.map((m) => (
                      <Option key={m.value} value={m.value}>
                        {m.text}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span="24">
                <Form.Item label="Descripción" name="descripcion">
                  <TextArea maxLength={500} showCount />
                </Form.Item>
              </Col>
            </Row>

            <div className="form-actions">
              <Link to="/novedades/licencias/accidentes">
                <Button type="secondary">Volver</Button>
              </Link>
              {GUARDAR && (
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={state.loading}
                >
                  Guardar
                </Button>
              )}
            </div>
          </div>
        </Form>
        {state.id > 0 && (
          <React.Fragment>
            <hr />
            <div className="section-header">
              <div className="section-title">
                Documentación
                <FiPaperclip />
              </div>
              <div className="section-subtitle">
                Documentación asociada con el accidente.
              </div>
            </div>
            <Archivos accidente={state.id} />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default AccidentesForm;
