/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { Button, Form, Select } from 'antd';

import { Context } from '../../services/context';
import { required } from '../../services/validation';

const LiquidacionFiltros = () => {
  const [state, setState] = useContext(Context);
  const [criterio, setCriterio] = useState();

  useEffect(() => {
    if (state.values.criterio) {
      onCriterioChange(state.values.criterio);
    }

    let nValues = { ...state.values };
    if (nValues.filtro && nValues.filtro.length > 0) {
      nValues.filtro = nValues.filtro.split(',');
    }

    state.form.setFieldsValue(nValues);
  }, []);

  const onCriterioChange = (value) => {
    state.form.setFieldsValue({
      filtro: undefined,
    });
    setCriterio(value);
  };

  const onBackClick = () => {
    state.form.setFieldsValue({
      tipo: undefined,
      criterio: undefined,
      filtro: undefined,
    });
    setState({ ...state, step: state.step - 1 });
  };

  return (
    <div className="form-section">
      <Form.Item label="Tipo" name="tipo" rules={[required]}>
        <Select allowClear>
          {state.tipos.map((x) => (
            <Select.Option key={x.value} value={x.value}>
              {x.text}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="Filtro" name="criterio" rules={[required]}>
        <Select allowClear onChange={onCriterioChange}>
          {state.criterios.map((x) => (
            <Select.Option key={x.value} value={x.value}>
              {x.text}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      {criterio === '1' && (
        <Form.Item label="Legajo" name="filtro" rules={[required]}>
          <Select
            mode="multiple"
            showSearch
            allowClear
            optionFilterProp="children"
            maxTagCount="responsive"
          >
            {state.empleados.map((x) => (
              <Select.Option key={x.value} value={x.value}>
                {x.text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}

      {criterio === '2' && (
        <Form.Item label="Convenio" name="filtro" rules={[required]}>
          <Select allowClear>
            {state.convenios.map((x) => (
              <Select.Option key={x.value} value={x.value}>
                {x.text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}

      {criterio === '3' && (
        <Form.Item label="Direccion" name="filtro" rules={[required]}>
          <Select allowClear>
            {state.direcciones.map((x) => (
              <Select.Option key={x.value} value={x.value}>
                {x.text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}

      <div className="actions">
        <Button type="secondary" onClick={onBackClick}>
          Anterior
        </Button>
        <Button type="primary" htmlType="submit" loading={state.loading}>
          Siguiente
        </Button>
      </div>
    </div>
  );
};

export default LiquidacionFiltros;
