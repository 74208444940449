/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Form, Input, Button, Select, DatePicker, Row, Col } from "antd";
import { FiFile } from "react-icons/fi";
import moment from "moment";

import "moment/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";

import { ContextProvider } from "./../../../../services/context";
import http from "./../../../../services/http";
import { showSuccess } from "./../../../../services/notificacion";
import { hasAction, actions } from "./../../../../services/security";
import { required } from "./../../../../services/validation";

import Clases from "./Clases";
import Conceptos from "./Conceptos";
import Header from "./../../../../components/Header";

const { Option } = Select;

const ConveniosForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const VER = hasAction(actions.CONVENIOS_VER);
  const CREAR = hasAction(actions.CONVENIOS_CREAR);
  const EDITAR = hasAction(actions.CONVENIOS_EDITAR);
  const GUARDAR = match.params.id === "nuevo" ? CREAR : EDITAR;

  const [state, setState] = useState({
    title: match.params.id === "nuevo" ? "Crear" : EDITAR ? "Editar" : "Ver",
    id: match.params.id === "nuevo" ? 0 : parseInt(match.params.id),
    loading: false,
    reload: false,
  });
  const [formatos, setFormatos] = useState([]);
  const [categorias, setCategorias] = useState([]);

  const breadcrumb = [
    {
      id: 1,
      title: "Parámetros",
    },
    {
      id: 2,
      title: "Generales",
      url: "/parametros/generales",
    },
    {
      id: 3,
      title: "Convenios",
      url: "/parametros/generales/convenios",
    },
    { id: 4, title: state.title },
  ];

  useEffect(() => {
    async function getData() {
      const response = await http.get("convenios/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setFormatos(data.formatos);
        setCategorias(data.categorias);
      }
    }
    getData();
  }, []);

  useEffect(() => {
    async function getForm() {
      const response = await http.get(`convenios/${state.id}`);
      if (response && response.data.code === 0) {
        const data = response.data.data;

        if (data.feriado) {
          data.feriado = moment(data.feriado);
        }

        form.setFieldsValue(data);
      }
    }

    getForm();
  }, [state.reload]);

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    const result =
      state.id === 0
        ? await http.post("convenios", values)
        : await http.put(`convenios/${state.id}`, values);

    if (result && result.data.code === 0) {
      showSuccess("El convenio fue guardado exitosamente.");
      history.push("/parametros/generales/convenios");
    } else {
      setState({ ...state, loading: false });
    }
  };

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="convenio">
      <ContextProvider value={[state, setState]}>
        <Header title={`${state.title} Convenio`} breadcrumb={breadcrumb} />

        <Form form={form} layout="vertical" onFinish={onSave}>
          <div className="form card">
            <div className="form-icon">
              <FiFile />
            </div>

            <Row gutter={20}>
              <Col span="12">
                <Form.Item label="Detalle" name="detalle" rules={[required]}>
                  <Input maxLength={200} disabled={!GUARDAR} />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item label="Código" name="codigo" rules={[required]}>
                  <Input maxLength={20} disabled={!GUARDAR} />
                </Form.Item>
              </Col>

              <Col span="12">
                <Form.Item label="Límite" name="limite" rules={[required]}>
                  <Input
                    type="number"
                    step="0.01"
                    disabled={!GUARDAR}
                    min={0}
                  />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="Antigüedad"
                  name="antiguedad"
                  rules={[required]}
                >
                  <Input
                    type="number"
                    step="0.01"
                    disabled={!GUARDAR}
                    min={0}
                  />
                </Form.Item>
              </Col>

              <Col span="12">
                <Form.Item
                  label="Formato"
                  name="impresoraId"
                  rules={[required]}
                >
                  <Select showSearch allowClear optionFilterProp="children">
                    {formatos.map((m) => (
                      <Option key={m.value} value={m.value}>
                        {m.text}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="Jubilacion"
                  name="jubilacion"
                  rules={[required]}
                >
                  <Input
                    type="number"
                    step="0.01"
                    disabled={!GUARDAR}
                    min={0}
                  />
                </Form.Item>
              </Col>

              <Col span="12">
                <Form.Item label="Feriado" name="feriado" rules={[required]}>
                  <DatePicker format="DD/MM" placeholder="" locale={locale} />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="Categoria"
                  name="algoritmoId"
                  rules={[required]}
                >
                  <Select showSearch allowClear optionFilterProp="children">
                    {categorias.map((m) => (
                      <Option key={m.value} value={m.value}>
                        {m.text}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <span>Mensualizados</span>
            <Row gutter={20}>
              <Col span="12">
                <Form.Item
                  label="Horas por mes"
                  name="mensualizados_mes"
                  rules={[required]}
                >
                  <Input type="number" disabled={!GUARDAR} />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="Horas por día"
                  name="mensualizados_dia"
                  rules={[required]}
                >
                  <Input type="number" step="0.01" disabled={!GUARDAR} />
                </Form.Item>
              </Col>
            </Row>

            <span>Jornalizados</span>
            <Row gutter={20}>
              <Col span="12">
                <Form.Item
                  label="Horas por mes"
                  name="jornalizados_dia"
                  rules={[required]}
                >
                  <Input type="number" disabled={!GUARDAR} />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="Horas por día"
                  name="jornalizados_mes"
                  rules={[required]}
                >
                  <Input type="number" step="0.01" disabled={!GUARDAR} />
                </Form.Item>
              </Col>
            </Row>

            <div className="form-actions">
              <Link to="/parametros/generales/convenios">
                <Button type="secondary">Volver</Button>
              </Link>
              {GUARDAR && (
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={state.loading}
                >
                  Guardar
                </Button>
              )}
            </div>
          </div>

          {state.id > 0 && (
            <React.Fragment>
              <Clases />
              <Conceptos />
            </React.Fragment>
          )}
        </Form>
      </ContextProvider>
    </div>
  );
};

export default ConveniosForm;
