/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
// import MaskedInput from "antd-mask-input";
import { FiPaperclip } from "react-icons/fi";
import locale from "antd/es/date-picker/locale/es_ES";
import "moment/locale/es";

import { Context } from "./../../../services/context";
import { parseDate, parseToDate } from "./../../../services/forms";
import http from "./../../../services/http";
import { showSuccess } from "./../../../services/notificacion";
import { required } from "./../../../services/validation";

import Archivos from "./Archivos";
import InputMasked from "./../../../components/InputMasked";

const { Option } = Select;

const FamiliaresForm = ({ current, onCurrentChange }) => {
  const [form] = Form.useForm();
  const [state, setState] = useContext(Context);
  const [prenatal, setPrenatal] = useState(false);
  const [incapacitado, setIncapacitado] = useState(false);

  const onBack = () => {
    onCurrentChange(null);
  };

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    values.empleadoId = state.id;
    values.fechaNacimiento = parseDate(values.fechaNacimiento);
    values.vencimientoIncapacidad = parseDate(values.vencimientoIncapacidad);
    values.fechaAlta = parseDate(values.fechaAlta);
    values.fechaBaja = parseDate(values.fechaBaja);
    values.fechaBajaIG = parseDate(values.fechaBajaIG);
    values.escolaridadPresentacion = parseDate(values.escolaridadPresentacion);
    values.escolaridadVencimiento = parseDate(values.escolaridadVencimiento);
    values.prenatalFUM = parseDate(values.prenatalFUM);
    values.prenatalFPP = parseDate(values.prenatalFPP);

    const rSave =
      current === 0
        ? await http.post("legajos/familiares", values)
        : await http.put(`legajos/familiares/${current}`, values);

    if (rSave && rSave.data.code === 0) {
      showSuccess("El familiar fue guardado exitosamente.");
      setState({ ...state, loading: false });
      onCurrentChange(null);
    } else {
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {
    async function getForm() {
      form.resetFields();

      const rForm = await http.get(`legajos/familiares/${current}`);
      if (rForm && rForm.data.code === 0) {
        const data = rForm.data.data;
        data.form.fechaNacimiento = parseToDate(data.form.fechaNacimiento);
        data.form.vencimientoIncapacidad = parseToDate(
          data.form.vencimientoIncapacidad
        );
        if (data.form.vencimientoIncapacidad) {
          setIncapacitado(true);
        }
        data.form.fechaAlta = parseToDate(data.form.fechaAlta);
        data.form.fechaBaja = parseToDate(data.form.fechaBaja);
        data.form.fechaBajaIG = parseToDate(data.form.fechaBajaIG);
        data.form.escolaridadPresentacion = parseToDate(
          data.form.escolaridadPresentacion
        );
        data.form.escolaridadVencimiento = parseToDate(
          data.form.escolaridadVencimiento
        );
        data.form.prenatalFUM = parseToDate(data.form.prenatalFUM);
        data.form.prenatalFPP = parseToDate(data.form.prenatalFPP);
        form.setFieldsValue(data.form);

        setPrenatal(data.form.prenatal === "1");
      }
    }

    getForm();
  }, [state.id, state.reload]);

  const onPrenatalChange = (value) => {
    setPrenatal(value === "1");
    form.resetFields(["prenatalFUM", "prenatalFPP"]);
  };
  const onIncapacitadoChange = (value) => {
    setIncapacitado(value === "1");
    form.resetFields(["vencimientoIncapacidad"]);
  };

  return (
    <div className="familiares-form ">
      <Form
        form={form}
        className="subsection-form"
        layout="vertical"
        onFinish={onSave}
      >
        <Form.Item label="Nombre y Apellido" name="nombre" rules={[required]}>
          <Input />
        </Form.Item>

        <Row gutter={20}>
          <Col span="12">
            <Form.Item
              label="Tipo Documento"
              name="documentoTipo"
              rules={[required]}
            >
              <Select allowClear showSearch optionFilterProp="children">
                {state.documentos.map((x) => (
                  <Option key={x.value} value={x.value}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item label="Documento" name="documento" rules={[required]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span="12">
            <Form.Item label="CUIL" name="cuil">
              <InputMasked mask="99 99999999 9" />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item label="Fecha de Nacimiento" name="fechaNacimiento">
              <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span="12">
            <Form.Item label="Estado Civil" name="estadoCivil">
              <Select allowClear showSearch optionFilterProp="children">
                {state.estadosCiviles.map((x) => (
                  <Option key={x.value} value={x.value}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item label="Sexo" name="sexo">
              <Select allowClear showSearch optionFilterProp="children">
                {state.sexos.map((x) => (
                  <Option key={x.value} value={x.value}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span="12">
            <Form.Item label="Vínculo" name="vinculo" rules={[required]}>
              <Select allowClear showSearch optionFilterProp="children">
                {state.vinculos.map((x) => (
                  <Option key={x.value} value={x.value}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item label="Asignación" name="asignacion">
              <Select allowClear showSearch optionFilterProp="children">
                {state.familiarAsignaciones.map((x) => (
                  <Option key={x.value} value={x.value}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span="12">
            <Form.Item label="Incapacitado" name="incapacitado">
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                onChange={onIncapacitadoChange}
              >
                <Option value="0">No</Option>
                <Option value="1">Si</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item label="Fecha de Alta" name="fechaAlta">
              <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
            </Form.Item>
          </Col>
        </Row>
        {incapacitado && (
          <Row gutter={20}>
            <Col span="12">
              <Form.Item
                label="Fecha de vencimiento incapacidad"
                name="vencimientoIncapacidad"
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  placeholder=""
                  locale={locale}
                />
              </Form.Item>
            </Col>
          </Row>
        )}

        <Row gutter={20}>
          <Col span="12">
            <Form.Item label="Fecha de Baja" name="fechaBaja">
              <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item label="Motivo de Baja" name="motivoBaja">
              <Select allowClear showSearch optionFilterProp="children">
                {state.motivosBaja.map((x) => (
                  <Option key={x.value} value={x.value} disabled={x.disabled}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span="12">
            <Form.Item label="D.J.I.G" name="djig">
              <Select allowClear showSearch optionFilterProp="children">
                <Option value="0">No</Option>
                <Option value="1">Si</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item label="Fecha de Baja I.G" name="fechaBajaIG">
              <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span="8">
            <Form.Item label="Seguro Mutual" name="seguroMutual">
              <Select allowClear showSearch optionFilterProp="children">
                <Option value="0">No</Option>
                <Option value="1">Si</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item
              label="Tipo de Aporte Obra Social"
              name="aporteObraSocial"
            >
              <Select allowClear showSearch optionFilterProp="children">
                {state.familiarAporteObraSocial.map((x) => (
                  <Option key={x.value} value={x.value}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item label="Tipo Voluntario" name="tipoVoluntario">
              <Select allowClear showSearch optionFilterProp="children">
                {state.familiarTiposVoluntario.map((x) => (
                  <Option key={x.value} value={x.value}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span="8">
            <Form.Item label="Prenatal" name="prenatal">
              <Select allowClear onChange={onPrenatalChange}>
                <Option value="0">No</Option>
                <Option value="1">Si</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item
              label="FUM"
              name="prenatalFUM"
              rules={prenatal ? [required] : []}
            >
              <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item
              label="FPP"
              name="prenatalFPP"
              rules={prenatal ? [required] : []}
            >
              <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span="12">
            <Form.Item label="Escolaridad" name="escolaridad">
              <Select allowClear showSearch optionFilterProp="children">
                {state.escolaridad.map((x) => (
                  <Option key={x.value} value={x.value}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item label="Curso" name="escolaridadCurso">
              <Input type="number" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span="8">
            <Form.Item
              label="Certificado Escolar"
              name="escolaridadCertificado"
            >
              <Select allowClear showSearch optionFilterProp="children">
                <Option value="0">No</Option>
                <Option value="1">Si</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item
              label="Fecha de Presentación"
              name="escolaridadPresentacion"
            >
              <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item
              label="Fecha de Vencimiento"
              name="escolaridadVencimiento"
            >
              <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
            </Form.Item>
          </Col>
        </Row>

        <div className="form-actions">
          <Button type="secondary" onClick={onBack}>
            Volver
          </Button>
          <Button type="primary" htmlType="submit" loading={state.loading}>
            Guardar
          </Button>
        </div>
      </Form>

      {current > 0 && (
        <React.Fragment>
          <hr />
          <div className="section-header">
            <div className="section-title">
              Documentación
              <FiPaperclip />
            </div>
            <div className="section-subtitle">
              Documentación asociada con el familiar.
            </div>
          </div>
          <Archivos seccion="5" familiar={current} />
        </React.Fragment>
      )}
    </div>
  );
};

export default FamiliaresForm;
