/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  Pagination,
  Row,
  Select,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  FiCheckCircle,
  FiChevronDown,
  FiChevronUp,
  FiDownload,
  FiFolder,
  FiMoreVertical,
  FiRepeat,
  FiSearch,
  FiTrash2,
} from "react-icons/fi";
import { SlideDown } from "react-slidedown";
import fileDownload from "js-file-download";
import { SiMicrosoftexcel } from "react-icons/si";

import http from "../../services/http";
import { showSuccess } from "./../../services/notificacion";
import { hasAction, actions } from "../../services/security";

import Header from "../../components/Header";
import LiquidacionHistorial from "./LiquidacionHistorial";
import LiquidacionLegajo from "./LiquidacionLegajo";

import "react-slidedown/lib/slidedown.css";
import Historial from "../../components/Historial";

const LiquidacionDetail = ({ match }) => {
  const [form] = Form.useForm();

  const VER = hasAction(actions.LIQUIDACION_VER);
  const RECALCULAR = hasAction(actions.LIQUIDACION_RECALCULAR);
  const ELIMINAR = hasAction(actions.LIQUIDACION_EMPLEADO_ELIMINAR);

  const [state, setState] = useState({
    id: match.params.id === "nuevo" ? 0 : parseInt(match.params.id),
    loading: false,
    historial: [],
    page: 1,
    reload: false,
  });
  const [historialVisible, setHistorialVisible] = useState(false);
  const [criterios, setCriterios] = useState([]);
  const [estados, setEstados] = useState([]);
  const [tipos, setTipos] = useState([]);

  const [estado, setEstado] = useState();
  const [empleados, setEmpleados] = useState([]);
  const [empleadosLoading, setEmpleadosLoading] = useState(false);
  const [empleadosVisible, setEmpleadosVisible] = useState(false);
  const [empleadosFilter, setEmpleadosFilter] = useState("");

  const [preview, setPreview] = useState();

  const breadcrumb = [
    { id: 1, title: "Liquidación" },
    { id: 2, title: "Liquidaciones", url: "/liquidaciones/" },
    { id: 3, title: "Ver" },
  ];

  const menu = (item) => {
    return (
      <Menu className="table-actions-menu">
        <Menu.Item key="0" onClick={() => onPreview(item)}>
          <FiSearch />
          Ver
        </Menu.Item>
        {RECALCULAR && estado !== "3" && (
          <Menu.Item key="1" onClick={() => onRecalculate(item)}>
            <FiRepeat />
            Recalcular
          </Menu.Item>
        )}
        <Menu.Item key="2" onClick={() => onExport(item)}>
          <SiMicrosoftexcel />
          Exportar
        </Menu.Item>
        <Menu.Item key="3" onClick={() => onDowload(item)}>
          <FiDownload />
          Descargar
        </Menu.Item>
        {ELIMINAR && estado !== "3" && (
          <Menu.Item key="4" onClick={() => onDelete(item)}>
            <FiTrash2 />
            Eliminar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  useEffect(() => {
    async function getForm() {
      const response = await http.get("liquidaciones/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setCriterios(data.criterios);
        setEstados(data.estados);
        setTipos(data.tipos);
      }

      const rForm = await http.get(`liquidaciones/${state.id}`);
      if (rForm && rForm.data.code === 0) {
        const data = rForm.data.data;
        form.setFieldsValue(data);
        setState({
          ...state,
          historial: data.historial,
        });
        setEstado(data.estado);
      }

      setEmpleadosLoading(true);
      const rEmpleados = await http.get(`liquidaciones/${state.id}/empleados`);
      if (rEmpleados && rEmpleados.data.code === 0) {
        const data = rEmpleados.data.data;
        setEmpleados(data.empleados);
        setEmpleadosLoading(false);
      }
    }

    if (VER) {
      getForm();
    }
  }, [state.reload]);

  const onPreview = (item) => {
    setPreview(item);
  };

  const onRecalculate = (item) => {
    Modal.confirm({
      title: "Confirmar acción",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea recalcular esta liquidación?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const rRecalculate = await http.put(
          `liquidaciones/empleados/${item.id}/recalculate`
        );
        if (rRecalculate && rRecalculate.data.code === 0) {
          showSuccess("La liquidación fue recalculada exitosamente.");
        }
      },
    });
  };

  const onDowload = async (item) => {
    const rDownload = await http.get(
      `liquidaciones/empleados/${item.id}/print`,
      true
    );

    fileDownload(rDownload.data, `BonoSueldo_${Date.now()}.pdf`);
  };

  const onExport = async (item) => {
    const rExport = await http.get(
      `liquidaciones/empleados/${item.id}/export`,
      true
    );

    fileDownload(rExport.data, `Liquidacion_${item.legajo}.xlsx`);
  };

  const onDelete = async (item) => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content:
        "¿Esta seguro que desea eliminar a este empleado de la liquidación?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const rDelete = await http.delete(`liquidaciones/empleados/${item.id}`);
        if (rDelete && rDelete.data.code === 0) {
          showSuccess("El empleado fue eliminado exitosamente.");
          setState({ ...state, reload: !state.reload });
        }
      },
    });
  };

  const onFilterChange = (e) => {
    setEmpleadosFilter(e.currentTarget.value);
  };

  const getFilteredEmpleados = () => {
    return empleados.filter(
      (x) =>
        empleadosFilter === "" ||
        x.legajo.toString().indexOf(empleadosFilter) > -1 ||
        x.nombre.toLowerCase().indexOf(empleadosFilter.toLowerCase()) > -1
    );
  };

  const renderEmpleadosRows = () => {
    return getFilteredEmpleados()
      .slice(state.page * 10 - 10, state.page * 10)
      .map((x) => (
        <tr key={x.id}>
          <td className="table-actions">
            <Dropdown
              key={x.id}
              overlay={() => menu(x)}
              trigger={["click"]}
              placement="bottomLeft"
              arrow
            >
              <div>
                <FiMoreVertical />
              </div>
            </Dropdown>
          </td>
          <td style={{ textAlign: "center", width: "30px" }}>{x.legajo}</td>
          <td>{x.nombre}</td>
          <td style={{ textAlign: "center" }}>{x.haberesSujetosTexto}</td>
          <td style={{ textAlign: "center" }}>{x.haberesNoSujetosTexto}</td>
          <td style={{ textAlign: "center" }}>{x.asignacionesTexto}</td>
          <td style={{ textAlign: "center" }}>{x.retencionesTexto}</td>
          <td style={{ textAlign: "center" }}>{x.contribucionPatronalTexto}</td>
          <td style={{ textAlign: "center" }}>{x.netoTexto}</td>
        </tr>
      ));
  };

  const renderEmpleadosTotals = () => {
    const nEmpleados = getFilteredEmpleados();

    let haberesSujetos = 0;
    let haberesNoSujetos = 0;
    let asignaciones = 0;
    let retenciones = 0;
    let contribucionPatronal = 0;
    let neto = 0;

    for (let i = 0; i < nEmpleados.length; i++) {
      const item = nEmpleados[i];
      haberesSujetos += item.haberesSujetos;
      haberesNoSujetos += item.haberesNoSujetos;
      asignaciones += item.asignaciones;
      retenciones += item.retenciones;
      contribucionPatronal += item.contribucionPatronal;
      neto += item.neto;
    }

    var formatter = new Intl.NumberFormat("es-AR", {
      style: "currency",
      currency: "ARS",
    });

    haberesSujetos = formatter.format(haberesSujetos);
    haberesNoSujetos = formatter.format(haberesNoSujetos);
    asignaciones = formatter.format(asignaciones);
    retenciones = formatter.format(retenciones);
    contribucionPatronal = formatter.format(contribucionPatronal);
    neto = formatter.format(neto);

    return (
      <tr>
        <td></td>
        <td></td>
        <td style={{ fontWeight: "500" }}>Totales: </td>
        <td style={{ textAlign: "center", fontWeight: "500" }}>
          {haberesSujetos}
        </td>
        <td style={{ textAlign: "center", fontWeight: "500" }}>
          {haberesNoSujetos}
        </td>
        <td style={{ textAlign: "center", fontWeight: "500" }}>
          {asignaciones}
        </td>
        <td style={{ textAlign: "center", fontWeight: "500" }}>
          {retenciones}
        </td>
        <td style={{ textAlign: "center", fontWeight: "500" }}>
          {contribucionPatronal}
        </td>
        <td style={{ textAlign: "center", fontWeight: "500" }}>{neto}</td>
      </tr>
    );
  };

  const renderEmpleadosFooter = () => {
    return (
      <React.Fragment>
        <div className="count">
          Mostrando {state.page * 10 - 10 + 1} - {state.page * 10} de{" "}
          {getFilteredEmpleados().length} resultados
        </div>
        <Pagination
          defaultPageSize={10}
          current={state.page}
          total={getFilteredEmpleados().length}
          showSizeChanger={false}
          onChange={(value) => setState({ ...state, page: value })}
        />
      </React.Fragment>
    );
  };

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="liquidacion">
      <Header title={`Ver Liquidación`} breadcrumb={breadcrumb} />

      <Form form={form} layout="vertical">
        <div className="form card">
          <div className="form-icon">
            <FiCheckCircle />
          </div>

          <Row gutter={20}>
            <Col span={12}>
              <Form.Item label="Fecha" name="fecha">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Tipo" name="tipo">
                <Select disabled={true}>
                  {tipos.map((x) => (
                    <Select.Option key={x.value} value={x.value}>
                      {x.text}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Mes" name="mes">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Año" name="anio">
                <Input disabled={true} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Filtro" name="criterio">
                <Select disabled={true}>
                  {criterios.map((x) => (
                    <Select.Option key={x.value} value={x.value}>
                      {x.text}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Valor" name="filtro">
                <Input disabled={true} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Historial
                visible={historialVisible}
                modalContent={<LiquidacionHistorial items={state.historial} />}
                onOpen={() => setHistorialVisible(true)}
                onClose={() => setHistorialVisible(false)}
              >
                <Form.Item label="Estado" name="estado">
                  <Select disabled={true}>
                    {estados.map((x) => (
                      <Select.Option key={x.value} value={x.value}>
                        {x.text}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Historial>
            </Col>
          </Row>

          <div className="form-actions">
            <Link to="/liquidaciones">
              <Button type="secondary">Volver</Button>
            </Link>
          </div>
        </div>

        <div className="section card">
          <div
            className="section-header"
            onClick={() => setEmpleadosVisible(!empleadosVisible)}
          >
            <div className="icon">
              <FiFolder />
            </div>
            <div className="content">
              <div className="title">Legajos</div>
              <div className="subtitle">
                {empleadosLoading
                  ? "Cargando..."
                  : `${empleados.length} elementos`}
              </div>
            </div>
            <div className="collapse">
              {empleadosVisible ? <FiChevronUp /> : <FiChevronDown />}
            </div>
          </div>
          <SlideDown closed={!empleadosVisible}>
            <div className="section-content">
              <table className="table-small">
                <thead>
                  <tr>
                    <th></th>
                    <th>#</th>
                    <th style={{ width: "250px" }}>Empleado</th>
                    <th style={{ textAlign: "center", width: "150px" }}>
                      Haberes Sujetos
                    </th>
                    <th style={{ textAlign: "center", width: "150px" }}>
                      Haberes No Sujetos
                    </th>
                    <th style={{ textAlign: "center", width: "150px" }}>
                      Asignaciones Familiares
                    </th>
                    <th style={{ textAlign: "center", width: "120px" }}>
                      Retenciones
                    </th>
                    <th style={{ textAlign: "center", width: "150px" }}>
                      Contribución Patronal
                    </th>
                    <th style={{ textAlign: "center", width: "150px" }}>
                      Neto a Pagar
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                    <td colSpan={8}>
                      <Input
                        placeholder="Buscar..."
                        onChange={onFilterChange}
                      />
                    </td>
                  </tr>
                  {renderEmpleadosRows()}
                  {renderEmpleadosTotals()}
                </tbody>
              </table>
              <div className="table-footer">{renderEmpleadosFooter()}</div>
            </div>
          </SlideDown>
        </div>

        <Modal
          className="liquidacion-empleado"
          title={`${preview?.legajo} - ${preview?.nombre}`}
          visible={preview}
          onCancel={() => setPreview(undefined)}
          footer={null}
          maskClosable={false}
          keyboard={false}
          destroyOnClose={true}
          width={1100}
        >
          <LiquidacionLegajo item={preview} />
        </Modal>
      </Form>
    </div>
  );
};

export default LiquidacionDetail;
