/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Button, Dropdown, Menu, Modal } from "antd";
import { FiEdit, FiMoreVertical, FiTrash2 } from "react-icons/fi";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { Context } from "./../../../services/context";
import http from "./../../../services/http";
import { showSuccess } from "./../../../services/notificacion";
import { hasAction, actions } from "./../../../services/security";

const FamiliaresList = ({ onCurrentChange }) => {
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useContext(Context);

  const CREAR = state.editing && hasAction(actions.LEGAJOS_FAMILIARES_CREAR);
  const EDITAR = state.editing && hasAction(actions.LEGAJOS_FAMILIARES_EDITAR);
  const ELIMINAR =
    state.editing && hasAction(actions.LEGAJOS_FAMILIARES_ELIMINAR);

  const [list, setList] = useState([]);

  const menu = (item) => {
    return (
      <Menu className="table-actions-menu">
        {EDITAR && (
          <Menu.Item key="0" onClick={() => onEdit(item)}>
            <FiEdit />
            Editar
          </Menu.Item>
        )}
        {ELIMINAR && (
          <Menu.Item key="1" onClick={() => onDelete(item)}>
            <FiTrash2 />
            Eliminar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const onAdd = () => {
    onCurrentChange(0);
  };

  const onEdit = (item) => {
    onCurrentChange(item.id);
  };

  const onDelete = (item) => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea eliminar este familiar?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const rDelete = await http.delete(`legajos/familiares/${item.id}`);

        if (rDelete && rDelete.data.code === 0) {
          showSuccess("El familiar se elimino exitosamente.");
        }
      },
    });
  };

  useEffect(() => {
    async function getList() {
      const rList = await http.get(
        `legajos/familiares/list?empleado=${state.id}`
      );

      if (rList && rList.data.code === 0) {
        const data = rList.data.data;
        setList(data);
      }
    }

    getList();
  }, [state.reload]);

  return (
    <div className="familiares-list">
      <div className="table-wrapper">
        <table className="subsection-table">
          <thead>
            <tr>
              <th></th>
              <th>Vínculo</th>
              <th>Nombre</th>
              <th style={{ textAlign: "center" }}>Incapacitado</th>
              <th style={{ textAlign: "center" }}>Documento</th>
              <th style={{ textAlign: "center" }}>Sexo</th>
              <th style={{ textAlign: "center" }}>Edad</th>
              <th style={{ textAlign: "center" }}>Asignación</th>
              <th style={{ textAlign: "center" }}>Escolaridad</th>
              <th style={{ textAlign: "center" }}>Seguro Mutual</th>
              <th style={{ textAlign: "center" }}>Prenatal</th>
            </tr>
          </thead>
          <tbody>
            {list.length === 0 && (
              <tr>
                <td className="table-empty" colSpan="9">
                  Sin resultados
                </td>
              </tr>
            )}

            {list.map((x) => (
              <tr key={x.id}>
                <td className="table-actions">
                  <Dropdown
                    key={x.id}
                    overlay={() => menu(x)}
                    trigger={["click"]}
                    placement="bottomLeft"
                    arrow
                  >
                    <div>
                      <FiMoreVertical />
                    </div>
                  </Dropdown>
                </td>
                <td>{x.vinculo}</td>
                <td>{x.nombre}</td>
                <td style={{ textAlign: "center" }}>{x.incapacitado}</td>
                <td style={{ textAlign: "center" }}>{x.documento}</td>
                <td style={{ textAlign: "center" }}>{x.sexo}</td>
                <td style={{ textAlign: "center" }}>{x.edad}</td>
                <td style={{ textAlign: "center" }}>{x.asignacion}</td>
                <td style={{ textAlign: "center" }}>{x.escolaridad}</td>
                <td style={{ textAlign: "center" }}>{x.seguroMutual}</td>
                <td style={{ textAlign: "center" }}>{x.prenatal}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {CREAR && (
        <div className="table-footer-actions">
          <Button type="primary" onClick={onAdd}>
            Agregar
          </Button>
        </div>
      )}
    </div>
  );
};

export default FamiliaresList;
