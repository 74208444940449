/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { FiCheckSquare } from "react-icons/fi";

import EvaluacionesList from "./EvaluacionesList";
import EvaluacionesForm from "./EvaluacionesForm";

const Evaluaciones = () => {
  const [current, setCurrent] = useState(null);

  return (
    <div className="evaluaciones">
      <div className="section-header">
        <div className="section-title">
          Evaluaciones de Desempeño
          <FiCheckSquare />
        </div>
        <div className="section-subtitle">
          Lista de evaluaciones de desempeño.
        </div>
      </div>

      {current === null && <EvaluacionesList onCurrentChange={setCurrent} />}
      {current !== null && (
        <EvaluacionesForm current={current} onCurrentChange={setCurrent} />
      )}
    </div>
  );
};

export default Evaluaciones;
