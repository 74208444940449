/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Calendar,
  Col,
  ConfigProvider,
  DatePicker,
  Form,
  Row,
  Select,
} from 'antd';
import esES from 'antd/lib/locale/es_ES';
import moment from 'moment';
import 'moment/locale/es';

import http from '../../../../services/http';
import { hasAction, actions } from '../../../../services/security';

import Header from '../../../../components/Header';

const Asistencia = () => {
  const VER = hasAction(actions.REPORTE_LEGAJO_ASISTENCIA);

  const [empleado, setEmpleado] = useState();
  const [mes, setMes] = useState((moment().month() + 1).toString());
  const [anio, setAnio] = useState(moment().year().toString());

  const [empleados, setEmpleados] = useState([]);
  const [list, setList] = useState([]);
  const [value, setValue] = useState();

  moment.updateLocale('es', {
    weekdaysMin: [
      'Domingo',
      'Lunes',
      'Martes',
      'Miércoles',
      'Jueves',
      'Viernes',
      'Sábado',
    ],
  });

  const breadcrumb = [
    {
      id: 1,
      title: 'Reportes',
    },
    {
      id: 2,
      title: 'Legajos',
      url: '/reportes/legajo',
    },
    {
      id: 3,
      title: 'Asistencia',
      url: '/reportes/legajo/asistencia',
    },
  ];

  useEffect(() => {
    async function getData() {
      const url = 'reportes/legajo/asistencia/data';
      const response = await http.get(url);
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setEmpleados(data.empleados);
      }
    }

    getData();
  }, []);

  useEffect(() => {
    async function getEvents() {
      const url = 'reportes/legajo/asistencia';
      const data = {
        empleado: empleado,
        mes: mes,
        anio: anio,
      };
      const response = await http.post(url, data);
      if (response && response.data.code === 0) {
        setList(response.data.data.list);

        let nDate = moment().startOf('month');

        if (response.data.data.list[0]?.fecha) {
          nDate = moment(response.data.data.list[0]?.fecha, 'DD/MM/YY');
        }

        setValue(nDate);
      }
    }

    getEvents();
  }, [empleado, mes, anio]);

  const onChangeEmpleado = (value) => {
    setEmpleado(value ?? '');
  };

  const onChangeMes = (value) => {
    setMes(value ?? '');
  };

  const onChangeAnio = (value) => {
    setAnio(value?.year()?.toString() ?? '');
  };

  const renderHeader = () => {
    return (
      <Form layout="vertical" initialValues={{ mes: mes, anio: moment() }}>
        <Row gutter={[16, 24]}>
          <Col span={16}>
            <Form.Item label="Legajo" name="empleado">
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                onChange={onChangeEmpleado}
              >
                {empleados.map((empleado, index) => (
                  <Select.Option key={index} value={empleado.value}>
                    {empleado.text}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Mes" name="mes">
              <Select onChange={onChangeMes}>
                <Select.Option value="1">Enero</Select.Option>
                <Select.Option value="2">Febrero</Select.Option>
                <Select.Option value="3">Marzo</Select.Option>
                <Select.Option value="4">Abril</Select.Option>
                <Select.Option value="5">Mayo</Select.Option>
                <Select.Option value="6">Junio</Select.Option>
                <Select.Option value="7">Julio</Select.Option>
                <Select.Option value="8">Agosto</Select.Option>
                <Select.Option value="9">Septiembre</Select.Option>
                <Select.Option value="10">Octubre</Select.Option>
                <Select.Option value="11">Noviembre</Select.Option>
                <Select.Option value="12">Diciembre</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Año" name="anio">
              <DatePicker
                picker="year"
                placeholder=""
                onChange={onChangeAnio}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  const renderCell = (date) => {
    if (list.length === 0) {
      return;
    }

    let classes = 'calendar-day';
    let badge = '';

    const value = list.find((x) => x.fecha === date.format('DD/MM/YY'));
    if (!value) {
      return (
        <div className={classes}>
          <div>{date.date()}</div>
        </div>
      );
    }

    const dayOfWeek = date.weekday();

    if (value?.enfermedad) {
      classes += ' enfermedad';
      badge = 'Licencia por Enfermedad';
    } else if (value?.accidente) {
      classes += ' accidente';
      badge = 'Accidente de Trabajo';
    } else if (value?.especial) {
      classes += ' especial';
      badge = 'Licencia Especial';
    } else if (value?.vacaciones) {
      classes += ' vacaciones';
      badge = 'Licencia por Vacaciones';
    } else if (value?.asistencia) {
      classes += ' asistencia';
      badge = 'Asistencia';
    } else if (dayOfWeek === 5 || dayOfWeek === 6) {
      classes += ' weekend';
      badge = 'Fin de Semana';
    } else {
      classes += ' inasistencia';
      badge = 'Sin registro';
    }

    return (
      <div className={classes}>
        <div>{date.date()}</div>
        {badge && <div className="badge">{badge}</div>}
      </div>
    );
  };

  if (!VER) {
    return <Redirect to="/" />;
  }
  return (
    <ConfigProvider locale={esES}>
      <div className="asistencia page">
        <Header title="Asistencia" breadcrumb={breadcrumb} />
        <div className="card-calendar">
          <Calendar
            value={value}
            headerRender={renderHeader}
            dateFullCellRender={renderCell}
          />
        </div>
      </div>
    </ConfigProvider>
  );
};

export default Asistencia;
