/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Col, DatePicker, Form, Row, Select } from "antd";
import * as moment from "moment";
import "moment/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";

import { Context } from "./../../services/context";
import { required } from "./../../services/validation";

const LiquidacionPeriodo = () => {
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useContext(Context);

  useEffect(() => {
    const mes = state.values.mes ?? (new Date().getMonth() + 1).toString();
    const anio = state.values.anio ?? moment();
    state.form.setFieldsValue({ mes: mes, anio: anio });
  }, []);

  return (
    <div className="form-section">
      <Row gutter={20}>
        <Col span={12}>
          <Form.Item label="Mes" name="mes" rules={[required]}>
            <Select allowClear>
              <Select.Option value="1">Enero</Select.Option>
              <Select.Option value="2">Febrero</Select.Option>
              <Select.Option value="3">Marzo</Select.Option>
              <Select.Option value="4">Abril</Select.Option>
              <Select.Option value="5">Mayo</Select.Option>
              <Select.Option value="6">Junio</Select.Option>
              <Select.Option value="7">Julio</Select.Option>
              <Select.Option value="8">Agosto</Select.Option>
              <Select.Option value="9">Septiembre</Select.Option>
              <Select.Option value="10">Octubre</Select.Option>
              <Select.Option value="11">Noviembre</Select.Option>
              <Select.Option value="12">Diciembre</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Año" name="anio" rules={[required]}>
            <DatePicker picker="year" placeholder="" locale={locale} />
          </Form.Item>
        </Col>
      </Row>

      <div className="actions">
        <Link to="/liquidaciones">
          <Button type="secondary">Volver</Button>
        </Link>
        <Button type="primary" htmlType="submit" loading={state.loading}>
          Siguiente
        </Button>
      </div>
    </div>
  );
};

export default LiquidacionPeriodo;
