/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useContext, useEffect, useState } from 'react';
import { Button, Pagination, Spin, Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { FiAlertTriangle } from 'react-icons/fi';

import { Context } from './../../services/context';
import { parseYear } from '../../services/forms';
import http from '../../services/http';

import sandClock from './../../assets/images/sand-clock.svg';

import './LiquidacionPreview.scss';

const LiquidacionPreview = () => {
  const [state, setState] = useContext(Context);
  const [empleados, setEmpleados] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState();

  useEffect(() => {
    async function getPreview() {
      setLoading(true);

      const rPreview = await http.post('liquidaciones/preview', {
        tipo: state.values.tipo,
        criterio: state.values.criterio,
        filtro: state.values.filtro,
        mes: state.values.mes,
        anio: parseYear(state.values.anio),
      });

      if (rPreview && rPreview.data.code === 0) {
        const data = rPreview.data.data;
        setEmpleados(data.empleados);
      }

      setLoading(false);
    }

    getPreview();
  }, []);

  const onBackClick = () => {
    setState({ ...state, step: state.step - 1 });
  };

  return (
    <div className="form-section table-section">
      {loading && (
        <div className="loading">
          <Spin
            indicator={
              <LoadingOutlined
                style={{ fontSize: 40, color: '#04bebe' }}
                spin
              />
            }
          />
          Cargando...
        </div>
      )}

      {!loading && (
        <Fragment>
          <table className="table-small">
            <thead>
              <tr>
                <th style={{ textAlign: 'center' }}>Legajo</th>
                <th>Nombre</th>
                <th>Convenio</th>
                <th>Dirección</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {empleados.length === 0 && (
                <tr>
                  <td className="table-empty" colSpan="4">
                    Sin resultados
                  </td>
                </tr>
              )}

              {empleados.slice(page * 10 - 10, page * 10).map((x) => (
                <tr key={x.id} className={`${x.repetido && 'warning'}`}>
                  <td style={{ textAlign: 'center', minWidth: '10%' }}>
                    {x.id}
                  </td>
                  <td style={{ minWidth: '30%' }}>{x.nombre}</td>
                  <td style={{ minWidth: '30%' }}>{x.convenio}</td>
                  <td style={{ minWidth: '25%' }}>{x.direccion}</td>
                  <td>
                    {x.repetido && (
                      <Tooltip
                        placement="top"
                        title="Este empleado ya fue incluido en una liquidación de ese tipo para este mes."
                      >
                        <div>
                          <FiAlertTriangle />
                          <span style={{ marginLeft: '5px' }}>LIQUIDADO</span>
                        </div>
                      </Tooltip>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="table-footer">
            <div className="count">
              Mostrando {page * 10 - 10 + 1} - {page * 10} de {empleados.length}{' '}
              resultados
            </div>
            <Pagination
              defaultPageSize={10}
              current={page}
              total={empleados.length}
              showSizeChanger={false}
              onChange={(value) => setPage(value)}
            />
          </div>
        </Fragment>
      )}

      {state.calculating && (
        <div className="calculating">
          <img src={sandClock} alt="Calculando" />
          Liquidando...
        </div>
      )}

      <div className="actions">
        <Button type="secondary" onClick={onBackClick}>
          Anterior
        </Button>
        <Button type="primary" htmlType="submit" loading={state.calculating}>
          Finalizar
        </Button>
      </div>
    </div>
  );
};

export default LiquidacionPreview;
