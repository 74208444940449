/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, DatePicker, Form, Row, Select } from "antd";
import locale from "antd/es/date-picker/locale/es_ES";
import "moment/locale/es";

import { Context } from "../../../services/context";
import { parseDate, parseToDate } from "../../../services/forms";
import http from "../../../services/http";
import { showSuccess } from "../../../services/notificacion";
import { required } from "../../../services/validation";


const { Option } = Select;

const FamiliaresPrenatalForm = ({ onCurrentChange }) => {
  const [form] = Form.useForm();
  const [state, setState] = useContext(Context);
  const [prenatal, setPrenatal] = useState(false);

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    values.empleadoId = state.id;
    values.prenatalFUM = parseDate(values.prenatalFUM);
    values.prenatalFPP = parseDate(values.prenatalFPP);

    const rSave =
    state.id === 0
        ? await http.post("legajos/familiaresPrenatal", values)
        : await http.put(`legajos/familiaresPrenatal/${state.id}`, values);

    if (rSave && rSave.data.code === 0) {
      showSuccess("El dato fue guardado exitosamente.");
      setState({ ...state, loading: false });
      onCurrentChange(null);
    } else {
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {
    async function getForm() {
      form.resetFields();

      const rForm = await http.get(`legajos/familiaresPrenatal/${state.id}`);
      if (rForm && rForm.data.code === 0) {
        const data = rForm.data.data;
        data.form.prenatalFUM = parseToDate(data.form.prenatalFUM);
        data.form.prenatalFPP = parseToDate(data.form.prenatalFPP);
        form.setFieldsValue(data.form);

        setPrenatal(data.form.prenatal === "1");
      }
    }

    getForm();
  }, [state.id, state.reload]);

  const onPrenatalChange = (value) => {
    setPrenatal(value === "1");
    form.resetFields(["prenatalFUM", "prenatalFPP"]);
  };

  return (
    <div className="familiares-form ">
      <Form
        form={form}
        className="subsection-form"
        layout="vertical"
        onFinish={onSave}
      >

        <Row gutter={20}>
          <Col span="8">
            <Form.Item label="Prenatal" name="prenatal">
              <Select allowClear onChange={onPrenatalChange}>
                <Option value="0">No</Option>
                <Option value="1">Si</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item
              label="FUM"
              name="prenatalFUM"
              rules={prenatal ? [required] : []}
            >
              <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} disabled={!prenatal}/>
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item
              label="FPP"
              name="prenatalFPP"
              rules={prenatal ? [required] : []}
            >
              <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} disabled={!prenatal}/>
            </Form.Item>
          </Col>
        </Row>

        <div className="form-actions">
          <Button type="primary" htmlType="submit" loading={state.loading}>
            Guardar
          </Button>
        </div>
      </Form>


    </div>
  );
};

export default FamiliaresPrenatalForm;
