/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Form, Input, Button, Select } from "antd";
import { FiGrid } from "react-icons/fi";

import { Context } from "./../../../../services/context";
import http from "./../../../../services/http";
import { required } from "./../../../../services/validation";

const { Option } = Select;

const ConceptosForm = ({ item, onSave, onClose }) => {
  const [form] = Form.useForm();

  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useContext(Context);
  const [loading, setLoading] = useState(false);

  const [conceptos, setConceptos] = useState([]);
  const [liquidaciones, setLiquidaciones] = useState([]);
  const [operandos, setOperandos] = useState([]);
  const [partidasPresupuestarias, setPartidasPresupuestarias] = useState([]);

  useEffect(() => {
    async function getData() {
      const result = await http.get(
        `convenios/conceptos/data?convenio=${state.id}`
      );

      if (result && result.data.code === 0) {
        const data = result.data.data;
        setConceptos(data.conceptos);
        setLiquidaciones(data.liquidaciones);
        setOperandos(data.operandos);
        setPartidasPresupuestarias(data.partidasPresupuestarias);
      }

      form.setFieldsValue(item);
    }

    getData();
  }, []);

  const onConceptoChange = (value) => {
    const item = conceptos.filter((x) => x.value === value)[0];
    form.setFieldsValue({ secuencia: item.extra });
  };

  const onSubmit = async (values) => {
    setLoading(true);
    values.convenioId = state.id;

    const rSave =
      item.id === undefined
        ? await http.post("convenios/conceptos", values)
        : await http.put(`convenios/conceptos/${item.id}`, values);

    setLoading(false);

    if (rSave && rSave.data.code === 0) {
      form.resetFields();
      onSave();
      onClose();
    }
  };

  return (
    <Form
      form={form}
      name="form-concepto"
      layout="vertical"
      onFinish={onSubmit}
    >
      <div className="form">
        <div className="form-icon">
          <FiGrid />
        </div>

        <Form.Item label="Concepto" name="conceptoId" rules={[required]}>
          <Select
            allowClear
            showSearch
            optionFilterProp="children"
            onChange={onConceptoChange}
          >
            {conceptos.map((x) => (
              <Option key={x.value} value={x.value} disabled={x.disabled}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Liquidación" name="liquidaciones">
          <Select
            mode="multiple"
            allowClear
            showSearch
            optionFilterProp="children"
          >
            {liquidaciones.map((x) => (
              <Option key={x.value} value={x.value} disabled={x.disabled}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Cantidad" name="operando">
          <Select allowClear showSearch optionFilterProp="children">
            {operandos.map((x) => (
              <Option key={x.id} value={x.id.toString()}>
                {x.token} - {x.descripcion}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Secuencia" name="secuencia">
          <Input type="number" />
        </Form.Item>

        <Form.Item label="Partida Presupuestaria" name="partidaPresupuestaria">
          <Select allowClear showSearch optionFilterProp="children">
            {partidasPresupuestarias.map((item, index) => (
              <Option key={index} value={item.value}>
                {item.text}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <div className="form-actions">
          <Button type="secondary" onClick={onClose}>
            Volver
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            Guardar
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default ConceptosForm;
