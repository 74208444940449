/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { Button, Form, Input, Select } from "antd";

import { Context } from "./../../../../services/context";
import http from "./../../../../services/http";
import { required } from "../../../../services/validation";

const CalcularForm = ({ conceptos, item, onClose }) => {
  const [form] = Form.useForm();
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [empleados, setEmpleados] = useState([]);
  const [tiposLiquidaciones, setTiposLiquidaciones] = useState([]);

  useEffect(() => {
    async function getData() {
      const response = await http.get("convenios/formulas/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setEmpleados(data.empleados);
      }
    }

    let list = [];
    item.liquidaciones.forEach((l, index) => {
      list.push({ value: l, text: item.liquidacionesTexto.split(",")[index] });
      setTiposLiquidaciones(list);
    });

    getData();
  }, []);

  useEffect(() => {
    form.resetFields();
    if (item) {
      form.setFieldsValue({ concepto: item.id });
    }
  }, [item]);

  const onFieldChange = () => {
    form.setFieldsValue({ resultado: "" });
  };

  const onFormSubmit = async (values) => {
    setLoading(true);

    const rSave = await http.post(`convenios/formulas`, values);

    if (rSave && rSave.data.code === 0) {
      const data = rSave.data.data;
      form.setFieldsValue({ resultado: data.resultado });
    }

    setLoading(false);
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFormSubmit}>
      <Form.Item label="Concepto" name="concepto" rules={[required]}>
        <Select
          allowClear
          showSearch
          optionFilterProp="children"
          onChange={onFieldChange}
        >
          {conceptos.map((x) => (
            <Select.Option key={x.id} value={x.id}>
              {x.concepto}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="Tipo Liquidacion"
        name="tipoLiquidacion"
        rules={[required]}
      >
        <Select
          allowClear
          showSearch
          optionFilterProp="children"
          onChange={onFieldChange}
        >
          {tiposLiquidaciones.map((x) => (
            <Select.Option key={x.value} value={x.value}>
              {x.text}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Legajo" name="legajo" required>
        <Select
          allowClear
          showSearch
          optionFilterProp="children"
          onChange={onFieldChange}
        >
          {empleados
            .filter((x) => !x.disabled)
            .map((x) => (
              <Select.Option key={x.value} value={x.value}>
                {x.text}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="Resultado" name="resultado" required>
        <Input readOnly />
      </Form.Item>

      <div className="modal-actions">
        <Button type="secondary" onClick={onClose}>
          Cerrar
        </Button>
        <Button type="primary" htmlType="submit" loading={loading}>
          Calcular
        </Button>
      </div>
    </Form>
  );
};

export default CalcularForm;
