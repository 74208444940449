/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Form, Input, Button, Select } from "antd";
import { FiList } from "react-icons/fi";

import { ContextProvider } from "./../../../../services/context";
import http from "./../../../../services/http";
import { showSuccess } from "./../../../../services/notificacion";
import { hasAction, actions } from "./../../../../services/security";
import { required } from "./../../../../services/validation";

import Header from "./../../../../components/Header";

const { Option } = Select;

const EscalafonForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const VER = hasAction(actions.ESCALAFON_VER);
  const CREAR = hasAction(actions.ESCALAFON_CREAR);
  const EDITAR = hasAction(actions.ESCALAFON_EDITAR);
  const GUARDAR = match.params.id === "nuevo" ? CREAR : EDITAR;

  const [state, setState] = useState({
    title: match.params.id === "nuevo" ? "Crear" : EDITAR ? "Editar" : "Ver",
    id: match.params.id === "nuevo" ? 0 : parseInt(match.params.id),
    loading: false,
    reload: false,
  });
  const [clases, setClases] = useState([]);

  const breadcrumb = [
    {
      id: 1,
      title: "Parámetros",
    },
    {
      id: 2,
      title: "Generales",
      url: "/parametros/generales",
    },
    {
      id: 3,
      title: "Escalafón",
      url: "/parametros/generales/escalafon",
    },
    { id: 4, title: state.title },
  ];

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    const rSave =
      state.id === 0
        ? await http.post("escalafon", values)
        : await http.put(`escalafon/${state.id}`, values);

    if (rSave && rSave.data.code === 0) {
      history.push("/parametros/generales/escalafon");
      showSuccess("El escalafón fue guardado exitosamente.");
    } else {
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {
    async function getForm() {
      const response = await http.get("escalafon/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setClases(data.clases);
      }

      const rForm = await http.get(`escalafon/${state.id}`);
      if (rForm && rForm.data.code === 0) {
        const data = rForm.data.data;
        setState({
          ...state,
          clases: data.escalafonClases,
        });
        form.setFieldsValue(data);
      }
    }

    if (VER) {
      getForm();
    }
  }, [state.id, state.reload]);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <ContextProvider value={[state, setState]}>
      <div className="escalafon">
        <Header title={`${state.title} Escalafón`} breadcrumb={breadcrumb} />
        <Form form={form} layout="vertical" onFinish={onSave}>
          <div className="form card">
            <div className="form-icon">
              <FiList />
            </div>

            <Form.Item label="Detalle" name="detalle" rules={[required]}>
              <Input disabled={!GUARDAR} />
            </Form.Item>

            <Form.Item label="Convenio" name="convenio" rules={[required]}>
              <Input type="number" disabled={!GUARDAR} />
            </Form.Item>

            <Form.Item label="Clases" name="clases">
              <Select mode="multiple" allowClear disabled={!GUARDAR}>
                {clases.map((x) => (
                  <Option key={x.value} value={x.value} disabled={x.disabled}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <div className="form-actions">
              <Link to="/parametros/generales/escalafon">
                <Button type="secondary">Volver</Button>
              </Link>
              {GUARDAR && (
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={state.loading}
                >
                  Guardar
                </Button>
              )}
            </div>
          </div>
        </Form>
      </div>
    </ContextProvider>
  );
};

export default EscalafonForm;
