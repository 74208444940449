/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Form, Input, Select, DatePicker, Row, Col, Modal } from "antd";

import locale from "antd/es/date-picker/locale/es_ES";
import moment from "moment";
import "moment/locale/es";

import http from "../../../../services/http";

import { parseToDate } from "../../../../services/forms";
import { required } from "../../../../services/validation";

import Historial from "../Historial";
import { ContextProvider } from "../../../../services/context";

const { TextArea } = Input;
const { Option } = Select;

const LicenciasForm = ({ dataForm, form, match }) => {
  const [conceptos, setConceptos] = useState([]);
  const [empleados, setEmpleados] = useState([]);
  const [codigos, setCodigos] = useState([]);
  const [estados, setEstados] = useState([]);
  const [historial, setHistorial] = useState(false);
  const [maxAnual, setMaxAnual] = useState("");
  const [maxMensual, setMaxMensual] = useState("");
  const [verEstado, setVerEstado] = useState(true);

  const [state, setState] = useState({
    id: match.params.id === "nuevo" ? 0 : parseInt(match.params.id),
    historial: [],
  });

  const onCodigoChange = (value) => {
    const item = codigos.find((x) => x.id === parseInt(value));
    const values = {
      concepto: item ? item.conceptoId : "",
    };
    if (item) {
      setMaxAnual(item.maximoAnual ?? "");
      setMaxMensual(item.maximoMensual ?? "");
      // calculateFechasWhitVal(item.maximoAnual ?? '', item.maximoMensual ?? '')
      getAcumulado(
        null,
        value,
        null,
        item.maximoAnual ?? "",
        item.maximoMensual ?? ""
      );
    }
    form.setFieldsValue(values);
    if (item && item.llevaEstado === "False") {
      setVerEstado(false);
    } else {
      form.setFieldsValue({ estado: "1" });
      setVerEstado(true);
    }
  };

  const onHistoryClick = () => {
    setHistorial(true);
  };

  const onFechaChange = async (value) => {
    if (value) {
      calculateFechas();
      getAcumulado(null, null, value);
    }
  };

  const onCantidadDiasChange = async () => {
    calculateFechas();
  };
  function addBusinessDays(originalDate, numDaysToAdd) {
    // const Sunday = 0;
    // const Saturday = 6;

    // let daysRemaining = numDaysToAdd;

    const newDate = originalDate.clone();
    newDate.add(numDaysToAdd, "days");

    // while (daysRemaining > 0) {
    //   newDate.add(1, 'days');
    //   if (newDate.day() !== Sunday && newDate.day() !== Saturday) {
    //     daysRemaining--;
    //   }
    // }

    return newDate;
  }

  async function calculateFechas() {
    let cantidad = parseFloat(form.getFieldValue("cantidadDias"));
    if (cantidad < 0) {
      cantidad = 0;
      form.setFieldsValue({ cantidadDias: cantidad });
      return;
    }
    const fecha = moment(form.getFieldValue("fechaInicio"));
    if (!fecha) {
      form.setFieldsValue({ fechaInicio: moment() });
    }
    const finMes = addBusinessDays(fecha, cantidad);

    let acumuladoAnual = parseInt(form.getFieldValue("acumuladoAnual"));

    if (cantidad) {
      if (maxAnual) {
        if (!acumuladoAnual) {
          acumuladoAnual = 0;
        }
        if (cantidad + acumuladoAnual > parseInt(maxAnual)) {
          cantidad = parseInt(maxAnual) - acumuladoAnual;
        }
      }
      if (maxMensual) {
        const cantMeses = finMes.month() - fecha.month() + 1;
        const daysToEnd = daysRemaining(fecha);
        let resto = 0;
        if (parseInt(maxMensual) >= daysToEnd) {
          resto = daysToEnd;
        } else {
          if (cantidad > parseInt(maxMensual)) {
            cantidad = parseInt(maxMensual);
          }
        }
        if (cantidad > parseInt(maxMensual) * cantMeses - resto) {
          cantidad =
            parseInt(maxMensual) + resto > cantidad
              ? cantidad
              : parseInt(maxMensual) + resto;
        }
      }
      form.setFieldsValue({ cantidadDias: cantidad });
      const fin = addBusinessDays(
        fecha,
        cantidad >= 1 ? cantidad - 1 : cantidad - 0.5
      );
      form.setFieldsValue({ fechaFin: fin });
    }
  }

  async function calculateFechasWhitVal(
    maximoAnual,
    maximoMensual,
    acumuladoAnual
  ) {
    let cantidad = parseFloat(form.getFieldValue("cantidadDias"));
    if (cantidad < 0) {
      cantidad = 0;
      form.setFieldsValue({ cantidadDias: cantidad });
      return;
    }
    const fecha = moment(form.getFieldValue("fechaInicio"));
    if (!fecha) {
      form.setFieldsValue({ fechaInicio: moment() });
    }
    const finMes = addBusinessDays(fecha, cantidad);

    if (!acumuladoAnual) {
      acumuladoAnual = parseInt(form.getFieldValue("acumuladoAnual"));
    }

    if (cantidad) {
      if (maximoAnual) {
        if (!acumuladoAnual) {
          acumuladoAnual = 0;
        }
        if (cantidad + acumuladoAnual > parseInt(maximoAnual)) {
          cantidad = parseInt(maximoAnual) - acumuladoAnual;
        }
      }
      if (maximoMensual) {
        const cantMeses = finMes.month() - fecha.month() + 1;
        const daysToEnd = daysRemaining(fecha);
        let resto = 0;
        if (parseInt(maximoMensual) >= daysToEnd) {
          resto = daysToEnd;
        } else {
          if (cantidad > parseInt(maximoMensual)) {
            cantidad = parseInt(maximoMensual);
          }
        }
        if (cantidad > parseInt(maximoMensual) * cantMeses - resto) {
          cantidad =
            parseInt(maximoMensual) + resto > cantidad
              ? cantidad
              : parseInt(maximoMensual) + resto;
        }
      }

      form.setFieldsValue({ cantidadDias: cantidad });
      const fin = addBusinessDays(
        fecha,
        cantidad >= 1 ? cantidad - 1 : cantidad - 0.5
      );
      form.setFieldsValue({ fechaFin: fin });
    }
  }

  async function getAcumulado(
    idEmpleado,
    idCodigo,
    fecha,
    maximoAnual,
    maximoMensual
  ) {
    if (!idEmpleado) {
      idEmpleado = form.getFieldValue("empleado");
    }
    if (!idCodigo) {
      idCodigo = form.getFieldValue("codigo");
    }
    if (!fecha) {
      fecha = form.getFieldValue("fechaInicio");
      fecha = moment(fecha);
    }
    const ano = fecha.year();

    if (idEmpleado && idCodigo && ano) {
      const response = await http.get(
        "licenciasEspeciales/Acumulado/" +
          idEmpleado +
          "/" +
          idCodigo +
          "/" +
          ano +
          "/" +
          state.id
      );
      if (response && response.data.code === 0) {
        const data = response.data.data;
        const values = {
          acumuladoAnual: data.acumuladoAnual ?? "",
          saldoAnual: data.saldoAnual ?? "",
        };
        form.setFieldsValue(values);
        calculateFechasWhitVal(
          maximoAnual ?? maxAnual,
          maximoMensual ?? maxMensual,
          data.acumuladoAnual ?? ""
        );
      }
    }
  }

  const onChangeEmpleado = async (value) => {
    if (value && empleados) {
      const empleado = empleados.find((e) => e.id === value);
      const data = {
        area: empleado.areaTrabajo,
        tarea: empleado.tarea + empleado.riesgoso,
        domicilio: empleado.areaTrabajo,
      };
      form.setFieldsValue(data);
      getAcumulado(value);
    }
  };

  function daysRemaining(date) {
    const eventdate = date;
    const daysIn = eventdate.daysInMonth();
    return daysIn - eventdate.date() + 1;
  }

  useEffect(() => {
    async function getForm() {
      let nCodigos = [];
      let nEmpleados = [];

      const response = await http.get("licenciasEspeciales/dataGeneric");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setConceptos(data.conceptos);
        setEmpleados(data.empleados);
        setCodigos(data.codigos);
        setEstados(data.estados);
        nCodigos = [...data.codigos];
        nEmpleados = [...data.empleados];
      }
      if (dataForm) {
        if (dataForm.estado === "-1") {
          setVerEstado(false);
        }
        setState({
          ...state,
          historial: dataForm.historial,
        });
        dataForm.fecha = parseToDate(dataForm.fecha);
        if (dataForm.codigo) {
          const item = nCodigos.find((x) => x.id === parseInt(dataForm.codigo));
          if (item) {
            setMaxAnual(item.maximoAnual ?? "");
            setMaxMensual(item.maximoMensual ?? "");
          }
        }

        if (dataForm.empleado) {
          const item = nEmpleados.find((x) => x.id === dataForm.empleado);
          dataForm.area = item.areaTrabajo;
          dataForm.tarea = item.tarea + item.riesgoso;
          dataForm.domicilio = item.areaTrabajo;
        }
        if (dataForm.fechaInicioD)
          dataForm.fechaInicio = moment(dataForm.fechaInicioD);

        if (dataForm.fechaFinD) dataForm.fechaFin = moment(dataForm.fechaFinD);
        form.setFieldsValue(dataForm);
      }
    }

    getForm();
  }, [dataForm]);

  return (
    <ContextProvider value={[state, setState]}>
      <Row gutter={20}>
        <Col span="18">
          <Form.Item label="Empleado" name="empleado" rules={[required]}>
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              onChange={onChangeEmpleado}
            >
              {empleados.map((x) => (
                <Option key={x.id} value={x.id}>
                  {x.nombre}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span="6">
          <Form.Item label="Fecha" name="creacion">
            <Input disabled={true} />
          </Form.Item>
        </Col>
        <Col span="8">
          <Form.Item label="Área" name="area">
            <Input disabled={true} />
          </Form.Item>
        </Col>
        <Col span="8">
          <Form.Item label="Tarea" name="tarea">
            <Input disabled={true} />
          </Form.Item>
        </Col>
        <Col span="8">
          <Form.Item label="Domicilio" name="domicilio">
            <Input disabled={true} />
          </Form.Item>
        </Col>
        <Col span="12">
          <Form.Item
            label="Código"
            name="codigo"
            rules={[required]}
            help={"Max Anual: " + maxAnual + " Max Mensual: " + maxMensual}
          >
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              onChange={onCodigoChange}
            >
              {codigos.map((x) => (
                <Option
                  key={x.id}
                  value={x.id.toString()}
                  disabled={x.disabled}
                >
                  {x.codigo} - {x.detalle}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span="12">
          <Form.Item label="Concepto" name="concepto" rules={[required]}>
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              disabled={true}
            >
              {conceptos.map((x) => (
                <Option key={x.value} value={x.value}>
                  {x.text}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span="12">
          <Form.Item label="Fecha Inicio" name="fechaInicio" rules={[required]}>
            <DatePicker
              format="DD/MM/YYYY"
              placeholder=""
              locale={locale}
              onChange={(val) => onFechaChange(val)}
            />
          </Form.Item>
        </Col>
        <Col span="12">
          <Form.Item
            label="Cantidad de días"
            name="cantidadDias"
            rules={[required]}
          >
            <Input
              type="number"
              onChange={(val) => onCantidadDiasChange(val)}
            />
          </Form.Item>
        </Col>

        <Col span="12">
          <Form.Item label="Fecha Fin" name="fechaFin">
            <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
          </Form.Item>
        </Col>
        {verEstado && (
          <Col span={12} className="section-estado">
            <Form.Item label="Estado" name="estado" rules={[required]}>
              <Select allowClear showSearch optionFilterProp="children">
                {estados.map((x) => (
                  <Option key={x.value} value={x.value}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {state.id > 0 && (
              <React.Fragment>
                <small className="cursor-pointer" onClick={onHistoryClick}>
                  Ver historial
                </small>
                <Modal
                  title="Historial"
                  visible={historial}
                  onCancel={() => setHistorial(false)}
                  footer={null}
                  maskClosable={false}
                  keyboard={false}
                  destroyOnClose={true}
                  width={900}
                >
                  <Historial onClose={() => setHistorial(false)} />
                </Modal>
              </React.Fragment>
            )}
          </Col>
        )}
        <Col span="12">
          <Form.Item label="Acumulado Anual" name="acumuladoAnual">
            <Input disabled={true} />
          </Form.Item>
        </Col>
        <Col span="12">
          <Form.Item label="Saldo Anual" name="saldoAnual">
            <Input disabled={true} />
          </Form.Item>
        </Col>
        <Col span="24">
          <Form.Item label="Observaciones" name="observacion">
            <TextArea maxLength={500} showCount />
          </Form.Item>
        </Col>
      </Row>
    </ContextProvider>
  );
};

export default LicenciasForm;
