/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Form, Input, Select } from "antd";
import { FiCopy } from "react-icons/fi";

import http from "../../../../services/http";
import { showSuccess } from "./../../../../services/notificacion";
import { required } from "../../../../services/validation";

const { Option } = Select;

const ClonarForm = ({ type, item, onClose }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [convenios, setConvenios] = useState([]);

  const onSave = async (values) => {
    setLoading(true);

    const rClone =
      type === "convenio"
        ? await http.put(`convenios/${item.id}/clone`, values)
        : await http.put(`convenios/conceptos/${item.id}/clone`, values);

    setLoading(false);

    if (rClone && rClone.data.code === 0) {
      showSuccess("Fórmulas clonadas exitosamente");
      onClose();
    }
  };

  useEffect(() => {
    async function getData() {
      const result = await http.get("convenios/data");

      if (result && result.data.code === 0) {
        const data = result.data.data;
        setConvenios(data.convenios);
      }

      form.resetFields();
      form.setFieldsValue({
        origen:
          type === "concepto"
            ? item.concepto
            : `${item.codigo} - ${item.detalle}`,
      });
    }

    getData();
  }, []);

  return (
    <Form form={form} name="form-clonar" layout="vertical" onFinish={onSave}>
      <div className="form">
        <div className="form-icon">
          <FiCopy />
        </div>

        <Form.Item
          label={type === "convenio" ? "Convenio" : "Concepto"}
          name="origen"
          rules={[required]}
        >
          <Input readOnly />
        </Form.Item>

        <Form.Item label="Destinos" name="destinos" rules={[required]}>
          <Select
            mode="multiple"
            allowClear
            showSearch
            optionFilterProp="children"
            maxTagCount={1}
          >
            {convenios.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <div className="form-actions">
          <Button type="secondary" onClick={onClose}>
            Volver
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            {loading ? "Clonando..." : "Clonar"}
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default ClonarForm;
