import React, { useContext, useState } from "react";
import { Dropdown, Menu, Button, Modal } from "antd";
import { SlideDown } from "react-slidedown";
import {
  FiBookmark,
  FiChevronDown,
  FiChevronUp,
  FiEdit,
  FiMoreVertical,
  FiTrash2,
} from "react-icons/fi";

import { Context } from "../../../../services/context";

import CamposForm from "./CamposForm";

import "react-slidedown/lib/slidedown.css";

const Campos = () => {
  const [state, setState] = useContext(Context);

  const [visible, setVisible] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const [current, setCurrent] = useState({});

  const menu = (item, index) => {
    return (
      <Menu className="table-actions-menu">
        <Menu.Item key="0" onClick={() => onEdit(item)}>
          <FiEdit />
          Editar
        </Menu.Item>
        <Menu.Item key="1" onClick={() => onDelete(index)}>
          <FiTrash2 />
          Eliminar
        </Menu.Item>
      </Menu>
    );
  };

  const onAdd = () => {
    setCurrent({});
    setFormVisible(true);
  };

  const onEdit = (item) => {
    setCurrent(item);
    setFormVisible(true);
  };

  const onDelete = (index) => {
    let nState = { ...state };
    nState.campos.splice(index, 1);
    setState(nState);
  };

  return (
    <div className="section card">
      <div className="section-header" onClick={() => setVisible(!visible)}>
        <div className="icon">
          <FiBookmark />
        </div>
        <div className="content">
          <div className="title">Campos</div>
          <div className="subtitle">{state.campos.length} elementos</div>
        </div>
        <div className="collapse">
          {visible ? <FiChevronUp /> : <FiChevronDown />}
        </div>
      </div>
      <SlideDown closed={!visible}>
        <div className="section-content">
          <table className="table-small">
            <thead>
              <tr>
                <th></th>
                <th>Nombre</th>
                <th>Inicio</th>
                <th>Fin</th>
              </tr>
            </thead>
            <tbody>
              {state.campos.map((x, i) => (
                <tr key={x.i}>
                  <td className="table-actions">
                    <Dropdown
                      key={x.id}
                      overlay={() => menu(x, i)}
                      trigger={["click"]}
                      placement="bottomLeft"
                      arrow
                    >
                      <div>
                        <FiMoreVertical />
                      </div>
                    </Dropdown>
                  </td>
                  <td>{x.nombre}</td>
                  <td>{x.inicio}</td>
                  <td>{x.fin}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="table-footer-actions">
            <Button type="primary" onClick={onAdd}>
              Agregar
            </Button>
          </div>
        </div>
      </SlideDown>

      <Modal
        visible={formVisible}
        onCancel={() => setFormVisible(false)}
        footer={null}
        maskClosable={false}
        keyboard={false}
        destroyOnClose={true}
      >
        <CamposForm item={current} onClose={() => setFormVisible(false)} />
      </Modal>
    </div>
  );
};

export default Campos;
