/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { SlideDown } from "react-slidedown";
import {
  FiArrowLeft,
  FiArrowRight,
  FiChevronDown,
  FiChevronUp,
  FiList,
} from "react-icons/fi";

import { Context } from "./../../../../services/context";
import http from "./../../../../services/http";

import "react-slidedown/lib/slidedown.css";

import "./Clases.scss";

const Clases = () => {
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useContext(Context);
  const [clases, setClases] = useState([]);
  const [visible, setVisible] = useState(false);
  const [index, setIndex] = useState(-1);
  const [current, setCurrent] = useState({
    clases: [],
  });

  useEffect(() => {
    async function getList() {
      const response = await http.get(`convenios/${state.id}/clases`);
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setClases(data.list);

        if (data.list.length > 0) {
          const nIndex = data.list.length - 1;
          setIndex(nIndex);
          setCurrent(data.list[nIndex]);
        }
      }
    }

    getList();
  }, []);

  const onPrevClick = () => {
    if (index === 0) {
      return;
    }

    const nIndex = index - 1;
    setIndex(nIndex);
    setCurrent(clases[nIndex]);
  };

  const onNextClick = () => {
    if (index === clases.length - 1) {
      return;
    }

    const nIndex = index + 1;
    setIndex(nIndex);
    setCurrent(clases[nIndex]);
  };

  return (
    <div className="section card">
      <div className="section-header" onClick={() => setVisible(!visible)}>
        <div className="icon">
          <FiList />
        </div>
        <div className="content">
          <div className="title">Clases</div>
          <div className="subtitle">{current.clases.length} elementos</div>
        </div>
        <div className="collapse">
          {visible ? <FiChevronUp /> : <FiChevronDown />}
        </div>
      </div>
      <SlideDown closed={!visible}>
        <div className="section-content">
          <table className="table-small table-clases">
            <thead>
              <tr>
                <th colSpan={2} style={{ padding: "0" }}>
                  <div className="clases-period">
                    <div
                      className={`prev ${index === 0 && "disabled"}`}
                      onClick={onPrevClick}
                    >
                      <FiArrowLeft />
                    </div>
                    <div>
                      {current.desde} - {current.hasta}
                    </div>
                    <div
                      className={`next ${
                        index === clases.length - 1 && "disabled"
                      }`}
                      onClick={onNextClick}
                    >
                      <FiArrowRight />
                    </div>
                  </div>
                </th>
              </tr>
              <tr>
                <th>Clase</th>
                <th>Básico</th>
              </tr>
            </thead>
            <tbody>
              {current.clases.map((x) => (
                <tr key={x.id}>
                  <td>{x.clase}</td>
                  <td>{x.basico}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </SlideDown>
    </div>
  );
};

export default Clases;
