/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Form, Input, Button, Select, DatePicker, Row, Col } from "antd";
import { FiCalendar } from "react-icons/fi";

import locale from "antd/es/date-picker/locale/es_ES";
import "moment/locale/es";

import { parseToYear } from "../../../../services/forms";
import http from "../../../../services/http";
import { showSuccess } from "../../../../services/notificacion";
import { hasAction, actions } from "../../../../services/security";
import { required } from "../../../../services/validation";

import Header from "../../../../components/Header";

const { Option } = Select;

const ServicioExtraordinarioForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const VER = hasAction(actions.SERVICIO_EXTRAORDINARIO_VER);
  const CREAR = hasAction(actions.SERVICIO_EXTRAORDINARIO_CREAR);
  const EDITAR = hasAction(actions.SERVICIO_EXTRAORDINARIO_EDITAR);
  const [editable, setEditable] = useState(
    match.params.id === "nuevo" ? CREAR : EDITAR
  );

  const [state, setState] = useState({
    title: match.params.id === "nuevo" ? "Crear" : EDITAR ? "Editar" : "Ver",
    id: match.params.id === "nuevo" ? 0 : parseInt(match.params.id),
    loading: false,
  });

  const [empleados, setEmpleados] = useState([]);
  const [meses, setMeses] = useState([]);

  const breadcrumb = [
    { id: 1, title: "Novedades" },
    {
      id: 2,
      title: "Liquidación",
      url: "/novedades/liquidacion",
    },
    {
      id: 3,
      title: "Servicios Extraordinarios",
      url: "/novedades/liquidacion/servicios_extraordinarios",
    },
    { id: 4, title: state.title },
  ];

  useEffect(() => {
    async function getForm() {
      const response = await http.get("serviciosextraordinarios/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setEmpleados(data.empleados);
      }

      const rForm = await http.get(`serviciosextraordinarios/${state.id}`);
      if (rForm && rForm.data.code === 0) {
        const data = rForm.data.data;
        data.anio = parseToYear(data.anio);
        form.setFieldsValue(data);
        await onYearChange();
        form.setFieldsValue(data);

        setEditable(editable && !data.periodoCerrado);
      }
    }

    if (VER) {
      getForm();
    }
  }, []);

  const onEmpleadoChange = (value) => {
    if (value === undefined) {
      form.setFieldsValue({ direccion: "" });
    }

    const empleado = empleados.find((x) => x.value === value.toString());
    form.setFieldsValue({ direccion: empleado.extra });
    onYearChange();
  };

  const onYearChange = async () => {
    const values = form.getFieldsValue();
    const legajo = values.legajo;
    const anio = values.anio?.year();

    const response = await http.get(
      `serviciosextraordinarios/anio?legajo=${legajo}&anio=${anio}`
    );
    if (response && response.data.code === 0) {
      const data = response.data.data;
      setMeses(data.meses);
    }
  };

  const onSave = async (values) => {
    values.anio = values.anio.year();

    setState({ ...state, loading: true });

    const result =
      state.id === 0
        ? await http.post("serviciosextraordinarios", values)
        : await http.put(`serviciosextraordinarios/${state.id}`, values);

    if (result && result.data.code === 0) {
      showSuccess("El servicio extraordinario fue guardado exitosamente.");
      history.push("/novedades/liquidacion/servicios_extraordinarios");
    } else {
      setState({ ...state, loading: false });
    }
  };

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="servicios-extraordinarios">
      <Header
        title={`${state.title} Servicio Extraordinario`}
        breadcrumb={breadcrumb}
      />
      <div className="form card">
        <Form form={form} layout="vertical" onFinish={onSave}>
          <div className="">
            <div className="form-icon">
              <FiCalendar />
            </div>
            <Row gutter={20}>
              <Col span={24}>
                <Form.Item label="Empleado" name="legajo" rules={[required]}>
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    onChange={onEmpleadoChange}
                    disabled={!editable}
                  >
                    {empleados.map((item) => (
                      <Option key={item.value} value={item.value}>
                        {item.text}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label="Direccion" name="direccion">
                  <Input disabled />
                </Form.Item>
              </Col>

              <Col span="12">
                <Form.Item label="Año" name="anio" rules={[required]}>
                  <DatePicker
                    picker="year"
                    format="YYYY"
                    placeholder=""
                    locale={locale}
                    onChange={onYearChange}
                    disabled={!editable}
                  />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item label="Mes" name="mes" rules={[required]}>
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    disabled={!editable}
                  >
                    {meses.map((item) => (
                      <Option
                        key={item.value}
                        value={item.value}
                        disabled={item.disabled}
                      >
                        {item.text}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span="12">
                <Form.Item
                  label="Días hábiles"
                  name="diasHabiles"
                  rules={[required]}
                >
                  <Input type="number" disabled={!editable} />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="Días no hábiles"
                  name="diasNoHabiles"
                  rules={[required]}
                >
                  <Input type="number" disabled={!editable} />
                </Form.Item>
              </Col>
            </Row>

            <div className="form-actions">
              <Link to="/novedades/liquidacion/servicios_extraordinarios">
                <Button type="secondary">Volver</Button>
              </Link>
              {editable && (
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={state.loading}
                >
                  Guardar
                </Button>
              )}
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ServicioExtraordinarioForm;
