/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import {
  Button,
  DatePicker,
  Dropdown,
  Form,
  Menu,
  Modal,
  Select,
  Input,
  Tag,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  FiEdit,
  FiPlus,
  FiTrash2,
  FiPrinter,
  FiSearch,
  FiUnlock,
} from "react-icons/fi";
import { SiMicrosoftexcel } from "react-icons/si";
import { ImFilePdf } from "react-icons/im";
import locale from "antd/es/date-picker/locale/es_ES";

import { ContextProvider } from "../../../../services/context";
import { downloadBase64 } from "../../../../services/forms";
import http from "../../../../services/http";
import { showSuccess } from "../../../../services/notificacion";
import { hasAction, actions } from "../../../../services/security";
import { required } from "../../../../services/validation";

import Filters from "../../../../components/Filters";
import FiltersButton from "../../../../components/FiltersButton";
import Header from "../../../../components/Header";
import Table from "../../../../components/Table";

const { Option } = Select;

const ServicioExtraordinario = () => {
  const history = useHistory();
  const [formPeriodo] = Form.useForm();

  const VER = hasAction(actions.SERVICIO_EXTRAORDINARIO_VER);
  const CREAR = hasAction(actions.SERVICIO_EXTRAORDINARIO_CREAR);
  const EDITAR = hasAction(actions.SERVICIO_EXTRAORDINARIO_EDITAR);
  const ELIMINAR = hasAction(actions.SERVICIO_EXTRAORDINARIO_ELIMINAR);
  const CERRAR = hasAction(actions.SERVICIO_EXTRAORDINARIO_CERRAR);

  const [state, setState] = useState({
    filters: {
      anio: new Date().getFullYear().toString(),
      mes: (new Date().getMonth() + 1).toString(),
      legajo: "",
      direccion: "",
    },
    columns: [
      {
        id: 1,
        title: "Legajo",
        style: {},
        render: (item) => item.legajo,
      },
      {
        id: 2,
        title: "Nombre",
        style: {},
        render: (item) => item.nombre,
      },
      {
        id: 3,
        title: "Año",
        style: {},
        render: (item) => item.anio,
      },
      {
        id: 4,
        title: "Mes",
        style: {},
        render: (item) => item.mes,
      },
      {
        id: 5,
        title: "Periodo",
        style: {},
        render: (item) =>
          item.periodoCerrado ? (
            <Tag color="red">Cerrado</Tag>
          ) : (
            <Tag color="green">Abierto</Tag>
          ),
      },
    ],
    loading: true,
    loaded: false,
    reload: true,
    list: [],
    total: 0,
    page: 1,
    size: 10,
  });

  const [direcciones, setDirecciones] = useState([]);
  const [empleados, setEmpleados] = useState([]);
  const [meses, setMeses] = useState([]);

  const [modalPeriodo, setModalPeriodo] = useState(false);
  const [loadingPeriodo, setLoadingPeriodo] = useState(false);

  const [loadingExport, setLoadingExport] = useState(false);

  const breadcrumb = [
    { id: 1, title: "Novedades" },
    {
      id: 2,
      title: "Liquidación",
      url: "/novedades/liquidacion",
    },
    {
      id: 3,
      title: "Servicios Extraordinarios",
      url: "/novedades/liquidacion/servicios_extraordinarios",
    },
  ];

  const exportMenu = () => (
    <Menu>
      <Menu.Item key="1" icon={<SiMicrosoftexcel />} onClick={onExportExcel}>
        Excel
      </Menu.Item>
      <Menu.Item key="2" icon={<ImFilePdf />} onClick={onExportPdf}>
        Pdf
      </Menu.Item>
    </Menu>
  );

  const buttons = () => {
    return (
      <React.Fragment>
        {CERRAR && (
          <Button
            type="secondary"
            icon={<FiUnlock />}
            onClick={() => setModalPeriodo(true)}
          >
            Periodo
          </Button>
        )}
        <Dropdown overlay={exportMenu}>
          <Button icon={<FiPrinter />} loading={loadingExport}>
            {!loadingExport && <React.Fragment>Exportar</React.Fragment>}
          </Button>
        </Dropdown>
        <FiltersButton />
        {CREAR && (
          <Button type="primary" icon={<FiPlus />} onClick={onAdd}>
            Nuevo
          </Button>
        )}
      </React.Fragment>
    );
  };

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Año" name="anio">
          <Input type="number" />
        </Form.Item>

        <Form.Item label="Mes" name="mes">
          <Select showSearch allowClear optionFilterProp="children">
            {meses.map((item) => (
              <Option key={item.value} value={item.value}>
                {item.text}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Empleado" name="legajo">
          <Select showSearch allowClear optionFilterProp="children">
            {empleados.map((m) => (
              <Option key={m.value} value={m.value}>
                {m.text}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Direccion" name="direccion">
          <Select showSearch allowClear optionFilterProp="children">
            {direcciones.map((item) => (
              <Option key={item.value} value={item.value}>
                {item.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </React.Fragment>
    );
  };

  const menu = (item) => {
    return (
      <Menu className="table-actions-menu">
        {item.periodoCerrado && (
          <Menu.Item key="0" onClick={() => onEdit(item)}>
            <FiSearch />
            Ver
          </Menu.Item>
        )}

        {EDITAR && !item.periodoCerrado && (
          <Menu.Item key="1" onClick={() => onEdit(item)}>
            <FiEdit />
            Editar
          </Menu.Item>
        )}
        {ELIMINAR && !item.periodoCerrado && (
          <Menu.Item key="2" onClick={() => onDelete(item)}>
            <FiTrash2 />
            Eliminar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  useEffect(() => {
    async function getList() {
      const response = await http.get("serviciosextraordinarios/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setEmpleados(data.empleados);
        setDirecciones(data.direcciones);
      }
    }

    getList();
  }, []);

  const onAdd = () => {
    history.push("/novedades/liquidacion/servicios_extraordinarios/nuevo");
  };

  const onEdit = (item) => {
    history.push(`/novedades/liquidacion/servicios_extraordinarios/${item.id}`);
  };

  const onExportExcel = async () => {
    setLoadingExport(true);

    const rExport = await http.post(
      "serviciosextraordinarios/export/excel",
      state.filters
    );
    if (rExport) {
      const data = rExport.data.data;
      downloadBase64(data.filename, data.content);
    }

    setLoadingExport(false);
  };

  const onExportPdf = async () => {
    setLoadingExport(true);

    const rExport = await http.post(
      "serviciosextraordinarios/export/pdf",
      state.filters
    );
    if (rExport) {
      const data = rExport.data.data;
      downloadBase64(data.filename, data.content);
    }

    setLoadingExport(false);
  };

  const onChangePeriodo = async (value) => {
    if (!value) {
      return;
    }

    const response = await http.get(
      `ServiciosExtraordinarios/periodo?anio=${value.year()}`
    );
    if (response && response.data.code === 0) {
      const data = response.data.data;
      setMeses(data.meses);
    }
  };

  const onClosePeriodo = async (values) => {
    values.anio = values.anio.year();

    setLoadingPeriodo(true);

    const result = await http.post("serviciosextraordinarios/periodo", values);

    if (result && result.data.code === 0) {
      showSuccess("El servicio fue actualizado correctamente.");
      formPeriodo.resetFields();
      setModalPeriodo(false);
    }

    setLoadingPeriodo(false);
  };

  const onDelete = (item) => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea eliminar este servicio extraordinario?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const rDelete = await http.delete(
          `serviciosextraordinarios/${item.id}`
        );
        if (rDelete && rDelete.data.code === 0) {
          showSuccess("El servicio extraordinario fue eliminado exitosamente.");
          setState({ ...state, reload: !state.reload });
        }
      },
    });
  };

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="servicios-extraordinarios page">
      <ContextProvider value={[state, setState]}>
        <Header
          title="Servicios Extraordinarios"
          breadcrumb={breadcrumb}
          buttons={buttons}
        />
        <Filters filters={filters} />
        <Table url="serviciosextraordinarios/list" menu={menu} />

        <Modal
          visible={modalPeriodo}
          footer={null}
          closable={false}
          maskClosable={false}
          keyboard={false}
          destroyOnClose={true}
        >
          <Form
            form={formPeriodo}
            className="subsection-form"
            layout="vertical"
            onFinish={onClosePeriodo}
          >
            <div className="form">
              <div className="form-icon">
                <FiUnlock />
              </div>

              <h3 style={{ textAlign: "center" }}>Abrir / Cerrar Periodo</h3>
              <Form.Item label="Año" name="anio" rules={[required]}>
                <DatePicker
                  picker="year"
                  format="YYYY"
                  placeholder=""
                  locale={locale}
                  onChange={onChangePeriodo}
                />
              </Form.Item>
              <Form.Item label="Mes" name="mes" rules={[required]}>
                <Select showSearch allowClear optionFilterProp="children">
                  {meses.map((item) => (
                    <Option key={item.value} value={item.value}>
                      {item.text} ({item.disabled ? "Cerrado" : "Abierto"})
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <div className="form-actions">
                <Button type="secondary" onClick={() => setModalPeriodo(false)}>
                  Cancelar
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loadingPeriodo}
                >
                  {loadingPeriodo ? "" : "Confirmar"}
                </Button>
              </div>
            </div>
          </Form>
        </Modal>
      </ContextProvider>
    </div>
  );
};

export default ServicioExtraordinario;
