/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { FiFolder } from "react-icons/fi";

import AdministrativosList from "./AdministrativosList";
import AdministrativosForm from "./AdministrativosForm";


const Administrativos = () => {
  const [current, setCurrent] = useState(null);

  return (
    <div className="administrativos">
      <div className="section-header">
        <div className="section-title">
          Actos Administrativos
          <FiFolder />
        </div>
        <div className="section-subtitle">
          Lista de actos administrativos que afectaron al empleado
        </div>
      </div>

      {current === null && <AdministrativosList onCurrentChange={setCurrent} />}
      {current !== null && (
        <AdministrativosForm current={current} onCurrentChange={setCurrent} />
      )}
    </div>
  );
};

export default Administrativos;
