/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { Button, Form, Input } from "antd";
import { FiPrinter } from "react-icons/fi";

import { ContextProvider } from "../../../../services/context";
import http from "../../../../services/http";
import { hasAction, actions } from "../../../../services/security";

import Filters from "../../../../components/Filters";
import FiltersButton from "../../../../components/FiltersButton";
import Header from "../../../../components/Header";
import fileDownload from "js-file-download";

import "moment/locale/es";
import * as moment from "moment";
import Table from "../../../../components/Table";

const SueldosCaidos = () => {
  const VER = hasAction(actions.REPORTE_LIQUIDACION_SUELDOSCAIDOS);
  const [loadingExport, setLoadingExport] = useState(false);

  const [state, setState] = useState({
    filters: {
      mes: moment().month() + 1,
      anio: moment().year(),
    },
    columns: [
      {
        id: 1,
        title: "#",
        style: {},
        render: (item) => item.legajo,
      },
      {
        id: 2,
        title: "Empleado",
        style: {},
        render: (item) => item.nombre,
      },
      {
        id: 3,
        title: "Haberes Sujetos",
        style: {},
        render: (item) => item.haberesSujetosTexto,
      },
      {
        id: 4,
        title: "Haberes No Sujeto",
        style: {},
        render: (item) => item.haberesNoSujetosTexto,
      },
      {
        id: 5,
        title: "Asignaciones Familiares",
        style: {},
        render: (item) => item.asignacionesTexto,
      },
      {
        id: 6,
        title: "Retenciones",
        style: {},
        render: (item) => item.retencionesTexto,
      },
      {
        id: 7,
        title: "Contribución Patronal",
        style: {},
        render: (item) => item.contribucionPatronalTexto,
      },
      {
        id: 8,
        title: "Neto a Pagar",
        style: {},
        render: (item) => item.netoTexto,
      },
      {
        id: 9,
        title: "Cant Días licencia",
        style: {},
        render: (item) => item.cantidad,
      },
      {
        id: 10,
        title: "Licencias",
        style: {},
        render: (item) => item.licencias,
      },
    ],
    loading: true,
    loaded: false,
    reload: true,
    list: [],
    total: 0,
    page: 1,
    size: 10,
  });

  const breadcrumb = [
    {
      id: 1,
      title: "Reportes",
    },
    {
      id: 2,
      title: "Liquidación",
      url: "/reportes/liquidacion",
    },
    {
      id: 3,
      title: "Sueldos caidos",
      url: "/reportes/liquidacion/sueldos",
    },
  ];

  const onExport = async () => {
    setLoadingExport(true);
    const rExport = await http.post(
      "Reportes/SueldosCaidos/export/List",
      state.filters,
      true
    );
    setLoadingExport(false);

    fileDownload(rExport.data, `SueldosCaidos${Date.now()}.xlsx`);
  };

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Año" name="anio" initialValue={moment().year()}>
          <Input />
        </Form.Item>
        <Form.Item label="Mes" name="mes" initialValue={moment().month() + 1}>
          <Input />
        </Form.Item>
      </React.Fragment>
    );
  };

  useEffect(() => {
    async function getList() {
      setState({ ...state, loading: true });
      const rList = await http.post("Reportes/SueldosCaidos/List/", {
        page: state.page,
        size: state.size,
        ...state.filters,
      });
      if (rList && rList.data.code === 0) {
        const data = rList.data.data;
        setState({
          ...state,
          list: data.list,
          total: data.total,

          loading: false,
        });
      } else {
        setState({ ...state, loading: false });
      }
    }

    if (VER) {
      getList();
    }
  }, [state.filters.anio, state.filters.mes, state.page, state.size]);

  if (!VER) {
    return <Redirect to="/" />;
  }
  const buttons = () => {
    return (
      <React.Fragment>
        <FiltersButton />
        <Button
          type="primary"
          icon={<FiPrinter />}
          onClick={onExport}
          loading={loadingExport}
        >
          Exportar
        </Button>
      </React.Fragment>
    );
  };

  return (
    <div className="sueldosCaidos page">
      <ContextProvider value={[state, setState]}>
        <Header
          title="Sueldos caidos"
          breadcrumb={breadcrumb}
          buttons={buttons}
        />
        <Filters filters={filters} />
        <Table columns={state.columns} />
      </ContextProvider>
    </div>
  );
};

export default SueldosCaidos;
