/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Button, Form, Input, Menu, Modal, Select, Tooltip } from "antd";
import { FiEye, FiEyeOff, FiPlus, FiSearch, FiPrinter } from "react-icons/fi";
import { SiMicrosoftexcel } from "react-icons/si";

import { ContextProvider } from "./../../../services/context";
import { downloadBase64 } from "./../../../services/forms";
import http from "./../../../services/http";
import { hasAction, actions } from "./../../../services/security";

import Filters from "./../../../components/Filters";
import FiltersButton from "./../../../components/FiltersButton";
import Header from "./../../../components/Header";
import Table from "./../../../components/Table";

const { Option } = Select;

const Legajos = () => {
  const history = useHistory();

  const VER = hasAction(actions.LEGAJOS_VER);
  const CREAR = hasAction(actions.LEGAJOS_CREAR);

  const [state, setState] = useState({
    filters: {
      legajo: "",
      nombre: "",
      ingresoDesde: "",
      ingresoHasta: "",
      documento: "",
      convenio: "",
      clase: "",
      formaPago: "",
      obraSocial: "",
      sindicato: "",
      lugarTrabajo: "",
      banco: "",
      cuenta: "",
      funcion: "",
      agrupamiento: "",
      baja: "",
      declaracionJurada: "",
      reduccionHoraria: "",
      sexo: "-1",
      edad: "",
      antiguedad: "",
    },
    columns: [
      {
        id: 1,
        title: "#",
        style: {},
        render: (item) => item.id,
      },
      {
        id: 2,
        title: "Nombre",
        style: {},
        render: (item) => (
          <Link to={`/administracion/legajos/${item.id}`}>{item.nombre}</Link>
        ),
      },
      {
        id: 3,
        title: "Documento",
        style: {},
        render: (item) => item.documento,
      },
      {
        id: 4,
        title: "",
        style: { width: "100px", textAlign: "center" },
        render: (item) =>
          item.visible && (
            <Tooltip placement="top" title="Visible">
              <FiEye className="table-icon active" />
            </Tooltip>
          ),
      },
    ],
    loading: true,
    loaded: false,
    reload: true,
    list: [],
    total: 0,
    page: 1,
    size: 10,
  });

  const [empleados, setEmpleados] = useState([]);
  const [clases, setClases] = useState([]);
  const [agrupamientos, setAgrupamientos] = useState([]);
  const [funciones, setFunciones] = useState([]);
  const [convenios, setConvenios] = useState([]);
  const [sexos, setSexos] = useState([]);

  const [formExport] = Form.useForm();
  const [modalExport, setModalExport] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);

  const breadcrumb = [
    { id: 1, title: "Administración" },
    {
      id: 2,
      title: "Legajos",
      url: "/administracion/legajos",
    },
  ];

  const buttons = () => {
    return (
      <React.Fragment>
        <FiltersButton />
        <Button
          type="primary"
          icon={<FiPrinter />}
          onClick={onClickExport}
          loading={state.loadingExport}
        >
          Exportar
        </Button>
        {CREAR && (
          <Button type="primary" icon={<FiPlus />} onClick={onAdd}>
            Nuevo
          </Button>
        )}
      </React.Fragment>
    );
  };

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Legajo" name="legajo">
          <Select allowClear showSearch optionFilterProp="children">
            {empleados.map((x) => (
              <Option key={x.id} value={x.id}>
                {x.nombre}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Nombre" name="nombre">
          <Input />
        </Form.Item>
        <Form.Item label="Fecha Ingreso Desde" name="ingresoDesde">
          <Input />
        </Form.Item>
        <Form.Item label="Fecha Ingreso Hasta" name="ingresoHasta">
          <Input />
        </Form.Item>
        <Form.Item label="Documento/Cuil" name="documento">
          <Input />
        </Form.Item>
        <Form.Item label="Convenio" name="convenio">
          <Select allowClear showSearch optionFilterProp="children">
            {convenios.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Clase" name="clase">
          <Select allowClear showSearch optionFilterProp="children">
            {clases.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Cod. Forma de Pago" name="formaPago">
          <Input />
        </Form.Item>
        <Form.Item label="Cod. Obra Social" name="obraSocial">
          <Input />
        </Form.Item>
        <Form.Item label="Cod. Sindicato" name="sindicato">
          <Input />
        </Form.Item>
        <Form.Item label="Cod. Lugar de Trabajo" name="lugarTrabajo">
          <Input />
        </Form.Item>
        <Form.Item label="Cod. Banco" name="banco">
          <Input />
        </Form.Item>
        <Form.Item label="Cuenta" name="cuenta">
          <Input />
        </Form.Item>
        <Form.Item label="Funcion" name="funcion">
          <Select allowClear showSearch optionFilterProp="children">
            {funciones.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Agrupamiento" name="agrupamiento">
          <Select allowClear showSearch optionFilterProp="children">
            {agrupamientos.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Dado de baja" name="baja">
          <Select defaultValue="-1">
            <Option value="-1">Indistinto</Option>
            <Option value="1">Si</Option>
            <Option value="0">No</Option>
          </Select>
        </Form.Item>
        <Form.Item label="DDJJ" name="declaracionJurada">
          <Select allowClear>
            <Option value="1">Si</Option>
            <Option value="0">No</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Reducción Horaria" name="reduccionHoraria">
          <Select allowClear>
            <Option value="1">Si</Option>
            <Option value="0">No</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Sexo" name="sexo">
          <Select defaultValue="-1">
            <Option value="-1">Indistinto</Option>
            {sexos.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Edad (en años)" name="edad">
          <Input />
        </Form.Item>
        <Form.Item label="Antiguedad (en años)" name="antiguedad">
          <Input />
        </Form.Item>
      </React.Fragment>
    );
  };

  const menu = (item) => {
    return (
      <Menu className="table-actions-menu">
        <Menu.Item key="0" onClick={() => onEdit(item)}>
          <FiSearch />
          Ver
        </Menu.Item>
        {actions.LEGAJOS_EDITAR && (
          <Menu.Item key="1" onClick={() => onToggleVisibility(item)}>
            {item.visible ? <FiEyeOff /> : <FiEye />}
            Cambiar visibilidad
          </Menu.Item>
        )}
      </Menu>
    );
  };

  useEffect(() => {
    async function getData() {
      const response = await http.get("legajos/dataList");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setEmpleados(data.empleados);
        setClases(data.clases);
        setFunciones(data.funciones);
        setAgrupamientos(data.agrupamientos);
        setConvenios(data.convenios);
        setSexos(data.sexos);
      }
    }

    getData();
  }, []);

  const onAdd = () => {
    history.push("/administracion/legajos/nuevo");
  };

  const onEdit = (item) => {
    history.push(`/administracion/legajos/${item.id}`);
  };

  const onToggleVisibility = async (item) => {
    const rToggle = await http.put(`/legajos/visible/${item.id}`, {});

    if (rToggle && rToggle.data.code === 0) {
      let nList = [...state.list];
      const index = nList.indexOf(item);
      nList[index].visible = !nList[index].visible;
      setState({ ...state, list: nList });
    }
  };

  const onClickExport = async () => {
    formExport.resetFields();
    setModalExport(true);
  };

  const onConfirmExport = async (values) => {
    setLoadingExport(true);

    let data = { ...state.filters, ...values };

    const response = await http.post("legajos/export", data);
    if (response) {
      const data = response.data.data;
      downloadBase64(data.filename, data.content);
    }

    setLoadingExport(false);
    setModalExport(false);
  };

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="legajos page">
      <ContextProvider value={[state, setState]}>
        <Header title="Legajos" breadcrumb={breadcrumb} buttons={buttons} />
        <Filters filters={filters} />
        <Table url="legajos/list" menu={menu} />

        <Modal
          visible={modalExport}
          forceRender
          footer={null}
          maskClosable={false}
          keyboard={false}
          destroyOnClose={true}
          onCancel={() => setModalExport(false)}
        >
          <Form form={formExport} layout="vertical" onFinish={onConfirmExport}>
            <div className="form">
              <div className="form-icon">
                <SiMicrosoftexcel />
              </div>
              <Form.Item label="Campos" name="campos">
                <Select
                  mode="multiple"
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  maxTagCount="responsive"
                >
                  <Option value="id">Legajo</Option>
                  <Option value="nombre">Nombre</Option>
                  <Option value="documentoTipo">Tipo de Documento</Option>
                  <Option value="documento">Documento</Option>
                  <Option value="cuil">CUIL</Option>
                  <Option value="fechaNacimiento">Fecha de Nacimiento</Option>
                  <Option value="calle">Calle</Option>
                  <Option value="provincia">Provincia</Option>
                  <Option value="barrio">Barrio</Option>
                  <Option value="manzana">Manzana</Option>
                  <Option value="casa">Casa</Option>
                  <Option value="departamento">Departamento</Option>
                  <Option value="distrito">Distrito</Option>
                  <Option value="codigoPostal">Código Postal</Option>
                  <Option value="domicilioDdjj">
                    Declaración Jurada del Domicilio
                  </Option>
                  <Option value="domicilioDdjjFecha">
                    Fecha Declaración Jurada del Domicilio
                  </Option>
                  <Option value="telefono">Teléfono</Option>
                  <Option value="celular1">Celular #1</Option>
                  <Option value="celular2">Celular #2</Option>
                  <Option value="email">Email</Option>
                  <Option value="lugarNacimiento">Lugar de Nacimiento</Option>
                  <Option value="nacionalidad">Nacionalidad</Option>
                  <Option value="estadoCivil">Estado Civil</Option>
                  <Option value="sexo">Sexo</Option>
                  <Option value="licenciaConducir">Licencia de Conducir</Option>
                  <Option value="licenciaVencimiento">
                    Licencia de Conducir - Vencimiento
                  </Option>
                  <Option value="buenaConducta">Buena Conducta</Option>
                  <Option value="grupoSanguineo">Grupo Sanguíneo</Option>
                  <Option value="funcion">Función</Option>
                  <Option value="secretaria">Secretaría</Option>
                  <Option value="direccion">Dirección</Option>
                  <Option value="subdireccion">Subdirección</Option>
                  <Option value="lugarTrabajo">Lugar de Trabajo</Option>
                  <Option value="convenio">Convenio</Option>
                  <Option value="clase">Clase</Option>
                  <Option value="claseRetenida">Clase Retenida</Option>
                  <Option value="agrupamiento">Agrupamiento</Option>
                  <Option value="tipoContratacion">Tipo de Contratación</Option>
                  <Option value="agrupamientoRetenido">
                    Agrupamiento Retenido
                  </Option>
                  <Option value="fechaIngreso">Fecha de Ingreso</Option>
                  <Option value="fechaIngresoBonoSueldo">
                    Fecha de Ingreso - Bono de Sueldo
                  </Option>
                  <Option value="antiguedadReconocida">
                    Antigüedad Reconocida
                  </Option>
                  <Option value="subrogaCargo">Subroga Cargo</Option>
                  <Option value="cargoInterino">Cargo Interino</Option>
                  <Option value="cargoInterinoDesde">
                    Cargo Interino - Desde
                  </Option>
                  <Option value="cargoInterinoHasta">
                    Cargo Interino - Hasta
                  </Option>
                  <Option value="regimenTrabajo">Regimen de Trabajo</Option>
                  <Option value="horarioDesde">Horario Desde</Option>
                  <Option value="horarioHasta">Horario Hasta</Option>
                  <Option value="reduccionHoraria">Reducción Horaria</Option>
                  <Option value="reduccionPorcentaje">
                    Reducción Porcentaje
                  </Option>
                  <Option value="relojBiometrico">Reloj Biométrico</Option>
                  <Option value="emailCorporativo">Email Corporativo</Option>
                  <Option value="telefonoCorporativo">
                    Teléfono Corporativo
                  </Option>
                  <Option value="condicionSindical">Condición Sindical</Option>
                  <Option value="sindicato">Sindicato</Option>
                  <Option value="delegadoDesde">Delegado Desde</Option>
                  <Option value="delegadoHasta">Delegado Hasta</Option>
                  <Option value="cargoSindical">Cargo Sindical</Option>
                  <Option value="sindicalDesde">Cargo Sindical - Desde</Option>
                  <Option value="sindicalHasta">Cargo Sindical - Hasta</Option>
                  <Option value="regimenLicencia">Regimen de Licencia</Option>
                  <Option value="horasMes">Horas por Mes</Option>
                  <Option value="horasDia">Horas por Día</Option>
                  <Option value="insalubre">Insalubre</Option>
                  <Option value="insalubreDesde">Insalubre - Desde</Option>
                  <Option value="insalubreHasta">Insalubre - Hasta</Option>
                  <Option value="tarea">Tarea</Option>
                  <Option value="tareaDesde">Tarea - Desde</Option>
                  <Option value="tareaHasta">Tarea - Hasta</Option>
                  <Option value="formaPago">Forma de Pago</Option>
                  <Option value="bancoSucursal">Banco Sucursal</Option>
                  <Option value="cuenta">Cuenta</Option>
                  <Option value="cbu">CBU</Option>
                  <Option value="obraSocial">Obra Social</Option>
                  <Option value="obraSocialNumero">Obra Social - Número</Option>
                  <Option value="art">A.R.T</Option>
                  <Option value="estado">Estado</Option>
                  <Option value="motivoBaja">Motivo de Baja</Option>
                  <Option value="fechaBaja">Fecha de Baja</Option>
                  <Option value="fechaReingreso">Fecha de Reingreso</Option>
                  <Option value="responsabilidadJerarquica">
                    Responsabilidad Jerárquica
                  </Option>
                  <Option value="prenatal">Prenatal</Option>
                  <Option value="prenatalFUM">Prenatal - FUM</Option>
                  <Option value="prenatalFPP">Prenatal - FPP</Option>
                </Select>
              </Form.Item>
              <div className="form-actions">
                <Button type="secondary" onClick={() => setModalExport(false)}>
                  Cancelar
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loadingExport}
                >
                  {!loadingExport && "Exportar"}
                </Button>
              </div>
            </div>
          </Form>
        </Modal>
      </ContextProvider>
    </div>
  );
};

export default Legajos;
