/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Button, Form, Select, Menu, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { FiEdit, FiPlus, FiTrash2 } from "react-icons/fi";

import { ContextProvider } from "../../../../services/context";
import http from "../../../../services/http";
import { showSuccess } from "../../../../services/notificacion";
import { hasAction, actions } from "../../../../services/security";

import Filters from "../../../../components/Filters";
import FiltersButton from "../../../../components/FiltersButton";
import Header from "../../../../components/Header";
import Table from "../../../../components/Table";

const { Option } = Select;

const DDJJ = () => {
  const history = useHistory();

  const VER = hasAction(actions.DDJJ_VER);
  const CREAR = hasAction(actions.DDJJ_CREAR);
  const EDITAR = hasAction(actions.DDJJ_EDITAR);
  const ELIMINAR = hasAction(actions.DDJJ_ELIMINAR);
  const [empleados, setEmpleados] = useState([]);

  const [state, setState] = useState({
    filters: {
      empleadoId: "",
    },
    columns: [
      {
        id: 1,
        title: "Legajo",
        style: {},
        render: (item) => item.empleadoId,
      },
      {
        id: 2,
        title: "Nombre",
        style: {},
        render: (item) => item.empleado,
      },
    ],
    loading: true,
    loaded: false,
    reload: true,
    list: [],
    total: 0,
    page: 1,
    size: 10,
  });

  const breadcrumb = [
    { id: 1, title: "Liquidación" },
    {
      id: 2,
      title: "Ganancias",
      url: "/liquidacion/ganancias",
    },
    {
      id: 3,
      title: "DDJJ",
      url: "/liquidacion/ganancias/ddjj",
    },
  ];

  const buttons = () => {
    return (
      <React.Fragment>
        <FiltersButton />
        {CREAR && (
          <Button type="primary" icon={<FiPlus />} onClick={onAdd}>
            Nuevo
          </Button>
        )}
      </React.Fragment>
    );
  };

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Empleado" name="empleadoId">
          <Select showSearch allowClear optionFilterProp="children">
            {empleados.map((m) => (
              <Option key={m.value} value={m.value} disabled={m.disabled}>
                {m.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </React.Fragment>
    );
  };

  const menu = (item) => {
    return (
      <Menu className="table-actions-menu">
        {EDITAR && (
          <Menu.Item key="0" onClick={() => onEdit(item)}>
            <FiEdit />
            Editar
          </Menu.Item>
        )}
        {ELIMINAR && (
          <Menu.Item key="1" onClick={() => onDelete(item)}>
            <FiTrash2 />
            Eliminar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const onAdd = () => {
    history.push("/liquidacion/ganancias/ddjj/nuevo");
  };

  const onEdit = (item) => {
    history.push(`/liquidacion/ganancias/ddjj/${item.id}`);
  };

  const onDelete = (item) => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea eliminar esta DDJJ?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const rDelete = await http.delete(`ddjj/${item.id}`);
        if (rDelete && rDelete.data.code === 0) {
          showSuccess("La DDJJ fue eliminado exitosamente.");
          setState({ ...state, reload: !state.reload });
        }
      },
    });
  };

  useEffect(() => {
    async function getData() {
      const response = await http.get("ddjj/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setEmpleados(data.empleados);
      }
    }
    getData();
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="ddjj page">
      <ContextProvider value={[state, setState]}>
        <Header title="DDJJ" breadcrumb={breadcrumb} buttons={buttons} />
        <Filters filters={filters} />
        <Table url="ddjj/list" menu={menu} />
      </ContextProvider>
    </div>
  );
};

export default DDJJ;
