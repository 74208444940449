import React from 'react';
import { Col, Row } from 'antd';

import { hasAction, actions } from './../../../services/security';

import Header from '../../../components/Header';
import MenuCard from '../../../components/MenuCard';

const Legajo = () => {
  const breadcrumb = [
    {
      id: 1,
      title: 'Reportes',
    },
    {
      id: 2,
      title: 'Legajos',
      url: '/reportes/legajo',
    },
  ];

  const items = [
    {
      id: 1,
      titulo: 'Formación y Titúlos',
      path: '/reportes/legajo/titulos',
      color: 'primary',
      icono: 'FiFileText',
      visible: hasAction(actions.REPORTE_LEGAJO_TITULO),
    },
    {
      id: 2,
      titulo: 'Control Escolaridad',
      path: '/reportes/legajo/escolaridad',
      color: 'primary',
      icono: 'FiFileText',
      visible: hasAction(actions.REPORTE_LEGAJO_CONTROLESCOLARIDAD),
    },
    {
      id: 3,
      titulo: 'Control Asignaciones',
      path: '/reportes/legajo/asignaciones',
      color: 'primary',
      icono: 'FiFileText',
      visible: hasAction(actions.REPORTE_LEGAJO_CONTROLASIGNACION),
    },
    {
      id: 4,
      titulo: 'Asistencia',
      path: '/reportes/legajo/asistencia',
      color: 'primary',
      icono: 'FiFileText',
      visible: hasAction(actions.REPORTE_LEGAJO_ASISTENCIA),
    },
  ];

  return (
    <div className="inicio">
      <Header title="Legajos" breadcrumb={breadcrumb} buttons={() => {}} />

      <Row gutter={[32, 36]}>
        {items.map((i) => (
          <Col key={i.id} xs={12} md={8} xl={6} xxl={4}>
            <MenuCard item={i} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Legajo;
