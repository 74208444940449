/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Form, Input, Button, Select } from "antd";
import { FiGrid } from "react-icons/fi";

import http from "./../../../../services/http";
import { showSuccess } from "./../../../../services/notificacion";
import { hasAction, actions } from "./../../../../services/security";
import { regex, required } from "./../../../../services/validation";

import Header from "./../../../../components/Header";
import InputMasked from "../../../../components/InputMasked";
const { Option } = Select;

const AgrupamientosForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const VER = hasAction(actions.AGRUPAMIENTOS_VER);
  const CREAR = hasAction(actions.AGRUPAMIENTOS_CREAR);
  const EDITAR = hasAction(actions.AGRUPAMIENTOS_EDITAR);
  const GUARDAR = match.params.id === "nuevo" ? CREAR : EDITAR;
  const [tipos, setTipos] = useState([]);

  const [state, setState] = useState({
    title: match.params.id === "nuevo" ? "Crear" : EDITAR ? "Editar" : "Ver",
    id: match.params.id === "nuevo" ? 0 : parseInt(match.params.id),
    loading: false,
  });

  const breadcrumb = [
    {
      id: 1,
      title: "Parámetros",
    },
    {
      id: 2,
      title: "Estructura",
      url: "/parametros/estructura",
    },
    {
      id: 3,
      title: "Agrupamientos",
      url: "/parametros/estructura/agrupamientos",
    },
    { id: 4, title: state.title },
  ];

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    const result =
      state.id === 0
        ? await http.post("agrupamientos", values)
        : await http.put(`agrupamientos/${state.id}`, values);

    if (result && result.data.code === 0) {
      showSuccess("El agrupamiento fue guardado exitosamente.");
      history.push("/parametros/estructura/agrupamientos");
    } else {
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {
    async function getData() {
      const result = await http.get("agrupamientos/data");

      if (result && result.data.code === 0) {
        const data = result.data.data;
        setTipos(data.tipos);
      }
    }

    getData();

    async function getForm() {
      const rForm = await http.get(`agrupamientos/${state.id}`);
      if (rForm && rForm.data.code === 0) {
        const data = rForm.data.data;
        form.setFieldsValue(data);
      }
    }

    if (VER) {
      getForm();
    }
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="agrupamiento">
      <Header title={`${state.title} Agrupamiento`} breadcrumb={breadcrumb} />

      <Form form={form} layout="vertical" onFinish={onSave}>
        <div className="form card">
          <div className="form-icon">
            <FiGrid />
          </div>

          <Form.Item label="Nombre" name="nombre" rules={[required]}>
            <Input maxLength={200} disabled={!GUARDAR} />
          </Form.Item>

          <Form.Item
            label="Código"
            name="codigo"
            rules={[
              required,
              regex(
                "[0-9]{2}.[0-9]{1}.[0-9]{1}.[0-9]{2}",
                "No cumple con el formato esperado"
              ),
            ]}
          >
            <InputMasked mask="99.9.9.99" disabled={!GUARDAR} />
          </Form.Item>

          <Form.Item label="Tipo" name="tipo" rules={[required]}>
            <Select allowClear showSearch optionFilterProp="children">
              {tipos.map((x) => (
                <Option key={x.value} value={x.value}>
                  {x.text}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <div className="form-actions">
            <Link to="/parametros/estructura/agrupamientos">
              <Button type="secondary">Volver</Button>
            </Link>
            {GUARDAR && (
              <Button type="primary" htmlType="submit" loading={state.loading}>
                Guardar
              </Button>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AgrupamientosForm;
