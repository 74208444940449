import React, { useContext, useState } from "react";
import { Dropdown, Menu, Button, Modal } from "antd";
import { SlideDown } from "react-slidedown";
import {
  FiChevronDown,
  FiChevronUp,
  FiEdit,
  FiMap,
  FiMoreVertical,
  FiTrash2,
} from "react-icons/fi";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { Context } from "./../../../../services/context";
import http from "./../../../../services/http";
import { showSuccess } from "./../../../../services/notificacion";
import { hasAction, actions } from "./../../../../services/security";

import SucursalesForm from "./SucursalesForm";

import "react-slidedown/lib/slidedown.css";

const Items = () => {
  const CREAR = hasAction(actions.BANCOS_SUCURSALES_CREAR);
  const EDITAR = hasAction(actions.BANCOS_SUCURSALES_EDITAR);
  const ELIMINAR = hasAction(actions.BANCOS_SUCURSALES_ELIMINAR);

  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useContext(Context);
  const [visible, setVisible] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const [current, setCurrent] = useState({});

  const menu = (item) => {
    return (
      <Menu className="table-actions-menu">
        {EDITAR && (
          <Menu.Item key="0" onClick={() => onEdit(item)}>
            <FiEdit />
            Editar
          </Menu.Item>
        )}
        {ELIMINAR && (
          <Menu.Item key="1" onClick={() => onDelete(item)}>
            <FiTrash2 />
            Eliminar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const onAdd = () => {
    setCurrent({});
    setFormVisible(true);
  };

  const onEdit = (item) => {
    setCurrent(item);
    setFormVisible(true);
  };

  const onDelete = (item) => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea eliminar esta sucursal?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const rDelete = await http.delete(`bancos/sucursales/${item.id}`);
        if (rDelete && rDelete.data.code === 0) {
          showSuccess("La sucursal fue eliminada exitosamente.");
        }
      },
    });
  };

  return (
    <div className="section card">
      <div className="section-header" onClick={() => setVisible(!visible)}>
        <div className="icon">
          <FiMap />
        </div>
        <div className="content">
          <div className="title">Sucursales</div>
          <div className="subtitle">{state.sucursales.length} elementos</div>
        </div>
        <div className="collapse">
          {visible ? <FiChevronUp /> : <FiChevronDown />}
        </div>
      </div>
      <SlideDown closed={!visible}>
        <div className="section-content">
          <table className="table-small">
            <thead>
              <tr>
                <th></th>
                <th>Detalle</th>
                <th>Sucursal</th>
                <th>Código</th>
              </tr>
            </thead>
            <tbody>
              {state.sucursales.map((x) => (
                <tr key={x.id}>
                  <td className="table-actions">
                    <Dropdown
                      key={x.id}
                      overlay={() => menu(x)}
                      trigger={["click"]}
                      placement="bottomLeft"
                      arrow
                    >
                      <div>
                        <FiMoreVertical />
                      </div>
                    </Dropdown>
                  </td>
                  <td>{x.detalle}</td>
                  <td>{x.sucursal}</td>
                  <td>{x.codigo}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {CREAR && (
            <div className="table-footer-actions">
              <Button type="primary" onClick={onAdd}>
                Agregar
              </Button>
            </div>
          )}
        </div>
      </SlideDown>

      <Modal
        visible={formVisible}
        onCancel={() => setFormVisible(false)}
        footer={null}
        maskClosable={false}
        keyboard={false}
        destroyOnClose={true}
      >
        <SucursalesForm item={current} onClose={() => setFormVisible(false)} />
      </Modal>
    </div>
  );
};

export default Items;
