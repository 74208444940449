/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import locale from "antd/es/date-picker/locale/es_ES";
import "moment/locale/es";

import { Context } from "./../../../services/context";
import { parseDate, parseToDate } from "./../../../services/forms";
import http from "./../../../services/http";
import { showSuccess } from "./../../../services/notificacion";
import { required } from "./../../../services/validation";

const { TextArea } = Input;
const { Option } = Select;

const AdministrativosForm = ({ current, onCurrentChange }) => {
  const [form] = Form.useForm();
  const [state, setState] = useContext(Context);

  const onBack = () => {
    onCurrentChange(null);
  };

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    values.empleadoId = state.id;
    values.fecha = parseDate(values.fecha);

    const rSave =
      current === 0
        ? await http.post("legajos/administrativos", values)
        : await http.put(`legajos/administrativos/${current}`, values);

    if (rSave && rSave.data.code === 0) {
      showSuccess("El acto administrativo fue guardado exitosamente.");
      setState({ ...state, loading: false });
      onCurrentChange(null);
    } else {
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {
    async function getForm() {
      form.resetFields();

      const rForm = await http.get(`legajos/administrativos/${current}`);
      if (rForm && rForm.data.code === 0) {
        const data = rForm.data.data;
        data.form.fecha = parseToDate(data.form.fecha);
        form.setFieldsValue(data.form);
      }
    }

    getForm();
  }, [state.id, state.reload]);

  return (
    <Form
      form={form}
      className="administrativos-form subsection-form"
      layout="vertical"
      onFinish={onSave}
    >
      <Row gutter={20}>
        <Col span="16">
          <Form.Item label="Motivo" name="motivoMovimiento" rules={[required]}>
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              disabled={true}
            >
              {state.motivosMovimiento.map((x) => (
                <Option key={x.value} value={x.value} disabled={x.disabled}>
                  {x.text}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span="8">
          <Form.Item label="Fecha" name="fecha" rules={[required]}>
            <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col span="12">
          <Form.Item label="Instrumento" name="instrumento">
            <Select allowClear showSearch optionFilterProp="children">
              {state.instrumentos.map((x) => (
                <Option key={x.value} value={x.value}>
                  {x.text}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span="12">
          <Form.Item label="Número" name="instrumentoNumero">
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col span="24">
          <Form.Item label="Expediente" name="expediente">
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item label="Comentarios" name="comentarios" className="textarea">
        <TextArea maxLength={2000} showCount rows={4} />
      </Form.Item>

      <Row gutter={20}>
        <Col span="24">
          <Form.Item label="Usuario" name="usuario">
            <Input disabled={true} />
          </Form.Item>
        </Col>
      </Row>

      <div className="form-actions">
        <Button type="secondary" onClick={onBack}>
          Volver
        </Button>
        <Button type="primary" htmlType="submit" loading={state.loading}>
          Guardar
        </Button>
      </div>
    </Form>
  );
};

export default AdministrativosForm;
