/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Button, Form, DatePicker, Input, Menu, Modal, Select } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  FiCheckCircle,
  FiEdit,
  FiPlus,
  FiTrash2,
  FiPrinter,
  FiRefreshCcw,
} from "react-icons/fi";
import fileDownload from "js-file-download";
import "moment/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";

import { ContextProvider } from "../../../../services/context";
import http from "../../../../services/http";
import { showSuccess } from "../../../../services/notificacion";
import { hasAction, actions } from "../../../../services/security";

import Filters from "../../../../components/Filters";
import FiltersButton from "../../../../components/FiltersButton";
import Header from "../../../../components/Header";
import Table from "../../../../components/Table";
import Historial from "../Historial";

const { Option } = Select;

const Vacaciones = () => {
  const history = useHistory();

  const VER = hasAction(actions.LICENCIAS_VACACIONES_VER);
  const CREAR = hasAction(actions.LICENCIAS_VACACIONES_CREAR);
  const EDITAR = hasAction(actions.LICENCIAS_VACACIONES_EDITAR);
  const ELIMINAR = hasAction(actions.LICENCIAS_VACACIONES_ELIMINAR);
  const AUTORIZAR = hasAction(actions.LICENCIAS_VACACIONES_AUTORIZAR);

  const [state, setState] = useState({
    filters: {
      desde: "",
      hasta: "",
      anio: "",
      empleado: "",
      area: "",
      estado: "",
      codigo: "",
      concepto: "",
      empleadoEstado: "",
    },
    historial: [],
    columns: [
      {
        title: "Empleado",
        style: {},
        render: (item) => item.empleado,
      },
      {
        title: "Año",
        style: {},
        render: (item) => item.anio,
      },
      {
        title: "Código",
        style: {},
        render: (item) => item.codigo,
      },
      {
        title: "Concepto",
        style: {},
        render: (item) => item.concepto,
      },
      {
        title: "Fecha Inicio",
        style: {},
        render: (item) => item.fechaInicio,
      },
      {
        title: "Fecha Fin",
        style: {},
        render: (item) => item.fechaFin,
      },
      {
        title: "Días",
        style: {},
        render: (item) => item.dias,
      },
      {
        title: "Estado",
        style: {},
        render: (item) => item.estado,
      },
      {
        title: "Acumulado",
        style: {},
        render: (item) => item.acumuladoAnual,
      },
      {
        title: "Saldo",
        style: {},
        render: (item) => item.saldoAnual,
      },
    ],
    loading: true,
    loaded: false,
    reload: true,
    loadingExport: false,
    list: [],
    total: 0,
    page: 1,
    size: 10,
  });

  const [areas, setAreas] = useState([]);
  const [codigos, setCodigos] = useState([]);
  const [conceptos, setConceptos] = useState([]);
  const [empleados, setEmpleados] = useState([]);
  const [estados, setEstados] = useState([]);
  const [historial, setHistorial] = useState(false);

  const breadcrumb = [
    { id: 1, title: "Novedades" },
    {
      id: 2,
      title: "Licencias",
      url: "/novedades/licencias",
    },
    {
      id: 3,
      title: "Vacaciones",
      url: "/novedades/licencias/vacaciones",
    },
  ];

  const buttons = () => {
    return (
      <React.Fragment>
        <FiltersButton />
        <Button
          type="primary"
          icon={<FiPrinter />}
          onClick={onExport}
          loading={state.loadingExport}
        >
          Exportar
        </Button>
        {CREAR && (
          <Button type="primary" icon={<FiPlus />} onClick={onAdd}>
            Nuevo
          </Button>
        )}
      </React.Fragment>
    );
  };

  const onExport = async () => {
    setState({ ...state, loadingExport: true });
    const rExport = await http.post(
      "licenciasVacaciones/export",
      state.filters,
      true
    );
    setState({ ...state, loadingExport: false });

    fileDownload(rExport.data, `LicenciaVacaciones_${Date.now()}.xlsx`);
  };

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Desde" name="desde">
          <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
        </Form.Item>
        <Form.Item label="Hasta" name="hasta">
          <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
        </Form.Item>
        <Form.Item label="Año" name="anio">
          <Input type="number" />
        </Form.Item>
        <Form.Item label="Empleado" name="empleado">
          <Select allowClear showSearch optionFilterProp="children">
            {empleados.map((x) => (
              <Option key={x.id} value={x.id}>
                {x.nombre}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Estado" name="estado">
          <Select allowClear showSearch optionFilterProp="children">
            {estados.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Código" name="codigo">
          <Select allowClear showSearch optionFilterProp="children">
            {codigos.map((x) => (
              <Option key={x.id} value={x.id.toString()} disabled={x.disabled}>
                {x.codigo} - {x.detalle}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Área" name="area">
          <Select allowClear showSearch optionFilterProp="children">
            {areas.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Concepto" name="concepto">
          <Select allowClear showSearch optionFilterProp="children">
            {conceptos.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Estado Empleado" name="empleadoEstado">
          <Select>
            <Option value="1">Habilitado</Option>
            <Option value="0">Dado de baja</Option>
          </Select>
        </Form.Item>
      </React.Fragment>
    );
  };

  const onHistoryClick = async (item) => {
    const response = await http.get("licenciasvacaciones/Historial/" + item.id);
    if (response && response.data.code === 0) {
      const data = response.data.data;
      setState({ ...state, historial: data });
    }
    setHistorial(true);
  };

  const menu = (item) => {
    return (
      <Menu className="table-actions-menu">
        {EDITAR && (
          <Menu.Item key="0" onClick={() => onEdit(item)}>
            <FiEdit />
            Editar
          </Menu.Item>
        )}
        <Menu.Item key="1" onClick={() => onHistoryClick(item)}>
          <FiRefreshCcw />
          Ver Historial
        </Menu.Item>
        {AUTORIZAR && item.autorizable && (
          <Menu.Item key="2" onClick={() => onAuthorize(item)}>
            <FiCheckCircle />
            Autorizar
          </Menu.Item>
        )}
        {ELIMINAR && (
          <Menu.Item key="3" onClick={() => onDelete(item)}>
            <FiTrash2 />
            Eliminar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const onAdd = () => {
    history.push("/novedades/licencias/vacaciones/nuevo");
  };

  const onEdit = (item) => {
    history.push(`/novedades/licencias/vacaciones/${item.id}`);
  };

  const onAuthorize = (item) => {
    Modal.confirm({
      title: "Confirmar autorización",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea autorizar esta licencia?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const rAuthorize = await http.put(
          `licenciasvacaciones/authorize/${item.id}`
        );
        if (rAuthorize && rAuthorize.data.code === 0) {
          showSuccess("La licencia fue autorizada exitosamente.");
        }
      },
    });
  };

  const onDelete = (item) => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea eliminar esta licencia?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const rDelete = await http.delete(`licenciasvacaciones/${item.id}`);
        if (rDelete && rDelete.data.code === 0) {
          showSuccess("La licencia fue eliminada exitosamente.");
        }
      },
    });
  };

  useEffect(() => {
    async function getData() {
      const response = await http.get("licenciasvacaciones/DataGeneric");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setAreas(data.areas);
        setCodigos(data.codigos);
        setConceptos(data.conceptos);
        setEmpleados(data.empleados);
        setEstados(data.estados);
      }
    }

    getData();
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="vacaciones page">
      <ContextProvider value={[state, setState]}>
        <Header
          title="Licencias por Vacaciones"
          breadcrumb={breadcrumb}
          buttons={buttons}
        />
        <Filters filters={filters} />
        <Table url="licenciasvacaciones/list" menu={menu} />
        <React.Fragment>
          <Modal
            title="Historial"
            visible={historial}
            onCancel={() => setHistorial(false)}
            footer={null}
            maskClosable={false}
            keyboard={false}
            destroyOnClose={true}
            width={900}
          >
            <Historial onClose={() => setHistorial(false)} />
          </Modal>
        </React.Fragment>
      </ContextProvider>
    </div>
  );
};

export default Vacaciones;
