import React from "react";
import { Col, Row } from "antd";

import { hasAction, actions } from "./../../../services/security";

import Header from "../../../components/Header";
import MenuCard from "./../../../components/MenuCard";

const Liquidacion = () => {
  const breadcrumb = [
    {
      id: 1,
      title: "Parámetros",
    },
    {
      id: 2,
      title: "Liquidación",
      url: "/parametros/liquidacion",
    },
  ];

  const items = [
    {
      id: 1,
      titulo: "Agentes Embargos",
      path: "/parametros/liquidacion/agenteEmbargo",
      color: "primary",
      icono: "FiGrid",
      entidad: "AgenteEmbargo",
      visible: hasAction(actions.AGENTEEMBARGO_VER),
    },
    {
      id: 2,
      titulo: "Conceptos",
      path: "/parametros/liquidacion/conceptos",
      color: "primary",
      icono: "FiGrid",
      entidad: "Concepto",
      visible: hasAction(actions.CONCEPTOS_VER),
    },
    {
      id: 3,
      titulo: "Códigos de Lic",
      path: "/parametros/liquidacion/licencias_codigos",
      color: "primary",
      icono: "FiCalendar",
      entidad: "LicenciaCodigo",
      visible: hasAction(actions.LICENCIAS_CODIGOS_VER),
    },
    {
      id: 4,
      titulo: "Formatos de Importación",
      path: "/parametros/liquidacion/formatos",
      color: "primary",
      icono: "FiFileText",
      entidad: "ImportacionFormato",
      visible: hasAction(actions.IMPORTACION_FORMATOS_VER),
    },
    {
      id: 5,
      titulo: "Operandos",
      path: "/parametros/liquidacion/operandos",
      color: "primary",
      icono: "FiCode",
      entidad: "Operando",
      visible: hasAction(actions.OPERANDO_VER),
    },
    {
      id: 6,
      titulo: "Regimenes de Licencia",
      path: "/parametros/liquidacion/regimenes",
      color: "primary",
      icono: "FiBook",
      entidad: "RegimenLicencia",
      visible: hasAction(actions.REGIMENES_LICENCIAS_VER),
    },
    {
      id: 7,
      titulo: "Tipos de Embargos",
      path: "/parametros/liquidacion/tipoEmbargo",
      color: "primary",
      icono: "FiGrid",
      entidad: "TipoEmbargo",
      visible: hasAction(actions.TIPOEMBARGO_VER),
    },
  ];

  return (
    <div className="inicio">
      <Header title="Liquidación" breadcrumb={breadcrumb} buttons={() => {}} />

      <Row gutter={[32, 36]}>
        {items.map((i) => (
          <Col key={i.id} xs={12} md={8} xl={6} xxl={4}>
            <MenuCard item={i} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Liquidacion;
