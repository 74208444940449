/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { Button, Form, Select, DatePicker } from "antd";
import { FiPrinter } from "react-icons/fi";

import fileDownload from "js-file-download";

import "moment/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";

import { ContextProvider } from "./../../../../../services/context";
import http from "./../../../../../services/http";
import { hasAction, actions } from "./../../../../../services/security";

import Filters from "./../../../../../components/Filters";
import FiltersButton from "./../../../../../components/FiltersButton";
import Header from "./../../../../../components/Header";
import Table from "./../../../../../components/Table";

const { Option } = Select;

const Funcional = () => {
  const VER = hasAction(actions.FUNCIONAL_VER);

  const [state, setState] = useState({
    filters: {
      empleadoId: "",
      agrupamientoId: "",
      funcionId: "",
      convenioId: "",
      direccionId: "",
      documento: "",
      fechaIngresoDesde: null,
      fechaIngresoHasta: null,
      estado: "",
      motivoBaja: "",
      retieneCargo: "",
    },
    columns: [
      {
        id: 1,
        title: "Legajo",
        style: {},
        render: (item) => item.empleadoId,
      },
      {
        id: 2,
        title: "Agrup.",
        style: {},
        render: (item) => item.agrupamiento,
      },
      {
        id: 3,
        title: "Denominación",
        style: {},
        render: (item) => item.agrupamientoDescripcion,
      },
      {
        id: 4,
        title: "Categoría",
        style: {},
        render: (item) => item.claseDescripcion,
      },
      {
        id: 5,
        title: "Apellido y Nombre",
        style: {},
        render: (item) => item.nombre,
      },
      {
        id: 6,
        title: "Dni",
        style: {},
        render: (item) => item.documento,
      },
      {
        id: 7,
        title: "Fec. Ingreso.",
        style: {},
        render: (item) => item.fechaIngreso,
      },
      {
        id: 8,
        title: "CV",
        style: {},
        render: (item) => item.convenio,
      },
      {
        id: 9,
        title: "Dirección",
        style: {},
        render: (item) => item.direccion,
      },
      {
        id: 10,
        title: "Función",
        style: {},
        render: (item) => item.funcion,
      },
      {
        id: 11,
        title: "Retiene Cargo",
        style: {},
        render: (item) => item.retieneCargo,
      },
      {
        id: 12,
        title: "Estado",
        style: {},
        render: (item) => item.estado,
      },
      {
        id: 13,
        title: "Motivo Baja",
        style: {},
        render: (item) => item.motivoBaja,
      },
    ],
    loading: true,
    loaded: false,
    reload: true,
    list: [],
    total: 0,
    page: 1,
    size: 10,
  });

  const [empleados, setEmpleados] = useState([]);
  const [funciones, setFunciones] = useState([]);
  const [direcciones, setDirecciones] = useState([]);
  const [agrupamientos, setAgrupamientos] = useState([]);
  const [convenios, setConvenios] = useState([]);
  const [motivosBaja, setMotivosBaja] = useState([]);
  const [estados, setEstados] = useState([]);

  const breadcrumb = [
    { id: 1, title: "Administración" },
    {
      id: 2,
      title: "Estructura",
      url: "/administracion/estructura",
    },
    {
      id: 3,
      title: "S.E.O.S",
      url: "/administracion/estructura/seos",
    },
    {
      id: 4,
      title: "Funcional",
      url: "/administracion/estructura/seos/funcional",
    },
  ];

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Empleado" name="empleadoId">
          <Select showSearch allowClear optionFilterProp="children">
            {empleados.map((m) => (
              <Option key={m.value} value={m.value}>
                {m.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Agrupamiento" name="agrupamientoId">
          <Select showSearch allowClear optionFilterProp="children">
            {agrupamientos.map((m, index) => (
              <Option key={index} value={m.value}>
                {m.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Dirección" name="direccionId">
          <Select showSearch allowClear optionFilterProp="children">
            {direcciones.map((m, index) => (
              <Option key={index} value={m.value}>
                {m.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Función" name="funcionId">
          <Select showSearch allowClear optionFilterProp="children">
            {funciones.map((m, index) => (
              <Option key={index} value={m.value}>
                {m.text}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Convenio" name="convenioId">
          <Select showSearch allowClear optionFilterProp="children">
            {convenios.map((m, index) => (
              <Option key={index} value={m.value}>
                {m.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Fecha Ingreso Desde" name="fechaIngresoDesde">
          <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
        </Form.Item>

        <Form.Item label="Fecha Ingreso Hasta" name="fechaIngresoHasta">
          <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
        </Form.Item>
        <Form.Item label="Estado" name="estado">
          <Select
            mode="multiple"
            showSearch
            allowClear
            optionFilterProp="children"
          >
            {estados.map((m) => (
              <Option key={m.value} value={m.value}>
                {m.text}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Motivo Baja" name="motivoBaja">
          <Select
            mode="multiple"
            showSearch
            allowClear
            optionFilterProp="children"
          >
            {motivosBaja.map((m) => (
              <Option key={m.value} value={m.value}>
                {m.text}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Retiene Cargo" name="retieneCargo">
          <Select showSearch allowClear>
            <Option key="0" value="0">
              No
            </Option>
            <Option key="1" value="1">
              Si
            </Option>
          </Select>
        </Form.Item>
      </React.Fragment>
    );
  };

  const onExport = async () => {
    const rExport = await http.post(
      "plantaFuncional/export/3",
      state.filters,
      true
    );

    fileDownload(rExport.data, `PlantaFuncional_${Date.now()}.xlsx`);
  };

  const buttons = () => {
    return (
      <React.Fragment>
        <FiltersButton />
        <Button type="primary" icon={<FiPrinter />} onClick={onExport}>
          Exportar
        </Button>
      </React.Fragment>
    );
  };

  useEffect(() => {
    async function getData() {
      const response = await http.get("plantaFuncional/data/3");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setEmpleados(data.empleados);
        setFunciones(data.funciones);
        setConvenios(data.convenios);
        setDirecciones(data.direcciones);
        setAgrupamientos(data.agrupamientos);
        setEstados(data.estados);
        setMotivosBaja(data.motivosBaja);
      }
    }

    getData();
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="funcional page">
      <ContextProvider value={[state, setState]}>
        <Header
          title="Planta Funcional"
          breadcrumb={breadcrumb}
          buttons={buttons}
        />
        <Filters filters={filters} />
        <Table url="plantaFuncional/list/3" />
      </ContextProvider>
    </div>
  );
};

export default Funcional;
