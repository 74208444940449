import InputMask from "react-input-mask";
import { Input } from "antd";

const InputMasked = (props) => {
  return (
    <InputMask maskChar=" " {...props}>
      {(inputProps) => <Input {...inputProps} />}
    </InputMask>
  );
};

export default InputMasked;
