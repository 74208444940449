/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Form, Input, Button } from "antd";
import { FiLayers } from "react-icons/fi";

import http from "../../../../services/http";
import { showSuccess } from "../../../../services/notificacion";
import { hasAction, actions } from "../../../../services/security";
import { required } from "../../../../services/validation";

import Header from "../../../../components/Header";

const TipoEmbargoForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const VER = hasAction(actions.TIPOEMBARGO_VER);
  const CREAR = hasAction(actions.TIPOEMBARGO_CREAR);
  const EDITAR = hasAction(actions.TIPOEMBARGO_EDITAR);
  const GUARDAR = match.params.id === "nuevo" ? CREAR : EDITAR;

  const [state, setState] = useState({
    title: match.params.id === "nuevo" ? "Crear" : EDITAR ? "Editar" : "Ver",
    id: match.params.id === "nuevo" ? 0 : parseInt(match.params.id),
    loading: false,
  });

  const breadcrumb = [
    {
      id: 1,
      title: "Parámetros",
    },
    {
      id: 2,
      title: "Liquidacion",
      url: "/parametros/liquidacion",
    },
    {
      id: 3,
      title: "Tipo De Embargo",
      url: "/parametros/liquidacion/tipoembargo",
    },
    { id: 4, title: state.title },
  ];

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    const rSave =
      state.id === 0
        ? await http.post("tipoembargo", values)
        : await http.put(`tipoembargo/${state.id}`, values);

    if (rSave && rSave.data.code === 0) {
      showSuccess("Tipo guardado exitosamente.");
      history.push("/parametros/liquidacion/tipoembargo");
    } else {
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {
    async function getForm() {
      const rForm = await http.get(`tipoembargo/${state.id}`);

      if (rForm && rForm.data.code === 0) {
        const data = rForm.data.data;
        form.setFieldsValue(data);
      }
    }

    if (VER) {
      getForm();
    }
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="tipoembargo">
      <Header title={`${state.title} Tipo de Embargo`} breadcrumb={breadcrumb} />

      <Form
        form={form}
        name="form-tipoembargo"
        layout="vertical"
        initialValues={state}
        onFinish={onSave}
      >
        <div className="form card">
          <div className="form-icon">
            <FiLayers />
          </div>

          <Form.Item label="Descripción" name="descripcion" rules={[required]}>
            <Input maxLength={500} disabled={!GUARDAR} />
          </Form.Item>

          <Form.Item label="Código" name="codigo" rules={[required]}>
            <Input  disabled={!GUARDAR} />
          </Form.Item>

          <div className="form-actions">
            <Link to="/parametros/liquidacion/tipoembargo">
              <Button type="secondary">Volver</Button>
            </Link>
            {GUARDAR && (
              <Button type="primary" htmlType="submit" loading={state.loading}>
                Guardar
              </Button>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};

export default TipoEmbargoForm;
