/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import { Form, Modal, Select, Button } from "antd";
import { FiGitPullRequest } from "react-icons/fi";

import { Context } from "./../services/context";
import http from "./../services/http";
import { required } from "./../services/validation";

const { Option } = Select;

const OrganigramaForm = () => {
  const [form] = Form.useForm();
  const [state, setState] = useContext(Context);

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    values.tipo = state.tipo;
    const rSave = await http.post("organigrama", values);

    if (rSave && rSave.data.code === 0) {
      setState({
        ...state,
        loading: false,
        reload: !state.reload,
        form: false,
      });
    } else {
      setState({ ...state, loading: false });
    }
  };

  form.resetFields();
  form.setFieldsValue(state.current);

  const onClose = () => {
    setState({ ...state, form: false });
  };

  return (
    <Modal
      visible={state.form}
      forceRender
      footer={null}
      maskClosable={false}
      keyboard={false}
      destroyOnClose={true}
      onCancel={onClose}
    >
      <Form form={form} layout="vertical" onFinish={onSave}>
        <div className="form">
          <div className="form-icon">
            <FiGitPullRequest />
          </div>

          <Form.Item label="Función Origen" name="padre" rules={[required]}>
            <Select allowClear showSearch optionFilterProp="children">
              {state.funciones
                .filter((x) => x.selected)
                .map((x) => (
                  <Option key={x.value} value={x.value}>
                    {x.text}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item label="Nueva Función" name="funcion" rules={[required]}>
            <Select allowClear showSearch optionFilterProp="children">
              {state.funciones
                .filter((x) => !x.selected)
                .map((x) => (
                  <Option key={x.value} value={x.value}>
                    {x.text}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <div className="form-actions">
            <Button type="secondary" onClick={onClose}>
              Volver
            </Button>
            <Button type="primary" htmlType="submit" loading={state.loading}>
              Guardar
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default OrganigramaForm;
