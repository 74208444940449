import React, { useContext } from "react";
import { Dropdown, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { FiMoreVertical } from "react-icons/fi";

import { Context } from "../services/context";


import "./Table.scss";

const TableSimple = ({ title, columns, menu }) => {
  const [state] = useContext(Context);


  return (
    <div className="card-table">
      <div className="table">
        {state.loading && (
          <div className="loading">
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 40, color: "#04bebe" }}
                  spin
                />
              }
            />
            Cargando...
          </div>
        )}

        {!state.loading && (
          <React.Fragment>
            <div className="table-wrapper">
              <table>
                <thead>
                  {title && (
                    <tr>
                      <th className="table-title" colSpan={columns.length + 1}>
                        {title}
                      </th>
                    </tr>
                  )}
                  <tr>
                    <th></th>
                    {columns.map((c) => (
                      <th key={c.id} style={c.style}>
                        {c.title}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {state.list.map((i) => (
                    <tr key={i.id} style={i.style}>
                      <td className="table-actions">
                        {menu && i.id !== undefined && (
                          <Dropdown
                            key={i.id}
                            overlay={() => menu(i)}
                            trigger="click"
                            placement="bottomLeft"
                            arrow
                          >
                            <div>
                              <FiMoreVertical />
                            </div>
                          </Dropdown>
                        )}
                      </td>
                      {columns.map((c) => (
                        <td key={c.id} style={c.style}>
                          {c.render(i)}
                        </td>
                      ))}

                    </tr>
                  ))}


                    <React.Fragment>
                      <tr key={-1} >
                        <td></td>
                        <td></td>
                        <td><b>Total</b></td>
                        <td>{state.totalTable}</td>
                        <td></td>

                      </tr>
                    </React.Fragment>


                </tbody>
              </table>
            </div>


          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default TableSimple;
