/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { FiCalendar } from "react-icons/fi";

import AntecedentesList from "./AntecedentesList";
import AntecedentesForm from "./AntecedentesForm";

const Antecedentes = () => {
  const [current, setCurrent] = useState(null);

  return (
    <div className="antecedentes">
      <div className="section-header">
        <div className="section-title">
          Antecedentes Disciplinarios
          <FiCalendar />
        </div>
        <div className="section-subtitle">
          Lista de sanciones disciplinarias del empleado
        </div>
      </div>

      {current === null && <AntecedentesList onCurrentChange={setCurrent} />}
      {current !== null && (
        <AntecedentesForm current={current} onCurrentChange={setCurrent} />
      )}
    </div>
  );
};

export default Antecedentes;
