/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import { Form, Select } from "antd";
import OrganizationChart from "@dabeng/react-orgchart";

import { Context } from "./../services/context";

import OrganigramaNode from "./OrganigramaNode";

import "./Organigrama.scss";

const { Option } = Select;

const Organigrama = () => {
  const [state, setState] = useContext(Context);

  const onCargoChange = async (value) => {
    setState({
      ...state,
      cargo: value,
    });
  };

  return (
    <div className="organigrama">
      <Form layout="vertical" className="form-organigrama">
        <Form.Item>
          <Select
            allowClear
            showSearch
            optionFilterProp="children"
            placeholder="Buscar función..."
            onChange={onCargoChange}
          >
            {state.funciones.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>

      <OrganizationChart
        ref={state.refChart}
        datasource={state.chart}
        NodeTemplate={OrganigramaNode}
      />
    </div>
  );
};

export default Organigrama;
