import { Fragment } from "react";
import { Col, Modal, Row, Tooltip } from "antd";
import { FiClock } from "react-icons/fi";

import "./Historial.scss";

const Historial = ({ children, visible, modalContent, onOpen, onClose }) => {
  return (
    <Fragment>
      <Row>
        <Col flex="auto" className="historial-content">
          {children}
        </Col>
        <Col flex="40px">
          <Tooltip title="Ver Historial">
            <div className="historial-trigger" onClick={onOpen}>
              <FiClock />
            </div>
          </Tooltip>
        </Col>
      </Row>

      <Modal
        className="modal-historial"
        title="Historial"
        visible={visible}
        onCancel={onClose}
        footer={null}
        maskClosable={false}
        keyboard={false}
        destroyOnClose={true}
        width={700}
      >
        {modalContent}
      </Modal>
    </Fragment>
  );
};

export default Historial;
