/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { FiUsers } from "react-icons/fi";

import FamiliaresList from "./FamiliaresList";
import FamiliaresForm from "./FamiliaresForm";
import FamiliaresPrenatalForm from "./FamiliaresPrenatalForm";

const Familiares = () => {
  const [current, setCurrent] = useState(null);

  return (
    <div className="familiares">
      <div className="section-header">
        <div className="section-title">
          Prenatalidad
          <FiUsers />
        </div>
      </div>
      <FamiliaresPrenatalForm onCurrentChange={setCurrent} />
      <div className="section-header">
        <div className="section-title">
          Grupo Familiar
          <FiUsers />
        </div>
        <div className="section-subtitle">Lista de familiares del empleado</div>
      </div>

      {current === null && <FamiliaresList current={current} onCurrentChange={setCurrent} />}
      {current !== null && (
        <FamiliaresForm current={current} onCurrentChange={setCurrent} />
      )}
    </div>
  );
};

export default Familiares;
