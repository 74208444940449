/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { Col, DatePicker, Form, Input, Row, Select } from "antd";
import { FiBriefcase } from "react-icons/fi";
import locale from "antd/es/date-picker/locale/es_ES";
import "moment/locale/es";

import { Context } from "./../../../services/context";
import { parseToDate, parseToTime } from "./../../../services/forms";
import http from "./../../../services/http";
import { hasAction, actions } from "./../../../services/security";

const { Option } = Select;

const LaboralesResumen = () => {
  const [form] = Form.useForm();
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useContext(Context);

  const CREAR = hasAction(actions.LEGAJOS_CREAR);
  const EDITAR = hasAction(actions.LEGAJOS_EDITAR);
  const GUARDAR =
    state.id < 0
      ? false
      : state.id === 0
      ? CREAR && state.editing
      : EDITAR && state.editing;

  useEffect(() => {
    async function getForm() {
      form.resetFields();

      const rForm = await http.get(`legajos/laborales/${state.id}`);
      if (rForm && rForm.data.code === 0) {
        const data = rForm.data.data;

        data.form.fechaIngreso = parseToDate(data.form.fechaIngreso);
        data.form.fechaIngresoBonoSueldo = parseToDate(
          data.form.fechaIngresoBonoSueldo
        );
        data.form.cargoInterinoDesde = parseToDate(
          data.form.cargoInterinoDesde
        );
        data.form.cargoInterinoHasta = parseToDate(
          data.form.cargoInterinoHasta
        );
        data.form.horarioDesde = parseToTime(data.form.horarioDesde);
        data.form.horarioHasta = parseToTime(data.form.horarioHasta);
        data.form.delegadoDesde = parseToDate(data.form.delegadoDesde);
        data.form.delegadoHasta = parseToDate(data.form.delegadoHasta);
        data.form.sindicalDesde = parseToDate(data.form.sindicalDesde);
        data.form.sindicalHasta = parseToDate(data.form.sindicalHasta);
        data.form.insalubreDesde = parseToDate(data.form.insalubreDesde);
        data.form.insalubreHasta = parseToDate(data.form.insalubreHasta);
        data.form.riesgosoDesde = parseToDate(data.form.riesgosoDesde);
        data.form.riesgosoHasta = parseToDate(data.form.riesgosoHasta);
        data.form.fechaEstado = parseToDate(data.form.fechaEstado);
        data.form.fechaBaja = parseToDate(data.form.fechaBaja);

        form.setFieldsValue(data.form);
      }
    }

    getForm();
  }, [state.id, state.reload]);

  return (
    <div className="personales">
      <div className="section-header">
        <div className="section-title">
          Datos Laborales
          <FiBriefcase />
        </div>
        <div className="section-subtitle">Información laboral del empleado</div>
      </div>

      <Form form={form} className="subsection-form" layout="vertical">
        <Row gutter={20}>
          <Col span="24">
            <Form.Item label="Función" name="funcion">
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                disabled={!GUARDAR}
              >
                {state.funciones.map((x) => (
                  <Option key={x.value} value={x.value} disabled={x.disabled}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span="24">
            <Form.Item label="Ubicación" name="ubicacion">
              <Input disabled={!GUARDAR} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span="24">
            <Form.Item label="Categoría / Clase" name="clase">
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                disabled={!GUARDAR}
              >
                {state.clases.map((x) => (
                  <Option key={x.value} value={x.value}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span="24">
            <Form.Item label="Agrupamiento" name="agrupamiento">
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                disabled={!GUARDAR}
              >
                {state.agrupamientos.map((x) => (
                  <Option key={x.value} value={x.value} disabled={x.disabled}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span="24">
            <Form.Item label="Antigüedad Total" name="antiguedadTotal">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span="16">
            <Form.Item label="Estado" name="estado">
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                disabled={!GUARDAR}
              >
                {state.estados.map((x) => (
                  <Option key={x.value} value={x.value}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item label="Fecha" name="fechaEstado">
              <DatePicker
                format="DD/MM/YYYY"
                placeholder=""
                locale={locale}
                disabled={!GUARDAR}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span="16">
            <Form.Item label="Motivo de Baja" name="motivoBaja">
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                disabled={!GUARDAR}
              >
                {state.motivosBaja.map((x) => (
                  <Option key={x.value} value={x.value} disabled={x.disabled}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item label="Fecha de Último Día de Trabajo" name="fechaBaja">
              <DatePicker
                format="DD/MM/YYYY"
                placeholder=""
                locale={locale}
                disabled={!GUARDAR}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default LaboralesResumen;
