/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Form, Input, Button, Row, Col, Switch, Modal, Select } from "antd";
import { FiCalendar, FiPaperclip } from "react-icons/fi";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import "moment/locale/es";

import { parseDate, parseToDate } from "./../../../../services/forms";
import http from "../../../../services/http";
import { showSuccess } from "../../../../services/notificacion";
import { hasAction, actions } from "../../../../services/security";

import Header from "../../../../components/Header";

import Archivos from "./Archivos";

import "../Licencias.scss";
import LicenciasForm from "./LicenciasForm";

const { Option } = Select;

const EnfermedadesForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const VER = hasAction(actions.LICENCIASENFERMEDAD_VER);
  const CREAR = hasAction(actions.LICENCIASENFERMEDAD_CREAR);
  const EDITAR = hasAction(actions.LICENCIASENFERMEDAD_EDITAR);
  const GUARDAR = match.params.id === "nuevo" ? CREAR : EDITAR;

  const [state, setState] = useState({
    title:
      match.params.id === "nuevo" ? "Registrar" : EDITAR ? "Editar" : "Ver",
    id: match.params.id === "nuevo" ? 0 : parseInt(match.params.id),
    loading: false,
    historial: [],
  });

  const [nroAfiliado, setNroAfiliado] = useState([]);
  const [diagnosticos, setDiagnosticos] = useState([]);
  const [dataForm, setDataForm] = useState(null);

  const breadcrumb = [
    { id: 1, title: "Novedades" },
    {
      id: 2,
      title: "Licencias",
      url: "/novedades/licencias",
    },
    {
      id: 3,
      title: "Licencias por Enfermedades",
      url: "/novedades/licencias/enfermedades",
    },
    { id: 4, title: state.title },
  ];

  const onSave = async (values) => {
    if (nroAfiliado !== values.nroAfiliado) {
      Modal.confirm({
        title: "Confirmar actualización",
        icon: <ExclamationCircleOutlined />,
        content: "Se actualizará el Nro de afiliado ¿Desea Continuar?",
        okText: "Confirmar",
        cancelText: "Cancelar",
        onOk: async () => {
          save(values);
        },
      });
    } else {
      save(values);
    }
  };

  async function save(values) {
    values.inicio = parseDate(values.inicio);
    values.fin = parseDate(values.fin);

    setState({ ...state, loading: true });
    const result =
      state.id === 0
        ? await http.post("licenciasEnfermedad", values)
        : await http.put(`licenciasEnfermedad/${state.id}`, values);

    if (result && result.data.code === 0) {
      showSuccess("La licencia fue guardado exitosamente.");
      history.push("/novedades/licencias/enfermedades");
    } else {
      setState({ ...state, loading: false });
    }
  }

  useEffect(() => {
    async function getForm() {
      const response = await http.get("licenciasEnfermedad/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setDiagnosticos(data.diagnosticos);
      }

      const rForm = await http.get(`licenciasEnfermedad/${state.id}`);
      if (rForm && rForm.data.code === 0) {
        const data = rForm.data.data;
        setDataForm(data);
        data.fecha = parseToDate(data.fecha);
        data.fechaInicio = parseToDate(data.fechaInicioD);
        data.fechaFin = parseToDate(data.fechaFinD);
        setNroAfiliado(data.nroAfiliado);
        form.setFieldsValue(data);
      }
    }

    if (VER) {
      getForm();
    }
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="LicenciaEnfermedad">
      <Header
        title={`${state.title} Licencia por Enfermedad`}
        breadcrumb={breadcrumb}
      />
      <div className="form card">
        <Form form={form} layout="vertical" onFinish={onSave}>
          <div className="">
            <div className="form-icon">
              <FiCalendar />
            </div>
            <LicenciasForm form={form} dataForm={dataForm} match={match} />
            <Row gutter={20}>
              <Col span="12">
                <Form.Item label="Diagnóstico" name="diagnostico">
                  <Select allowClear showSearch optionFilterProp="children">
                    {diagnosticos.map((x) => (
                      <Option key={x.value} value={x.value.toString()}>
                        {x.text}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item label="Nro parte médico" name="nroParteMedico">
                  <Input disabled={!GUARDAR} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={20}>
              <Col span="12">
                <Form.Item label="Nro Ausentismo" name="numeroAusentismo">
                  <Input maxLength={100} disabled={!GUARDAR} />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item label="Nro Afiliado OSEP" name="nroAfiliado">
                  <Input maxLength={100} disabled={!GUARDAR} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={20}>
              <Col span="12">
                <Form.Item
                  label="Matricula Profesional"
                  name="matriculaProfesional"
                >
                  <Input disabled={!GUARDAR} />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item label="Nombre Profesional" name="nombreProfesional">
                  <Input disabled={!GUARDAR} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={20}>
              <Col span="12">
                <Form.Item label="Zona Profesional" name="zonaProfesional">
                  <Input disabled={!GUARDAR} />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="Domicilio Profesional"
                  name="domicilioProfesional"
                >
                  <Input disabled={!GUARDAR} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={20}>
              <Col span="12">
                <Form.Item label="Matricula Auditor" name="matriculaAuditor">
                  <Input disabled={!GUARDAR} />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item label="Nombre Auditor" name="nombreAuditor">
                  <Input disabled={!GUARDAR} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={20}>
              <Col span="12">
                <Form.Item
                  label="Cant Dìas de Licencia Auditor"
                  name="cantDiasLicenciaAuditor"
                >
                  <Input type="number" disabled={!GUARDAR} />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="¿Es Dudoso?"
                  valuePropName="checked"
                  name="esDudoso"
                >
                  <Switch checkedChildren="Si" unCheckedChildren="No" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={20}>
              <Col span="12">
                <Form.Item
                  label="Matricula Profesional Certificado"
                  name="matriculaProfesionalCertificado"
                >
                  <Input disabled={!GUARDAR} />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="Nombre Profesional Certificado"
                  name="nombreProfesionalCertificado"
                >
                  <Input disabled={!GUARDAR} />
                </Form.Item>
              </Col>
            </Row>
            {state.id > 0 && (
              <Form.Item label="Registrado por" name="usuario">
                <Input disabled={true} />
              </Form.Item>
            )}

            <div className="form-actions">
              <Link to="/novedades/licencias/enfermedades">
                <Button type="secondary">Volver</Button>
              </Link>
              {GUARDAR && (
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={state.loading}
                >
                  Guardar
                </Button>
              )}
            </div>
          </div>
        </Form>
        {state.id > 0 && (
          <React.Fragment>
            <hr />
            <div className="section-header">
              <div className="section-title">
                Documentación
                <FiPaperclip />
              </div>
              <div className="section-subtitle">
                Documentación asociada con la licencia.
              </div>
            </div>
            <Archivos licencia={state.id} />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default EnfermedadesForm;
