/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import { FiPaperclip } from "react-icons/fi";
import locale from "antd/es/date-picker/locale/es_ES";
import "moment/locale/es";

import { Context } from "./../../../services/context";
import { parseDate, parseToDate } from "./../../../services/forms";
import http from "./../../../services/http";
import { showSuccess } from "./../../../services/notificacion";
import { required } from "./../../../services/validation";

import Archivos from "./Archivos";

const { TextArea } = Input;
const { Option } = Select;

const AntecedentesForm = ({ current, onCurrentChange }) => {
  const [form] = Form.useForm();
  const [state, setState] = useContext(Context);

  const onBack = () => {
    onCurrentChange(null);
  };

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    values.empleadoId = state.id;
    values.fecha = parseDate(values.fecha);

    const rSave =
      current === 0
        ? await http.post("legajos/antecedentes", values)
        : await http.put(`legajos/antecedentes/${current}`, values);

    if (rSave && rSave.data.code === 0) {
      showSuccess("El antecedente fue guardado exitosamente.");
      setState({ ...state, loading: false });
      onCurrentChange(null);
    } else {
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {
    async function getForm() {
      form.resetFields();

      const rForm = await http.get(`legajos/antecedentes/${current}`);
      if (rForm && rForm.data.code === 0) {
        const data = rForm.data.data;
        data.form.fecha = parseToDate(data.form.fecha);
        form.setFieldsValue(data.form);
      }
    }

    getForm();
  }, [state.id, state.reload]);

  return (
    <div className="antecedentes-form ">
      <Form
        form={form}
        className="subsection-form"
        layout="vertical"
        onFinish={onSave}
      >
        <Form.Item
          label="Tipo de Sanción"
          name="tipoSancion"
          rules={[required]}
        >
          <Select allowClear showSearch optionFilterProp="children">
            {state.tiposSancion.map((x) => (
              <Option key={x.value} value={x.value} disabled={x.disabled}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Motivo" name="motivo" rules={[required]}>
          <Select allowClear showSearch optionFilterProp="children">
            {state.motivos.map((x) => (
              <Option key={x.value} value={x.value} disabled={x.disabled}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Row gutter={20}>
          <Col span="8">
            <Form.Item label="Instrumento" name="instrumento">
              <Select allowClear showSearch optionFilterProp="children">
                {state.instrumentos.map((x) => (
                  <Option key={x.value} value={x.value}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item label="N°" name="instrumentoNumero">
              <Input />
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item label="Fecha" name="fecha">
              <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span="12">
            <Form.Item label="Días de Sanción" name="dias">
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item label="Estado" name="estado">
              <Select allowClear showSearch optionFilterProp="children">
                {state.antecedentesEstados.map((x) => (
                  <Option key={x.value} value={x.value}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Expediente" name="expediente" rules={[required]}>
          <Input />
        </Form.Item>

        <Form.Item label="Comentarios" name="comentarios" className="textarea">
          <TextArea maxLength={2000} showCount rows={4} />
        </Form.Item>

        <div className="form-actions">
          <Button type="secondary" onClick={onBack}>
            Volver
          </Button>
          <Button type="primary" htmlType="submit" loading={state.loading}>
            Guardar
          </Button>
        </div>
      </Form>

      {current > 0 && (
        <React.Fragment>
          <hr />
          <div className="section-header">
            <div className="section-title">
              Documentación
              <FiPaperclip />
            </div>
            <div className="section-subtitle">
              Documentación asociada con el estudio / capacitación.
            </div>
          </div>
          <Archivos seccion="4" antecedente={current} />
        </React.Fragment>
      )}
    </div>
  );
};

export default AntecedentesForm;
