import React from "react";
import { Col, Row } from "antd";

import { hasAction, actions } from "./../../../services/security";

import Header from "../../../components/Header";
import MenuCard from "../../../components/MenuCard";

const Novedad = () => {
  const breadcrumb = [
    {
      id: 1,
      title: "Reportes",
    },
    {
      id: 2,
      title: "Novedades",
      url: "/reportes/novedad",
    },
  ];

  const items = [
    {
      id: 1,
      titulo: "Licencias Por Vacaciones",
      path: "/reportes/novedad/vacaciones",
      color: "primary",
      icono: "FiFileText",
      visible: hasAction(actions.REPORTE_NOVEDAD_VACACIONES),
    },
    {
      id: 2,
      titulo: "Accidentes de Trabajo",
      path: "/reportes/novedad/accidentes",
      color: "primary",
      icono: "FiFileText",
      visible: hasAction(actions.REPORTE_NOVEDAD_ACCIDENTES),
    },
    {
      id: 3,
      titulo: "Licencias por Enfermedad",
      path: "/reportes/novedad/enfermedades",
      color: "primary",
      icono: "FiFileText",
      visible: hasAction(actions.REPORTE_NOVEDAD_ENFERMEDADES),
    },
    {
      id: 4,
      titulo: "Licencias Especiales / Ausencias",
      path: "/reportes/novedad/especiales",
      color: "primary",
      icono: "FiFileText",
      visible: hasAction(actions.REPORTE_NOVEDAD_ESPECIALES),
    },
    {
      id: 5,
      titulo: "Ausentismo",
      path: "/reportes/novedad/ausentismo",
      color: "primary",
      icono: "FiFileText",
      visible: hasAction(actions.REPORTE_NOVEDAD_AUSENTISMO),
    },
    {
      id: 6,
      titulo: "Vacaciones Pendientes",
      path: "/reportes/novedad/vacaciones-pendientes",
      color: "primary",
      icono: "FiCalendar",
      visible: hasAction(actions.REPORTE_NOVEDAD_VACACIONES_PENDIENTES),
    },
  ];

  return (
    <div className="inicio">
      <Header title="Novedades" breadcrumb={breadcrumb} buttons={() => {}} />

      <Row gutter={[32, 36]}>
        {items.map((i) => (
          <Col key={i.id} xs={12} md={8} xl={6} xxl={4}>
            <MenuCard item={i} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Novedad;
