/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Button, Form, Input, Spin, Select } from "antd";
import { FiPrinter } from "react-icons/fi";
import { LoadingOutlined } from "@ant-design/icons";

import { ContextProvider } from "../../../../services/context";
import { formatCurrency } from "../../../../services/forms";
import http from "../../../../services/http";
import { hasAction, actions } from "../../../../services/security";

import Filters from "../../../../components/Filters";
import FiltersButton from "../../../../components/FiltersButton";
import Header from "../../../../components/Header";
import fileDownload from "js-file-download";

import "moment/locale/es";
import * as moment from "moment";

import "./FinalidadFuncion.scss";

const { Option } = Select;

const FinalidadFuncion = () => {
  const history = useHistory();

  const VER = hasAction(actions.REPORTE_LIQUIDACION_FINALIDAD_FUNCION);

  const [loadingExport, setLoadingExport] = useState(false);
  const [conceptos, setConceptos] = useState([]);
  const [convenios, setConvenios] = useState([]);
  const [agrupamientos, setAgrupamientos] = useState([]);
  const [direcciones, setDirecciones] = useState([]);
  const [finalidadFuncion, setFinalidadFuncion] = useState([]);
  const [reparticiones, setReparticiones] = useState([]);
  const [tiposLiquidacion, setTiposLiquidacion] = useState([]);

  const [reporte, setReporte] = useState([]);

  const [state, setState] = useState({
    filters: {
      mes: (moment().month() + 1).toString(),
      anio: moment().year().toString(),
      tipoLiquidacion: "",
      finalidadFuncion: "",
      banco: "",
      concepto: [],
      convenio: [],
      agrupamiento: "",
      reparticion: "",
      direccion: "",
    },
    loading: true,
    loaded: false,
    reload: true,
  });

  const breadcrumb = [
    {
      id: 1,
      title: "Reportes",
    },
    {
      id: 2,
      title: "Liquidación",
      url: "/reportes/liquidacion",
    },
    {
      id: 3,
      title: "Finalidad y Función",
      url: "/reportes/liquidacion/finalidad-funcion",
    },
  ];

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Año" name="anio">
          <Input />
        </Form.Item>
        <Form.Item label="Mes" name="mes">
          <Select allowClear>
            <Option value="1">Enero</Option>
            <Option value="2">Febrero</Option>
            <Option value="3">Marzo</Option>
            <Option value="4">Abril</Option>
            <Option value="5">Mayo</Option>
            <Option value="6">Junio</Option>
            <Option value="7">Julio</Option>
            <Option value="8">Agosto</Option>
            <Option value="9">Septiembre</Option>
            <Option value="10">Octubre</Option>
            <Option value="11">Noviembre</Option>
            <Option value="12">Diciembre</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Tipo de Liquidación" name="tipoLiquidacion">
          <Select allowClear>
            {tiposLiquidacion.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Finalidad y Funcion" name="finalidadFuncion">
          <Select
            mode="multiple"
            allowClear
            showSearch
            optionFilterProp="children"
            maxTagCount="responsive"
          >
            {finalidadFuncion.map((item, index) => (
              <Option key={index} value={item.value}>
                {item.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Concepto" name="concepto">
          <Select
            mode="multiple"
            allowClear
            showSearch
            optionFilterProp="children"
            maxTagCount="responsive"
          >
            {conceptos.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Convenio" name="convenio">
          <Select
            mode="multiple"
            allowClear
            showSearch
            optionFilterProp="children"
            maxTagCount={0}
          >
            {convenios.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Repartición" name="reparticion">
          <Select allowClear showSearch optionFilterProp="children">
            {reparticiones.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Agrupamiento" name="agrupamiento">
          <Select allowClear showSearch optionFilterProp="children">
            {agrupamientos.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Dirección" name="direccion">
          <Select allowClear showSearch optionFilterProp="children">
            {direcciones.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Banco" name="banco">
          <Input />
        </Form.Item>
      </React.Fragment>
    );
  };

  useEffect(() => {
    async function getData() {
      const response = await http.get(
        "reportes/Liquidacion/FinalidadFuncion/Data"
      );
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setAgrupamientos(data.agrupamientos);
        setConceptos(data.conceptos);
        setConvenios(data.convenios);
        setDirecciones(data.direcciones);
        setFinalidadFuncion(data.finalidadFuncion);
        setReparticiones(data.reparticiones);
        setTiposLiquidacion(data.tiposLiquidacion);
        setState({ ...state, loaded: true });
      }
    }

    getData();
  }, []);

  useEffect(() => {
    async function getList() {
      if (!state.loaded) {
        return;
      }

      setState({ ...state, loading: true });

      const response = await http.post(
        "reportes/liquidacion/finalidadfuncion/list",
        { ...state.filters }
      );

      if (response && response.data.code === 0) {
        const data = response.data.data;
        setReporte(data);
      }

      setState({ ...state, loading: false });

      localStorage.setItem(
        history.location.pathname,
        JSON.stringify({
          filters: state.filters,
        })
      );
    }

    getList();
  }, [state.loaded, state.reload]);

  const onExport = async () => {
    setLoadingExport(true);
    const rExport = await http.post(
      "Reportes/Liquidacion/FinalidadFuncion/Export",
      state.filters,
      true
    );
    setLoadingExport(false);

    fileDownload(rExport.data, `FinalidadFuncion${Date.now()}.xlsx`);
  };

  if (!VER) {
    return <Redirect to="/" />;
  }
  const buttons = () => {
    return (
      <React.Fragment>
        <FiltersButton />
        <Button
          type="primary"
          icon={<FiPrinter />}
          onClick={onExport}
          loading={loadingExport}
        >
          Exportar
        </Button>
      </React.Fragment>
    );
  };

  return (
    <div className="finalidadFuncion page">
      <ContextProvider value={[state, setState]}>
        <Header
          title="Finalidad y Función"
          breadcrumb={breadcrumb}
          buttons={buttons}
        />
        <Filters filters={filters} />

        <div className="card-table">
          {state.loading === true && (
            <div className="loading">
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 40, color: "#04bebe" }}
                    spin
                  />
                }
              />
              Cargando...
            </div>
          )}
        </div>

        {state.loading === false && (
          <div>
            {reporte.grupos.map((grupo, index) => (
              <div key={index} className="card grupo">
                <div className="grupo-name">
                  {grupo.denominacion ?? "Sin especificar"}
                </div>
                {grupo.unidades.map((unidad, indexUnidad) => (
                  <div key={indexUnidad} className="convenio">
                    <div className="row grupo-header">
                      <div className="convenio-name">U. Ejecutora</div>
                      <div className="convenio-name">Denominacion</div>
                      <div className="concepto">Partida</div>
                      <div className="concepto">Denominacion</div>
                      <div className="importe">Importe</div>
                    </div>
                    {unidad.partidas.map((partida, indexPartida) => (
                      <div key={indexPartida} className="row">
                        <div className="convenio-name">
                          {indexPartida === 0 && unidad.unidad}
                        </div>
                        <div className="convenio-name">
                          {indexPartida === 0 && unidad.denominacion}
                        </div>
                        <div className="concepto">{partida.partida}</div>
                        <div className="concepto">{partida.denominacion}</div>
                        <div className="importe">
                          {formatCurrency(partida.importe)}
                        </div>
                      </div>
                    ))}
                    <div className="row">
                      <div className="total-title">Total Unidad Ejecutora</div>
                      <div className="total-value">
                        {formatCurrency(unidad.total)}
                      </div>
                    </div>
                  </div>
                ))}
                <div className="row">
                  <div className="total-title">Total Finalidad y Función</div>
                  <div className="total-value">
                    {formatCurrency(grupo.total)}
                  </div>
                </div>
              </div>
            ))}
            <div className="row">
              <div className="total-title-lg">Total</div>
              <div className="total-value-lg">
                {formatCurrency(reporte.total)}
              </div>
            </div>
          </div>
        )}
      </ContextProvider>
    </div>
  );
};

export default FinalidadFuncion;
