import React, { useContext } from "react";
import { Form, Input, Button } from "antd";
import { LoginContext } from "./LoginContext";
import http from "./../../services/http";
import { required } from "./../../services/validation";
import { showSuccess } from "./../../services/notificacion";
import "./ResetForm.scss";

const ResetForm = () => {
  const [form] = Form.useForm();
  const [login, setLogin] = useContext(LoginContext);

  const showLogin = () => {
    setLogin({ ...login, form: "login" });
  };

  const onSave = async (values) => {
    setLogin({ ...login, loading: true });
    let nLogin = { ...login, loading: false };

    const result = await http.post("auth/reset", values);
    if (result && result.data.code === 0) {
      showSuccess(
        "Te enviamos un email a tu correo electrónico con tus nuevas credenciales."
      );
      nLogin.form = "login";
    }

    setLogin(nLogin);
  };

  return (
    <Form
      form={form}
      name="reset"
      layout="vertical"
      initialValues={login}
      className="form-reset"
      onFinish={onSave}
    >
      <h2 className="title">Recuperá tu contraseña</h2>

      <Form.Item
        name="username"
        label="Usuario o correo electrónico"
        rules={[required]}
      >
        <Input />
      </Form.Item>

      <div className="form-actions">
        <Button type="secondary" onClick={showLogin}>
          Cancelar
        </Button>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={login.loading}>
            Recuperar
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};

export default ResetForm;
