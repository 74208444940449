/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useContext, useEffect, useState } from "react";
import { Button, Pagination, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { Context } from "../../../../services/context";
import http from "../../../../services/http";
import fileDownload from "js-file-download";

import "./EliminacionPreview.scss";
import { FiPrinter } from "react-icons/fi";

const EliminacionPreview = () => {
  const [state, setState] = useContext(Context);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState();


  useEffect(() => {
    async function getPreview() {
      setLoading(true);

      const rPreview = await http.post("NovedadVariable/preview", state.values);
      if (rPreview && rPreview.data.code === 0) {
        const data = rPreview.data;
        setState({ ...state, novedades: data.data });
      }
      else {
        state.form.setFieldsValue({ archivo: '' });
        setState({ ...state, step: state.step - 1 });
      }
      setLoading(false);
    }

    getPreview();
  }, []);

  const onBackClick = () => {
    state.form.setFieldsValue({ archivo: '' });
    setState({ ...state, step: state.step - 1 });
  };
  const onExport = async () => {
    const rExport = await http.post(
      "NovedadVariable/Eliminacion/export",
      state.novedades,
      true
    );
    fileDownload(rExport.data, `NovedadesEliminar${Date.now()}.xlsx`);
  };
  return (
    <div className="form-section table-section">
      {loading && (
        <div className="loading">
          <Spin
            indicator={
              <LoadingOutlined
                style={{ fontSize: 40, color: "#04bebe" }}
                spin
              />
            }
          />
          Cargando...
        </div>
      )}

      {!loading && (
        <Fragment>
          <table className="table-small">
            <thead>
              <tr>
                {
                  state.novedades[0] &&
                  state.novedades[0].error === true && (
                    <th>
                      <Button type="secondary" icon={<FiPrinter />} onClick={onExport} >
                        Exportar errores
                      </Button>
                    </th>
                  )
                }
              </tr>
              <tr>
                <th>Linea</th>
                <th style={{ textAlign: "center" }}>Legajo</th>
                <th>Nombre</th>
                <th>Concepto</th>
                <th>Fecha</th>
                <th>Vencimiento</th>
                <th>Importe</th>
                <th>Porcentaje</th>
                <th>Unidades</th>
                <th>Mensajes</th>
              </tr>
            </thead>
            <tbody>
              {state.novedades.length === 0 && (
                <tr>
                  <td className="table-empty" colSpan="9">
                    Sin resultados
                  </td>
                </tr>
              )}

              {state.novedades.slice(page * 10 - 10, page * 10).map((x) => (
                <tr key={x.linea}>
                  <td style={{ minWidth: "5%" }}>{x.linea}</td>
                  <td style={{ textAlign: "center", minWidth: "10%" }}>
                    {x.empleadoId}
                  </td>
                  <td style={{ minWidth: "30%" }}>{x.empleado}</td>
                  <td style={{ minWidth: "10%" }}>{x.concepto}</td>
                  <td style={{ minWidth: "30%" }}>{x.fecha}</td>
                  <td style={{ minWidth: "30%" }}>{x.vencimiento}</td>
                  <td style={{ minWidth: "10%" }}>{x.importe}</td>
                  <td style={{ minWidth: "10%" }}>{x.porcentaje}</td>
                  <td style={{ minWidth: "10%" }}>{x.unidad}</td>
                  <td style={{ minWidth: "30%" }}>{x.mensajes}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="table-footer">
            <div className="count">
              Mostrando {page * 10 - 10 + 1} - {page * 10} de {state.novedades.length}{" "}
              resultados
            </div>
            <Pagination
              defaultPageSize={10}
              current={page}
              total={state.novedades.length}
              showSizeChanger={false}
              onChange={(value) => setPage(value)}
            />
          </div>
        </Fragment>
      )}

      <div className="actions">
        <Button type="secondary" onClick={onBackClick}>
          Anterior
        </Button>
        <Button type="primary" htmlType="submit" loading={state.loading} disabled={state.novedades.length === 0} >
          Finalizar
        </Button>
      </div>
    </div>
  );
};

export default EliminacionPreview