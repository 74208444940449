/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Form, Input, Button } from "antd";
import { FiList } from "react-icons/fi";

import http from "./../../../../services/http";
import { showSuccess } from "./../../../../services/notificacion";
import { hasAction, actions } from "./../../../../services/security";
import { required } from "./../../../../services/validation";

import Header from "./../../../../components/Header";

const MotivosMovimientoForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const VER = hasAction(actions.MOTIVOSMOVIMIENTOS_VER);
  const CREAR = hasAction(actions.MOTIVOSMOVIMIENTOS_CREAR);
  const EDITAR = hasAction(actions.MOTIVOSMOVIMIENTOS_EDITAR);
  const GUARDAR = match.params.id === "nuevo" ? CREAR : EDITAR;

  const [state, setState] = useState({
    title: match.params.id === "nuevo" ? "Crear" : EDITAR ? "Editar" : "Ver",
    id: match.params.id === "nuevo" ? 0 : parseInt(match.params.id),
    loading: false,
  });

  const breadcrumb = [
    {
      id: 1,
      title: "Parámetros",
    },
    {
      id: 2,
      title: "Generales",
      url: "/parametros/generales",
    },
    {
      id: 3,
      title: "Motivos de Movimiento",
      url: "/parametros/generales/motivos_movimiento",
    },
    { id: 4, title: state.title },
  ];

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    const result =
      state.id === 0
        ? await http.post("motivoMovimientos", values)
        : await http.put(`motivoMovimientos/${state.id}`, values);

    if (result && result.data.code === 0) {
      showSuccess("El motivo fue guardado exitosamente.");
      history.push("/parametros/generales/motivos_movimiento");
    } else {
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {
    async function getForm() {
      const rForm = await http.get(`motivoMovimientos/${state.id}`);
      if (rForm && rForm.data.code === 0) {
        const data = rForm.data.data;
        form.setFieldsValue(data);
      }
    }

    if (VER) {
      getForm();
    }
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="motivoMovimiento">
      <Header
        title={`${state.title} Motivo de Movimiento`}
        breadcrumb={breadcrumb}
      />

      <Form form={form} layout="vertical" onFinish={onSave}>
        <div className="form card">
          <div className="form-icon">
            <FiList />
          </div>

          <Form.Item label="Nombre" name="nombre" rules={[required]}>
            <Input maxLength={200} disabled={!GUARDAR} />
          </Form.Item>

          <Form.Item label="Código" name="codigo" rules={[required]}>
            <Input type="number" maxLength={20} disabled={!GUARDAR} />
          </Form.Item>

          <div className="form-actions">
            <Link to="/parametros/generales/motivos_movimiento">
              <Button type="secondary">Volver</Button>
            </Link>
            {GUARDAR && (
              <Button type="primary" htmlType="submit" loading={state.loading}>
                Guardar
              </Button>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};

export default MotivosMovimientoForm;
