/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, Form, Rate } from "antd";
import http from "./../services/http";
import { FiChevronsRight, FiHome } from "react-icons/fi";
import "./Header.scss";


const items = [
  {
    id: 101,
    titulo: "Legajos",
    path: "/administracion/legajos",
    color: "primary",
    icono: "FiFolder",
    entidad: "Empleado",
  },
  {
    id: 102,
    titulo: "Estructura Organizacional",
    path: "/administracion/estructura",
    color: "primary",
    icono: "FiFilter",
  },
  {
    id: 103,
    titulo: "Medicina Laboral",
    path: "/administracion/medicina",
    color: "primary",
    icono: "FiFolderPlus",
    entidad: "MedicinaLaboral",
  },
  {
    id: 104,
    titulo: "Seguridad Laboral",
    path: "/administracion/seguridad",
    color: "primary",
    icono: "FiShield",
  },
  {
    id: 201,
    titulo: "Licencias",
    path: "/novedades/licencias",
    color: "success",
    icono: "FiCalendar",
  },
  {
    id: 202,
    titulo: "Liquidación",
    path: "/novedades/liquidacion",
    color: "success",
    icono: "FiCheckCircle",
  },
  {
    id: 301,
    titulo: "Liquidaciones",
    path: "/liquidaciones",
    color: "info",
    icono: "FiCheckCircle",
    entidad: "Liquidacion",
  },
  {
    id: 302,
    titulo: "Ganancias",
    path: "/liquidacion/ganancias",
    color: "info",
    icono: "FiDollarSign",
  },
  {
    id: 401,
    titulo: "Reportes",
    path: "/reportes",
    color: "primary",
    icono: "FiFileText",
  },
  {
    id: 501,
    titulo: "Generales",
    path: "/parametros/generales",
    color: "success",
    icono: "FiLayers",
  },
  {
    id: 502,
    titulo: "Estructura",
    path: "/parametros/estructura",
    color: "success",
    icono: "FiFilter",
  },
  {
    id: 503,
    titulo: "Liquidación",
    path: "/parametros/liquidacion",
    color: "success",
    icono: "FiCheckCircle",
  },
  {
    id: 504,
    titulo: "Medicina Laboral",
    path: "/parametros/medicina",
    color: "success",
    icono: "FiFolderPlus",
  },
  {
    id: 505,
    titulo: "Seguridad Laboral",
    path: "/parametros/seguridad",
    color: "success",
    icono: "FiShield",
  },
  {
    id: 601,
    titulo: "Perfiles",
    path: "/entorno/perfiles",
    color: "info",
    icono: "FiShield",
    entidad: "Perfil",
  },
  {
    id: 602,
    titulo: "Usuarios",
    path: "/entorno/usuarios",
    color: "info",
    icono: "FiUser",
    entidad: "Usuario",
  },
  {
    id: 603,
    titulo: "Auditoría",
    path: "/entorno/auditoria",
    color: "info",
    icono: "FiClock",
    entidad: "Auditoria",
  },
  {
    id: 604,
    titulo: "Municipio",
    path: "/entorno/municipio",
    color: "info",
    icono: "FiSettings",
    entidad: "Municipio",
  },
];


const Header = ({ title, breadcrumb, buttons }) => {
  const [form] = Form.useForm();

  const [item, setItem] = useState(undefined);
  useEffect(() => {
    async function getCard(element) {
      const rCard = await http.post("menu/card", element);
      if (rCard && rCard.data.code === 0) {
        const response = rCard.data.data;
        form.setFieldsValue({ favorito: response.favourite ? 1 : 0 });
      }
    }
    const i = items.find(a => a.path === breadcrumb[breadcrumb.length - 1].url);
    if (i) {
      setItem(i);
      getCard(i);
    }
  }, []);



  const onFavoriteChange = async () => {
    await http.post("menu/favoritos", item);
  };

  return (
    <div className="header">
      <div className="header-breadcrumb">
        <Breadcrumb separator={<FiChevronsRight />}>
          <Breadcrumb.Item>
            <Link to="/">
              <FiHome />
            </Link>
          </Breadcrumb.Item>
          {breadcrumb.map((level) => (
            <Breadcrumb.Item key={level.id}>
              {level.url === undefined && level.title}
              {level.url !== undefined && (
                <Link to={level.url}>{level.title}</Link>
              )}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </div>

      <div className="header-info">
        <div className="header-title">{title}

          {item && (
            <div className="favourite" >
              <Form form={form}>
                <Form.Item name="favorito" className="favorito-header" title="Favorito">
                  <Rate count={1} onChange={onFavoriteChange} />
                </Form.Item>
              </Form>
            </div>
          )}

        </div>

        <div className="header-actions">{buttons && buttons()}</div>
      </div>
    </div>
  );
};

export default Header;
