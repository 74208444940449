/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Form, Button, Row, Col, Modal, Select } from "antd";
import { FiFileText, FiLogIn, FiPaperclip } from "react-icons/fi";

import { ExclamationCircleOutlined } from "@ant-design/icons";

import http from "../../../../services/http";
import { showSuccess } from "../../../../services/notificacion";
import { hasAction, actions } from "../../../../services/security";
import { required } from "../../../../services/validation";

import Header from "../../../../components/Header";
import { ContextProvider } from "../../../../services/context";
import Archivos from "./Archivos";
import Deducciones from "./Deducciones";

const { Option } = Select;

const DDJJForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const VER = hasAction(actions.DDJJ_VER);
  const CREAR = hasAction(actions.DDJJ_CREAR);
  const EDITAR = hasAction(actions.DDJJ_EDITAR);
  const GUARDAR = match.params.id === "nuevo" ? CREAR : EDITAR;
  const [empleados, setEmpleados] = useState([]);

  const [state, setState] = useState({
    title: match.params.id === "nuevo" ? "Crear" : EDITAR ? "Editar" : "Ver",
    id: match.params.id === "nuevo" ? 0 : parseInt(match.params.id),
    loading: false,
    reload: false,
    deducciones: [],
  });

  const breadcrumb = [
    { id: 1, title: "Liquidación" },
    {
      id: 2,
      title: "Ganancias",
      url: "/liquidacion/ganancias",
    },
    {
      id: 3,
      title: "DDJJ",
      url: "/liquidacion/ganancias/ddjj",
    },
    { id: 4, title: state.title },
  ];

  const onSave = async (values) => {
    setState({ ...state, loading: true });
    const rGuardar =
      state.id === 0
        ? await http.post("ddjj", values)
        : await http.put(`ddjj/${state.id}`, values);

    if (rGuardar && rGuardar.data.code === 0) {
      showSuccess("El DDJJ fue guardado exitosamente.");
      if (state.id !== 0) {
        history.push("/liquidacion/ganancias/ddjj");
        return;
      }

      const data = rGuardar.data.data;

      Modal.confirm({
        title: "Confirmación",
        icon: <ExclamationCircleOutlined />,
        content: "¿Desea continuar editando esta DDJJ?",
        okText: "Confirmar",
        cancelText: "Cancelar",
        onOk: async () => {
          setState({ ...state, id: data.id, title: "Editar", deducciones: [] });
          history.push(`/liquidacion/ganancias/ddjj/${data.id}`);
        },
        onCancel: async () => {
          history.push("/liquidacion/ganancias/ddjj");
        },
      });
    } else {
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {
    async function getData() {
      const response = await http.get("ddjj/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setEmpleados(data.empleados);
      }
    }
    getData();
    async function getForm() {
      const rForm = await http.get(`ddjj/${state.id}`);
      if (rForm && rForm.data.code === 0) {
        const data = rForm.data.data;
        setState({
          ...state,
          deducciones: data.deducciones,
        });
        form.setFieldsValue(data);
      }
    }

    if (VER) {
      getForm();
    }
  }, [state.reload]);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <ContextProvider value={[state, setState]}>
      <div className="ddjj">
        <Header title={`${state.title} DDJJ`} breadcrumb={breadcrumb} />
        <div className="form card">
          <Form form={form} layout="vertical" onFinish={onSave}>
            <div className="form-icon">
              <FiFileText />
            </div>

            <Row gutter={20}>
              <Col span="24">
                <Form.Item
                  label="Empleado"
                  name="empleadoId"
                  rules={[required]}
                >
                  <Select showSearch allowClear optionFilterProp="children">
                    {empleados.map((m) => (
                      <Option
                        key={m.value}
                        value={m.value}
                        disabled={m.disabled}
                      >
                        {m.text}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <div className="form-actions">
              <Link to="/liquidacion/ganancias/ddjj">
                <Button type="secondary">Volver</Button>
              </Link>
              {GUARDAR && (
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={state.loading}
                >
                  Guardar
                </Button>
              )}
            </div>
          </Form>
          {state.id > 0 && (
            <React.Fragment>
              <hr />
              <div className="section-header">
                <div className="section-title">
                  Deducciones
                  <FiLogIn />
                </div>

                <div className="section-subtitle">
                  {state.deducciones.length} elementos.
                </div>
              </div>
              <Deducciones />
            </React.Fragment>
          )}
          {state.id > 0 && (
            <React.Fragment>
              <hr />
              <div className="section-header">
                <div className="section-title">
                  Documentación
                  <FiPaperclip />
                </div>
                <div className="section-subtitle">Documentación asociada.</div>
              </div>
              <Archivos ddjj={state.id} />
            </React.Fragment>
          )}
        </div>
      </div>
    </ContextProvider>
  );
};

export default DDJJForm;
