/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Button, Form, Input, Modal, Select } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { FiDollarSign } from "react-icons/fi";

import { ContextProvider } from "./../../../../services/context";
import http from "./../../../../services/http";
import { showSuccess } from "./../../../../services/notificacion";
import { hasAction, actions } from "./../../../../services/security";
import { required } from "./../../../../services/validation";

import Header from "./../../../../components/Header";
import Sucursales from "./Sucursales";

const BancosForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const VER = hasAction(actions.BANCOS_VER);
  const CREAR = hasAction(actions.BANCOS_CREAR);
  const EDITAR = hasAction(actions.BANCOS_EDITAR);
  const GUARDAR = match.params.id === "nuevo" ? CREAR : EDITAR;

  const [state, setState] = useState({
    title: match.params.id === "nuevo" ? "Crear" : EDITAR ? "Editar" : "Ver",
    id: match.params.id === "nuevo" ? 0 : parseInt(match.params.id),
    loading: false,
    reload: false,
    sucursales: [],
  });

  const [formatos, setFormatos] = useState([]);

  const breadcrumb = [
    {
      id: 1,
      title: "Parámetros",
    },
    {
      id: 2,
      title: "Generales",
      url: "/parametros/generales",
    },
    {
      id: 3,
      title: "Bancos",
      url: "/parametros/generales/bancos",
    },
    { id: 4, title: state.title },
  ];

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    const response =
      state.id === 0
        ? await http.post("bancos", values)
        : await http.put(`bancos/${state.id}`, values);

    if (response && response.data.code === 0) {
      const data = response.data.data;

      if (state.id === 0) {
        Modal.confirm({
          title: "Continuar editando",
          icon: <ExclamationCircleOutlined />,
          content: "¿Desea seguir editando este banco?",
          okText: "Confirmar",
          cancelText: "Cancelar",
          onOk: () => {
            setState({ ...state, id: data.id, title: "Editar" });
            history.push(`/parametros/generales/bancos/${data.id}`);
          },
          onCancel: () => history.push("/parametros/generales/bancos"),
        });
      } else {
        showSuccess("El banco fue guardado exitosamente.");
        history.push("/parametros/generales/bancos");
      }
    } else {
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {
    async function getForm() {
      let response = await http.get("bancos/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setFormatos(data.formatos);
      }

      response = await http.get(`bancos/${state.id}`);
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setState({ ...state, sucursales: data.bancoSucursales });
        form.setFieldsValue(data);
      }
    }

    if (VER) {
      getForm();
    }
  }, [state.id, state.reload]);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <ContextProvider value={[state, setState]}>
      <div className="banco">
        <Header title={`${state.title} Banco`} breadcrumb={breadcrumb} />
        <Form form={form} layout="vertical" onFinish={onSave}>
          <div className="form card">
            <div className="form-icon">
              <FiDollarSign />
            </div>

            <Form.Item label="Detalle" name="detalle" rules={[required]}>
              <Input disabled={!GUARDAR} />
            </Form.Item>

            <Form.Item label="Código" name="codigo" rules={[required]}>
              <Input type="number" disabled={!GUARDAR} />
            </Form.Item>

            <Form.Item label="Cuenta" name="cuenta">
              <Input disabled={!GUARDAR} />
            </Form.Item>

            <Form.Item label="Formato" name="formato" rules={[required]}>
              <Select allowClear showSearch optionFilterProp="children">
                {formatos.map((x) => (
                  <Select.Option key={x.value} value={x.value}>
                    {x.text}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <div className="form-actions">
              <Link to="/parametros/generales/bancos">
                <Button type="secondary">Volver</Button>
              </Link>
              {GUARDAR && (
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={state.loading}
                >
                  Guardar
                </Button>
              )}
            </div>
          </div>

          {state.id > 0 && (
            <React.Fragment>
              <Sucursales />
            </React.Fragment>
          )}
        </Form>
      </div>
    </ContextProvider>
  );
};

export default BancosForm;
