/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Row,
  Col,
  Modal,
  Switch,
} from "antd";
import { FiAlertTriangle } from "react-icons/fi";
import { QuestionCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import locale from "antd/es/date-picker/locale/es_ES";
import "moment/locale/es";

import http from "../../../../services/http";
import { showSuccess } from "../../../../services/notificacion";
import { hasAction, actions } from "../../../../services/security";
import { required } from "../../../../services/validation";

import Header from "../../../../components/Header";

const { Option } = Select;

const NovedadVariableForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const VER = hasAction(actions.NOVEDADVARIABLE_VER);
  const CREAR = hasAction(actions.NOVEDADVARIABLE_CREAR);
  const EDITAR = hasAction(actions.NOVEDADVARIABLE_EDITAR);
  const GUARDAR = match.params.id === "nuevo" ? CREAR : EDITAR;

  const [state, setState] = useState({
    title:
      match.params.id === "nuevo" ? "Registrar" : EDITAR ? "Editar" : "Ver",
    id: match.params.id === "nuevo" ? 0 : parseInt(match.params.id),
    loading: false,
    data: false,
  });

  const [tipo, setTipo] = useState("Importe");
  const [conceptos, setConceptos] = useState([]);
  const [empleados, setEmpleados] = useState([]);
  const [tiposLiquidacion, setTiposLiquidacion] = useState([]);
  const [forzado, setForzado] = useState(false);

  const refEmpleado = useRef(null);
  const refTipo = useRef(null);
  const refValor = useRef(null);

  const breadcrumb = [
    { id: 1, title: "Novedades" },
    {
      id: 2,
      title: "Liquidación",
      url: "/novedades/liquidacion",
    },
    {
      id: 3,
      title: "Novedades Variables",
      url: "/novedades/liquidacion/novedadvariable",
    },
    { id: 4, title: state.title },
  ];

  useEffect(() => {
    async function getData() {
      const response = await http.get("NovedadVariable/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setConceptos(data.conceptos);
        setEmpleados(data.empleados);
        setTiposLiquidacion(data.tiposLiquidacion);
        setState({ ...state, data: true });
      }
    }

    if (VER) {
      getData();
    }
  }, []);

  useEffect(() => {
    async function getForm() {
      const rForm = await http.get(`NovedadVariable/${state.id}`);
      if (rForm && rForm.data.code === 0) {
        const data = rForm.data.data;
        data.periodo = data.periodo ? moment(data.periodo) : moment();
        form.setFieldsValue(data);
        updateLabelValor(data.conceptoId, data.forzado);
        setForzado(data.forzado);
      }
    }

    if (VER && state.data) {
      getForm();
    }
  }, [state.data]);

  const updateLabelValor = (conceptoId, forzado) => {
    if (!conceptoId || forzado) {
      setTipo("Importe");
      return;
    }

    const concepto = conceptos.find((x) => x.value === conceptoId);
    setTipo(concepto.extra);
  };

  const onConceptoChange = async (value) => {
    updateLabelValor(value, forzado);
  };

  const onSelectConcepto = async () => {
    refEmpleado.current.focus();
  };

  const onSelectEmpleado = async () => {
    refTipo.current.focus();
  };

  const onSelectTipo = async () => {
    refValor.current.focus();
  };

  const onChangeForzado = (value) => {
    setForzado(value);

    var conceptoId = form.getFieldValue("conceptoId");
    updateLabelValor(conceptoId, value);
  };

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    const isNew = state.id === 0;

    const result =
      state.id === 0
        ? await http.post("NovedadVariable", values)
        : await http.put(`NovedadVariable/${state.id}`, values);

    if (result && result.data.code === 0) {
      showSuccess("La novedad fue guardada exitosamente.");
      if (isNew) {
        Modal.confirm({
          title: "Confirmar",
          icon: <QuestionCircleOutlined />,
          content:
            "¿Desea continuar cargando novedades para el mismo periodo y concepto?",
          okText: "Confirmar",
          cancelText: "Cancelar",
          onOk: async () => {
            state.empleadoId = null;
            state.unidades = null;
            state.importe = null;
            state.porcentaje = null;
            state.forzado = false;
            form.setFieldsValue(state);
            setState({ ...state, loading: false });
            refEmpleado.current.focus();
          },
          onCancel: async () => {
            history.push("/novedades/liquidacion/novedadvariable");
          },
        });
      } else {
        history.push("/novedades/liquidacion/novedadvariable");
      }
    } else {
      setState({ ...state, loading: false });
    }
  };

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="novedades">
      <Header
        title={`${state.title} Novedad Variable`}
        breadcrumb={breadcrumb}
      />
      <div className="form card">
        <Form form={form} layout="vertical" onFinish={onSave}>
          <div className="">
            <div className="form-icon">
              <FiAlertTriangle />
            </div>

            <Form.Item label="Periodo" name="periodo" rules={[required]}>
              <DatePicker
                disabled={!GUARDAR}
                format="MM/YYYY"
                placeholder=""
                picker="month"
                locale={locale}
              />
            </Form.Item>
            <Form.Item label="Concepto" name="conceptoId" rules={[required]}>
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                showAction={["focus", "click"]}
                autoFocus={state.id === 0}
                defaultOpen={state.id === 0}
                onSelect={onSelectConcepto}
                onChange={onConceptoChange}
              >
                {conceptos.map((m) => (
                  <Option key={m.value} value={m.value}>
                    {m.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Empleado" name="empleadoId" rules={[required]}>
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                showAction={["focus", "click"]}
                onSelect={onSelectEmpleado}
                ref={refEmpleado}
              >
                {empleados.map((m) => (
                  <Option key={m.value} value={m.value}>
                    {m.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Tipo Liquidación" name="tipoLiquidacion">
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                showAction={["focus", "click"]}
                onSelect={onSelectTipo}
                ref={refTipo}
              >
                {tiposLiquidacion.map((m) => (
                  <Option key={m.value} value={m.value}>
                    {m.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Row gutter={20}>
              <Col span="24">
                <Form.Item label={tipo} name="valor" rules={[required]}>
                  <Input type="number" disabled={!GUARDAR} ref={refValor} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={20}>
              <Col span="24">
                <Form.Item label="Cantidad" name="cantidad">
                  <Input type="number" disabled={!GUARDAR} />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item label="Forzado" name="forzado" valuePropName="checked">
              <Switch
                checkedChildren="Si"
                unCheckedChildren="No"
                onChange={onChangeForzado}
              />
            </Form.Item>

            <div className="form-actions">
              <Link to="/novedades/liquidacion/novedadvariable">
                <Button type="secondary">Volver</Button>
              </Link>
              {GUARDAR && (
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={state.loading}
                >
                  Guardar
                </Button>
              )}
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default NovedadVariableForm;
