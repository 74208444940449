import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import {
  FiCalendar,
  FiChevronDown,
  FiChevronUp,
  FiClock,
  FiFileText,
  FiFilter,
  FiFolder,
  FiFolderPlus,
  FiHome,
  FiLayers,
  FiPercent,
  FiSearch,
  FiSettings,
  FiShield,
  FiUser,
  FiX,
  FiCheckCircle,
  FiDollarSign,
} from "react-icons/fi";
import { SlideDown } from "react-slidedown";
import "react-slidedown/lib/slidedown.css";

import logo from "./../assets/images/logo.png";

import "./SideBar.scss";
import { hasAction, actions } from "./../services/security";

const SideBar = ({ collapsed, onCollapse }) => {
  const [parametros, setParametros] = useState(false);
  const [administracion, setAdministracion] = useState(false);
  const [novedades, setNovedades] = useState(false);
  const [liquidacion, setLiquidacion] = useState(false);
  const [reportes, setReportes] = useState(false);
  const [entorno, setEntorno] = useState(false);

  // Administracion
  const VER_LEGAJOS = hasAction(actions.LEGAJOS_VER);
  const VER_ESTRUCTURA =
    hasAction(actions.ORGANIGRAMA_VER) ||
    hasAction(actions.CONSOLIDADO_VER) ||
    hasAction(actions.FUNCIONAL_VER);
  const VER_MEDICINA = hasAction(actions.MEDICINALABORAL_VER);
  const VER_SEGURIDAD = hasAction(actions.ASIGNACIONESELEMENTOS_VER);
  const SECCION_ADMINISTRACION =
    VER_LEGAJOS || VER_ESTRUCTURA || VER_MEDICINA || VER_SEGURIDAD;

  // Novedades
  const VER_NOVEDADES = hasAction(actions.NOVEDADES_VER);
  const VER_LICENCIAS =
    hasAction(actions.ACCIDENTESTRABAJOS_VER) ||
    hasAction(actions.ASISTENCIAS_VER) ||
    hasAction(actions.LICENCIASENFERMEDAD_VER) ||
    hasAction(actions.LICENCIAS_ESPECIALES_VER) ||
    hasAction(actions.LICENCIAS_VACACIONES_VER);
  const VER_LIQUIDACION =
    hasAction(actions.CONCURSOS_QUIEBRAS_VER) ||
    hasAction(actions.CUOTAALIMENTARIA_VER) ||
    hasAction(actions.EMBARGO_VER) ||
    hasAction(actions.NOVEDADFIJA_VER) ||
    hasAction(actions.NOVEDADVARIABLE_VER) ||
    hasAction(actions.SERVICIO_EXTRAORDINARIO_VER) ||
    hasAction(actions.IMPORTACION_NOVEDADES_VER);
  const SECCION_NOVEDADES = VER_LICENCIAS || VER_LIQUIDACION;

  // Liquidacion
  const VER_LIQUIDACIONES = hasAction(actions.LIQUIDACION_VER);
  const VER_LIQUIDACIONES_BUSCADOR = hasAction(
    actions.LIQUIDACION_BUSCADOR_VER
  );
  const VER_GANANCIAS =
    hasAction(actions.TRAMOS_VER) ||
    hasAction(actions.DEDUCCIONES_VER) ||
    hasAction(actions.DDJJ_VER);
  const VER_ACUMULADO = hasAction(actions.ACUMULADO_VER);
  const SECCION_LIQUIDACION =
    VER_LIQUIDACIONES ||
    VER_LIQUIDACIONES_BUSCADOR ||
    VER_GANANCIAS ||
    VER_ACUMULADO;

  // Reportes
  const VER_REPORTE_LIQUIDACION =
    hasAction(actions.REPORTE_LIQUIDACION_SUELDOSCAIDOS) ||
    hasAction(actions.REPORTE_LIQUIDACION_SINTETICO) ||
    hasAction(actions.REPORTE_LIQUIDACION_FACTURACIONENTIDADES);
  const VER_REPORTE_LEGAJO =
    hasAction(actions.REPORTE_LEGAJO_TITULO) ||
    hasAction(actions.REPORTE_LEGAJO_CONTROLESCOLARIDAD) ||
    hasAction(actions.REPORTE_LEGAJO_CONTROLASIGNACION);
  const VER_REPORTE_NOVEDADES =
    hasAction(actions.REPORTE_NOVEDAD_VACACIONES) ||
    hasAction(actions.REPORTE_NOVEDAD_ACCIDENTES) ||
    hasAction(actions.REPORTE_NOVEDAD_ENFERMEDADES) ||
    hasAction(actions.REPORTE_NOVEDAD_ESPECIALES) ||
    hasAction(actions.REPORTE_NOVEDAD_AUSENTISMO);
  const SECCION_REPORTES =
    VER_REPORTE_LIQUIDACION || VER_REPORTE_LEGAJO || VER_REPORTE_NOVEDADES;

  // Parametros
  const VER_PARAMETROS_GENERALES =
    hasAction(actions.AFJP_VER) ||
    hasAction(actions.ART_VER) ||
    hasAction(actions.ASIGNACIONTITULO_VER) ||
    hasAction(actions.BANCOS_VER) ||
    hasAction(actions.CLASES_VER) ||
    hasAction(actions.CONVENIOS_VER) ||
    hasAction(actions.CRITERIOS_EVALUACION_VER) ||
    hasAction(actions.ESCALAFON_VER) ||
    hasAction(actions.FUNCIONES_VER) ||
    hasAction(actions.GRUPOS_SANGUINEOS_VER) ||
    hasAction(actions.IDIOMAS_VER) ||
    hasAction(actions.MOTIVOS_VER) ||
    hasAction(actions.MOTIVOSBAJA_VER) ||
    hasAction(actions.MOTIVOSMOVIMIENTOS_VER) ||
    hasAction(actions.NIVELESTUDIO_VER) ||
    hasAction(actions.PAISES_VER) ||
    hasAction(actions.PROVINCIAS_VER) ||
    hasAction(actions.SINDICATOS_VER) ||
    hasAction(actions.TAREAS_VER) ||
    hasAction(actions.TIPOS_SANCION_VER) ||
    hasAction(actions.TITULO_VER);
  const VER_PARAMETROS_ESTRUCTURA =
    hasAction(actions.AGRUPAMIENTOS_VER) ||
    hasAction(actions.AGRUPAMIENTOSCLASES_VER) ||
    hasAction(actions.CARGOSSINDICALES_VER) ||
    hasAction(actions.DIRECCIONES_VER) ||
    hasAction(actions.LUGARTRABAJOS_VER) ||
    hasAction(actions.RELOJESBIOMETRICOS_VER) ||
    hasAction(actions.SECRETARIAS_VER) ||
    hasAction(actions.SUBDIRECCIONES_VER);
  const VER_PARAMETROS_LIQUIDACION =
    hasAction(actions.AGENTEEMBARGO_VER) ||
    hasAction(actions.CONCEPTOS_VER) ||
    hasAction(actions.LICENCIAS_CODIGOS_VER) ||
    hasAction(actions.IMPORTACION_FORMATOS_VER) ||
    hasAction(actions.OPERANDO_VER) ||
    hasAction(actions.REGIMENES_LICENCIAS_VER) ||
    hasAction(actions.TIPOEMBARGO_VER);
  const VER_PARAMETROS_MEDICINA =
    hasAction(actions.TIPOSACCIDENTES_VER) ||
    hasAction(actions.MOTIVOACCIDENTE_VER) ||
    hasAction(actions.DIAGNOSTICO_VER);
  const VER_PARAMETROS_SEGURIDAD =
    hasAction(actions.ELEMENTOS_VER) || hasAction(actions.TIPOSELEMENTOS_VER);
  const SECCION_PARAMETROS =
    VER_PARAMETROS_GENERALES ||
    VER_PARAMETROS_ESTRUCTURA ||
    VER_PARAMETROS_LIQUIDACION ||
    VER_PARAMETROS_MEDICINA ||
    VER_PARAMETROS_SEGURIDAD;

  // Entorno
  const VER_PERFILES = hasAction(actions.PERFILES_VER);
  const VER_USUARIOS = hasAction(actions.USUARIOS_VER);
  const VER_AUDITORIA = hasAction(actions.AUDITORIA_VER);
  const VER_MUNICIPIO = hasAction(actions.MUNICIPIO_VER);
  const SECCION_ENTORNO =
    VER_PERFILES || VER_USUARIOS || VER_AUDITORIA || VER_MUNICIPIO;

  const getSize = () => {
    return collapsed
      ? { width: "80px", height: "calc(100% - 74px)" }
      : { width: "260px", height: "calc(100% - 74px)" };
  };

  const getOverlay = () => {
    return collapsed ? "sidebar-overlay" : "sidebar-overlay visible";
  };

  const getClasses = () => {
    return collapsed ? "sidebar collapsed" : "sidebar";
  };

  return (
    <div className={getOverlay()}>
      <div className={getClasses()}>
        <div className="sidebar-header">
          <img className="sidebar-logo" src={logo} alt="logo" />
          <p className="sidebar-title">
            <span className="small">Gestión de Recursos</span>
            <span className="name">Humanos</span>
          </p>
          <FiX className="sidebar-collapse" onClick={() => onCollapse(true)} />
        </div>
        <Scrollbars className="sidebar-scroll" style={getSize()} autoHide>
          <ul className="sidebar-list">
            <li className="nav-item">
              <NavLink to="/" exact>
                <FiHome />
                <div>Inicio</div>
              </NavLink>
            </li>

            {SECCION_ADMINISTRACION && (
              <React.Fragment>
                <li
                  className="nav-header"
                  onClick={() => setAdministracion(!administracion)}
                >
                  <div>Administración</div>
                  {administracion ? <FiChevronUp /> : <FiChevronDown />}
                </li>
                <SlideDown closed={!administracion}>
                  {VER_LEGAJOS && (
                    <li className="nav-item">
                      <NavLink to="/administracion/legajos">
                        <FiFolder />
                        <div>Legajos</div>
                      </NavLink>
                    </li>
                  )}
                  {VER_ESTRUCTURA && (
                    <li className="nav-item">
                      <NavLink to="/administracion/estructura">
                        <FiFilter />
                        <div>Estructura</div>
                      </NavLink>
                    </li>
                  )}
                  {VER_MEDICINA && (
                    <li className="nav-item">
                      <NavLink to="/administracion/medicina">
                        <FiFolderPlus />
                        <div>Medicina Laboral</div>
                      </NavLink>
                    </li>
                  )}
                  {VER_SEGURIDAD && (
                    <li className="nav-item">
                      <NavLink to="/administracion/seguridad">
                        <FiShield />
                        <div>Seguridad Laboral</div>
                      </NavLink>
                    </li>
                  )}
                </SlideDown>
              </React.Fragment>
            )}

            {SECCION_NOVEDADES && (
              <React.Fragment>
                <li
                  className="nav-header"
                  onClick={() => setNovedades(!novedades)}
                >
                  <div>Novedades</div>
                  {novedades ? <FiChevronUp /> : <FiChevronDown />}
                </li>

                <SlideDown closed={!novedades}>
                  {VER_NOVEDADES && (
                    <li className="nav-item">
                      <NavLink to="/novedades">
                        <FiSearch />
                        <div>Buscador</div>
                      </NavLink>
                    </li>
                  )}
                  {VER_LICENCIAS && (
                    <li className="nav-item">
                      <NavLink to="/novedades/licencias">
                        <FiCalendar />
                        <div>Licencias</div>
                      </NavLink>
                    </li>
                  )}
                  {VER_LIQUIDACION && (
                    <li className="nav-item">
                      <NavLink to="/novedades/liquidacion">
                        <FiCheckCircle />
                        <div>Liquidación</div>
                      </NavLink>
                    </li>
                  )}
                </SlideDown>
              </React.Fragment>
            )}

            {SECCION_LIQUIDACION && (
              <React.Fragment>
                <li
                  className="nav-header"
                  onClick={() => setLiquidacion(!liquidacion)}
                >
                  <div>Liquidación</div>
                  {liquidacion ? <FiChevronUp /> : <FiChevronDown />}
                </li>
                <SlideDown closed={!liquidacion}>
                  {VER_LIQUIDACIONES && (
                    <li className="nav-item">
                      <NavLink to="/liquidaciones">
                        <FiCheckCircle />
                        <div>Liquidaciones</div>
                      </NavLink>
                    </li>
                  )}
                  {VER_LIQUIDACIONES_BUSCADOR && (
                    <li className="nav-item">
                      <NavLink to="/liquidacion/buscador">
                        <FiSearch />
                        <div>Buscador</div>
                      </NavLink>
                    </li>
                  )}
                  {VER_GANANCIAS && (
                    <li className="nav-item">
                      <NavLink to="/liquidacion/ganancias">
                        <FiDollarSign />
                        <div>Ganancias</div>
                      </NavLink>
                    </li>
                  )}
                  {VER_ACUMULADO && (
                    <li className="nav-item">
                      <NavLink to="/acumulado">
                        <FiPercent />
                        <div>Acumulado SAC</div>
                      </NavLink>
                    </li>
                  )}
                </SlideDown>
              </React.Fragment>
            )}

            {SECCION_REPORTES && (
              <React.Fragment>
                <li
                  className="nav-header"
                  onClick={() => setReportes(!reportes)}
                >
                  <div>Reportes</div>
                  {reportes ? <FiChevronUp /> : <FiChevronDown />}
                </li>
                <SlideDown closed={!reportes}>
                  {VER_REPORTE_LIQUIDACION && (
                    <li className="nav-item">
                      <NavLink to="/reportes/liquidacion">
                        <FiCheckCircle />
                        <div>Liquidación</div>
                      </NavLink>
                    </li>
                  )}
                  {VER_REPORTE_LEGAJO && (
                    <li className="nav-item">
                      <NavLink to="/reportes/legajo">
                        <FiFolder />
                        <div>Legajo</div>
                      </NavLink>
                    </li>
                  )}
                  {VER_REPORTE_NOVEDADES && (
                    <li className="nav-item">
                      <NavLink to="/reportes/novedad">
                        <FiFileText />
                        <div>Novedades</div>
                      </NavLink>
                    </li>
                  )}
                </SlideDown>
              </React.Fragment>
            )}

            {SECCION_PARAMETROS && (
              <React.Fragment>
                <li
                  className="nav-header"
                  onClick={() => setParametros(!parametros)}
                >
                  <div>Parámetros</div>
                  {parametros ? <FiChevronUp /> : <FiChevronDown />}
                </li>
                <SlideDown closed={!parametros}>
                  {VER_PARAMETROS_GENERALES && (
                    <li className="nav-item">
                      <NavLink to="/parametros/generales">
                        <FiLayers />
                        <div>Generales</div>
                      </NavLink>
                    </li>
                  )}
                  {VER_PARAMETROS_ESTRUCTURA && (
                    <li className="nav-item">
                      <NavLink to="/parametros/estructura">
                        <FiFilter />
                        <div>Estructura</div>
                      </NavLink>
                    </li>
                  )}
                  {VER_PARAMETROS_LIQUIDACION && (
                    <li className="nav-item">
                      <NavLink to="/parametros/liquidacion">
                        <FiCheckCircle />
                        <div>Liquidación</div>
                      </NavLink>
                    </li>
                  )}
                  {VER_PARAMETROS_MEDICINA && (
                    <li className="nav-item">
                      <NavLink to="/parametros/medicina">
                        <FiFolderPlus />
                        <div>Medicina Laboral</div>
                      </NavLink>
                    </li>
                  )}
                  {VER_PARAMETROS_SEGURIDAD && (
                    <li className="nav-item">
                      <NavLink to="/parametros/seguridad">
                        <FiShield />
                        <div>Seguridad Laboral</div>
                      </NavLink>
                    </li>
                  )}
                </SlideDown>
              </React.Fragment>
            )}

            {SECCION_ENTORNO && (
              <React.Fragment>
                <li className="nav-header" onClick={() => setEntorno(!entorno)}>
                  <div>Entorno</div>
                  {entorno ? <FiChevronUp /> : <FiChevronDown />}
                </li>
                <SlideDown closed={!entorno}>
                  {VER_PERFILES && (
                    <li className="nav-item">
                      <NavLink to="/entorno/perfiles">
                        <FiShield />
                        <div>Perfiles</div>
                      </NavLink>
                    </li>
                  )}
                  {VER_USUARIOS && (
                    <li className="nav-item">
                      <NavLink to="/entorno/usuarios">
                        <FiUser />
                        <div>Usuarios</div>
                      </NavLink>
                    </li>
                  )}
                  {VER_AUDITORIA && (
                    <li className="nav-item">
                      <NavLink to="/entorno/auditoria">
                        <FiClock />
                        <div>Auditoría</div>
                      </NavLink>
                    </li>
                  )}
                  {VER_MUNICIPIO && (
                    <li className="nav-item">
                      <NavLink to="/entorno/municipio">
                        <FiSettings />
                        <div>Municipio</div>
                      </NavLink>
                    </li>
                  )}
                </SlideDown>
              </React.Fragment>
            )}
          </ul>
        </Scrollbars>
      </div>
    </div>
  );
};

export default SideBar;
