/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Button, Col, Form, Input, Row, Select } from "antd";
import { FiCalendar } from "react-icons/fi";

import http from "../../../../services/http";
import { showSuccess } from "../../../../services/notificacion";
import { hasAction, actions } from "../../../../services/security";
import { required } from "../../../../services/validation";

import Header from "../../../../components/Header";

const LicenciaCodigosForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const VER = hasAction(actions.LICENCIAS_CODIGOS_VER);
  const CREAR = hasAction(actions.LICENCIAS_CODIGOS_CREAR);
  const EDITAR = hasAction(actions.LICENCIAS_CODIGOS_EDITAR);
  const GUARDAR = match.params.id === "nuevo" ? CREAR : EDITAR;

  const [state, setState] = useState({
    title: match.params.id === "nuevo" ? "Crear" : EDITAR ? "Editar" : "Ver",
    id: match.params.id === "nuevo" ? 0 : parseInt(match.params.id),
    loading: false,
  });
  const [conceptos, setConceptos] = useState([]);
  const [tratamientos, setTratamientos] = useState([]);
  const [tipos, setTipos] = useState([]);

  const breadcrumb = [
    {
      id: 1,
      title: "Parámetros",
    },
    {
      id: 2,
      title: "Liquidación",
      url: "/parametros/liquidacion",
    },
    {
      id: 3,
      title: "Códigos de Licencias",
      url: "/parametros/liquidacion/licencias_codigos",
    },
    { id: 4, title: state.title },
  ];

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    const rSave =
      state.id === 0
        ? await http.post("licenciasCodigos", values)
        : await http.put(`licenciasCodigos/${state.id}`, values);

    if (rSave && rSave.data.code === 0) {
      showSuccess("El Código fue guardado exitosamente.");
      history.push("/parametros/liquidacion/licencias_codigos");
    } else {
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {
    async function getForm() {
      const response = await http.get(`licenciasCodigos/data`);
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setConceptos(data.conceptos);
        setTratamientos(data.tratamientos);
        setTipos(data.tipos);
      }

      const rForm = await http.get(`licenciasCodigos/${state.id}`);
      if (rForm && rForm.data.code === 0) {
        const data = rForm.data.data;
        form.setFieldsValue(data);
      }
    }

    if (VER) {
      getForm();
    }
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="licencia-especial-motivo">
      <Header
        title={`${state.title} Código de Licencia`}
        breadcrumb={breadcrumb}
      />

      <Form form={form} layout="vertical" onFinish={onSave}>
        <div className="form card">
          <div className="form-icon">
            <FiCalendar />
          </div>

          <Row gutter={20}>
            <Col span="24">
              <Form.Item label="Código" name="codigo" rules={[required]}>
                <Input maxLength={50} disabled={!GUARDAR} />
              </Form.Item>
            </Col>

            <Col span="24">
              <Form.Item label="Detalle" name="detalle" rules={[required]}>
                <Input maxLength={250} disabled={!GUARDAR} />
              </Form.Item>
            </Col>

            <Col span="24">
              <Form.Item
                label="Abreviatura"
                name="abreviatura"
                rules={[required]}
              >
                <Input maxLength={20} disabled={!GUARDAR} />
              </Form.Item>
            </Col>

            <Col span="24">
              <Form.Item label="Tratamiento" name="tratamiento">
                <Select allowClear disabled={!GUARDAR}>
                  {tratamientos.map((x) => (
                    <Select.Option
                      key={x.value}
                      value={x.value}
                      disabled={x.disabled}
                    >
                      {x.text}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span="24">
              <Form.Item label="Tipo" name="tipo">
                <Select allowClear disabled={!GUARDAR}>
                  {tipos.map((x) => (
                    <Select.Option
                      key={x.value}
                      value={x.value}
                      disabled={x.disabled}
                    >
                      {x.text}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span="24">
              <Form.Item label="Concepto" name="concepto" rules={[required]}>
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  disabled={!GUARDAR}
                >
                  {conceptos.map((x) => (
                    <Select.Option
                      key={x.value}
                      value={x.value}
                      disabled={x.disabled}
                    >
                      {x.text}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span="12">
              <Form.Item label="Novedad" name="novedad">
                <Select allowClear disabled={!GUARDAR}>
                  <Select.Option value="1">Si</Select.Option>
                  <Select.Option value="0">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item label="Licencia sin goce" name="sinGoce">
                <Select allowClear disabled={!GUARDAR}>
                  <Select.Option value="1">Si</Select.Option>
                  <Select.Option value="0">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span="12">
              <Form.Item label="Días Corridos" name="diasCorridos">
                <Select allowClear disabled={!GUARDAR}>
                  <Select.Option value="1">Si</Select.Option>
                  <Select.Option value="0">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item label="Carpeta Médica" name="carpetaMedica">
                <Select allowClear disabled={!GUARDAR}>
                  <Select.Option value="1">Si</Select.Option>
                  <Select.Option value="0">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item label="Lleva Estado" name="llevaEstado">
                <Select disabled={!GUARDAR}>
                  <Select.Option value="1">Si</Select.Option>
                  <Select.Option value="0">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item label="Usar Por Defecto" name="usarPorDefecto">
                <Select allowClear disabled={!GUARDAR}>
                  <Select.Option value="1">Si</Select.Option>
                  <Select.Option value="0">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span="8">
              <Form.Item label="Ley" name="ley">
                <Input type="number" maxLength={10} disabled={!GUARDAR} />
              </Form.Item>
            </Col>
            <Col span="8">
              <Form.Item label="Título" name="titulo">
                <Input type="number" maxLength={10} disabled={!GUARDAR} />
              </Form.Item>
            </Col>
            <Col span="8">
              <Form.Item label="Capítulo" name="capitulo">
                <Input type="number" maxLength={10} disabled={!GUARDAR} />
              </Form.Item>
            </Col>

            <Col span="8">
              <Form.Item label="Articulo" name="articulo">
                <Input type="number" maxLength={10} disabled={!GUARDAR} />
              </Form.Item>
            </Col>
            <Col span="8">
              <Form.Item label="Inciso" name="inciso">
                <Input type="number" maxLength={10} disabled={!GUARDAR} />
              </Form.Item>
            </Col>
            <Col span="8">
              <Form.Item label="Apartado" name="apartado">
                <Input type="number" maxLength={10} disabled={!GUARDAR} />
              </Form.Item>
            </Col>

            <Col span="12">
              <Form.Item label="Máximo Mensual" name="maximoMensual">
                <Input type="number" disabled={!GUARDAR} />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item label="Máximo Anual" name="maximoAnual">
                <Input type="number" disabled={!GUARDAR} />
              </Form.Item>
            </Col>
          </Row>

          <div className="form-actions">
            <Link to="/parametros/liquidacion/licencias_codigos">
              <Button type="secondary">Volver</Button>
            </Link>
            {GUARDAR && (
              <Button type="primary" htmlType="submit" loading={state.loading}>
                Guardar
              </Button>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};

export default LicenciaCodigosForm;
