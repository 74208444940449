/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Form, Input, Button, Row, Col, Select } from "antd";
import { Animated } from "react-animated-css";
import { FiUser } from "react-icons/fi";

import http from "./../../../services/http";
import { showSuccess } from "./../../../services/notificacion";
import { hasAction, actions } from "./../../../services/security";
import { required } from "./../../../services/validation";

import Header from "./../../../components/Header";

import man from "./../../../assets/images/man.png";
import woman from "./../../../assets/images/woman.png";

const { Option } = Select;

const UsuariosForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const VER = hasAction(actions.USUARIOS_VER);
  const CREAR = hasAction(actions.USUARIOS_CREAR);
  const EDITAR = hasAction(actions.USUARIOS_EDITAR);
  const GUARDAR = match.params.id === "nuevo" ? CREAR : EDITAR;

  const [state, setState] = useState({
    title: match.params.id === "nuevo" ? "Crear" : EDITAR ? "Editar" : "Ver",
    id: match.params.id === "nuevo" ? 0 : parseInt(match.params.id),
    loading: false,
  });
  const [sexo, setSexo] = useState("");
  const [perfiles, setPerfiles] = useState([]);
  const [direcciones, setDirecciones] = useState([]);

  const breadcrumb = [
    { id: 1, title: "Entorno" },
    { id: 2, title: "Usuarios", url: "/entorno/usuarios" },
    { id: 3, title: state.title },
  ];

  const onSexoChange = (value) => {
    setSexo(value);
  };

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    const rSave =
      state.id === 0
        ? await http.post("usuarios", values)
        : await http.put(`usuarios/${state.id}`, values);

    if (rSave && rSave.data.code === 0) {
      showSuccess("El usuario fue guardado exitosamente.");
      history.push("/entorno/usuarios");
    } else {
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {
    async function getForm() {
      const response = await http.get("usuarios/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setPerfiles(data.perfiles);
        setDirecciones(data.direcciones);
      }

      const rForm = await http.get(`usuarios/${state.id}`);
      if (rForm && rForm.data.code === 0) {
        const data = rForm.data.data;

        if (data.sexo) {
          setSexo(data.sexo);
        }

        form.setFieldsValue(data);
      }
    }

    if (VER) {
      getForm();
    }
  }, []);

  return (
    <div className="usuario">
      <Header title={`${state.title} Usuario`} breadcrumb={breadcrumb} />

      <Form form={form} layout="vertical" onFinish={onSave}>
        <div className="form card">
          <div className="form-icon">
            {sexo === "" && (
              <Animated
                animationIn="fadeIn"
                animationOut="fadeOut"
                isVisible={sexo === ""}
              >
                <FiUser style={{ marginTop: "25px" }} />
              </Animated>
            )}
            {sexo === "M" && (
              <Animated
                animationIn="fadeIn"
                animationOut="fadeOut"
                isVisible={sexo === "M"}
              >
                <img src={man} alt="Masculino" />
              </Animated>
            )}
            {sexo === "F" && (
              <Animated
                animationIn="fadeIn"
                animationOut="fadeOut"
                isVisible={sexo === "F"}
              >
                <img src={woman} alt="Femenino" />
              </Animated>
            )}
          </div>

          <Row gutter={20}>
            <Col span="12">
              <Form.Item label="Usuario" name="username" rules={[required]}>
                <Input maxLength={20} disabled={!GUARDAR} />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item label="Password" name="password" rules={[required]}>
                <Input.Password maxLength={20} disabled={!GUARDAR} />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Nombre" name="nombre" rules={[required]}>
            <Input maxLength={100} disabled={!GUARDAR} />
          </Form.Item>

          <Form.Item label="Email" name="email" rules={[required]}>
            <Input maxLength={100} disabled={!GUARDAR} />
          </Form.Item>

          <Form.Item label="Sexo" name="sexo" rules={[required]}>
            <Select
              onChange={(value) => onSexoChange(value)}
              disabled={!GUARDAR}
            >
              <Option value="M">Masculino</Option>
              <Option value="F">Femenino</Option>
            </Select>
          </Form.Item>

          <Form.Item label="Perfiles" name="perfiles">
            <Select mode="multiple" allowClear disabled={!GUARDAR}>
              {perfiles.map((x) => (
                <Option key={x.value} value={x.value}>
                  {x.text}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Direcciones" name="direcciones">
            <Select mode="multiple" allowClear disabled={!GUARDAR}>
              {direcciones.map((x) => (
                <Option key={x.value} value={x.value}>
                  {x.text}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <div className="form-actions">
            <Link to="/entorno/usuarios">
              <Button type="secondary">Volver</Button>
            </Link>
            {GUARDAR && (
              <Button type="primary" htmlType="submit" loading={state.loading}>
                Guardar
              </Button>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};

export default UsuariosForm;
