/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import { Tag } from "antd";

import { Context } from "../../../../services/context";
import "./Historial.scss";


const LicenciaVacacionesHistorial = () => {
  const [state] = useContext(Context);

  return (
    <div className="licencia-historial">
      <table>
        <thead>
          <tr>
            <th>Fecha</th>
            <th>Hora</th>
            <th>Estado</th>
            <th>Fecha Inicio</th>
            <th>Cantidad Días</th>
            <th>Fecha Fin</th>
            <th>Usuario</th>
          </tr>
        </thead>
        <tbody>
          {state.historial.map((x) => (
            <tr key={x.id}>
              <td>{x.fecha}</td>
              <td>{x.hora}</td>
              <td>
                <Tag color="purple">{x.estado}</Tag>
              </td>
              <td>{x.fechaInicio}</td>
              <td>{x.cantidadDias}</td>
              <td>{x.fechaFin}</td>
              <td>{x.usuario}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LicenciaVacacionesHistorial;