/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Form, Input, Button, Select, Row, Col, DatePicker } from "antd";
import { FiAlertTriangle, FiPaperclip } from "react-icons/fi";
// import MaskedInput from "antd-mask-input";

import moment from "moment";
import locale from "antd/es/date-picker/locale/es_ES";
import "moment/locale/es";

import http from "../../../../services/http";
import { showSuccess } from "../../../../services/notificacion";
import { hasAction, actions } from "../../../../services/security";
import { required } from "../../../../services/validation";

import FormulasForm, {
  parseFormulas,
  validateFormulas,
} from "./../../../../components/FormulasForm";
import Header from "../../../../components/Header";
import ArchivosCuotas from "./Archivos";
import InputMasked from "./../../../../components/InputMasked";

const { Option } = Select;

const CuotaAlimentariaForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const VER = hasAction(actions.CUOTAALIMENTARIA_VER);
  const CREAR = hasAction(actions.CUOTAALIMENTARIA_CREAR);
  const EDITAR = hasAction(actions.CUOTAALIMENTARIA_EDITAR);
  const GUARDAR = match.params.id === "nuevo" ? CREAR : EDITAR;

  const [state, setState] = useState({
    title:
      match.params.id === "nuevo" ? "Registrar" : EDITAR ? "Editar" : "Ver",
    id: match.params.id === "nuevo" ? 0 : parseInt(match.params.id),
    loading: false,
  });

  const [tipos, setTipos] = useState([]);
  const [conceptos, setConceptos] = useState([]);
  const [empleados, setEmpleados] = useState([]);
  const [hijos, setHijos] = useState([]);

  const [formulas, setFormulas] = useState([]);

  const breadcrumb = [
    { id: 1, title: "Novedades" },
    {
      id: 2,
      title: "Liquidación",
      url: "/novedades/liquidacion",
    },
    {
      id: 3,
      title: "Cuotas Alimentarias",
      url: "/novedades/liquidacion/cuotaalimentaria",
    },
    { id: 4, title: state.title },
  ];

  const onChangeEmleado = async (empleado) => {
    if (empleado) {
      const rList = await http.get("cuotaAlimentaria/hijos/list/" + empleado);
      if (rList && rList.data.code === 0) {
        const data = rList.data.data;
        setHijos(data.list);
      }
    }
  };

  async function setHijosEmpleados() {
    if (state.id !== 0) {
      const rList = await http.get(
        "cuotaAlimentaria/hijosCuota/list/" + state.id
      );
      if (rList && rList.data.code === 0) {
        const data = rList.data.data;
        setHijos(data.list);
      }
    }
  }

  const onSave = async (values) => {
    const nFormulas = parseFormulas(values);
    if (!validateFormulas(nFormulas)) {
      return;
    }

    values.formulas = nFormulas;

    setState({ ...state, loading: true });

    const result =
      state.id === 0
        ? await http.post("CuotaAlimentaria", values)
        : await http.put(`CuotaAlimentaria/${state.id}`, values);

    if (result && result.data.code === 0) {
      showSuccess("El embargo fue guardado exitosamente.");
      history.push("/novedades/liquidacion/cuotaalimentaria");
    } else {
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {
    setHijosEmpleados();

    async function getForm() {
      const response = await http.get("CuotaAlimentaria/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setConceptos(data.conceptos);
        setEmpleados(data.empleados);
        setTipos(data.tipos);
      }

      const rForm = await http.get(`CuotaAlimentaria/${state.id}`);
      if (rForm && rForm.data.code === 0) {
        const data = rForm.data.data;
        if (data.fechaInicio) data.fechaInicio = moment(data.fechaInicio);
        if (data.fechaBaja) data.fechaBaja = moment(data.fechaBaja);

        form.setFieldsValue(data);
        setFormulas(data.formulas ?? []);
      }
    }

    if (VER) {
      getForm();
    }
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="alimentarios">
      <Header
        title={`${state.title} Cuota Alimentaria`}
        breadcrumb={breadcrumb}
      />
      <div className="form card">
        <Form form={form} layout="vertical" onFinish={onSave}>
          <div className="">
            <div className="form-icon">
              <FiAlertTriangle />
            </div>
            <Form.Item label="Empleado" name="empleadoId" rules={[required]}>
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                onChange={onChangeEmleado}
              >
                {empleados.map((m) => (
                  <Option key={m.value} value={m.value}>
                    {m.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Concepto" name="conceptoId" rules={[required]}>
              <Select showSearch allowClear optionFilterProp="children">
                {conceptos.map((m) => (
                  <Option key={m.value} value={m.value}>
                    {m.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Row gutter={20}>
              <Col span="12">
                <Form.Item
                  label="Fecha Inicio Oficio"
                  name="fechaInicio"
                  rules={[required]}
                >
                  <DatePicker
                    disabled={!GUARDAR}
                    format="DD/MM/YYYY"
                    placeholder=""
                    locale={locale}
                  />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item label="Fecha Fin Oficio" name="fechaBaja">
                  <DatePicker
                    disabled={!GUARDAR}
                    format="DD/MM/YYYY"
                    placeholder=""
                    locale={locale}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item label="Tipo" name="tipoCuotaAlimentaria">
              <Select showSearch allowClear optionFilterProp="children">
                {tipos.map((m) => (
                  <Option key={m.value} value={m.value} disabled={m.disabled}>
                    {m.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Hijos vinculados" name="hijos">
              <Select mode="multiple" allowClear disabled={!GUARDAR}>
                {hijos.map((x) => (
                  <Option key={x.value} value={x.value}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="CBU" name="cbu">
              <Input disabled={!GUARDAR} />
            </Form.Item>

            <Form.Item label="Autos" name="autos">
              <Input disabled={!GUARDAR} />
            </Form.Item>

            <Form.Item label="Beneficiario" name="beneficiario">
              <Input disabled={!GUARDAR} />
            </Form.Item>

            <Form.Item label="CUIL" name="cuil">
              <InputMasked mask="99 99999999 9" />
            </Form.Item>

            <h4>Fórmulas</h4>
            <FormulasForm form={form} formulas={formulas} />

            <div className="form-actions">
              <Link to="/novedades/liquidacion/cuotaalimentaria">
                <Button type="secondary">Volver</Button>
              </Link>
              {GUARDAR && (
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={state.loading}
                >
                  Guardar
                </Button>
              )}
            </div>
          </div>
        </Form>
        {state.id > 0 && (
          <React.Fragment>
            <hr />
            <div className="section-header">
              <div className="section-title">
                Documentación
                <FiPaperclip />
              </div>
              <div className="section-subtitle">Documentación asociada.</div>
            </div>
            <ArchivosCuotas cuotaAlimentaria={state.id} />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default CuotaAlimentariaForm;
