/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Button, Form, Input, Menu, Modal, Select, DatePicker } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { FiEdit, FiPlus, FiTrash2 } from "react-icons/fi";

import "moment/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";

import { ContextProvider } from "../../../../services/context";
import http from "../../../../services/http";
import { showSuccess } from "../../../../services/notificacion";
import { hasAction, actions } from "../../../../services/security";

import Filters from "../../../../components/Filters";
import FiltersButton from "../../../../components/FiltersButton";
import Header from "../../../../components/Header";
import Table from "../../../../components/Table";

const { Option } = Select;

const AsignacionesElementos = () => {
  const history = useHistory();

  const VER = hasAction(actions.ASIGNACIONESELEMENTOS_VER);
  const CREAR = hasAction(actions.ASIGNACIONESELEMENTOS_CREAR);
  const EDITAR = hasAction(actions.ASIGNACIONESELEMENTOS_EDITAR);
  const ELIMINAR = hasAction(actions.ASIGNACIONESELEMENTOS_ELIMINAR);

  const [state, setState] = useState({
    filters: {
      desde: null,
      hasta: null,
      nroOrden: "",
      elementoId: "",
      empleadoId: "",
      tipoElementoId: "",
    },
    columns: [
      {
        id: 1,
        title: "Nro de Orden",
        style: {},
        render: (item) => item.id,
      },
      {
        id: 2,
        title: "Elemento",
        style: {},
        render: (item) => item.elemento,
      },
      {
        id: 3,
        title: "Empleado",
        style: {},
        render: (item) => item.empleado,
      },
      {
        id: 4,
        title: "Talle",
        style: {},
        render: (item) => item.talle,
      },
      {
        id: 5,
        title: "Fecha Vencimiento",
        style: {},
        render: (item) => item.fechaVencimientoStr,
      },
    ],
    loading: true,
    loaded: false,
    reload: true,
    list: [],
    total: 0,
    page: 1,
    size: 10,
  });

  const [elementos, setElementos] = useState([]);
  const [empleados, setEmpleados] = useState([]);
  const [tipos, setTipos] = useState([]);

  const breadcrumb = [
    { id: 1, title: "Administracion" },
    {
      id: 2,
      title: "Seguridad Laboral",
      url: "/administracion/seguridad",
    },
    {
      id: 3,
      title: "Asignación de Elementos",
      url: "/administracion/seguridad/asignacion_elementos",
    },
  ];

  const buttons = () => {
    return (
      <React.Fragment>
        <FiltersButton />
        {CREAR && (
          <Button type="primary" icon={<FiPlus />} onClick={onAdd}>
            Nuevo
          </Button>
        )}
      </React.Fragment>
    );
  };

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Fecha Vencimiento Desde" name="desde">
          <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
        </Form.Item>

        <Form.Item label="Fecha Vencimiento Hasta" name="hasta">
          <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
        </Form.Item>

        <Form.Item label="Nro de Orden" name="nroOrden">
          <Input type="number" />
        </Form.Item>

        <Form.Item label="Empleado" name="empleadoId">
          <Select showSearch allowClear optionFilterProp="children">
            {empleados.map((m) => (
              <Option key={m.value} value={m.value}>
                {m.text}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Tipo Elemento" name="tipoElementoId">
          <Select showSearch allowClear optionFilterProp="children">
            {tipos.map((m) => (
              <Option key={m.value} value={m.value}>
                {m.text}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Elemento" name="elementoId">
          <Select showSearch allowClear optionFilterProp="children">
            {elementos.map((m) => (
              <Option key={m.value} value={m.value}>
                {m.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </React.Fragment>
    );
  };

  const menu = (item) => {
    return (
      <Menu className="table-actions-menu">
        {EDITAR && (
          <Menu.Item key="0" onClick={() => onEdit(item)}>
            <FiEdit />
            Editar
          </Menu.Item>
        )}
        {ELIMINAR && (
          <Menu.Item key="1" onClick={() => onDelete(item)}>
            <FiTrash2 />
            Eliminar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const onAdd = () => {
    history.push("/administracion/seguridad/asignacion_elementos/nuevo");
  };

  const onEdit = (item) => {
    history.push(`/administracion/seguridad/asignacion_elementos/${item.id}`);
  };

  const onDelete = (item) => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea eliminar esta asignación?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const rDelete = await http.delete(`AsignacionesElementos/${item.id}`);
        if (rDelete && rDelete.data.code === 0) {
          showSuccess("El elemento fue eliminado exitosamente.");
          setState({ ...state, reload: !state.reload });
        }
      },
    });
  };

  useEffect(() => {
    async function getData() {
      const response = await http.get("AsignacionesElementos/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setElementos(data.elementos);
        setEmpleados(data.empleados);
        setTipos(data.tipos);
      }
    }

    getData();
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="AsignacionesElementos page">
      <ContextProvider value={[state, setState]}>
        <Header
          title="Asignación de Elementos"
          breadcrumb={breadcrumb}
          buttons={buttons}
        />
        <Filters filters={filters} />
        <Table url="AsignacionesElementos/list" menu={menu} />
      </ContextProvider>
    </div>
  );
};

export default AsignacionesElementos;
