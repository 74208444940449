import React from "react";
import { Col, Row } from "antd";

import { hasAction, actions } from "./../../../services/security";

import Header from "./../../../components/Header";
import MenuCard from "./../../../components/MenuCard";

const Generales = () => {
  const items = [
    {
      id: 1,
      titulo: "A.F.J.P",
      path: "/parametros/generales/afjp",
      color: "primary",
      icono: "FiLayers",
      entidad: "Afjp",
      visible: hasAction(actions.AFJP_VER),
    },
    {
      id: 2,
      titulo: "A.R.T",
      path: "/parametros/generales/art",
      color: "primary",
      icono: "FiInbox",
      entidad: "Art",
      visible: hasAction(actions.ART_VER),
    },
    {
      id: 3,
      titulo: "Asignaciones por Título",
      path: "/parametros/generales/asignaciones",
      color: "primary",
      icono: "FiPaperclip",
      entidad: "AsignacionTitulo",
      visible: hasAction(actions.ASIGNACIONTITULO_VER),
    },
    {
      id: 4,
      titulo: "Bancos",
      path: "/parametros/generales/bancos",
      color: "primary",
      icono: "FiDollarSign",
      entidad: "Banco",
      visible: hasAction(actions.BANCOS_VER),
    },
    {
      id: 5,
      titulo: "Clases",
      path: "/parametros/generales/clases",
      color: "primary",
      icono: "FiList",
      entidad: "Clase",
      visible: hasAction(actions.CLASES_VER),
    },
    {
      id: 6,
      titulo: "Convenios",
      path: "/parametros/generales/convenios",
      color: "primary",
      icono: "FiFile",
      entidad: "Convenio",
      visible: hasAction(actions.CONVENIOS_VER),
    },
    {
      id: 7,
      titulo: "Criterios de Evaluación",
      path: "/parametros/generales/criterios",
      color: "primary",
      icono: "FiCheckCircle",
      entidad: "CriterioEvaluacion",
      visible: hasAction(actions.CRITERIOS_EVALUACION_VER),
    },
    {
      id: 8,
      titulo: "Escalafón",
      path: "/parametros/generales/escalafon",
      color: "primary",
      icono: "FiList",
      entidad: "Escalafon",
      visible: hasAction(actions.ESCALAFON_VER),
    },
    {
      id: 9,
      titulo: "Funciones",
      path: "/parametros/generales/funciones",
      color: "primary",
      icono: "FiList",
      entidad: "Funcion",
      visible: hasAction(actions.FUNCIONES_VER),
    },
    {
      id: 10,
      titulo: "Grupos Sanguíneos",
      path: "/parametros/generales/grupos_sanguineos",
      color: "primary",
      icono: "FiBookmark",
      entidad: "GrupoSanguineo",
      visible: hasAction(actions.GRUPOS_SANGUINEOS_VER),
    },
    {
      id: 11,
      titulo: "Idiomas",
      path: "/parametros/generales/idiomas",
      color: "primary",
      icono: "FiPenTool",
      entidad: "Idioma",
      visible: hasAction(actions.IDIOMAS_VER),
    },
    {
      id: 12,
      titulo: "Motivos",
      path: "/parametros/generales/motivos",
      color: "primary",
      icono: "FiList",
      entidad: "Motivo",
      visible: hasAction(actions.MOTIVOS_VER),
    },
    {
      id: 13,
      titulo: "Motivos de Baja",
      path: "/parametros/generales/motivos_baja",
      color: "primary",
      icono: "FiList",
      entidad: "MotivoBaja",
      visible: hasAction(actions.MOTIVOSBAJA_VER),
    },
    {
      id: 14,
      titulo: "Motivos de Movimiento",
      path: "/parametros/generales/motivos_movimiento",
      color: "primary",
      icono: "FiList",
      entidad: "MotivoMovimiento",
      visible: hasAction(actions.MOTIVOSMOVIMIENTOS_VER),
    },
    {
      id: 15,
      titulo: "Niveles de Estudio",
      path: "/parametros/generales/niveles_estudio",
      color: "primary",
      icono: "FiPaperclip",
      entidad: "NivelEstudio",
      visible: hasAction(actions.NIVELESTUDIO_VER),
    },
    {
      id: 16,
      titulo: "Paises",
      path: "/parametros/generales/paises",
      color: "primary",
      icono: "FiMap",
      entidad: "Pais",
      visible: hasAction(actions.PAISES_VER),
    },
    {
      id: 17,
      titulo: "Partidas Presupuestarias",
      path: "/parametros/generales/partidas-presupuestarias",
      color: "primary",
      icono: "FiDollarSign",
      entidad: "PartidaPresupuestaria",
      visible: hasAction(actions.PARTIDAS_PRESUPUESTARIAS_VER),
    },
    {
      id: 18,
      titulo: "Provincias",
      path: "/parametros/generales/provincias",
      color: "primary",
      icono: "FiMap",
      entidad: "Provincia",
      visible: hasAction(actions.PROVINCIAS_VER),
    },
    {
      id: 19,
      titulo: "Sindicatos",
      path: "/parametros/generales/sindicatos",
      color: "primary",
      icono: "FiShield",
      entidad: "Sindicato",
      visible: hasAction(actions.SINDICATOS_VER),
    },
    {
      id: 20,
      titulo: "Tareas",
      path: "/parametros/generales/tareas",
      color: "primary",
      icono: "FiAlertOctagon",
      entidad: "Tarea",
      visible: hasAction(actions.TAREAS_VER),
    },
    {
      id: 21,
      titulo: "Tipos de Sanción",
      path: "/parametros/generales/tipos_sancion",
      color: "primary",
      icono: "FiHexagon",
      entidad: "TipoSancion",
      visible: hasAction(actions.TIPOS_SANCION_VER),
    },
    {
      id: 22,
      titulo: "Títulos",
      path: "/parametros/generales/titulos",
      color: "primary",
      icono: "FiShield",
      entidad: "Titulo",
      visible: hasAction(actions.TITULO_VER),
    },
  ];

  const breadcrumb = [
    {
      id: 1,
      title: "Parámetros",
    },
    {
      id: 2,
      title: "Generales",
      url: "/parametros/generales",
    },
  ];

  return (
    <div className="inicio">
      <Header title="Generales" breadcrumb={breadcrumb} buttons={() => {}} />

      <Row gutter={[32, 36]}>
        {items.map((i) => (
          <Col key={i.id} xs={12} md={8} xl={6} xxl={4}>
            <MenuCard item={i} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Generales;
