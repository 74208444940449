/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  TimePicker,
  Switch,
} from 'antd';
// import MaskedInput from "antd-mask-input";
import {
  FiBriefcase,
  FiCalendar,
  FiFolder,
  FiPaperclip,
  FiRotateCcw,
} from 'react-icons/fi';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/es_ES';
import 'moment/locale/es';

import { Context } from './../../../services/context';
import {
  parseDate,
  parseToDate,
  parseTime,
  parseToTime,
} from './../../../services/forms';
import http from './../../../services/http';
import { showSuccess } from './../../../services/notificacion';
import { hasAction, actions } from './../../../services/security';
import { required } from './../../../services/validation';

import Archivos from './Archivos';
import ExperienciasList from './ExperienciasList';
import ExperienciasForm from './ExperienciasForm';
import InputMasked from './../../../components/InputMasked';

const { Option } = Select;

const Laborales = () => {
  const [form] = Form.useForm();
  const [formActo] = Form.useForm();
  const [state, setState] = useContext(Context);

  const CREAR = hasAction(actions.LEGAJOS_CREAR);
  const EDITAR = hasAction(actions.LEGAJOS_EDITAR);
  const GUARDAR =
    state.id < 0
      ? false
      : state.id === 0
      ? CREAR && state.editing
      : EDITAR && state.editing;

  let agrupacionClaseConfirmada = false;

  const [baja, setBaja] = useState(undefined);
  const [section, setSection] = useState(null);
  const [current, setCurrent] = useState(null);
  const [reingreso, setReingreso] = useState(false);
  const [retieneCargo, setRetieneCargo] = useState(false);
  const [esRiesgosa, setEsRiesgosa] = useState(false);
  const [bajaDefinitiva, setBajaDefinitiva] = useState(false);
  const [bajaTransitoria, setBajaTransitoria] = useState(false);
  const [tipoContratacion, setTipoContratacion] = useState(false);

  const [initial, setInitial] = useState(true);
  const [original, setOriginal] = useState();
  const [actos, setActos] = useState([]);
  const [currentActo, setCurrentActo] = useState();

  useEffect(() => {
    async function getForm() {
      form.resetFields();

      const rForm = await http.get(`legajos/laborales/${state.id}`);
      if (rForm && rForm.data.code === 0) {
        const data = rForm.data.data;

        data.form.fechaIngreso = parseToDate(data.form.fechaIngreso);
        data.form.fechaIngresoBonoSueldo = parseToDate(
          data.form.fechaIngresoBonoSueldo
        );
        data.form.cargoInterinoDesde = parseToDate(
          data.form.cargoInterinoDesde
        );
        data.form.cargoInterinoHasta = parseToDate(
          data.form.cargoInterinoHasta
        );

        data.form.horarioDesde = parseToTime(data.form.horarioDesde);
        data.form.horarioHasta = parseToTime(data.form.horarioHasta);
        data.form.delegadoDesde = parseToDate(data.form.delegadoDesde);
        data.form.delegadoHasta = parseToDate(data.form.delegadoHasta);
        data.form.sindicalDesde = parseToDate(data.form.sindicalDesde);
        data.form.sindicalHasta = parseToDate(data.form.sindicalHasta);
        data.form.insalubreDesde = parseToDate(data.form.insalubreDesde);
        data.form.insalubreHasta = parseToDate(data.form.insalubreHasta);
        data.form.riesgosoDesde = parseToDate(data.form.riesgosoDesde);
        data.form.riesgosoHasta = parseToDate(data.form.riesgosoHasta);
        data.form.fechaEstado = parseToDate(data.form.fechaEstado);
        data.form.fechaBaja = parseToDate(data.form.fechaBaja);
        data.form.tareaDesde = parseToDate(data.form.tareaDesde);
        data.form.tareaHasta = parseToDate(data.form.tareaHasta);

        setBaja(data.form.fechaBaja);

        form.setFieldsValue(data.form);

        onTareaChange(data.form.tarea);
        if (data.form.estado === '2') {
          setBajaDefinitiva(true);
        }
        if (data.form.estado === '3') {
          setBajaTransitoria(true);
        }
        onRetieneCargoChange(data.form.esClaseRetenida);

        setOriginal(data.form);
        setInitial(false);
      }
    }

    getForm();
  }, [state.id, state.reload]);

  const onCurrentChange = (section, current) => {
    setSection(section);
    setCurrent(current);
  };

  const onReingresoChange = (value) => {
    setReingreso(value);
  };

  const onEstadoChange = (value) => {
    form.setFieldsValue({
      motivoBaja: '',
    });

    if (value === '2') {
      setBajaDefinitiva(true);
      setBajaTransitoria(false);
    } else if (value === '3') {
      setBajaDefinitiva(false);
      setBajaTransitoria(true);
    } else {
      setBajaDefinitiva(false);
      setBajaTransitoria(false);
      form.setFieldsValue({
        fechaBaja: '',
      });
    }
    onRegisterActo('estado');
  };

  const onChangeConvenio = (obj) => {
    setTipoContratacion(false);
    if (obj && obj.codigo) {
      const value = obj.codigo;
      if (value === '1') {
        form.setFieldsValue({
          tipoContratacion: '1',
        });
        setTipoContratacion(true);
      } else if (value === '2') {
        form.setFieldsValue({
          tipoContratacion: '2',
        });
        setTipoContratacion(true);
      } else if (value === '7') {
        form.setFieldsValue({
          tipoContratacion: '',
        });
        setTipoContratacion(false);
      } else {
        form.setFieldsValue({
          tipoContratacion: '3',
        });
        setTipoContratacion(true);
      }
    }
    onRegisterActo('convenio');
  };

  const onReingresoConfirm = async (values) => {
    values.fechaReingreso = parseDate(values.fechaReingreso);

    const rSave = await http.put(
      `legajos/laborales/reingreso/${state.id}`,
      values
    );

    if (rSave && rSave.data.code === 0) {
      showSuccess('El reingreso fue registrado exitosamente.');
      form.setFieldsValue({ estado: '1', fechaBaja: '' });
      setBajaDefinitiva(false);
      setBajaTransitoria(false);
      setBaja(undefined);
      onReingresoChange(false);
    }
  };

  const onTareaChange = (value) => {
    const item = state.tareas.find((x) => x.id === parseInt(value));
    form.setFieldsValue({
      tareaTipo: item?.tipo ?? '',
      tareaRiesgosa: item?.riesgosa ?? '',
      tareaPorcentaje: item?.porcentaje ?? '',
    });
    if (item?.riesgosa === '1') {
      setEsRiesgosa(true);
    } else {
      setEsRiesgosa(false);
    }

    onRegisterActo('tarea');
  };

  function onRetieneCargoChange(obj) {
    if (obj && obj === '1') {
      setRetieneCargo(true);
    } else {
      setRetieneCargo(false);
      const claseRetenidaValue = form.getFieldValue('claseRetenida');
      const agrupamientoRetenidoValue = form.getFieldValue(
        'agrupamientoRetenido'
      );
      if (claseRetenidaValue) {
        onRegisterActo('claseRetenida');
      }
      if (agrupamientoRetenidoValue) {
        onRegisterActo('agrupamientoRetenido');
      }
      form.setFieldsValue({
        claseRetenida: undefined,
        agrupamientoRetenido: undefined,
      });
      form.validateFields();
    }
  }

  const onRegisterActo = (property) => {
    if (initial) {
      return;
    }

    // Limpiar actos para la misma propiedad
    let nActos = [...actos];
    nActos = nActos.filter((item) => item.propiedad !== property);
    setActos(nActos);

    const value = form.getFieldValue(property);

    if (original[property] !== value) {
      setCurrentActo(property);
    }
  };

  const onConfirmActo = (values) => {
    const fecha = values.fecha;
    values.fecha = parseDate(values.fecha);
    values.propiedad = currentActo;

    if (currentActo === 'estado') {
      form.setFieldsValue({ fechaEstado: fecha });
    }

    let nActos = [...actos];
    nActos.push(values);
    setActos(nActos);

    setCurrentActo(undefined);
  };

  const onCancelActo = () => {
    const values = [];
    values[currentActo] = original[currentActo];
    form.setFieldsValue(values);
    if (currentActo === 'estado') {
      onEstadoChange(values[currentActo]);
    }
    if (currentActo === 'convenio') {
      values['tipoContratacion'] = original['tipoContratacion'];
      form.setFieldsValue(values);
    }
    setCurrentActo(undefined);
  };

  const onSave = async (values) => {
    values.fechaIngreso = parseDate(values.fechaIngreso);
    values.fechaIngresoBonoSueldo = parseDate(values.fechaIngresoBonoSueldo);
    values.cargoInterinoDesde = parseDate(values.cargoInterinoDesde);
    values.cargoInterinoHasta = parseDate(values.cargoInterinoHasta);
    values.horarioDesde = parseTime(values.horarioDesde);
    values.horarioHasta = parseTime(values.horarioHasta);
    values.delegadoDesde = parseDate(values.delegadoDesde);
    values.delegadoHasta = parseDate(values.delegadoHasta);
    values.sindicalDesde = parseDate(values.sindicalDesde);
    values.sindicalHasta = parseDate(values.sindicalHasta);
    values.insalubreDesde = parseDate(values.insalubreDesde);
    values.insalubreHasta = parseDate(values.insalubreHasta);
    values.riesgosoDesde = parseDate(values.riesgosoDesde);
    values.riesgosoHasta = parseDate(values.riesgosoHasta);
    values.fechaBaja = parseDate(values.fechaBaja);
    values.agrupacionClaseConfirmada = agrupacionClaseConfirmada;
    values.actos = actos;

    setState({ ...state, loading: true });
    const response = await http.put(`legajos/laborales/${state.id}`, values);
    setState({ ...state, loading: false });

    if (response && response.data.code === 0) {
      showSuccess('El legajo fue guardado exitosamente.');
      setOriginal(form);
    }

    if (response && response.data.code === 5) {
      Modal.confirm({
        title: 'Confirmar acción',
        icon: <ExclamationCircleOutlined />,
        content: response.data.message,
        okText: 'Confirmar',
        cancelText: 'Cancelar',
        onOk: async () => {
          agrupacionClaseConfirmada = true;
          onSave(form.getFieldsValue());
        },
      });
    }
  };

  return (
    <div className="personales">
      <div className="section-header">
        <div className="section-title">
          Datos Laborales
          <FiBriefcase />
        </div>
        <div className="section-subtitle">Información laboral del empleado</div>
      </div>

      <Form
        form={form}
        className="subsection-form"
        layout="vertical"
        onFinish={onSave}
      >
        <Row gutter={20}>
          <Col span="12">
            <Form.Item label="Función" name="funcion">
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                disabled={!GUARDAR}
                onChange={() => onRegisterActo('funcion')}
              >
                {state.funciones.map((x) => (
                  <Option key={x.value} value={x.value} disabled={x.disabled}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item label="Secretaría" name="secretaria">
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                disabled={!GUARDAR}
                onChange={() => onRegisterActo('secretaria')}
              >
                {state.secretarias.map((x) => (
                  <Option key={x.value} value={x.value} disabled={x.disabled}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span="12">
            <Form.Item label="Dirección" name="direccion">
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                disabled={!GUARDAR}
                onChange={() => onRegisterActo('direccion')}
              >
                {state.direcciones.map((x) => (
                  <Option key={x.value} value={x.value} disabled={x.disabled}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item label="Subdirección" name="subDireccion">
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                disabled={!GUARDAR}
                onChange={() => onRegisterActo('subDireccion')}
              >
                {state.subdirecciones.map((x) => (
                  <Option key={x.value} value={x.value} disabled={x.disabled}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Lugar de Trabajo" name="lugarTrabajo">
          <Select
            allowClear
            showSearch
            optionFilterProp="children"
            disabled={!GUARDAR}
            onChange={() => onRegisterActo('lugarTrabajo')}
          >
            {state.lugares.map((x) => (
              <Option key={x.value} value={x.value} disabled={x.disabled}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Row gutter={20}>
          <Col span="12">
            <Form.Item label="Convenio" name="convenio">
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                disabled={!GUARDAR}
                onChange={(text, obj) => {
                  onChangeConvenio(obj);
                }}
              >
                {state.convenios.map((x) => (
                  <Option
                    key={x.value}
                    value={x.value}
                    codigo={x.extra}
                    disabled={x.disabled}
                  >
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item label="Categoría" name="clase">
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                disabled={!GUARDAR}
                onChange={() => onRegisterActo('clase')}
              >
                {state.clases.map((x) => (
                  <Option key={x.value} value={x.value} disabled={x.disabled}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Agrupamiento" name="agrupamiento">
          <Select
            allowClear
            showSearch
            optionFilterProp="children"
            disabled={!GUARDAR}
            onChange={() => onRegisterActo('agrupamiento')}
          >
            {state.agrupamientos.map((x) => (
              <Option key={x.value} value={x.value} disabled={x.disabled}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Row gutter={20}>
          <Col span="8">
            <Form.Item
              label="Fecha Ingreso Bono Sueldo"
              name="fechaIngresoBonoSueldo"
            >
              <DatePicker
                format="DD/MM/YYYY"
                placeholder=""
                locale={locale}
                disabled={!GUARDAR}
              />
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item label="Fecha Ingreso" name="fechaIngreso">
              <DatePicker
                format="DD/MM/YYYY"
                placeholder=""
                locale={locale}
                disabled={!GUARDAR}
              />
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item label="Antigüedad" name="antiguedad">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span="12">
            <Form.Item
              label="Antigüedad Reconocida"
              name="antiguedadReconocida"
            >
              <Input disabled={!GUARDAR} />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item label="Antigüedad Total" name="antiguedadTotal">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span="8">
            <Form.Item label="Retiene Cargo" name="esClaseRetenida">
              <Select disabled={!GUARDAR} onChange={onRetieneCargoChange}>
                <Option value="0">No</Option>
                <Option value="1">Si</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item
              label="Categoría Retenida"
              name="claseRetenida"
              rules={[
                {
                  required: retieneCargo,
                  message: 'Este campo es obligatorio',
                },
              ]}
              onChange={() => onRegisterActo('claseRetenida')}
            >
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                disabled={!GUARDAR || !retieneCargo}
              >
                {state.clases.map((x) => (
                  <Option key={x.value} value={x.value} disabled={x.disabled}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item
              label="Agrupamiento Retenido"
              name="agrupamientoRetenido"
              rules={[
                {
                  required: retieneCargo,
                  message: 'Este campo es obligatorio',
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                disabled={!GUARDAR || !retieneCargo}
                onChange={() => onRegisterActo('agrupamientoRetenido')}
              >
                {state.agrupamientos.map((x) => (
                  <Option key={x.value} value={x.value} disabled={x.disabled}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span="8">
            <Form.Item label="Subroga Cargo" name="subrogaCargo">
              <Select disabled={!GUARDAR}>
                <Option value="0">No</Option>
                <Option value="1">Si</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span="16">
            <Form.Item label="Cargo Interino" name="cargoInterino">
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                disabled={!GUARDAR}
              >
                {state.funciones.map((x) => (
                  <Option key={x.value} value={x.value} disabled={x.disabled}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span="12">
            <Form.Item label="Cargo Interino Desde" name="cargoInterinoDesde">
              <DatePicker
                format="DD/MM/YYYY"
                placeholder=""
                locale={locale}
                disabled={!GUARDAR}
              />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item label="Cargo Interino Hasta" name="cargoInterinoHasta">
              <DatePicker
                format="DD/MM/YYYY"
                placeholder=""
                locale={locale}
                disabled={!GUARDAR}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span="8">
            <Form.Item label="Régimen de Trabajo" name="regimenTrabajo">
              <Select allowClear disabled={!GUARDAR}>
                {state.regimenesTrabajo.map((x) => (
                  <Option key={x.value} value={x.value}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item label="Horario de Trabajo" name="horarioDesde">
              <TimePicker
                format="HH:mm"
                showNow={false}
                placeholder=""
                locale={locale}
                disabled={!GUARDAR}
              />
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item label="Hasta" name="horarioHasta">
              <TimePicker
                format="HH:mm"
                showNow={false}
                placeholder=""
                locale={locale}
                disabled={!GUARDAR}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span="8">
            <Form.Item label="Reducción Horaria" name="reduccionHoraria">
              <Select disabled={!GUARDAR}>
                <Option value="0">No</Option>
                <Option value="1">Si</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item label="Porcentaje Reducción" name="reduccionPorcentaje">
              <Input type="number" disabled={!GUARDAR} />
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item
              label="Marca en Reloj Biométrico"
              name="tieneRelojBiometrico"
            >
              <Select disabled={!GUARDAR}>
                <Option value="0">No</Option>
                <Option value="1">Si</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span="24">
            <Form.Item
              label="Locación del Reloj Biométrico"
              name="relojBiometrico"
            >
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                disabled={!GUARDAR}
              >
                {state.relojes.map((x) => (
                  <Option key={x.value} value={x.value} disabled={x.disabled}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span="12">
            <Form.Item
              label="Correo Electrónico Corporativo"
              name="emailCorporativo"
            >
              <Input disabled={!GUARDAR} />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item label="Teléfono Corporativo" name="telefonoCorporativo">
              <Input disabled={!GUARDAR} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span="8">
            <Form.Item label="Condición Sindical" name="condicionSindical">
              <Select allowClear disabled={!GUARDAR}>
                {state.condicionesSindicales.map((x) => (
                  <Option key={x.value} value={x.value}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span="16">
            <Form.Item label="Sindicato" name="sindicato">
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                disabled={!GUARDAR}
              >
                {state.sindicatos.map((x) => (
                  <Option key={x.value} value={x.value} disabled={x.disabled}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span="12">
            <Form.Item label="Delegado Sindical Desde" name="delegadoDesde">
              <DatePicker
                format="DD/MM/YYYY"
                placeholder=""
                locale={locale}
                disabled={!GUARDAR}
              />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item label="Delegado Sindical Hasta" name="delegadoHasta">
              <DatePicker
                format="DD/MM/YYYY"
                placeholder=""
                locale={locale}
                disabled={!GUARDAR}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Cargo Sindical del Delegado" name="cargoSindical">
          <Select
            allowClear
            showSearch
            optionFilterProp="children"
            disabled={!GUARDAR}
          >
            {state.cargosSindicales.map((x) => (
              <Option key={x.value} value={x.value} disabled={x.disabled}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Row gutter={20}>
          <Col span="12">
            <Form.Item label="Cargo Sindical Desde" name="sindicalDesde">
              <DatePicker
                format="DD/MM/YYYY"
                placeholder=""
                locale={locale}
                disabled={!GUARDAR}
              />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item label="Cargo Sindical Hasta" name="sindicalHasta">
              <DatePicker
                format="DD/MM/YYYY"
                placeholder=""
                locale={locale}
                disabled={!GUARDAR}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Licencia" name="regimenLicencia">
          <Select
            allowClear
            showSearch
            optionFilterProp="children"
            disabled={!GUARDAR}
          >
            {state.regimenesLicencias.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Row gutter={20}>
          <Col span="12">
            <Form.Item label="Horas por Mes" name="horasMes">
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item label="Horas por Día" name="horasDia">
              <Input type="number" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span="8">
            <Form.Item label="Insalubre" name="insalubre">
              <Select disabled={!GUARDAR}>
                <Option value="0">No</Option>
                <Option value="1">Si</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item label="Desde" name="insalubreDesde">
              <DatePicker
                format="DD/MM/YYYY"
                placeholder=""
                locale={locale}
                disabled={!GUARDAR}
              />
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item label="Hasta" name="insalubreHasta">
              <DatePicker
                format="DD/MM/YYYY"
                placeholder=""
                locale={locale}
                disabled={!GUARDAR}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span="24">
            <Form.Item label="Tarea" name="tarea">
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                disabled={!GUARDAR}
                onChange={onTareaChange}
              >
                {state.tareas.map((x) => (
                  <Option key={x.id.toString()} value={x.id.toString()}>
                    {x.codigo} - {x.nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span="8">
            <Form.Item label="Tipo" name="tareaTipo">
              <Select disabled={true}>
                <Option value="1">Total</Option>
                <Option value="2">Parcial</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item label="Riesgosa" name="tareaRiesgosa">
              <Select disabled={true}>
                <Option value="1">Si</Option>
                <Option value="0">No</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item label="Porcentaje" name="tareaPorcentaje">
              <Input disabled={true} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span="12">
            <Form.Item
              label="Desde"
              name="tareaDesde"
              rules={[
                {
                  required: esRiesgosa,
                  message: 'Este campo es obligatorio',
                },
              ]}
            >
              <DatePicker
                format="DD/MM/YYYY"
                placeholder=""
                locale={locale}
                disabled={!GUARDAR}
              />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item label="Hasta" name="tareaHasta">
              <DatePicker
                format="DD/MM/YYYY"
                placeholder=""
                locale={locale}
                disabled={!GUARDAR}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Forma de Pago" name="formaPago">
          <Select
            allowClear
            showSearch
            optionFilterProp="children"
            disabled={!GUARDAR}
          >
            {state.formasPago.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Row gutter={20}>
          <Col span="24">
            <Form.Item label="Banco" name="bancoSucursal">
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                disabled={!GUARDAR}
              >
                {state.bancos.map((x) => (
                  <Option key={x.value} value={x.value} disabled={x.disabled}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span="12">
            <Form.Item label="Cuenta" name="cuenta">
              <Input disabled={!GUARDAR} />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item label="CBU" name="cbu">
              <InputMasked mask="9999999999999999999999" disabled={!GUARDAR} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span="16">
            <Form.Item label="Obra Social" name="obraSocial">
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                disabled={!GUARDAR}
              >
                {state.obrasSociales.map((x) => (
                  <Option key={x.value} value={x.value}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item label="N° Afiliado" name="obraSocialNumero">
              <Input disabled={!GUARDAR} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span="12">
            <Form.Item label="A.R.T" name="art">
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                disabled={!GUARDAR}
              >
                {state.arts.map((x) => (
                  <Option key={x.value} value={x.value} disabled={x.disabled}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span="6">
            <Form.Item label="Estado" name="estado">
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                disabled={!GUARDAR}
                onChange={onEstadoChange}
              >
                {state.estados.map((x) => (
                  <Option key={x.value} value={x.value}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span="6">
            <Form.Item label="Fecha" name="fechaEstado">
              <DatePicker
                format="DD/MM/YYYY"
                placeholder=""
                locale={locale}
                disabled={true}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span="12">
            <Form.Item
              label="Responsabilidad Jerárquica"
              name="responsabilidadJerarquica"
            >
              <Select
                disabled={!GUARDAR}
                onChange={() => onRegisterActo('responsabilidadJerarquica')}
              >
                <Option value="0">No</Option>
                <Option value="1">Si</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              label="Tipo de contratación"
              name="tipoContratacion"
              rules={[required]}
            >
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                disabled={!GUARDAR || tipoContratacion}
              >
                {state.tiposContratacion.map((x) => (
                  <Option key={x.value} value={x.value} disabled={x.disabled}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          {bajaDefinitiva && (
            <Col span={baja !== undefined ? '10' : '12'}>
              <Form.Item
                label="Motivo de Baja"
                name="motivoBaja"
                rules={[
                  {
                    required: bajaDefinitiva,
                    message: 'Este campo es obligatorio',
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  disabled={!GUARDAR || !bajaDefinitiva}
                  onChange={() => onRegisterActo('motivoBaja')}
                >
                  {state.motivosBajaDefinitiva.map((x) => (
                    <Option key={x.value} value={x.value} disabled={x.disabled}>
                      {x.text}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          {bajaTransitoria && (
            <Col span={baja !== undefined ? '10' : '12'}>
              <Form.Item
                label="Motivo de Baja"
                name="motivoBaja"
                rules={[
                  {
                    required: bajaTransitoria,
                    message: 'Este campo es obligatorio',
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  disabled={!GUARDAR || !bajaTransitoria}
                  onChange={() => onRegisterActo('motivoBaja')}
                >
                  {state.motivosBajaTransitoria.map((x) => (
                    <Option key={x.value} value={x.value} disabled={x.disabled}>
                      {x.text}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}

          {(bajaDefinitiva || bajaTransitoria) && (
            <React.Fragment>
              <Col span={baja !== undefined ? '8' : '10'}>
                <Form.Item
                  label="Fecha de Último Día de Trabajo"
                  name="fechaBaja"
                >
                  <DatePicker
                    format="DD/MM/YYYY"
                    placeholder=""
                    locale={locale}
                    disabled={!GUARDAR}
                  />
                </Form.Item>
              </Col>
              <Col span={'2'}>
                <Form.Item label="¿Liquida?" name="liquida">
                  <Select disabled={!GUARDAR}>
                    <Option value="0">No</Option>
                    <Option value="1">Si</Option>
                  </Select>
                </Form.Item>
              </Col>
            </React.Fragment>
          )}

          {baja !== undefined && (
            <Col span="4">
              <Button
                type="secondary"
                onClick={() => onReingresoChange(true)}
                style={{ marginTop: '20px', width: '100%' }}
              >
                Reingreso
              </Button>
              <Modal
                visible={reingreso}
                onCancel={() => onReingresoChange(false)}
                footer={null}
                maskClosable={false}
                keyboard={false}
                destroyOnClose={true}
              >
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={onReingresoConfirm}
                >
                  <div className="form">
                    <div className="form-icon">
                      <FiRotateCcw />
                    </div>
                    <Form.Item label="Fecha de Reingreso" name="fechaReingreso">
                      <DatePicker
                        format="DD/MM/YYYY"
                        placeholder=""
                        locale={locale}
                        disabled={!GUARDAR}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Reconocer antiguedad anterior "
                      name="reconocerAntiguedad"
                      valuePropName="checked"
                    >
                      <Switch checkedChildren="Si" unCheckedChildren="No" />
                    </Form.Item>

                    <div className="form-actions">
                      <Button
                        type="secondary"
                        onClick={() => onReingresoChange(false)}
                      >
                        Cancelar
                      </Button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={state.loading}
                      >
                        Confirmar
                      </Button>
                    </div>
                  </div>
                </Form>
              </Modal>
            </Col>
          )}
        </Row>

        {GUARDAR && (
          <div className="form-actions">
            <Button type="primary" htmlType="submit" loading={state.loading}>
              Guardar
            </Button>
          </div>
        )}
      </Form>
      <hr />
      {(section === null || section === 'experiencias') && (
        <div className="section-header">
          <div className="section-title">
            Antecedentes Laborales
            <FiCalendar />
          </div>
          <div className="section-subtitle">
            Historial de experiencias laborales del empleado.
          </div>
        </div>
      )}
      {section === null && current === null && (
        <ExperienciasList onCurrentChange={onCurrentChange} />
      )}
      {section === 'experiencias' && current !== null && (
        <ExperienciasForm current={current} onCurrentChange={onCurrentChange} />
      )}
      {state.id > 0 && (
        <React.Fragment>
          <hr />
          <div className="section-header">
            <div className="section-title">
              Documentación
              <FiPaperclip />
            </div>
            <div className="section-subtitle">
              Documentación asociada con la información laboral del empleado
            </div>
          </div>
          <Archivos seccion="2" />
        </React.Fragment>
      )}

      <Modal
        visible={currentActo}
        footer={null}
        closable={false}
        maskClosable={false}
        keyboard={false}
        destroyOnClose={true}
      >
        <Form
          form={formActo}
          className="subsection-form"
          layout="vertical"
          onFinish={onConfirmActo}
        >
          <div className="form">
            <div className="form-icon">
              <FiFolder />
            </div>

            <Row gutter={20}>
              <Col span="24">
                <Form.Item label="Fecha" name="fecha" rules={[required]}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    placeholder=""
                    locale={locale}
                  />
                </Form.Item>
              </Col>
              <Col span="24">
                <Form.Item label="Instrumento" name="instrumento">
                  <Select allowClear showSearch optionFilterProp="children">
                    {state.instrumentos.map((x) => (
                      <Option key={x.value} value={x.value}>
                        {x.text}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span="24">
                <Form.Item label="N°" name="instrumentoNumero">
                  <Input />
                </Form.Item>
              </Col>
              <Col span="24">
                <Form.Item label="Expediente" name="expediente">
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <div className="form-actions">
              <Button type="secondary" onClick={onCancelActo}>
                Cancelar
              </Button>
              <Button type="primary" htmlType="submit">
                Confirmar
              </Button>
            </div>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default Laborales;
