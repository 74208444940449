/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { ContextProvider } from "./../../../services/context";
import http from "./../../../services/http";
import { hasAction, actions } from "./../../../services/security";

import Header from "./../../../components/Header";
import LegajoHeader from "./Header";
import Personales from "./Personales";
import PersonalesResumen from "./PersonalesResumen";
import Laborales from "./Laborales";
import LaboralesResumen from "./LaboralesResumen";
import Administrativos from "./Administrativos";
import Estudios from "./Estudios";
import Antecedentes from "./Antecedentes";
import Evaluaciones from "./Evaluaciones";
import Familiares from "./Familiares";
import FamiliaresResumen from "./FamiliaresResumen";

import "./LegajosForm.scss";
import Liquidaciones from "./Liquidaciones";

const LegajosForm = ({ match }) => {
  const VER = hasAction(actions.LEGAJOS_VER);

  const [state, setState] = useState({
    title: match.params.id === "nuevo" ? "Crear" : "Ver",
    id: match.params.id === "nuevo" ? 0 : parseInt(match.params.id),
    editing: match.params.id === "nuevo" ? true : false,
    loading: false,
    reload: false,
    visible: false,

    agrupamientos: [],
    antecedentesEstados: [],
    arts: [],
    asignaciones: [],
    bancos: [],
    cargosSindicales: [],
    clases: [],
    condicionesSindicales: [],
    convenios: [],
    criterios: [],
    departamentos: [],
    distritos: [],
    documentos: [],
    direcciones: [],
    empleados: [],
    escolaridad: [],
    estados: [],
    estadosCiviles: [],
    estudioEstados: [],
    estudioTipos: [],
    familiarAporteObraSocial: [],
    familiarAsignaciones: [],
    familiarTiposVoluntario: [],
    formasPago: [],
    funciones: [],
    gruposSanguineos: [],
    instrumentos: [],
    licenciasConducir: [],
    lugares: [],
    motivos: [],
    motivosBaja: [],
    motivosBajaDefinitiva: [],
    motivosBajaTransitoria: [],
    motivosMovimiento: [],
    nivelesEstudio: [],
    obrasSociales: [],
    paises: [],
    provincias: [],
    regimenesLicencias: [],
    regimenesTrabajo: [],
    relojes: [],
    secretarias: [],
    sexos: [],
    sindicatos: [],
    subdirecciones: [],
    tareas: [],
    tiposEvaluacion: [],
    tiposSancion: [],
    titulos: [],
    tiposContratacion: [],
    vinculos: [],
  });
  const [section, setSection] = useState("personales");

  const breadcrumb = [
    { id: 1, title: "Administración" },
    {
      id: 2,
      title: "Legajos",
      url: "/administracion/legajos",
    },
    {
      id: 3,
      title: state.title,
    },
  ];

  useEffect(() => {
    async function getData() {
      const response = await http.get(`legajos/data`);

      if (response && response.data.code === 0) {
        const data = response.data.data;

        setState({
          ...state,
          visible: true,

          agrupamientos: data.agrupamientos,
          antecedentesEstados: data.antecedentesEstados,
          arts: data.arts,
          asignaciones: data.asignaciones,
          bancos: data.bancos,
          cargosSindicales: data.cargosSindicales,
          clases: data.clases,
          condicionesSindicales: data.condicionesSindicales,
          convenios: data.convenios,
          criterios: data.criterios,
          escolaridad: data.escolaridad,
          departamentos: data.departamentos,
          direcciones: data.direcciones,
          distritos: data.distritos,
          documentos: data.documentos,
          empleados: data.empleados,
          estados: data.estados,
          estadosCiviles: data.estadosCiviles,
          estudioEstados: data.estudioEstados,
          estudioTipos: data.estudioTipos,
          familiarAporteObraSocial: data.familiarAporteObraSocial,
          familiarAsignaciones: data.familiarAsignaciones,
          familiarTiposVoluntario: data.familiarTiposVoluntario,
          formasPago: data.formasPago,
          funciones: data.funciones,
          gruposSanguineos: data.gruposSanguineos,
          instrumentos: data.instrumentos,
          licenciasConducir: data.licenciasConducir,
          lugares: data.lugares,
          motivos: data.motivos,
          motivosBaja: data.motivosBaja,
          motivosBajaDefinitiva: data.motivosBajaDefinitiva,
          motivosBajaTransitoria: data.motivosBajaTransitoria,
          motivosMovimiento: data.motivosMovimiento,
          nivelesEstudio: data.nivelesEstudio,
          obrasSociales: data.obrasSociales,
          paises: data.paises,
          provincias: data.provincias,
          regimenesLicencias: data.regimenesLicencias,
          regimenesTrabajo: data.regimenesTrabajo,
          relojes: data.relojes,
          secretarias: data.secretarias,
          sexos: data.sexos,
          sindicatos: data.sindicatos,
          subdirecciones: data.subdirecciones,
          tareas: data.tareas,
          tiposEvaluacion: data.tiposEvaluacion,
          tiposSancion: data.tiposSancion,
          titulos: data.titulos,
          tiposContratacion: data.tiposContratacion,
          vinculos: data.vinculos,
        });
      }
    }

    getData();
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="legajos-form">
      <Header title={`${state.title} Legajo`} breadcrumb={breadcrumb} />

      <div className="form card">
        <ContextProvider value={[state, setState]}>
          {!state.visible && (
            <div className="loading">
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 40, color: "#04bebe" }}
                    spin
                  />
                }
              />
              Cargando...
            </div>
          )}

          {state.visible && (
            <React.Fragment>
              <LegajoHeader onUpdate={setSection} />
              <div className="section">
                {section === "personales" && !state.editing && (
                  <PersonalesResumen />
                )}
                {section === "personales" && state.editing && <Personales />}
                {section === "laborales" && !state.editing && (
                  <LaboralesResumen />
                )}
                {section === "laborales" && state.editing && <Laborales />}
                {section === "administrativos" && <Administrativos />}
                {section === "estudios" && <Estudios />}
                {section === "antecedentes" && <Antecedentes />}
                {section === "evaluaciones" && <Evaluaciones />}
                {section === "familiares" && !state.editing && (
                  <FamiliaresResumen />
                )}
                {section === "familiares" && state.editing && <Familiares />}
                {section === "liquidaciones" && <Liquidaciones />}
              </div>
            </React.Fragment>
          )}
        </ContextProvider>
      </div>
    </div>
  );
};

export default LegajosForm;
