/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Col, Divider, Row } from "antd";

import { ContextProvider } from "./../services/context";
import http from "./../services/http";

import MenuCard from "../components/MenuCard";

import "react-slidedown/lib/slidedown.css";

const Inicio = () => {
  const [state, setState] = useState({
    favourites: [],
    reload: false,
  });



  useEffect(() => {
    async function getData() {
      const rFavourites = await http.get("menu/favoritos");
      if (rFavourites && rFavourites.data.code === 0) {
        const data = rFavourites.data.data;
        setState({ ...state, favourites: data.list });
      }
    }

    getData();
  }, [state.reload]);



  return (
    <div className="menu">
      <ContextProvider value={[state, setState]}>
        <Divider orientation="left">Mis Favoritos</Divider>
        {state.favourites.length === 0 && (
          <span>Aquí aparecerán tus favoritos</span>
        )}
        <Row gutter={[32, 36]}>
          {state.favourites.map((x, i) => (
            <Col key={i} xs={12} md={8} xl={6} xxl={4}>
              <MenuCard item={x} />
            </Col>
          ))}
        </Row>

      </ContextProvider>
    </div>
  );
};

export default Inicio;
