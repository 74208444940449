import React from "react";
import { Col, Row } from "antd";

import { hasAction, actions } from "./../../../services/security";

import Header from "../../../components/Header";
import MenuCard from "../../../components/MenuCard";

const Liquidacion = () => {
  const items = [
    {
      id: 1,
      titulo: "Concursos / Quiebras",
      path: "/novedades/liquidacion/concursos_quiebras",
      color: "primary",
      icono: "FiTrendingDown",
      entidad: "ConcursoQuiebra",
      visible: hasAction(actions.CONCURSOS_QUIEBRAS_VER),
    },
    {
      id: 2,
      titulo: "Cuotas Alimentarias",
      path: "/novedades/liquidacion/cuotaalimentaria",
      color: "primary",
      icono: "FiAlertTriangle",
      entidad: "CuotaAlimentaria",
      visible: hasAction(actions.CUOTAALIMENTARIA_VER),
    },
    {
      id: 3,
      titulo: "Embargos",
      path: "/novedades/liquidacion/embargo",
      color: "primary",
      icono: "FiAlertTriangle",
      entidad: "Embargo",
      visible: hasAction(actions.EMBARGO_VER),
    },
    {
      id: 4,
      titulo: "Novedades Fijas",
      path: "/novedades/liquidacion/novedadFija",
      color: "primary",
      icono: "FiAlertTriangle",
      entidad: "NovedadFija",
      visible: hasAction(actions.NOVEDADFIJA_VER),
    },
    {
      id: 5,
      titulo: "Novedades Variables",
      path: "/novedades/liquidacion/novedadVariable",
      color: "primary",
      icono: "FiAlertTriangle",
      entidad: "NovedadVariable",
      visible: hasAction(actions.NOVEDADVARIABLE_VER),
    },
    {
      id: 6,
      titulo: "Servicios Extraordinarios",
      path: "/novedades/liquidacion/servicios_extraordinarios",
      color: "primary",
      icono: "FiCalendar",
      entidad: "ServicioExtraordinario",
      visible: hasAction(actions.SERVICIO_EXTRAORDINARIO_VER),
    },
    {
      id: 7,
      titulo: "Importar Novedades",
      path: "/novedades/liquidacion/importar",
      color: "primary",

      icono: "FiDownload",
      visible: hasAction(actions.IMPORTACION_NOVEDADES_VER),
    },
  ];

  const breadcrumb = [
    { id: 1, title: "Novedades" },
    { id: 2, title: "Liquidacion", url: "/novedades/liquidacion" },
  ];

  return (
    <div className="inicio">
      <Header title="Liquidación" breadcrumb={breadcrumb} buttons={() => {}} />
      <Row gutter={[32, 36]}>
        {items.map((i) => (
          <Col key={i.id} xs={12} md={8} xl={6} xxl={4}>
            <MenuCard item={i} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Liquidacion;
