/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Button, Col, DatePicker, Form, Input, Modal, Select, Row } from "antd";
import { FiBookmark, FiPaperclip } from "react-icons/fi";
import moment from "moment";
import locale from "antd/es/date-picker/locale/es_ES";
import "moment/locale/es";

import { ContextProvider } from "../../../../services/context";
import {
  parseDate,
  parseToDate,
  parseYear,
  parseToYear,
} from "../../../../services/forms";
import http from "../../../../services/http";
import { showSuccess } from "../../../../services/notificacion";
import { hasAction, actions } from "../../../../services/security";
import { required } from "./../../../../services/validation";

import Header from "../../../../components/Header";
import Historial from "./Historial";
import Archivos from "./Archivos";

const VacacionesForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const VER = hasAction(actions.LICENCIAS_VACACIONES_VER);
  const CREAR = hasAction(actions.LICENCIAS_VACACIONES_CREAR);
  const EDITAR = hasAction(actions.LICENCIAS_VACACIONES_EDITAR);
  const GUARDAR = match.params.id === "nuevo" ? CREAR : EDITAR;

  const [state, setState] = useState({
    title: match.params.id === "nuevo" ? "Crear" : EDITAR ? "Editar" : "Ver",
    id: match.params.id === "nuevo" ? 0 : parseInt(match.params.id),
    loading: false,
    historial: [],
  });

  const [loaded, setLoaded] = useState(false);

  const [codigos, setCodigos] = useState([]);
  const [conceptos, setConceptos] = useState([]);
  const [empleados, setEmpleados] = useState([]);
  const [estados, setEstados] = useState([]);
  const [instrumentos, setInstrumentos] = useState([]);
  const [tipos, setTipos] = useState([]);

  const [historial, setHistorial] = useState(false);
  const [verEstado, setVerEstado] = useState(false);

  const breadcrumb = [
    { id: 1, title: "Novedades" },
    {
      id: 2,
      title: "Licencias",
      url: "/novedades/licencias",
    },
    {
      id: 3,
      title: "Vacaciones",
      url: "/novedades/licencias/vacaciones",
    },
    { id: 4, title: state.title },
  ];

  useEffect(() => {
    async function getData() {
      let response = await http.get("licenciasvacaciones/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setCodigos(data.codigos);
        setConceptos(data.conceptos);
        setEmpleados(data.empleados);
        setEstados(data.estados);
        setInstrumentos(data.instrumentos);
        setTipos(data.tipos);

        setLoaded(true);
      }
    }

    getData();
  }, []);

  useEffect(() => {
    async function getForm() {
      if (!loaded) {
        return;
      }

      const rForm = await http.get(`licenciasvacaciones/${state.id}`);
      if (rForm && rForm.data.code === 0) {
        const data = rForm.data.data;
        data.fecha = parseToDate(data.fecha);
        data.anio = parseToYear(data.anio);

        if (data.fechaInicioD) {
          data.fechaInicio = moment(data.fechaInicioD);
        }

        if (data.fechaFinD) {
          data.fechaFin = moment(data.fechaFinD);
        }

        if (data.empleado) {
          const item = empleados.find((x) => x.id === data.empleado);
          data.area = item.areaTrabajo;
          data.tarea = item.tarea + item.riesgoso;
          data.domicilio = item.areaTrabajo;
        }

        form.setFieldsValue(data);

        onChangeAnio(data.anio);

        setState({
          ...state,
          historial: data.historial,
        });
        setVerEstado(data.estado !== "-1");
      }
    }

    if (VER) {
      getForm();
    }
  }, [loaded]);

  const onHistoryClick = () => {
    setHistorial(true);
  };

  const onChangeEmpleado = async () => {
    await getSaldo();
  };

  const onChangeAnio = async (value) => {
    const anio = form.getFieldValue("anio");
    let nCodigos = [...codigos];

    for (let i = 0; i < nCodigos.length; i++) {
      nCodigos[i].disabled = false;

      if (nCodigos[i].codigo === "500") {
        nCodigos[i].disabled = anio.year() === moment().year();
      }

      if (nCodigos[i].codigo === "501") {
        nCodigos[i].disabled = anio.year() < moment().year();
      }
    }

    setCodigos(nCodigos);
    await getSaldo();
  };

  const getSaldo = async () => {
    let values = form.getFieldsValue();

    if (values.empleado && values.anio) {
      const empleado = empleados.find((e) => e.id === values.empleado);

      const data = {
        area: empleado.areaTrabajo,
        tarea: empleado.tarea + empleado.riesgoso,
        domicilio: empleado.areaTrabajo,
      };
      form.setFieldsValue(data);
      values.anio = parseYear(values.anio);

      const result = await http.post(
        "licenciasvacaciones/dias/" + state.id,
        values
      );

      if (result && result.data.code === 0) {
        const data = result.data.data;
        form.setFieldsValue({ saldoAnual: data.disponibles });
        form.setFieldsValue({ acumuladoAnual: data.acumulado });
      }
    }
  };

  const onInicioChange = () => {
    let values = form.getFieldsValue();

    if (values.cantidadDiasdias && values.fechaInicio) {
      values.fechaFin = addBusinessDays(
        values.fechaInicio.clone(),
        values.cantidadDias >= 1
          ? values.cantidadDias - 1
          : values.cantidadDias - 0.5
      );
    } else {
      values.fechaFin = null;
    }

    form.setFieldsValue(values);
  };

  const addBusinessDays = (originalDate, numDaysToAdd) => {
    const newDate = originalDate.clone();
    newDate.add(numDaysToAdd, "days");
    return newDate;
  };

  const onDiasChange = () => {
    let values = form.getFieldsValue();
    if (parseInt(values.cantidadDias) < 0) {
      values.cantidadDias = 0;
    }
    if (parseInt(values.cantidadDias) > parseInt(values.saldoAnual)) {
      values.cantidadDias = values.saldoAnual;
    }

    if (values.cantidadDias && values.fechaInicio) {
      values.fechaFin = addBusinessDays(
        values.fechaInicio.clone(),
        values.cantidadDias >= 1
          ? values.cantidadDias - 1
          : values.cantidadDias - 0.5
      );
    } else {
      values.fechaFin = null;
    }

    form.setFieldsValue(values);
  };

  const onCodigoChange = (value) => {
    const item = codigos.find((x) => x.id === parseInt(value));
    const values = {
      concepto: item ? item.conceptoId : "",
    };
    form.setFieldsValue(values);
    if (item && item.llevaEstado === "False") {
      setVerEstado(false);
    } else {
      form.setFieldsValue({ estado: "1" });
      setVerEstado(true);
    }
  };

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    values.anio = parseYear(values.anio);
    values.inicio = parseDate(values.inicio);
    values.fin = parseDate(values.fin);

    const result =
      state.id === 0
        ? await http.post("licenciasvacaciones", values)
        : await http.put(`licenciasvacaciones/${state.id}`, values);

    if (result && result.data.code === 0) {
      showSuccess("La licencia fue guardada exitosamente.");
      history.push("/novedades/licencias/vacaciones");
    } else {
      setState({ ...state, loading: false });
    }
  };

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <ContextProvider value={[state, setState]}>
      <div className="vacaciones">
        <Header
          title={`${state.title} Licencia por Vacaciones`}
          breadcrumb={breadcrumb}
        />
        <div className="form card">
          <Form form={form} layout="vertical" onFinish={onSave}>
            <div className="form-icon">
              <FiBookmark />
            </div>
            <Row gutter={20}>
              <Col span="12">
                <Form.Item label="Empleado" name="empleado" rules={[required]}>
                  <Select
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    onChange={onChangeEmpleado}
                  >
                    {empleados.map((x) => (
                      <Select.Option key={x.id} value={x.id}>
                        {x.nombre}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span="6">
                <Form.Item label="Fecha" name="creacion">
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              <Col span="6">
                <Form.Item label="Año" name="anio" rules={[required]}>
                  <DatePicker
                    picker="year"
                    placeholder=""
                    disabledDate={(current) =>
                      current &&
                      (current < moment().add(-2, "years") ||
                        current > moment())
                    }
                    onChange={onChangeAnio}
                  />
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item label="Área" name="area">
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item label="Tarea" name="tarea">
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item label="Domicilio" name="domicilio">
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item label="Código" name="codigo" rules={[required]}>
                  <Select
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    onChange={onCodigoChange}
                  >
                    {codigos.map((x) => (
                      <Select.Option
                        key={x.id}
                        value={x.id.toString()}
                        disabled={x.disabled}
                      >
                        {x.codigo} - {x.detalle}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item label="Concepto" name="concepto" rules={[required]}>
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    disabled={true}
                  >
                    {conceptos.map((x) => (
                      <Select.Option key={x.value} value={x.value}>
                        {x.text}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="Fecha Inicio"
                  name="fechaInicio"
                  rules={[required]}
                >
                  <DatePicker
                    format="DD/MM/YYYY"
                    placeholder=""
                    locale={locale}
                    disabledDate={(current) =>
                      current && current < moment().add(-3, "months")
                    }
                    onChange={(val) => onInicioChange(val)}
                  />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="Cantidad de días"
                  name="cantidadDias"
                  rules={[required]}
                >
                  <Input type="number" onChange={(val) => onDiasChange(val)} />
                </Form.Item>
              </Col>

              <Col span="12">
                <Form.Item label="Fecha Fin" name="fechaFin">
                  <DatePicker
                    format="DD/MM/YYYY"
                    placeholder=""
                    locale={locale}
                    disabledDate={(current) =>
                      current && current < moment().add(-3, "months")
                    }
                  />
                </Form.Item>
              </Col>
              {verEstado && (
                <Col span={12} className="section-estado">
                  <Form.Item label="Estado" name="estado" rules={[required]}>
                    <Select allowClear showSearch optionFilterProp="children">
                      {estados.map((x) => (
                        <Select.Option key={x.value} value={x.value}>
                          {x.text}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {state.id > 0 && (
                    <React.Fragment>
                      <small
                        className="cursor-pointer"
                        onClick={onHistoryClick}
                      >
                        Ver historial
                      </small>
                      <Modal
                        title="Historial"
                        visible={historial}
                        onCancel={() => setHistorial(false)}
                        footer={null}
                        maskClosable={false}
                        keyboard={false}
                        destroyOnClose={true}
                        width={900}
                      >
                        <Historial onClose={() => setHistorial(false)} />
                      </Modal>
                    </React.Fragment>
                  )}
                </Col>
              )}
              <Col span="12">
                <Form.Item label="Acumulado Anual" name="acumuladoAnual">
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item label="Saldo Anual" name="saldoAnual">
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              <Col span="24">
                <Form.Item label="Observaciones" name="observacion">
                  <Input.TextArea maxLength={500} showCount />
                </Form.Item>
              </Col>

              <Col span="12">
                <Form.Item label="Instrumento" name="instrumento">
                  <Select
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    disabled={!GUARDAR}
                  >
                    {instrumentos.map((x) => (
                      <Select.Option
                        key={x.value}
                        value={x.value}
                        disabled={x.disabled}
                      >
                        {x.text}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item label="Tipo" name="tipo">
                  <Select
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    disabled={!GUARDAR}
                  >
                    {tipos.map((x) => (
                      <Select.Option
                        key={x.value}
                        value={x.value}
                        disabled={x.disabled}
                      >
                        {x.text}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item label="Número" name="instrumentoNumero">
                  <Input disabled={!GUARDAR} />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item label="Parte" name="parte">
                  <Input disabled={!GUARDAR} />
                </Form.Item>
              </Col>
            </Row>

            <div className="form-actions">
              <Link to="/novedades/licencias/vacaciones">
                <Button type="secondary">Volver</Button>
              </Link>
              {GUARDAR && (
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={state.loading}
                >
                  Guardar
                </Button>
              )}
            </div>
          </Form>
          {state.id > 0 && (
            <React.Fragment>
              <hr />
              <div className="section-header">
                <div className="section-title">
                  Documentación
                  <FiPaperclip />
                </div>
                <div className="section-subtitle">
                  Documentación asociada con la licencia.
                </div>
              </div>
              <Archivos licenciaVacaciones={state.id} />
            </React.Fragment>
          )}
        </div>
      </div>
    </ContextProvider>
  );
};

export default VacacionesForm;
