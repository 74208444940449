/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { Button, Form, Menu, Select, DatePicker } from "antd";
import { FiPrinter } from "react-icons/fi";
import fileDownload from "js-file-download";
import locale from "antd/es/date-picker/locale/es_ES";
import "moment/locale/es";

import { ContextProvider } from "../../services/context";
import http from "../../services/http";
import { hasAction, actions } from "../../services/security";

import Filters from "../../components/Filters";
import FiltersButton from "../../components/FiltersButton";
import Header from "../../components/Header";
import Table from "../../components/Table";

const Novedades = () => {
  const VER = hasAction(actions.NOVEDADES_VER);

  const [state, setState] = useState({
    filters: {
      empleado: "",
      concepto: "",
      periodo: "",
      tipo: "",
      forzado: "",
      desde: "",
      hasta: "",
    },
    columns: [
      {
        id: 1,
        title: "Empleado",
        style: {},
        render: (item) => item.empleado,
      },
      {
        id: 3,
        title: "Fecha",
        style: { textAlign: "center" },
        render: (item) => item.fecha,
      },
      {
        id: 2,
        title: "Tipo",
        style: {},
        render: (item) => item.tipo,
      },
      {
        id: 4,
        title: "Concepto",
        render: (item) => item.concepto,
      },
      {
        id: 5,
        title: "Forzado",
        style: { textAlign: "center" },
        render: (item) => item.forzado,
      },
    ],
    loading: true,
    loaded: false,
    reload: true,
    list: [],
    selected: [],
    total: 0,
    page: 1,
    size: 10,
  });

  const [conceptos, setConceptos] = useState([]);
  const [empleados, setEmpleados] = useState([]);
  const [tipos, setTipos] = useState([]);

  const breadcrumb = [{ id: 1, title: "Novedades", url: "/novedades" }];

  const buttons = () => {
    return (
      <React.Fragment>
        <Button type="secondary" icon={<FiPrinter />} onClick={onExport}>
          Exportar
        </Button>
        <FiltersButton />
      </React.Fragment>
    );
  };

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Empleado" name="empleado">
          <Select showSearch allowClear optionFilterProp="children">
            {empleados.map((item) => (
              <Select.Option key={item.value} value={item.value}>
                {item.text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Concepto" name="concepto">
          <Select showSearch allowClear optionFilterProp="children">
            {conceptos.map((item) => (
              <Select.Option key={item.value} value={item.value}>
                {item.text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Periodo" name="periodo">
          <DatePicker
            format="MM/YYYY"
            placeholder=""
            picker="month"
            locale={locale}
          />
        </Form.Item>

        <Form.Item label="Tipo" name="tipo">
          <Select showSearch allowClear optionFilterProp="children">
            {tipos.map((item) => (
              <Select.Option key={item.value} value={item.value}>
                {item.text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Forzado" name="forzado">
          <Select allowClear>
            <Select.Option value="1">Si</Select.Option>
            <Select.Option value="0">No</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label="Desde" name="desde">
          <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
        </Form.Item>

        <Form.Item label="Hasta" name="hasta">
          <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
        </Form.Item>
      </React.Fragment>
    );
  };

  const menu = (item) => {
    return <Menu className="table-actions-menu"></Menu>;
  };

  const onExport = async () => {
    const response = await http.post("novedades/export", state.filters, true);
    fileDownload(response.data, `Novedades_${Date.now()}.xlsx`);
  };

  useEffect(() => {
    async function getData() {
      const response = await http.get("novedades/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setConceptos(data.conceptos);
        setEmpleados(data.empleados);
        setTipos(data.tipos);
      }
    }

    getData();
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="novedades page">
      <ContextProvider value={[state, setState]}>
        <Header title="Novedades" breadcrumb={breadcrumb} buttons={buttons} />
        <Filters filters={filters} />
        <Table url="novedades/list" menu={menu} checkbox />
      </ContextProvider>
    </div>
  );
};

export default Novedades;
