import "./Historial.scss";

const EmbargosHistorial = ({historial}) => {

  return (
    <div className="embargos-historial">
      <table className="">
        <thead>
          <tr>
            <th>Fecha</th>
            <th>Movimiento</th>
            <th>Monto Acumulado</th>
            <th>Saldo</th>
          </tr>
        </thead>
        <tbody>
          {historial && historial.map((x) => (
            <tr key={x.id}>
              <td>{x.fecha}</td>
              <td>{x.movimiento}</td>
              <td>{x.montoAcumulado}</td>
              <td>{x.saldo}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EmbargosHistorial;
