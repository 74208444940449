/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import locale from "antd/es/date-picker/locale/es_ES";
import "moment/locale/es";

import { Context } from "./../../../services/context";
import { parseDate, parseToDate } from "./../../../services/forms";
import http from "./../../../services/http";
import { showSuccess } from "./../../../services/notificacion";
import { required } from "./../../../services/validation";

const { Option } = Select;

const AsignacionesForm = ({ current, onCurrentChange }) => {
  const [form] = Form.useForm();
  const [state, setState] = useContext(Context);

  const onBack = () => {
    onCurrentChange(null, null);
  };

  const onChangeAsignacion = async (current) => {
    const rGet = await http.get(`legajos/asignacion/${current}`);
    if (rGet && rGet.data.code === 0) {
      const data = rGet.data.data.data;
      form.setFieldsValue({titulo: data.titulo, adicional: data.adicional});
    }
  };


  const onSave = async (values) => {
    setState({ ...state, loading: true });

    values.empleadoId = state.id;
    values.fecha = parseDate(values.fecha);
    values.fechaActo = parseDate(values.fechaActo);

    const rSave =
      current === 0
        ? await http.post("legajos/asignaciones", values)
        : await http.put(`legajos/asignaciones/${current}`, values);

    if (rSave && rSave.data.code === 0) {
      showSuccess("La asignación fue guardada exitosamente.");
      setState({ ...state, loading: false });
      onCurrentChange(null, null);
    } else {
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {
    async function getForm() {
      form.resetFields();

      const rForm = await http.get(`legajos/asignaciones/${current}`);
      if (rForm && rForm.data.code === 0) {
        const data = rForm.data.data;
        data.form.fecha = parseToDate(data.form.fecha);
        data.form.fechaActo = parseToDate(data.form.fechaActo);
        form.setFieldsValue(data.form);
      }
    }

    getForm();
  }, [state.id, state.reload]);

  return (
    <div className="asignaciones-form ">
      <Form
        form={form}
        className="subsection-form"
        layout="vertical"
        onFinish={onSave}
      >
        <Row gutter={20}>
          <Col span="24">
            <Form.Item
              label="Asignación"
              name="asignacionPorTitulo"
              rules={[required]}
            >
              <Select allowClear showSearch optionFilterProp="children" onChange={onChangeAsignacion}>
                {state.asignaciones.map((x) => (
                  <Option key={x.value} value={x.value} disabled={x.disabled}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span="8">
            <Form.Item label="Título (%)" name="titulo" >
              <Input type="number" disabled={true} />
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item label="Adicional (%)" name="adicional" >
              <Input type="number" disabled={true} />
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item label="Fecha" name="fecha">
              <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span="12">
            <Form.Item label="Instrumento" name="instrumento">
              <Select allowClear showSearch optionFilterProp="children">
                {state.instrumentos.map((x) => (
                  <Option key={x.value} value={x.value}>
                    {x.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item label="Número" name="instrumentoNumero">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span="12">
            <Form.Item label="Fecha Acto Administrativo" name="fechaActo">
              <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item label="Expediente" name="expediente">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Autoriza" name="autorizante">
          <Select allowClear showSearch optionFilterProp="children">
            {state.empleados.map((x) => (
              <Option key={x.value} value={x.value} disabled={x.disabled}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <div className="form-actions">
          <Button type="secondary" onClick={onBack}>
            Volver
          </Button>
          <Button type="primary" htmlType="submit" loading={state.loading}>
            Guardar
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AsignacionesForm;
