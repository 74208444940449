/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { FiBook, FiLayers } from "react-icons/fi";

import EstudiosList from "./EstudiosList";
import EstudiosForm from "./EstudiosForm";
import AsignacionesList from "./AsignacionesList";
import AsignacionesForm from "./AsignacionesForm";

const Estudios = () => {
  const [section, setSection] = useState(null);
  const [current, setCurrent] = useState(null);

  const onCurrentChange = (section, current) => {
    setSection(section);
    setCurrent(current);
  };

  return (
    <div className="estudios">
      {(section === null || section === "estudios") && (
        <div className="section-header">
          <div className="section-title">
            Estudios y Formación
            <FiBook />
          </div>
          <div className="section-subtitle">
            Lista de estudios y capacitaciones del empleado
          </div>
        </div>
      )}
      {section === null && current === null && (
        <EstudiosList onCurrentChange={onCurrentChange} />
      )}
      {section === "estudios" && current !== null && (
        <EstudiosForm current={current} onCurrentChange={onCurrentChange} />
      )}

      {(section === null || section === "asignaciones") && (
        <div className="section-header">
          <div className="section-title">
            Asignaciones por Títulos
            <FiLayers />
          </div>
          <div className="section-subtitle">
            Lista de asignaciones correspondientes al empleado.
          </div>
        </div>
      )}
      {section === null && current === null && (
        <AsignacionesList onCurrentChange={onCurrentChange} />
      )}
      {section === "asignaciones" && current !== null && (
        <AsignacionesForm current={current} onCurrentChange={onCurrentChange} />
      )}
    </div>
  );
};

export default Estudios;
