/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { Button, Drawer, Form } from "antd";
import { Scrollbars } from "react-custom-scrollbars";

import { Context } from "../../../services/context";

import "./Periodo.scss";

const Periodo = ({ filters, buttonName, title, actionSave, validator }) => {
  const [form] = Form.useForm();
  const [state, setState] = useContext(Context);

  const onSave = () => {
    const values = form.getFieldsValue();
    if (validator) {
      if (!validator(values)) {
        return;
      }
    }

    let nState = { ...state };
    for (const property in values) {
      if (typeof values[property] === "object") {
        if (!Array.isArray(values[property])) {
          values[property] = values[property]?.format("DD/MM/YYYY");
        }
      }

      nState.filters[property] =
        values[property] === undefined ? "" : values[property].toString();
    }
    nState.filters.visible = false;

    setState(nState);
  };

  if (!actionSave) {
    actionSave = onSave;
  }

  if (!buttonName) {
    buttonName = "Buscar";
  }

  if (!title) {
    title = "Filtros";
  }

  const close = () => {
    let nState = { ...state };
    nState.filters.visible = false;
    setState(nState);
  };

  const reset = () => {
    form.resetFields();
  };

  const onEnter = (e) => {
    if (e.key === "Enter") {
      actionSave();
    }
  };

  useEffect(() => {
    form.setFieldsValue(state.filters);
  }, []);

  return (
    <Drawer
      className="periodo"
      title={title}
      placement="right"
      onClose={close}
      visible={state.filters.visible}
    >
      <Form
        form={form}
        name="form-periodo"
        layout="vertical"
        onKeyPress={onEnter}
      >
        <Scrollbars
          className="sidebar-scroll"
          style={{ width: "256px", height: "100%" }}
          autoHide
        >
          <div className="periodo-content">{filters && filters()}</div>
        </Scrollbars>

        <div className="periodo-actions">
          <Button type="secondary" onClick={reset}>
            Limpiar
          </Button>
          <Button type="primary" onClick={actionSave}>
            {buttonName}
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};

export default Periodo;
