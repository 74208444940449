/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Button, Dropdown, Menu, Modal } from "antd";
import { FiEdit, FiMoreVertical, FiTrash2 } from "react-icons/fi";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { Context } from "./../../../services/context";
import http from "./../../../services/http";
import { showSuccess } from "./../../../services/notificacion";
import { hasAction, actions } from "./../../../services/security";

const AsignacionesList = ({ onCurrentChange }) => {
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useContext(Context);

  const CREAR = state.editing && hasAction(actions.LEGAJOS_ESTUDIOS_CREAR);
  const EDITAR = state.editing && hasAction(actions.LEGAJOS_ESTUDIOS_EDITAR);
  const ELIMINAR =
    state.editing && hasAction(actions.LEGAJOS_ESTUDIOS_ELIMINAR);

  const [list, setList] = useState([]);

  const menu = (item) => {
    return (
      <Menu className="table-actions-menu">
        {EDITAR && (
          <Menu.Item key="0" onClick={() => onEdit(item)}>
            <FiEdit />
            Editar
          </Menu.Item>
        )}
        {ELIMINAR && (
          <Menu.Item key="1" onClick={() => onDelete(item)}>
            <FiTrash2 />
            Eliminar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const onAdd = () => {
    onCurrentChange("asignaciones", 0);
  };

  const onEdit = (item) => {
    onCurrentChange("asignaciones", item.id);
  };

  const onDelete = (item) => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea eliminar esta asignación?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const rDelete = await http.delete(`legajos/asignaciones/${item.id}`);

        if (rDelete && rDelete.data.code === 0) {
          showSuccess("La asignación se elimino exitosamente.");
        }
      },
    });
  };

  useEffect(() => {
    async function getList() {
      const rList = await http.get(
        `legajos/asignaciones/list?empleado=${state.id}`
      );

      if (rList && rList.data.code === 0) {
        const data = rList.data.data;
        setList(data);
      }
    }

    getList();
  }, [state.reload]);

  return (
    <div className="asignaciones-list">
      <div className="table-wrapper">
        <table className="subsection-table">
          <thead>
            <tr>
              <th></th>
              <th>Detalle</th>
              <th style={{ textAlign: "center", minWidth: "120px" }}>
                Título (%)
              </th>
              <th style={{ textAlign: "center", minWidth: "120px" }}>
                Adicional (%)
              </th>
              <th style={{ textAlign: "center", minWidth: "120px" }}>Fecha</th>
              <th>Autoriza</th>
            </tr>
          </thead>
          <tbody>
            {list.length === 0 && (
              <tr>
                <td className="table-empty" colSpan="9">
                  Sin resultados
                </td>
              </tr>
            )}

            {list.map((x) => (
              <tr key={x.id}>
                <td className="table-actions">
                  <Dropdown
                    key={x.id}
                    overlay={() => menu(x)}
                    trigger={["click"]}
                    placement="bottomLeft"
                    arrow
                  >
                    <div>
                      <FiMoreVertical />
                    </div>
                  </Dropdown>
                </td>
                <td>{x.asignacionPorTitulo}</td>
                <td style={{ textAlign: "center" }}>{x.titulo}</td>
                <td style={{ textAlign: "center" }}>{x.adicional}</td>
                <td style={{ textAlign: "center" }}>{x.fecha}</td>
                <td>{x.autorizante}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {CREAR && (
        <div className="table-footer-actions">
          <Button type="primary" onClick={onAdd}>
            Agregar
          </Button>
        </div>
      )}
    </div>
  );
};

export default AsignacionesList;
